import React from 'react';
import './DeleteUserImgPopup.scss';
import { Avatar, Button, IconButton, Modal } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const DeleteUserImgPopup = ({
  isOpen,
  handlClose,
  img,
  handleRemove,
  isLoading,
}) => {
  return (
    <Modal open={isOpen} onClose={handlClose}>
      <div className="delete-userimg-box">
        <div className="upper">
          <p>Do you want to remove your image?</p>
          <IconButton
            style={{ width: '40px', height: '40px', fontSize: '40px' }}
            onClick={handlClose}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </div>
        <Avatar
          alt="Remy Sharp"
          src={img}
          className="user-img"
          style={{ width: '130px', height: '130px', opacity: '0.6' }}
        />
        <div className="bttns-div">
          <Button
            className="delete-bttn"
            onClick={handleRemove}
            disabled={isLoading}
          >
            Remove
          </Button>
          <Button className="cancel-bttn" onClick={handlClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteUserImgPopup;
