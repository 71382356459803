import React from 'react';
import './MinorAliments.scss';
import DashboardNavigation from '../../../components/DashboardComponents/DashboardNavigation';
import {
  Button,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ['Pfizer', 'Moderna', 'Shingles', 'Pneumonia', 'Pneumococcal'];
const assessmentsNames = [
  'Acne',
  'Allergic Rhinitis',
  'Aphthous Ulcers (canker sores)',
  'Candidal stomatitis (oral thrush)',
  'Dermatitis (atopic, eczema, allergic and contact)',
];

const MinorAliments = () => {
  const [vaccineName, setVaccineName] = React.useState([]);
  const [assessName, setAssessname] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setVaccineName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const handleAssessmentChange = (event) => {
    const {
      target: { value },
    } = event;
    setAssessname(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <div className="minorAliments">
      <div className="main-conatiner">
        <div className="wrapper">
          <DashboardNavigation />
          <div className="right-div-minors">
            <div className="minor-services">
              <div className="top-div">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <path
                    d="M22.5 2.5L27.5 7.5"
                    stroke="black"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.25 8.75L25 5"
                    stroke="black"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M23.75 11.25L10.875 24.125C9.625 25.375 7.75 25.375 6.625 24.125L5.875 23.375C4.625 22.125 4.625 20.25 5.875 19.125L18.75 6.25"
                    stroke="black"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.25 13.75L16.25 18.75"
                    stroke="black"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.25 23.75L2.5 27.5"
                    stroke="black"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17.5 5L25 12.5"
                    stroke="black"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p>Minor Aliments & Vaccinations Services</p>
              </div>
              <div className="services-boxes-wrapper">
                <div className="box">
                  <div className="top">
                    <p>PCR Test</p>
                    <span>
                      Stay protected against evolving COVID-19 variants with our
                      tailored vaccine
                    </span>
                  </div>
                  <Button
                    className="book-bttn"
                    as="a"
                    href="https://citypharmacy.medmeapp.ca/schedule/3759"
                    target="_blank"
                  >
                    Book PCR test Now
                  </Button>
                </div>
                <div className="box">
                  <div className="top">
                    <p>Book Vaccine</p>
                    <span>
                      Book your vaccines hassle-free with us! Whether it's
                      COVID-19, flu, or other essential vacciness
                    </span>
                  </div>
                  <div className="vacine-selection-div">
                    <div className="select-vac-div">
                      <FormControl sx={{ m: 1, width: '100%' }}>
                        <Select
                          style={{
                            textAlign: 'left',
                            maxWidth: '225px',
                            overflow: 'hidden',
                          }}
                          multiple
                          displayEmpty
                          value={vaccineName}
                          onChange={handleChange}
                          renderValue={(vaccineName) => {
                            if (vaccineName.length === 0) {
                              return (
                                <span className="select-label">
                                  Select Type of Vaccine
                                </span>
                              );
                            }

                            return vaccineName.join(', ');
                          }}
                          MenuProps={MenuProps}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem disabled value="">
                            <em>Select Type of Vaccine</em>
                          </MenuItem>
                          {names.map((name) => (
                            <MenuItem
                              sx={{
                                '&.Mui-selected': {
                                  backgroundColor: '#68D49145',
                                  color: '#333333s',
                                  '&:hover': {
                                    backgroundColor: '#68D49145',
                                    color: '#333333s',
                                  },
                                },
                              }}
                              key={name}
                              value={name}
                            >
                              <Checkbox
                                sx={{
                                  '&.Mui-checked': {
                                    color: '#219D50',
                                  },
                                }}
                                checked={vaccineName.indexOf(name) > -1}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <Button
                      className={`book-vac ${
                        vaccineName.length === 0 ? 'disabled' : ''
                      }`}
                      as="a"
                      href="https://citypharmacy.medmeapp.ca/schedule"
                      target="_blank"
                    >
                      Book Vaccine
                    </Button>
                  </div>
                  <Button
                    className="book-vac-mobile"
                    disabled={vaccineName.length === 0}
                  >
                    Book Vaccine
                  </Button>
                </div>
              </div>
              <div className="minor-assessment">
                <div className="left-text-div">
                  <p>Minor Aliment Assessment</p>
                  <span>
                    Efficiently and accurately assess a wide range of minor
                    ailments, providing quick and reliable evaluations to help
                    you make informed healthcare decisions.
                  </span>
                </div>
                <div className="right-assessment-select">
                  <div className="select-vac-div">
                    <FormControl sx={{ m: 1, width: '100%' }}>
                      <Select
                        style={{
                          textAlign: 'left',
                          maxWidth: '280px',
                          overflow: 'hidden',
                        }}
                        multiple
                        displayEmpty
                        value={assessName}
                        onChange={handleAssessmentChange}
                        renderValue={(assessName) => {
                          if (assessName.length === 0) {
                            return (
                              <span className="select-label">
                                Select Type of Minor Aliment Assessment
                              </span>
                            );
                          }

                          return assessName.join(', ');
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem disabled value="">
                          <em>Select Type of Minor Aliment Assessment</em>
                        </MenuItem>
                        {assessmentsNames.map((name) => (
                          <MenuItem
                            sx={{
                              '&.Mui-selected': {
                                backgroundColor: '#68D49145',
                                color: '#333333s',
                                '&:hover': {
                                  backgroundColor: '#68D49145',
                                  color: '#333333s',
                                },
                              },
                            }}
                            key={name}
                            value={name}
                          >
                            <Checkbox
                              sx={{
                                '&.Mui-checked': {
                                  color: '#219D50',
                                },
                              }}
                              checked={assessName.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <Button
                    className={`book-vac ${
                      assessName.length === 0 ? 'disabled' : ''
                    }`}
                    as="a"
                    href="https://citypharmacy.medmeapp.ca/schedule/groups/minorAilments"
                    target="_blank"
                  >
                    Book Minor Aliment
                  </Button>
                </div>
                <Button
                  className="book-vac-mobile"
                  disabled={assessName.length === 0}
                >
                  Book Minor Aliment
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MinorAliments;
