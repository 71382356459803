import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { publicRequest, userRequest } from '../utils/ApiConfig';
import { clearCart } from './CartSlice';
import { handleAutoLogout } from '../utils/callBack';
import { Evalon } from '../utils/Evalon';

export const getAllOrdersByUserId = createAsyncThunk(
  'order/getOrderByuserId',
  async ({ magentoUserId }, { dispatch }) => {
    try {
      const orders = await userRequest.get(`/orders/${magentoUserId}`);
      return orders.data;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching all products');
      }
    }
  }
);

export const getOrderById = createAsyncThunk(
  'order/getOrderById',
  async ({ magentoUserId, orderId }, { dispatch }) => {
    try {
      const order = await userRequest.get(
        `/orders/${orderId}/user/${magentoUserId}`
      );
      return order.data;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching order');
      }
    }
  }
);

export const createOrder = createAsyncThunk(
  'order/createOrder',
  async (
    {
      cartId,
      cartItems,
      magentoUserId,
      shipping_address,
      billing_address,
      grand_total,
      customer_email,
      customer_firstname,
      customer_lastname,
      telephone,
      payment_info,
      shipping_name,
      shipping_amount,
      tax_amount,
      additional_data,
      order_status,
      save_card,
    },
    { dispatch }
  ) => {
    try {
      let apiRequest;
      let customerInfo = {};
      let urlEndpoint = '';
      if (magentoUserId !== 0) {
        customerInfo = {
          customer_email: customer_email,
          customer_firstname: customer_firstname,
          customer_lastname: customer_lastname,
        };
        urlEndpoint = `/orders/${magentoUserId}`;
        apiRequest = userRequest;
      } else {
        customerInfo = {
          customer_email: customer_email,
          customer_firstname: customer_firstname,
          customer_lastname: customer_lastname,
          telephone: telephone,
        };
        urlEndpoint = '/guest/orders';
        apiRequest = publicRequest;
      }
      const res = await apiRequest.post(urlEndpoint, {
        cartId: cartId,
        cartItems: cartItems,
        shipping_address: shipping_address,
        billing_address: billing_address,
        grand_total: grand_total,
        payment_info: payment_info,
        shipping_name: shipping_name,
        shipping_amount: shipping_amount,
        tax_amount: tax_amount,
        additional_data: additional_data,
        ...customerInfo,
        order_status: order_status,
      });
      const order = res.data;
      if (order.status === 'processing') {
        dispatch(clearCart());
      } else {
        dispatch(
          getPaymentToken({
            userId: magentoUserId,
            totalAmount: order.grand_total,
            firstname: order.firstname,
            lastname: order.lastname,
            orderId: order.entity_id,
            isSaleWithToken: magentoUserId !== 0 && save_card,
          })
        );
      }
      return order;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching order');
      }
    }
  }
);
export const updateOrder = createAsyncThunk(
  'order/updateOrder',
  async (
    {
      orderId,
      paymentId,
      cartId,
      cartItems,
      magentoUserId,
      shipping_address,
      billing_address,
      grand_total,
      customer_email,
      customer_firstname,
      customer_lastname,
      telephone,
      payment_info,
      shipping_name,
      shipping_amount,
      tax_amount,
      additional_data,
    },
    { dispatch }
  ) => {
    try {
      let apiRequest;
      let customerInfo = {};
      let urlEndpoint = '';
      if (magentoUserId !== 0) {
        customerInfo = {
          customer_email: customer_email,
          customer_firstname: customer_firstname,
          customer_lastname: customer_lastname,
        };
        urlEndpoint = `/orders/${orderId}/user/${magentoUserId}`;
        apiRequest = userRequest;
      } else {
        customerInfo = {
          customer_email: customer_email,
          customer_firstname: customer_firstname,
          customer_lastname: customer_lastname,
          telephone: telephone,
        };
        urlEndpoint = `/guest/orders/${orderId}`;
        apiRequest = publicRequest;
      }

      const res = await apiRequest.put(urlEndpoint, {
        cartId: cartId,
        cartItems: cartItems,
        shipping_address: shipping_address,
        billing_address: billing_address,
        grand_total: grand_total,
        payment_info: { ...payment_info, entity_id: paymentId },
        shipping_name: shipping_name,
        shipping_amount: shipping_amount,
        tax_amount: tax_amount,
        additional_data: additional_data,
        ...customerInfo,
      });
      const order = res.data;
      dispatch(clearCart());
      return order;
    } catch (err) {
      // console.log(err);
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching order');
      }
    }
  }
);

export const getPaymentToken = createAsyncThunk(
  'order/getPaymentToken',
  async ({
    userId,
    totalAmount,
    firstname,
    lastname,
    orderId,
    isSaleWithToken,
  }) => {
    try {
      const evalonPaymentToken = await Evalon.getPaymentToken(
        totalAmount.toString(),
        firstname,
        lastname,
        orderId,
        '',
        userId !== 0
          ? isSaleWithToken
            ? 'saleAndGetCardToken'
            : 'processToken'
          : ''
      );
      return evalonPaymentToken;
    } catch (err) {
      return false;
    }
  }
);

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    isLoading: false,
    isOrderProcessing: false,
    paymentToken: null,
    error: {
      status: false,
      message: null,
    },
    ordersList: [],
    hasOrder: null,
    orderNumber: null,
    shippingMethods: [],
    paymentMethods: [],
    count: 0,
    singleOrder: null,
  },
  reducers: {
    resetPaymentToken: (state) => {
      state.paymentToken = null;
    },
    resetOrder: (state) => {
      state.orderNumber = null;
      state.shippingMethods = [];
    },
    clearShippingMethods: (state) => {
      state.shippingMethods = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllOrdersByUserId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllOrdersByUserId.fulfilled, (state, action) => {
        state.isLoading = false;
        const orderItems = action.payload.items;
        const AllOrders = orderItems
          .filter((order) => order.status !== 'pending payment')
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        state.ordersList = AllOrders;
        state.count = AllOrders.length;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getAllOrdersByUserId.rejected, (state) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: 'Error getting orders data',
        };
      })
      .addCase(getOrderById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrderById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.singleOrder = action.payload;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getOrderById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: 'failed loading order',
        };
      })
      .addCase(createOrder.pending, (state) => {
        state.isOrderProcessing = true;
        state.isLoading = true;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.isOrderProcessing = false;
        state.isLoading = false;
        const order = action.payload;
        if (order.status === 'processing') {
          state.orderNumber = action.payload.entity_id;
          state.hasOrder = null;
        } else {
          state.hasOrder = action.payload;
        }
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(createOrder.rejected, (state) => {
        state.isOrderProcessing = false;
        state.isLoading = false;
        state.error = {
          status: true,
          message: 'Unable creating new order',
        };
      })
      .addCase(updateOrder.pending, (state) => {
        state.isOrderProcessing = true;
        state.isLoading = true;
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.isOrderProcessing = false;
        state.isLoading = false;
        state.hasOrder = null;
        state.orderNumber = action.payload.entity_id;
      })
      .addCase(updateOrder.rejected, (state) => {
        state.isOrderProcessing = false;
        state.isLoading = false;
        state.error = {
          status: true,
          message: 'Unable updating order',
        };
      })
      .addCase(getPaymentToken.fulfilled, (state, action) => {
        state.paymentToken = action.payload;
      });
  },
});
export const { resetOrder, clearShippingMethods, resetPaymentToken } =
  orderSlice.actions;
export const orderReducer = orderSlice.reducer;
