import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { userRequest } from '../utils/ApiConfig';
import { handleAutoLogout } from '../utils/callBack';

export const getAllPrescriptions = createAsyncThunk(
  'prescription/getall',
  async ({ userId }, { dispatch }) => {
    try {
      const response = await userRequest.get(
        `/customer/${userId}/prescription`
      );
      return response.data;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed adding new address');
      }
    }
  }
);

export const submitNewPrescription = createAsyncThunk(
  'prescription/createNewPrescription',
  async ({ userId, prescData, message }, { dispatch }) => {
    try {
      const response = await userRequest.post(
        `/customer/${userId}/prescription`,
        {
          prescData: prescData,
          message: message,
        }
      );
      return response.data;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed adding new address');
      }
    }
  }
);

export const getPrescriptionById = createAsyncThunk(
  'prescription/getPrescriptionById',
  async ({ userId, prescriptionId }, { dispatch }) => {
    try {
      const response = await userRequest.get(
        `/customer/${userId}/prescription/${prescriptionId}`
      );
      return response.data;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed adding new address');
      }
    }
  }
);

export const updatePrescriptionOrder = createAsyncThunk(
  'prescription/submitOrder',
  async ({ userId, prescriptionId, order }, { dispatch }) => {
    try {
      const response = await userRequest.put(
        `/customer/${userId}/prescription/${prescriptionId}/order`,
        order
      );
      return response.data;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed adding new address');
      }
    }
  }
);

const PrescriptionSlice = createSlice({
  name: 'prescription',
  initialState: {
    list: [],
    prescription: null,
    isLoading: false,
    error: {
      status: false,
      message: null,
    },
    submittedId: [],
    orderSuccess: false,
  },
  reducers: {
    resetPresc: (state) => {
      state.orderSuccess = false;
      state.isLoading = false;
      state.error = {
        status: false,
        message: null,
      };
    },
    resetSavedPresc: (state) => {
      state.orderSuccess = false;
      state.prescription = null;
      state.submittedId = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPrescriptions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPrescriptions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = action.payload;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getAllPrescriptions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Failed loading prescription',
        };
      })
      .addCase(submitNewPrescription.pending, (state) => {
        state.submittedId = [];
        state.isLoading = true;
      })
      .addCase(submitNewPrescription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.submittedId.push(action.payload._id);
        state.list.unshift(action.payload);
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(submitNewPrescription.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Failed submitting prescription',
        };
      })
      .addCase(getPrescriptionById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPrescriptionById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.prescription = action.payload;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getPrescriptionById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'unable fetching prescription data',
        };
      })
      .addCase(updatePrescriptionOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePrescriptionOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.orderSuccess = true;
        state.prescription.order = action.payload;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(updatePrescriptionOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'unable fetching prescription data',
        };
      });
  },
});
export const { resetPresc, resetSavedPresc } = PrescriptionSlice.actions;
export const prescriptionReducer = PrescriptionSlice.reducer;
