import React, { useEffect, useState } from 'react';
import './SingleOrder.scss';
import DashboardNavigation from '../../../components/DashboardComponents/DashboardNavigation';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Alert, Button, CircularProgress, Divider } from '@mui/material';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import paymentMethod from '../../../assets/visapayment.png';
import DashboardTable from '../../../components/DashboardComponents/DashboardTable';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReorderModal from '../../../components/DashboardComponents/ReorderModal';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderById } from '../../../redux/OrderSlice';
import { formatDate } from '../../../utils/callBack';

const SingleOrder = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [shippingAddress, setShippingAddress] = useState({});
  const user = useSelector((state) => state.user.currentUser);
  const { singleOrder, isLoading, error } = useSelector((state) => state.order);
  const [shippingData, setShippingData] = useState([]);
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const handleReorderClick = () => {
    // after api fetching will set open true
    setOpen(true);
  };

  useEffect(() => {
    if (orderId) {
      dispatch(
        getOrderById({
          magentoUserId: user.refrence_id,
          orderId,
        })
      );
    }
  }, [orderId]);

  useEffect(() => {
    if (singleOrder) {
      if (singleOrder.shipping_description !== 'Pick-up') {
        const shippingdata = singleOrder.shipping_description.split(',');
        setShippingData(shippingdata);
      }
    }
  }, [singleOrder]);

  useEffect(() => {
    if (singleOrder) {
      const shippingInfo =
        singleOrder.extension_attributes.shipping_assignments[0];
      setShippingAddress(shippingInfo.shipping);
    }
  }, [singleOrder]);

  return (
    <div className="singleOrder">
      <ReorderModal
        setOpen={setOpen}
        open={open}
        items={singleOrder?.items}
        orderId={singleOrder?.increment_id}
      />
      <div className="main-conatiner">
        <div className="wrapper">
          <DashboardNavigation />
          {!isLoading && !error.status && singleOrder ? (
            <div className="right-div-single-order">
              <Button className="back-bttn" onClick={() => navigate(-1)}>
                <ArrowBackIcon />
                Back
              </Button>
              <div className="order-title-div">
                <div className="left">
                  <div className="top">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27"
                      height="26"
                      viewBox="0 0 27 26"
                      fill="none"
                    >
                      <path
                        d="M18.5625 10.1835L8.4375 4.56104"
                        stroke="#1E1E1E"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M23.625 17.3334V8.66672C23.6246 8.28677 23.5204 7.9136 23.323 7.58465C23.1255 7.2557 22.8417 6.98253 22.5 6.79256L14.625 2.45922C14.283 2.26906 13.895 2.16895 13.5 2.16895C13.105 2.16895 12.717 2.26906 12.375 2.45922L4.5 6.79256C4.1583 6.98253 3.87448 7.2557 3.67702 7.58465C3.47956 7.9136 3.3754 8.28677 3.375 8.66672V17.3334C3.3754 17.7133 3.47956 18.0865 3.67702 18.4155C3.87448 18.7444 4.1583 19.0176 4.5 19.2076L12.375 23.5409C12.717 23.7311 13.105 23.8312 13.5 23.8312C13.895 23.8312 14.283 23.7311 14.625 23.5409L22.5 19.2076C22.8417 19.0176 23.1255 18.7444 23.323 18.4155C23.5204 18.0865 23.6246 17.7133 23.625 17.3334Z"
                        stroke="#1E1E1E"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.70117 7.5835L13.4999 13.0002L23.2987 7.5835"
                        stroke="#1E1E1E"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.5 23.8333V13"
                        stroke="#1E1E1E"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p className="order-title">Order Details</p>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      className="divider"
                    />
                    <p className="order-number">
                      Order number: #{singleOrder?.increment_id}
                    </p>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      className="divider"
                    />
                    <p className="order-date">
                      Date: {formatDate(singleOrder?.created_at)}
                    </p>
                  </div>
                  <div className="bottom-order-status">
                    <LocalShippingOutlinedIcon />
                    {singleOrder?.status}
                  </div>
                </div>
                <Button className="reorder-bttn" onClick={handleReorderClick}>
                  Reorder
                </Button>
              </div>
              <div style={{ display: 'flex', gap: '12px' }}>
                <p className="order-number-mobile">
                  Order number: #{singleOrder?.increment_id}
                </p>
                <Divider orientation="vertical" flexItem />

                <p className="order-date-mobile">
                  Date: {formatDate(singleOrder?.created_at)}
                </p>
              </div>
              <div className="all-order-info">
                <div className="left-box">
                  <div className="address-div">
                    <p>Shipping Address</p>
                    <p>
                      {shippingAddress?.address?.street[0]},{' '}
                      {shippingAddress?.address?.city},{' '}
                      {shippingAddress?.address?.country_id},{' '}
                      {shippingAddress?.address?.postcode},{' '}
                      {shippingAddress?.address?.region}
                    </p>
                  </div>
                  <Divider light sx={{ width: '100%' }} />
                  <div className="address-div">
                    <p>Billing Address</p>
                    <p>
                      {singleOrder?.billing_address?.street[0]},{' '}
                      {singleOrder?.billing_address?.city},{' '}
                      {singleOrder?.billing_address?.country_id},{' '}
                      {singleOrder?.billing_address?.postcode},{' '}
                      {singleOrder?.billing_address?.region}
                    </p>
                  </div>
                  <Divider light sx={{ width: '100%' }} />
                  <div className="delivery-div">
                    <div className="delivery-inner-div">
                      <p>Delivery Method</p>
                      <div className="delivery-info">
                        {singleOrder?.shipping_description !== 'Pick-up' &&
                          !singleOrder?.shipping_description.includes(
                            'Free Shipping'
                          ) &&
                          !singleOrder?.shipping_description.includes(
                            'Local Delivery'
                          ) && (
                            <img
                              src={shippingData[1] || ''}
                              alt="delivery-method"
                            />
                          )}
                        <p>
                          {' '}
                          {singleOrder?.shipping_description !== 'Pick-up'
                            ? shippingData[0] || 'N/A'
                            : 'N/A'}
                        </p>
                      </div>
                    </div>
                    <div className="delivery-inner-div">
                      <p>Order Method</p>
                      <div className="delivery-info">
                        <LocalShippingOutlinedIcon />
                        <p>
                          {singleOrder?.shipping_description === 'Pick-up'
                            ? singleOrder?.shipping_description
                            : 'Delivery'}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <Divider light sx={{ width: '100%' }} /> */}
                  {singleOrder?.shipping_description !== 'Pick-up' &&
                    !singleOrder?.shipping_description.includes(
                      'Free Shipping'
                    ) &&
                    !singleOrder?.shipping_description.includes(
                      'Local Delivery'
                    ) && (
                      <div className="delivery-div">
                        <div className="delivery-inner-div">
                          <p>Tracking Number</p>
                          <div className="delivery-info">
                            {singleOrder?.x_forwarded_for ? (
                              <Link to={singleOrder?.x_forwarded_for}>
                                {singleOrder?.customer_note}
                              </Link>
                            ) : (
                              <p>{singleOrder?.customer_note || 'N/A'}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  <Divider light sx={{ width: '100%' }} />
                  <div className="payment-div">
                    <p className="div-title">Payment Method</p>
                    {singleOrder?.payment?.method !== 'Pay-In-Store' && (
                      <img src={paymentMethod} alt="visa" />
                    )}
                    {singleOrder?.payment?.method !== 'Pay-In-Store' ? (
                      <div className="bottom">
                        <p className="card-number">
                          {singleOrder?.payment?.cc_last4 || '0000'}
                        </p>
                        <div className="card-info">
                          <p>
                            {user.firstname} {user.lastname}
                          </p>
                          {/* <p>
                          {singleOrder?.payment?.cc_ss_start_month}/
                          {singleOrder?.payment?.cc_exp_year}
                        </p> */}
                        </div>
                      </div>
                    ) : (
                      <div className="bottom">
                        <p>{singleOrder?.payment.method}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="right-box">
                  <div className="top-div-summary">
                    <div className="price">
                      <p>Subtotal</p>
                      <p>${singleOrder?.base_grand_total?.toFixed(2) || 0}</p>
                    </div>
                    <div className="price">
                      <p>Shipping cost</p>
                      <p>+${singleOrder?.shipping_amount}</p>
                    </div>
                    <div className="price">
                      <p>Taxes</p>
                      <p>+${singleOrder?.tax_amount || 0}</p>
                    </div>
                    <div className="price">
                      <p>Coupon</p>
                      <p>
                        -$0
                        {/* {singleOrder?.grand_total -
                          // (singleOrder?.base_subtotal +
                          singleOrder?.shipping_amount -
                          singleOrder?.discount_amount || 0} */}
                      </p>
                    </div>
                    <div className="price">
                      <p>Discount</p>
                      <p>-${singleOrder?.discount_amount || 0}</p>
                    </div>
                  </div>
                  <div className="subtotal">
                    <p>Total Price</p>
                    <p>${singleOrder?.grand_total}</p>
                  </div>
                </div>
              </div>
              <DashboardTable
                data={singleOrder?.items}
                perPage={4}
                type={'ProductsDetails'}
              />
            </div>
          ) : (
            <div
              className="right-div-single-order"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {!error.status ? (
                <>
                  <CircularProgress />
                  <span>Loading Order...</span>
                </>
              ) : (
                <Alert severity="error">Error! order not found</Alert>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleOrder;
