import React, { useEffect, useState } from 'react';
import DashboardNavigation from '../../../../../components/DashboardComponents/DashboardNavigation';
import './General.scss';
import { Alert, Avatar, Button, IconButton, TextField } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import dayjs from 'dayjs';
import NotificationsComponent from '../../../../../components/DashboardComponents/NotificationComponent';
import DeleteUserImgPopup from '../../../../../components/DashboardComponents/DeleteUserImgPopup';
import noUserImg from '../../../../../assets/noUser.svg';
import { useDispatch, useSelector } from 'react-redux';
import { resetState, updateUser } from '../../../../../redux/userSlice';
import { getFileUrl } from '../../../../../utils/callBack';

const SettingsGeneral = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [userData, setUserData] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [errorUploadingFile, setErrorUploadingFile] = useState(false);
  const { currentUser, error, isUpdated, isLoading } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();

  const handleChange = (e) => {
    if (e['$d']) {
      setUserData({ ...userData, dob: e['$d'] });
    } else {
      const { name, value } = e.target;
      setUserData({ ...userData, [name]: value });
    }
  };

  const handleUpdateUser = async () => {
    dispatch(updateUser({ userId: currentUser._id, data: userData }));
  };

  const handleFile = async (e) => {
    const fileImg = e.target.files[0];
    setIsUploading(true);
    const fileUrl = await getFileUrl(fileImg);
    if (fileUrl) {
      setUserData({ ...userData, profile_img: fileUrl });
    } else {
      setErrorUploadingFile(true);
      setIsUploading(false);
    }
    setIsUploading(false);
  };

  const handleImageDelete = async (e) => {
    dispatch(
      updateUser({
        userId: currentUser._id,
        data: {
          profile_img: null,
        },
      })
    );
    setUserData({ ...userData, profile_img: null });
  };

  // makes the alert disapperas after 5 seconds in either cases error or success
  useEffect(() => {
    if (isUpdated || error.status) {
      setIsOpen(false);
      setTimeout(() => {
        dispatch(resetState());
      }, [5000]);
    }
    if (errorUploadingFile) {
      setTimeout(() => {
        setErrorUploadingFile(false);
      }, [5000]);
    }
  }, [isUpdated, error, errorUploadingFile]);
  return (
    <div className="SettingsGeneral">
      <DeleteUserImgPopup
        isOpen={isOpen}
        isLoading={isLoading}
        handlClose={() => setIsOpen(false)}
        img={currentUser?.profile_img}
        handleRemove={handleImageDelete}
      />
      <div className="main-conatiner">
        {(error.status || isUpdated || errorUploadingFile) && (
          <Alert
            severity={error.status || errorUploadingFile ? 'error' : 'success'}
            style={{
              position: 'fixed',
              bottom: '16px',
              right: '16px',
              zIndex: '50',
            }}
          >
            {error.status
              ? error.message
              : isUpdated
              ? 'User Updated successfully'
              : 'error uploading file'}
          </Alert>
        )}
        <div className="wrapper">
          <DashboardNavigation />
          <div
            className={`SettingsGeneral-right ${
              isLoading || isUploading ? 'disabled' : ''
            }`}
          >
            <div className="profile-section">
              <h1>My Profile</h1>
              <div className="profile-img-wrapper">
                <Avatar
                  alt={userData.firstname}
                  src={
                    userData?.profile_img
                      ? userData?.profile_img
                      : currentUser?.profile_img || noUserImg
                  }
                  style={{ width: '54px', height: '54px' }}
                />
                <Button
                  className="uploadImg"
                  component="label"
                  variant="contained"
                >
                  Upload New Image
                  <input
                    type="file"
                    className="inputFile"
                    onChange={handleFile}
                  />
                </Button>
                {currentUser.profile_img && (
                  <IconButton
                    className="delete-bttn"
                    onClick={() => setIsOpen(true)}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                )}
              </div>
              <div className="form-wrapper">
                <TextField
                  label="First Name*"
                  name="firstname"
                  className="input short"
                  value={userData?.firstname || currentUser?.firstname}
                  onChange={handleChange}
                />
                <TextField
                  label="Last Name*"
                  name="lastname"
                  className="input short"
                  value={userData?.lastname || currentUser?.lastname}
                  onChange={handleChange}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateField
                    label="Date of Birth"
                    name="dob"
                    className="input"
                    value={
                      userData.dob
                        ? dayjs(userData.dob)
                        : currentUser?.dob
                        ? dayjs(currentUser?.dob)
                        : ''
                    }
                    onChange={handleChange}
                  />
                </LocalizationProvider>
              </div>
              <Button className="save-changes" onClick={handleUpdateUser}>
                Save Changes
              </Button>
            </div>
            <NotificationsComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsGeneral;
