import React, { useEffect, useState } from 'react';
import noImg from '../../assets/No_Image_Available.jpg';
import { publicRequest } from '../../utils/ApiConfig';

const ProductImgWrapper = ({ item }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setIsLoading(true);
        if (item?.product_id) {
          const product = await publicRequest.get(
            `/products?product_id=${item.product_id}`
          );
          setIsLoading(false);
          setProduct(product.data.products[0]);
        } else {
          const product = await publicRequest.get(`/products/sku/${item?.sku}`);
          setIsLoading(false);
          setProduct(product.data);
        }
      } catch (err) {
        setIsLoading(false);
      }
    };
    if (product === null && (item?.product_id || item?.sku) && !isLoading) {
      fetchProduct();
    }
  }, [item]);

  return (
    <div className="img-wrapper">
      <img
        src={isLoading ? noImg : product?.images[0]?.url || noImg}
        alt="product-img"
      />
    </div>
  );
};

export default ProductImgWrapper;
