import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { userRequest } from '../utils/ApiConfig';
import { handleAutoLogout } from '../utils/callBack';

export const storeUserCard = createAsyncThunk(
  'user/addCard',
  async ({ userId, cardData }, { dispatch }) => {
    try {
      await userRequest.post(`/payments/user/${userId}/cards`, {
        cardData: cardData,
      });
      dispatch(
        getUserCards({
          userId: userId,
        })
      );
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed adding new card');
      }
    }
  }
);
export const getUserCards = createAsyncThunk(
  'user/getCards',
  async ({ userId }, { dispatch }) => {
    try {
      const response = await userRequest.get(`/payments/user/${userId}/cards`);
      const userCards = response.data;
      return userCards;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed adding new card');
      }
    }
  }
);

export const setDefaultCard = createAsyncThunk(
  'user/setDefaultCard',
  async ({ userId, cardId }, { dispatch }) => {
    try {
      await userRequest.put(`/payments/user/${userId}/cards/${cardId}`);
      dispatch(
        getUserCards({
          userId: userId,
        })
      );
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed adding new card');
      }
    }
  }
);
export const deleteCardById = createAsyncThunk(
  'user/deleteCardbyId',
  async ({ userId, cardId }, { dispatch }) => {
    try {
      await userRequest.delete(`/payments/user/${userId}/cards/${cardId}`);
      dispatch(
        getUserCards({
          userId: userId,
        })
      );
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed adding new card');
      }
    }
  }
);

const cardSlice = createSlice({
  name: 'card',
  initialState: {
    isLoading: false,
    error: {
      status: false,
      message: null,
    },
    userCards: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserCards.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserCards.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userCards = action.payload;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getUserCards.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Failed fethcing user cards.',
        };
      })
      .addCase(storeUserCard.pending, (state) => {
        state.isLoading = true;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(storeUserCard.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Failed storing card.',
        };
      })
      .addCase(setDefaultCard.pending, (state) => {
        state.isLoading = true;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(setDefaultCard.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Failed updating card.',
        };
      })
      .addCase(deleteCardById.pending, (state) => {
        state.isLoading = true;
        state.error = {
          status: false,
          message: null,
        };
      });
  },
});
export const cardReducer = cardSlice.reducer;
