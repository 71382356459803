import React, { useEffect, useRef, useState } from 'react';
import './NotificationAlert.scss';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import NotificationAlertList from './NotificationAlertList';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToNotifList,
  getAlluserNotifications,
} from '../../redux/NotificationSlice';
import notificationSound from '../../assets/notifSound.wav';
import { socket } from '../../socket';

const NotificationAlert = () => {
  const scrollableDivRef = useRef(null); // Ref to the scrollable div
  const notificationPopupRef = useRef();
  const [show, setShow] = useState(false);
  const [notCount, setNotCount] = useState(3);
  const [page, setPage] = useState(1);
  const { currentUser } = useSelector((state) => state.user);
  const { notifList, notifLoading } = useSelector(
    (state) => state.notification
  );
  const dispatch = useDispatch();
  const [containsUnread, setIsContainsUnread] = useState(false);
  const sound = new Audio(notificationSound);

  const fetchNotifications = () => {
    if (currentUser) {
      dispatch(
        getAlluserNotifications({
          userId: currentUser._id,
          page: page,
        })
      );
    }
  };

  // fetch all user notifications
  useEffect(() => {
    fetchNotifications();
  }, [page]);

  const handleScroll = () => {
    const div = scrollableDivRef.current;
    if (div) {
      const { scrollTop, scrollHeight, clientHeight } = div;
      const isAtEnd = scrollTop + clientHeight >= scrollHeight;
      // console.log(isAtEnd, !notifLoading);
      if (isAtEnd && !notifLoading) {
        setPage((prev) => prev + 1);
        setNotCount((prev) => prev + 10);
      }
    }
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false);
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(notificationPopupRef);

  useEffect(() => {
    if (notifList?.length > 0) {
      const isFound = notifList?.find((not) => not?.is_read === false);
      setIsContainsUnread(isFound);
    }
  }, [notifList]);

  // handles recieved Notification from socket
  useEffect(() => {
    // Define the event handler
    const handleGetNotification = (notif) => {
      dispatch(addToNotifList(notif));
      if (currentUser?.isSoundNotifActive) {
        sound.play();
      }
    };

    // Attach the event handler to the socket
    socket.on('getNotification', handleGetNotification);

    // Clean up the event handler when the component unmounts
    return () => {
      socket.off('getNotification', handleGetNotification);
    };
  }, []);

  return (
    <div className="notification-alert-nav" ref={notificationPopupRef}>
      <div className="notification" onClick={() => setShow(!show)}>
        <NotificationsNoneOutlinedIcon style={{ fontSize: '26px' }} />
        {notifList?.length > 0 && containsUnread && (
          <div className="new-notification-circle"></div>
        )}
      </div>
      <div className={`notification-container ${show ? 'open' : ''}`}>
        <div
          className="notification-list"
          ref={scrollableDivRef}
          onScroll={handleScroll}
        >
          {notifList?.length ? (
            notifList
              .slice(0, notCount)
              .map((notifComp) => (
                <NotificationAlertList data={notifComp} page={page} />
              ))
          ) : (
            <div className="empty-not">no new notication</div>
          )}
        </div>
        {notifList?.length > 3 && notCount === 3 && (
          <div className="load-more-div">
            <Button
              className="see-more-notication"
              onClick={() => setNotCount(notifList?.length)}
            >
              See all Notifications
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationAlert;
