import React, { useEffect, useState } from 'react';
import './NotificationComponent.scss';
import Switch from '@mui/material/Switch';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserPrefNot } from '../../redux/userSlice';

const NotificationsComponent = () => {
  const [checkedData, setCheckedData] = useState({});
  const { currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { checked, name } = e.target;
    setCheckedData({ ...checkedData, [name]: checked });
  };
  useEffect(() => {
    if (Object.keys(checkedData).length) {
      const handleChecked = async () => {
        await dispatch(
          updateUserPrefNot({
            userId: currentUser._id,
            prefrence: checkedData,
          })
        );
        setCheckedData({});
      };
      handleChecked();
    }
  }, [checkedData]);

  return (
    <div className="profile-section">
      <h1>Notifications</h1>
      <div className="notSetting-container">
        <div className="left-wrapper">
          <p className="setting-title">Email Notifications:</p>
          <span>
            You can enable your email for receiving updates on your
            prescriptions and order status.
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Switch
            name="isEmailNotifActive"
            checked={currentUser?.isEmailNotifActive}
            onChange={handleChange}
            disabled={!currentUser?.isEmailVerified}
          />
          {!currentUser?.isEmailVerified &&
            !currentUser?.isEmailNotifActive && (
              <span style={{ color: '#ba000d', fontSize: '12px' }}>
                Please verify your Email to enable
              </span>
            )}
        </div>
      </div>
      <div className="notSetting-container">
        <div className="left-wrapper">
          <p className="setting-title">Phone Notifications:</p>
          <span>Stay informed via SMS or phone call alerts.</span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Switch
            disabled={!currentUser?.telephone}
            name="isSmsNotifActive"
            checked={currentUser?.isSmsNotifActive}
            onChange={handleChange}
          />
          {!currentUser?.telephone && !currentUser?.isSmsNotifActive && (
            <span style={{ color: '#ba000d', fontSize: '12px' }}>
              Please add phone number to enable
            </span>
          )}
        </div>
      </div>
      <div className="notSetting-container">
        <div className="left-wrapper">
          <p className="setting-title">Notification Sound:</p>
          <span>Customize the notification sound to distinguish alerts.</span>
        </div>
        <Switch
          name="isSoundNotifActive"
          checked={currentUser?.isSoundNotifActive}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default NotificationsComponent;
