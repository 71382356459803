import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CMSURL } from '../../utils/config';
import { publicRequest } from '../../utils/ApiConfig';
import { Skeleton } from '@mui/material';
import noImage from '../../assets/No_Image_Available.jpg';

const CategorySwipper = ({ category }) => {
  const [loadingImg, setLoadingImg] = useState(false);
  const [categoryImage, setCategoryImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getCategoryById = async () => {
      try {
        setLoadingImg(true);
        const response = await publicRequest.get(`/categories/${category?.id}`);
        const categoryData = response.data;
        const catImage = categoryData.custom_attributes.find(
          (att) => att.attribute_code === 'image'
        );
        setCategoryImage(`${CMSURL}/${catImage?.value}`);
        setLoadingImg(false);
      } catch (err) {
        setLoadingImg(false);
      }
    };
    if (!categoryImage && category?.id && !loadingImg) {
      getCategoryById();
    }
  }, [category, loadingImg]);

  return (
    <div
      className="inside-swiper"
      onClick={() => navigate(`/shop/${category?.id}`)}
    >
      {/* <p>{category?.product_count} items</p> */}
      {loadingImg ? (
        <Skeleton variant="rectangular" width={'100%'} height={200} />
      ) : (
        <img src={categoryImage || noImage} alt={category?.name} />
      )}
      <p>{category?.name}</p>

      <Link className="button" to={`/shop/${category?.id}`}>
        Shop Now
      </Link>
    </div>
  );
};

export default CategorySwipper;
