import React, { useRef, useState, useEffect } from 'react';
import './Navbar.scss';
import logo from '../../../assets/City Pharmacy.svg';
import { Link, useNavigate } from 'react-router-dom';
import { BsChevronDown } from 'react-icons/bs';
// import menu from "../../../assets/Hamburger Menu.png";
import fill from '../../../assets/fill.png';
import refill from '../../../assets/refill.png';
import transfer from '../../../assets/transfer.png';
// import avatarImg from '../../../assets/avatarimg.jpeg';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { FiPhoneCall } from 'react-icons/fi';
import {
  Avatar,
  Button,
  // IconButton,
  // InputAdornment,
  // InputBase,
  useMediaQuery,
} from '@mui/material';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import Sidecart from '../../Sidecart';
import { useDispatch, useSelector } from 'react-redux';
import LoginSignUpModal from '../../LoginSignUpModal';
import ShopMegaMenu from '../../shopMegaMenu/ShopMegaMenu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NavigationLinks from '../../DashboardComponents/NavigationLinks';
import NotificationAlert from '../../DashboardComponents/NotificationAlert';
import { userModelClose, userModelOpen } from '../../../redux/CartSlice';
import SearchBarComponent from '../../SearchBarComponent';

const NavBar = () => {
  const Desktop = useMediaQuery('(min-width:1100px)');
  const isTablet = useMediaQuery('(max-width:1030px)');
  const navbar = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showCart, setshowCart] = useState(false);
  const [showAccountMega, setShowAccountMega] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();
  const { items, cartData, userModel } = useSelector((state) => state.cart);
  const [cartTotal, setCartTotal] = useState(0);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [mod, setMod] = useState('none');
  const [megaShown, setMegaShown] = useState(false);
  const { WishItems } = useSelector((state) => state.wishlist);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //gets cart total
  useEffect(() => {
    if (items?.length > 0) {
      const total = items.reduce(
        (prev, curr) => prev + curr.price * curr.qty,
        0
      );
      setCartTotal(total);
    } else {
      setCartTotal(0);
    }
  }, [items]);

  const handleMega = (current) => {
    if (current === showMenu) {
      setShowMenu(0);
    } else {
      setShowMenu(current);
    }
  };

  const handlePhoneMega = (event, current) => {
    event.stopPropagation();
    if (current === showMenu) {
      setShowMenu(0);
    } else {
      setShowMenu(current);
    }
  };
  const handlePhoneMegaSecondlevel = (event, current) => {
    if (current === showMenu) {
      setShowMenu(0);
      setShowMobileMenu(!showMobileMenu);
    } else {
      setShowMenu(current);
    }
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowMenu(0);
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  function handlePopup(type) {
    dispatch(userModelOpen());
    setMod(type);
  }
  useOutsideAlerter(navbar);

  return (
    <div className="Navbar">
      <ShopMegaMenu shown={megaShown} setMegaShown={setMegaShown} />
      <LoginSignUpModal
        open={userModel}
        handleClose={() => dispatch(userModelClose())}
        type={mod}
      />
      <Sidecart open={showCart} handleClose={() => setshowCart(false)} />
      {Desktop ? (
        <div className="nav-wrapper">
          <div className="upper">
            <div
              className="left"
              style={{ display: 'flex', gap: '4%', width: '50%' }}
            >
              <Link to={'/'}>
                <img src={logo} alt="logo" className="logo-img" />
              </Link>
              <SearchBarComponent />
            </div>
            <div className="middle">
              <div className="UpperRight">
                <div className="boxWrap">
                  <FiPhoneCall
                    style={{ color: '#219d50', width: '22px', height: '22px' }}
                  />
                  <div className="textwrap" style={{ gap: '0' }}>
                    <p className="title">Contact Us</p>
                    <p className="contonet">416 469 1898</p>
                  </div>
                </div>
                <div className="boxWrap">
                  <AccessTimeOutlinedIcon
                    style={{ color: '#219d50', maxWidth: '28px' }}
                  />{' '}
                  <div className="textwrap" style={{ gap: '0' }}>
                    <p className="title">Mon-Fri</p>
                    <p className="contonet">10AM - 6PM</p>
                    <p className="title">Sat</p>
                    <span className="contonet"> 11:00-4:00</span>
                  </div>
                </div>
                {user ? (
                  <div className="right loggedin">
                    <button onClick={() => navigate('/dashboard/wishlist')}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <path
                          d="M13.6134 3.55298C13.2789 3.21736 12.8815 2.95107 12.4439 2.76937C12.0064 2.58767 11.5372 2.49414 11.0634 2.49414C10.5896 2.49414 10.1204 2.58767 9.68284 2.76937C9.24526 2.95107 8.84784 3.21736 8.51339 3.55298L8.00006 4.07298L7.48672 3.55298C7.15227 3.21736 6.75486 2.95107 6.31727 2.76937C5.87968 2.58767 5.41053 2.49414 4.93672 2.49414C4.46291 2.49414 3.99376 2.58767 3.55618 2.76937C3.11859 2.95107 2.72118 3.21736 2.38672 3.55298C0.973391 4.96631 0.886724 7.35298 2.66672 9.16631L8.00006 14.4996L13.3334 9.16631C15.1134 7.35298 15.0267 4.96631 13.6134 3.55298Z"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{' '}
                      {WishItems?.length}{' '}
                    </button>
                    <button
                      className="green"
                      onClick={() => navigate('/dashboard')}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <path
                          d="M12.6668 14.5V13.1667C12.6668 12.4594 12.3859 11.7811 11.8858 11.281C11.3857 10.781 10.7074 10.5 10.0002 10.5H6.00016C5.29292 10.5 4.61464 10.781 4.11454 11.281C3.61445 11.7811 3.3335 12.4594 3.3335 13.1667V14.5"
                          stroke="#219D50"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.00016 7.83333C9.47292 7.83333 10.6668 6.63943 10.6668 5.16667C10.6668 3.69391 9.47292 2.5 8.00016 2.5C6.5274 2.5 5.3335 3.69391 5.3335 5.16667C5.3335 6.63943 6.5274 7.83333 8.00016 7.83333Z"
                          stroke="#219D50"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{' '}
                      My Account
                    </button>
                  </div>
                ) : (
                  <div className="right">
                    <button
                      onClick={() => {
                        handlePopup('login');
                      }}
                    >
                      Login
                    </button>
                    <button
                      className="green"
                      onClick={() => {
                        handlePopup('signup');
                      }}
                    >
                      Sign Up
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="lower">
            <div className="lowrewrap">
              <div className="lowerwrapper">
                <div className="links-wrapper" ref={navbar}>
                  <div
                    className="drop-down-link"
                    to="/prescription"
                    onClick={() => handleMega(2)}
                  >
                    Prescription
                    <BsChevronDown
                      style={{
                        color: 'black',
                        transform: showMenu === 2 && 'rotate(180deg)',
                        transition: '.3s all ease-in-out',
                      }}
                    />
                    {showMenu === 2 && (
                      <div className="drop-menu two">
                        <Link to="/Fillprescription" className="menu-box">
                          <img src={fill} alt="icon" />
                          <div className="text-div">
                            <span className="menuitemtitle">
                              Submit a prescription online
                            </span>
                          </div>
                        </Link>
                        <Link to="/Refillprescription" className="menu-box">
                          <img src={refill} alt="icon" />
                          <div className="text-div">
                            <span className="menuitemtitle">
                              Refill Prescription{' '}
                            </span>
                          </div>
                        </Link>
                        <Link to="/Transferprescription" className="menu-box">
                          <img src={transfer} alt="icon" />
                          <div className="text-div">
                            <span className="menuitemtitle">
                              Transfer Prescription
                            </span>
                          </div>
                        </Link>
                      </div>
                    )}
                  </div>
                  <Link to="/Travel">Travel Health</Link>
                  <Link to="/MinorAliments">Minor Aliments</Link>
                  <Link to="/Vaccinations">Vaccinations </Link>
                  <Link to="/Testing">Testing </Link>
                  <Link to="/Compression ">Compression stockings </Link>
                  <Link to="/Consult_a_pharmacist">Consult a Pharmacist </Link>
                  <Link to="/About">About </Link>
                  <Link to="/Contact">Contact</Link>
                </div>
                <div className="buttons"></div>
              </div>
              <div className="shopButton">
                <Link
                  className={`ShopBut ${megaShown ? 'active' : ''}`}
                  onClick={() =>
                    isTablet ? navigate('/shop') : setMegaShown(!megaShown)
                  }
                >
                  Shop
                  {!isTablet && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M4 6L8 10L12 6"
                        stroke="#219D50"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </Link>
                <div className="cartMini" onClick={() => setshowCart(true)}>
                  <ShoppingCartOutlinedIcon
                    style={{ color: '#219d50', maxWidth: '18px' }}
                  />
                  <span className="total">
                    {cartData?.items_count || 0} / ${cartTotal?.toFixed(2) || 0}
                  </span>
                </div>
                {user && <NotificationAlert />}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mobileNav">
          <div className="upper">
            <p className="Text_wrap">
              <FiPhoneCall
                style={{ color: '#219d50', width: '22px', height: '22px' }}
              />{' '}
              <span>Contact Us</span> 416 469 1898
            </p>
            <div className="hours-wrapper">
              <p className="Text_wrap">
                <AccessTimeOutlinedIcon
                  style={{ color: '#219d50', maxWidth: '28px' }}
                />
                <span>Mon-Fri</span> 10AM - 6PM
              </p>
              <p className="Text_wrap" style={{ paddingLeft: '30px' }}>
                {/* <AccessTimeOutlinedIcon
                  style={{ color: '#219d50', maxWidth: '28px' }}
                /> */}
                <span>Sat</span>
                11:00-4:00
              </p>
            </div>
          </div>
          <div className="middle">
            <Link to={'/'}>
              <img src={logo} alt="logo" />
            </Link>
            <div className="shopButton">
              <Button
                className={`ShopBut ${megaShown ? 'active' : ''}`}
                onClick={() =>
                  isTablet ? navigate('/shop') : setMegaShown(!megaShown)
                }
              >
                Shop{' '}
                {!isTablet && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M4 6L8 10L12 6"
                      stroke="#219D50"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </Button>
            </div>
          </div>
          <div className="lower">
            {user ? (
              // <div className="right loggedin">
              //   <button>
              //     <svg
              //       xmlns="http://www.w3.org/2000/svg"
              //       width="16"
              //       height="17"
              //       viewBox="0 0 16 17"
              //       fill="none"
              //     >
              //       <path
              //         d="M13.6134 3.55298C13.2789 3.21736 12.8815 2.95107 12.4439 2.76937C12.0064 2.58767 11.5372 2.49414 11.0634 2.49414C10.5896 2.49414 10.1204 2.58767 9.68284 2.76937C9.24526 2.95107 8.84784 3.21736 8.51339 3.55298L8.00006 4.07298L7.48672 3.55298C7.15227 3.21736 6.75486 2.95107 6.31727 2.76937C5.87968 2.58767 5.41053 2.49414 4.93672 2.49414C4.46291 2.49414 3.99376 2.58767 3.55618 2.76937C3.11859 2.95107 2.72118 3.21736 2.38672 3.55298C0.973391 4.96631 0.886724 7.35298 2.66672 9.16631L8.00006 14.4996L13.3334 9.16631C15.1134 7.35298 15.0267 4.96631 13.6134 3.55298Z"
              //         stroke="black"
              //         strokeWidth="2"
              //         strokeLinecap="round"
              //         strokeLinejoin="round"
              //       />
              //     </svg>{' '}
              //     2{' '}
              //   </button>
              //   <button
              //     className="green"
              //     onClick={() => dispatch(logoutUser())}
              //   >
              //     <svg
              //       xmlns="http://www.w3.org/2000/svg"
              //       width="16"
              //       height="17"
              //       viewBox="0 0 16 17"
              //       fill="none"
              //     >
              //       <path
              //         d="M12.6668 14.5V13.1667C12.6668 12.4594 12.3859 11.7811 11.8858 11.281C11.3857 10.781 10.7074 10.5 10.0002 10.5H6.00016C5.29292 10.5 4.61464 10.781 4.11454 11.281C3.61445 11.7811 3.3335 12.4594 3.3335 13.1667V14.5"
              //         stroke="#219D50"
              //         strokeWidth="2"
              //         strokeLinecap="round"
              //         strokeLinejoin="round"
              //       />
              //       <path
              //         d="M8.00016 7.83333C9.47292 7.83333 10.6668 6.63943 10.6668 5.16667C10.6668 3.69391 9.47292 2.5 8.00016 2.5C6.5274 2.5 5.3335 3.69391 5.3335 5.16667C5.3335 6.63943 6.5274 7.83333 8.00016 7.83333Z"
              //         stroke="#219D50"
              //         strokeWidth="2"
              //         strokeLinecap="round"
              //         strokeLinejoin="round"
              //       />
              //     </svg>{' '}
              //     My Account
              //   </button>
              // </div>
              <div
                className={`navMenu-avatar-account ${
                  showAccountMega ? 'show' : ''
                }`}
                onClick={() => setShowAccountMega(!showAccountMega)}
              >
                <div className="main-account-mobile-bttn">
                  <Avatar
                    alt={user.firstname}
                    src={user?.profile_img || ''}
                    style={{ width: '40px', height: '40px' }}
                  />
                  <div className="right-bttn">
                    <p className="my-account-drop">My Account</p>
                    <KeyboardArrowDownIcon />
                  </div>
                </div>
                <NavigationLinks />
              </div>
            ) : (
              <div className="right">
                <button
                  onClick={() => {
                    handlePopup('login');
                  }}
                >
                  Login
                </button>
                <button
                  className="green"
                  onClick={() => {
                    handlePopup('signup');
                  }}
                >
                  Sign Up
                </button>
              </div>
            )}
            <div className="cart">
              <ShoppingCartOutlinedIcon
                style={{ color: '#219d50', width: '22px', marginRight: '8px' }}
                onClick={() => setshowCart(true)}
              />{' '}
              {user && <NotificationAlert />}
              <div onClick={() => setShowMobileMenu(!showMobileMenu)}>
                {showMobileMenu ? (
                  <AiOutlineClose className="menu-icon" />
                ) : (
                  <AiOutlineMenu className="menu-icon" />
                )}
                {showMobileMenu && (
                  <div className="dropdown-links-wrapper">
                    <div
                      className="drop-down-link "
                      onClick={(event) => handlePhoneMega(event, 1)}
                    >
                      <div
                        className="supmenuparent"
                        style={{
                          color: showMenu === 1 && '#0A1F8F',
                        }}
                      >
                        <span>Prescription</span>
                        <BsChevronDown
                          style={{
                            transform: showMenu === 1 && 'rotate(180deg)',
                            transition: '.3s all ease-in-out',
                          }}
                        />
                      </div>
                      {showMenu === 1 && (
                        <div
                          className="MobDrop"
                          onClick={(event) =>
                            handlePhoneMegaSecondlevel(event, 1)
                          }
                        >
                          <Link
                            to="/Fillprescription"
                            className="menu-box Mobbox"
                          >
                            <div className="text-div">
                              <span> Submit a prescription online</span>
                            </div>
                          </Link>
                          <Link
                            to="/Refillprescription"
                            className="menu-box Mobbox"
                          >
                            <div className="text-div">
                              <span>Refill Prescription</span>
                            </div>
                          </Link>
                          <Link
                            to="/Transferprescription"
                            className="menu-box Mobbox"
                          >
                            <div className="text-div">
                              <span>Transfer Prescription</span>
                            </div>
                          </Link>
                        </div>
                      )}
                    </div>
                    <Link to="/Travel">Travel</Link>
                    <Link to="/MinorAliments">Minor Aliments</Link>
                    <Link to="/Vaccinations">Vaccinations </Link>
                    <Link to="/Testing">Testing </Link>
                    <Link to="/Compression ">Compression stockings </Link>
                    <Link to="/Consult_a_pharmacist">
                      Consult a Pharmacist{' '}
                    </Link>
                    <Link to="/About">About </Link>
                    <Link to="/Contact">Contact</Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBar;
