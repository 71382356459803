import { publicRequest } from './ApiConfig';

export class Evalon {
  static async getPaymentToken(
    amount,
    first_name,
    last_name,
    orderId,
    type,
    sale
  ) {
    try {
      const res = await publicRequest.post(
        `/payments/elavon/token?type=${type || ''}&sale=${sale || ''}`,
        {
          amount,
          first_name,
          last_name,
          orderId,
        }
      );
      const token = res.data;
      return token;
    } catch (err) {
      return null;
    }
  }
}
