import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Snackbar,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import './PrescriptionTransfer.scss';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { TransferPresForm } from './Prescriptionforms';
import { MuiTelInput } from 'mui-tel-input';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Textarea from '@mui/joy/Textarea';
// import { TimePicker } from '@mui/x-date-pickers';
// import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import ThankYouPresc from './ThankYouPres';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFile, getFileUrl } from '../../utils/callBack';
import { submitNewPrescription } from '../../redux/PrescriptionSlice';

const PrescriptionFill = () => {
  const navigate = useNavigate();
  const [noPrintVersion, setNoPrintVersion] = useState(false);
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [data, setData] = useState({});
  const [uploadError, setUploadError] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const { submittedId, isLoading } = useSelector((state) => state.prescription);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  // handle multi files upload to AWS.
  const handleFileUploads = async (e) => {
    const AllFiles = e.target.files;
    let attachments = [];
    const AllFilesArray = Array.from(AllFiles);
    setIsUploading(true);
    for (let i = 0; i < AllFilesArray.length; i++) {
      const fileUrl = await getFileUrl(AllFiles[i]);
      if (fileUrl) {
        attachments.push(fileUrl);
      } else {
        setUploadError(true);
        break;
      }
    }
    setData({ ...data, attachments });
    setIsUploading(false);
  };

  // handle delete selected file from array.
  const handleDeleteFile = async (index) => {
    const slicedFiles = data.attachments;
    const filteredFiles = slicedFiles.filter((file, i) => i !== index);
    setIsDeleting(true);
    const res = await deleteFile(data.attachments[index]);
    if (res) {
      setData({ ...data, attachments: filteredFiles });
    } else {
      return null;
    }
    setIsDeleting(false);
  };

  const handleSubmitPres = async () => {
    dispatch(
      submitNewPrescription({
        userId: user._id,
        prescData: {
          patient_fname: data?.firstname || user?.firstname,
          patient_lname: data?.lastname || user.lastname,
          attachments: !noPrintVersion ? data?.attachments : [],
          phone: data?.telephone || user?.telephone,
          type: 'Fill',
          name: data?.name || 'N/A',
          has_printVersion: !noPrintVersion,
          doctor_fname: noPrintVersion ? data.doctor_fname : '',
          doctor_lname: noPrintVersion ? data.doctor_lname : '',
        },
        message: data?.message || '',
      })
    );
  };

  useEffect(() => {
    if (submittedId.length > 0) {
      setSuccess(true);
    }
  }, [submittedId]);

  useEffect(() => {
    const validate = () => {
      if (
        // data?.name &&
        ((data?.attachments &&
          data.attachments?.length > 0 &&
          !noPrintVersion) ||
          (noPrintVersion && data.doctor_fname && data.doctor_lname)) &&
        (data?.telephone || user?.telephone)
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };
    if (data) {
      validate();
    }
  }, [data, noPrintVersion]);

  return (
    <>
      {!success ? (
        <div className="prescription-component">
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={uploadError}
            autoHideDuration={6000}
            onClose={() => setUploadError(false)}
          >
            <Alert severity="error" variant="filled">
              One or more files are not supported only (jpg, png, jpeg, webp,
              svg)
            </Alert>
          </Snackbar>
          <div className="prescription-form">
            <Button className="back-bttn" onClick={() => navigate(-1)}>
              <ArrowBackIcon />
              Back
            </Button>
            <div className="top-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
              >
                <path
                  d="M18.083 3.875V9.04167C18.083 9.38424 18.2191 9.71278 18.4613 9.95501C18.7036 10.1972 19.0321 10.3333 19.3747 10.3333H24.5413M18.083 3.875H9.04134C8.3562 3.875 7.69912 4.14717 7.21465 4.63164C6.73018 5.11611 6.45801 5.77319 6.45801 6.45833V16.7917M18.083 3.875L24.5413 10.3333M24.5413 10.3333V24.5417C24.5413 25.2268 24.2692 25.8839 23.7847 26.3684C23.3002 26.8528 22.6431 27.125 21.958 27.125H14.8538"
                  stroke="#1E1E1E"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.136 18.248L13.338 16.724H13.332V18.248H12V14H13.98C14.324 14 14.618 14.062 14.862 14.186C15.106 14.306 15.288 14.472 15.408 14.684C15.532 14.892 15.594 15.128 15.594 15.392C15.594 15.676 15.514 15.928 15.354 16.148C15.198 16.368 14.972 16.526 14.676 16.622L15.6 18.248H14.136ZM13.332 15.836H13.86C13.988 15.836 14.084 15.808 14.148 15.752C14.212 15.692 14.244 15.6 14.244 15.476C14.244 15.364 14.21 15.276 14.142 15.212C14.078 15.148 13.984 15.116 13.86 15.116H13.332V15.836Z"
                  fill="#1E1E1E"
                />
                <path
                  d="M18.7255 18.248L17.9455 17.144L17.2975 18.248H15.7795L17.1775 16.058L15.7195 14H17.2975L18.0475 15.062L18.6715 14H20.1895L18.8155 16.148L20.3035 18.248H18.7255Z"
                  fill="#1E1E1E"
                />
                <path
                  d="M2.58301 25.8333H10.333M6.45801 21.9583V29.7083"
                  stroke="#1E1E1E"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p>New Prescription Submission</p>
            </div>
            <span className="slogan">
              Submit your new prescription quickly and securely. Fill in the
              details below, and we'll prepare your medication for pickup or
              delivery. (Subject to Pharmacist's discretion and approval.
              Original must be presented at pickup)
            </span>
            <div className="form">
              <span className="form-title">Personal Information:</span>
              <div className="input-container">
                {TransferPresForm.map((input) =>
                  input.type === 'text' ? (
                    input?.name !== 'name' && (
                      <TextField
                        value={data[input.name] || user[input.name]}
                        name={input.name}
                        label={input.label}
                        className={`input ${input.size}`}
                        onChange={handleChange}
                        disabled={isUploading || input.name === 'email'}
                      />
                    )
                  ) : (
                    <MuiTelInput
                      disabled={isUploading}
                      label={input.label}
                      value={data?.telephone || user?.telephone || ''}
                      className={`input ${input.size}`}
                      InputLabelProps={{
                        sx: {
                          color: '#616161',
                          fontWeight: 400,
                          fontSize: '16px',
                        },
                      }}
                      InputProps={{
                        sx: {
                          '&:hover fieldset': {
                            border: '1px solid #122146 !important',
                          },
                          '&:focus-within fieldset, &:focus-visible fieldset': {
                            border: '1px solid #122146 !important',
                            boxShadow:
                              '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(32, 66, 137, 0.10)',
                          },
                        },
                      }}
                      defaultCountry="CA"
                      onChange={(value) =>
                        setData({ ...data, telephone: value })
                      }
                    />
                  )
                )}
              </div>
            </div>
            <div
              className={`upload-div ${data.files?.length ? 'active' : ''} ${
                noPrintVersion || isDeleting ? 'disabled' : ''
              }`}
            >
              <div className="left-div">
                <FileUploadOutlinedIcon />
                <p>Upload Prescription Image</p>
              </div>
              {data?.attachments?.length ? (
                <div className="right-div">
                  {data?.attachments.map((currFile, i) => (
                    <div
                      key={i}
                      className="file-preview"
                      style={{
                        backgroundImage: `url(${currFile})`,
                      }}
                    >
                      <div className="overlay-div">
                        <IconButton
                          className="deleteImg"
                          onClick={() => handleDeleteFile(i)}
                        >
                          <DeleteOutlinedIcon
                            fontSize="inherit"
                            style={{ color: '#F83B3F' }}
                          />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                </div>
              ) : isUploading ? (
                <Box>
                  <CircularProgress color="success" />
                  <p>uploading files...</p>
                </Box>
              ) : (
                <div className="right-div">
                  <Button
                    component="label"
                    variant="contained"
                    className="upload-bttn"
                  >
                    Upload file
                    <input
                      type="file"
                      accept="image/*"
                      name="files"
                      className="input-file"
                      onChange={handleFileUploads}
                      multiple="true"
                    />
                  </Button>
                  <span className="filename">No file chosen</span>
                </div>
              )}
            </div>
            <div className="checkbox-presc">
              <Checkbox
                checked={noPrintVersion}
                color="success"
                onChange={(e) => setNoPrintVersion(e.target.checked)}
              />
              <span className="desc">
                I don't have a print-out version of my prescription.
              </span>
            </div>
            {noPrintVersion && (
              <div className="form">
                <span className="form-title">Doctor Information:</span>
                <div className="input-container">
                  <TextField
                    value={data?.doctor_fname}
                    name="doctor_fname"
                    label="Doctor's First Name"
                    className={'input short'}
                    onChange={handleChange}
                    disabled={isUploading}
                  />
                  <TextField
                    value={data?.doctor_lname}
                    name="doctor_lname"
                    label="Doctor's Last Name"
                    className={'input short'}
                    onChange={handleChange}
                    disabled={isUploading}
                  />
                </div>
              </div>
            )}
            <Textarea
              disabled={isUploading}
              style={{
                backgroundColor: '#ffff',
                width: '100%',
              }}
              sx={{
                '--Textarea-focusedHighlight': '#4C9B58 !important',
                '&:focus-within': {
                  borderColor: '#4C9B58',
                },
              }}
              placeholder="Additional Information or Requests (Include any specific requirements or questions you may have)"
              minRows={4}
              name="message"
              onChange={handleChange}
            />
            {/* <div className="form">
              <span className="form-title">Preferred Appointment Date:</span>
              <div className="input-container">
                {TransferPresThree.map((input) =>
                  input.type === 'text' ? (
                    <TextField
                      label={input.label}
                      className={`input ${input.size}`}
                    />
                  ) : input.type === 'date' ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={input.label}
                        className={`input ${input.size}`}
                      />
                    </LocalizationProvider>
                  ) : input.type === 'time' ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label={input.label}
                        className={`input ${input.size}`}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                      />
                    </LocalizationProvider>
                  ) : (
                    <Textarea
                      style={{ backgroundColor: '#ffff' }}
                      variant="outlined"
                      color="neutral"
                      className={`input ${input.size}`}
                      placeholder="Additional Information or Requests (Include any specific requirements or questions you may have)"
                      minRows={4}
                    />
                  )
                )}
              </div>
            </div> */}
          </div>
          <div className="submit-div">
            <Button className="cancel-bttn" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              className="submitForm"
              onClick={handleSubmitPres}
              disabled={disabled || isLoading}
            >
              Submit New Prescription
            </Button>
          </div>
        </div>
      ) : (
        <ThankYouPresc
          prescId={submittedId}
          text="Thank you for filling your prescription you will receive it according to the date and preferred time you selected."
        />
      )}
    </>
  );
};

export default PrescriptionFill;
