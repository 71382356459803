import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from '@mui/material';
import './Guesaddressbox.scss';
import { Checkbox } from '@mui/material';
import { MagCountries } from '../../utils/MagCountriesList';
import { getRegionByCountryId } from '../../utils/callBack';

const Guesaddressbox = ({ data, setData }) => {
  const [regionsShip, setRegionsShip] = useState([]);
  const [regionsBil, setRegionsBil] = useState([]);
  const [SameAddress, setSameAddress] = useState(false);
  const [shipping, setShipping] = useState({});
  const [billing, setBilling] = useState({});
  const smallDisplay = useMediaQuery('(min-width:521px)');

  const handleChange = (event, index, type) => {
    const { name, value } = event.target;
    if (name !== 'street') {
      if (type === 'shipping') {
        let newShipping = { ...shipping, [name]: value };
        setShipping(newShipping);
        setData({ ...data, shipping: newShipping });
      } else {
        let newBilling = { ...billing, [name]: value };
        setBilling(newBilling);
        setData({ ...data, billing: newBilling });
      }
    } else {
      let streetArry = data?.[type]?.street
        ? data?.[type].street.slice()
        : ['', ''];
      if (streetArry.length < 2) {
        streetArry.push('');
      }
      if (index === 0) {
        streetArry.shift();
        streetArry.unshift(event.target.value);
      } else {
        streetArry.pop();
        streetArry.push(event.target.value);
      }
      if (type === 'shipping') {
        let newShipping = { ...shipping, street: streetArry };
        setShipping(newShipping);
        setData({ ...data, shipping: newShipping });
      } else {
        let newBilling = { ...billing, street: streetArry };
        setBilling(newBilling);
        setData({ ...data, billing: newBilling });
      }
    }
  };

  useEffect(() => {
    if (data?.shipping?.country_id) {
      setRegionsShip(getRegionByCountryId(data?.shipping?.country_id || ''));
      if (data?.billing?.country_id) {
        setRegionsBil(getRegionByCountryId(data?.billing?.country_id || ''));
      }
    }
  }, [data]);

  useEffect(() => {
    setData({ ...data, ['Billing_same_as_shipping']: SameAddress });
  }, [SameAddress]);

  return (
    <form className="guesform">
      <div className="shipping">
        <div className="top-part">
          <p className="heading">Shipping Address</p>
        </div>
        <div className="inputs-wrapper">
          <div className="inputs-row">
            <FormControl
              style={{
                width: smallDisplay
                  ? 'calc(50% + 32px)'
                  : '-webkit-fill-available',
              }}
            >
              <InputLabel>Country</InputLabel>
              <Select
                name="country_id"
                value={data?.shipping?.country_id || ''}
                label="Country"
                onChange={(e) => handleChange(e, 0, 'shipping')}
              >
                {MagCountries.map((country) => (
                  <MenuItem key={country.id} value={country.id}>
                    {country.full_name_english}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              style={{
                width: smallDisplay
                  ? 'calc(50% + 32px)'
                  : '-webkit-fill-available',
              }}
              disabled={regionsShip.length === 0}
            >
              <InputLabel>Province</InputLabel>
              <Select
                name="region_id"
                value={data?.shipping?.region_id || ''}
                label="Province"
                onChange={(e) => handleChange(e, 0, 'shipping')}
              >
                {regionsShip.map((region) => (
                  <MenuItem value={region?.id || ''}>{region?.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="inputs-row">
            <input
              value={data?.shipping?.city}
              onChange={(e) => handleChange(e, 0, 'shipping')}
              name="city"
              placeholder="Town / City"
            />
            <input
              value={data?.shipping?.postcode}
              onChange={(e) => handleChange(e, 0, 'shipping')}
              name="postcode"
              placeholder="Postal Code"
            />
          </div>
          <div className="inputs-row">
            <input
              value={data?.shipping?.street ? data?.shipping?.street[0] : ''}
              onChange={(e) => handleChange(e, 0, 'shipping')}
              name="street"
              placeholder="Street Address 1"
            />
            <input
              value={data?.shipping?.street ? data?.shipping?.street[1] : ''}
              onChange={(e) => handleChange(e, 1, 'shipping')}
              name="street"
              placeholder="Street Address 2"
            />
          </div>
        </div>
      </div>
      {!SameAddress && (
        <div className="billing" style={{ paddingTop: '24px' }}>
          <div className="top-part">
            <p className="heading">Billing Address </p>
          </div>
          <div className="inputs-wrapper">
            <div className="inputs-row">
              <FormControl
                style={{
                  width: smallDisplay
                    ? 'calc(50% + 32px)'
                    : '-webkit-fill-available',
                }}
              >
                <InputLabel>Country</InputLabel>
                <Select
                  name="country_id"
                  value={data?.billing?.country_id || ''}
                  label="Country"
                  onChange={(e) => handleChange(e, 0, 'billing')}
                >
                  {MagCountries.map((country) => (
                    <MenuItem key={country.id} value={country.id}>
                      {country.full_name_english}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                style={{
                  width: smallDisplay
                    ? 'calc(50% + 32px)'
                    : '-webkit-fill-available',
                }}
                disabled={regionsBil.length === 0}
              >
                <InputLabel>Province</InputLabel>
                <Select
                  name="region_id"
                  value={data?.billing?.region_id || ''}
                  label="Province"
                  onChange={(e) => handleChange(e, 0, 'billing')}
                >
                  {regionsBil.map((region) => (
                    <MenuItem value={region?.id || ''}>{region?.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="inputs-row">
              <input
                value={data?.billing?.city}
                onChange={(e) => handleChange(e, 0, 'billing')}
                name="city"
                placeholder="Town / City"
              />
              <input
                value={data?.billing?.postcode}
                onChange={(e) => handleChange(e, 0, 'billing')}
                name="postcode"
                placeholder="Postal Code"
              />
            </div>
            <div className="inputs-row">
              <input
                value={data?.billing?.street ? data?.billing?.street[0] : ''}
                onChange={(e) => handleChange(e, 0, 'billing')}
                name="street"
                placeholder="Street Address 1"
              />
              <input
                value={data?.billing?.street ? data?.billing?.street[1] : ''}
                onChange={(e) => handleChange(e, 1, 'billing')}
                name="street"
                placeholder="Street Address 2"
              />
            </div>
          </div>
        </div>
      )}
      <div className="checkbox">
        <Checkbox
          onChange={() => setSameAddress(!SameAddress)}
          checked={SameAddress}
        />
        <p className="checkbox_title">
          Billing address is same as shipping address
        </p>
      </div>
    </form>
  );
};

export default Guesaddressbox;
