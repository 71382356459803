import { Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { publicRequest } from '../utils/ApiConfig';
import noImage from '../assets/No_Image_Available.jpg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addTocart, createGuestCart } from '../redux/CartSlice';

const MegaMenyBestSeller = ({ categoryId }) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { categories } = useSelector((state) => state.category);
  const user = useSelector((state) => state.user.currentUser);
  const { guestCartId } = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProductByCategoryId = async () => {
      try {
        setIsLoading(true);
        const response = await publicRequest(
          `/products?category_id=${
            categoryId || categories[0].id
          }&page_size=3&page=1`
        );
        const producstData = response.data;
        setProducts(producstData.products);
        setIsLoading(false);
      } catch (err) {}
    };
    if (categoryId) {
      fetchProductByCategoryId();
    } else {
      if (categories && categories?.length > 0) {
        fetchProductByCategoryId();
      }
    }
  }, [categoryId]);

  // add to cart functionlaity
  const handleAddToCart = (e, product) => {
    e.stopPropagation();
    if (user) {
      dispatch(
        addTocart({
          magentoUserId: user.refrence_id,
          cartItem: {
            qty: 1,
            product_id: product?.id,
          },
        })
      );
    } else {
      if (guestCartId) {
        dispatch(
          addTocart({
            cartItem: {
              qty: 1,
              product_id: product?.id,
              quote_id: guestCartId,
            },
            cartId: guestCartId,
          })
        );
      } else {
        dispatch(
          createGuestCart({
            cartItem: {
              qty: 1,
              product_id: product?.id,
            },
            cartId: guestCartId,
          })
        );
      }
    }
  };

  return (
    <div className="megamenu-bestSeller-cont">
      <span className="best-seller-title">Best Sellers</span>
      <div className="products-wrapper">
        {isLoading ? (
          <CircularProgress sx={{ alignItems: 'center' }} color="success" />
        ) : (
          products.map((prod) => (
            <div
              className="product-box"
              onClick={() => navigate(`/product/${prod.id}`)}
              key={prod.id}
            >
              <div className="img-wrapper">
                <img src={prod?.images[0]?.url || noImage} alt="product" />
              </div>
              <div className="right-productDesc">
                <span className="product-name">{prod?.name}</span>
                <span className="price">
                  $
                  {prod?.price_info?.final_price === 0
                    ? prod?.price_info?.minimal_price
                    : prod?.price_info?.final_price}
                </span>
                {prod.type !== 'configurable' && (
                  <Button
                    className="add-to-cart"
                    onClick={(e) => handleAddToCart(e, prod)}
                  >
                    <ShoppingCartOutlinedIcon className="icon" />
                    Add
                  </Button>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default MegaMenyBestSeller;
