import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PatientDashboard from './PatientDashboard/PatientDashboard';
import SingleOrder from './SingleOrder/SingleOrder';
import Orders from './Orders/Orders';
import MinorAliments from './MinorAliments/MinorAliments';
import Prescription from './Prescriptions/Prescriptions';
import Wishlist from './Wishlist/Wishlist';
import SettingsAddress from './PatientDashboard/Settings/Adddress/Address';
import SettingsPayments from './PatientDashboard/Settings/Payments/Payments';
import SettingsInsurance from './PatientDashboard/Settings/Insurance/Insurance';
import SinglePrescription from './SinglePrescription/SinglePrescription';
import PrescriptionOrder from './PrescriptionOrder/PrescriptionOrder';
import SettingsGeneral from './PatientDashboard/Settings/General/General';
import SettingsSecurity from './PatientDashboard/Settings/Security/Security';

const DashboardRoutes = () => {
  return (
    <Routes>
      <Route index element={<PatientDashboard />} />
      <Route path="order/:orderId" element={<SingleOrder />} />
      <Route path="orders" element={<Orders />} />
      <Route path="minoraliments" element={<MinorAliments />} />
      <Route path="prescription">
        <Route index element={<Prescription />} />
        <Route path=":prescId" element={<SinglePrescription />} />
        <Route path="order/:prescId" element={<PrescriptionOrder />} />
      </Route>
      <Route path="wishlist" element={<Wishlist />} />
      <Route path="settings">
        <Route path="address" element={<SettingsAddress />} />
        <Route path="payments" element={<SettingsPayments />} />
        <Route path="insurance" element={<SettingsInsurance />} />
        <Route path="general" element={<SettingsGeneral />} />
        <Route path="security" element={<SettingsSecurity />} />
      </Route>
    </Routes>
  );
};

export default DashboardRoutes;
