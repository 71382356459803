import React, { useEffect, useState } from 'react';
import { CircularProgress, Container, useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { FreeMode, Navigation } from 'swiper/modules';
import './OurProductsSwiper.scss';
// import covid from '../../assets/covid.svg';
// import health from '../../assets/health.png';
// import beauty from '../../assets/beauty.png';
// import personalcare from '../../assets/personalcare.png';
// import babycare from '../../assets/babycare.png';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategories } from '../../redux/CategoriesSlice';
import CategorySwipper from './categorySwipper';
// import next from "../../assets/Button - Next slide.png";
// import prev from "../../assets/Left Arrow.png";

const OurProductsSwiper = () => {
  const small = useMediaQuery('(max-width:620px)');
  const medium = useMediaQuery('(max-width:870px)');
  const large = useMediaQuery('(max-width:960px)');
  const isMobile = useMediaQuery('(max-width:960px)');
  const [doubledCategory, setDoubledCategory] = useState([]);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const { categories, error, isLoading } = useSelector(
    (state) => state.category
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (categories && categories?.length === 0 && !error.status) {
      dispatch(getAllCategories());
    }
  }, []);

  useEffect(() => {
    if (categories && categories?.length > 0 && isMobile) {
      const groupedData = [];
      for (let i = 0; i < categories.length; i += 2) {
        groupedData.push(categories.slice(i, i + 2));
      }
      setDoubledCategory(groupedData);
    }
  }, [categories, isMobile]);
  return (
    <Container
      sx={{
        maxWidth: '1440px !important',
        paddingX: { xs: '0px !important', md: 'auto' },
      }}
    >
      <div className="our-products-wrapperH">
        {isLoading ? (
          <>
            <CircularProgress
              style={{
                alignSelf: 'center',
                color: 'green',
                marginTop: '150px',
              }}
            />
            <span style={{ marginBottom: '150px' }}>Loading</span>
          </>
        ) : (
          <div className="products">
            {isMobile ? (
              <Swiper
                // autoplay={true}
                loop={false}
                slidesPerView={(small && 1.6) || (medium && 3) || (large && 4)}
                spaceBetween={
                  18
                  // large ? 24 : medium ? 16 : small && 12
                }
                modules={[FreeMode, Navigation]}
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                }}
                onBeforeInit={(swiper) => {
                  swiper.params.navigation.prevEl = navigationPrevRef.current;
                  swiper.params.navigation.nextEl = navigationNextRef.current;
                }}
                className="mySwiper"
                freeMode={true}
                // speed={400}
              >
                {doubledCategory?.map((category) => (
                  <SwiperSlide>
                    {category.map((cat) => (
                      <CategorySwipper category={cat} />
                    ))}
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <div className="new-categories-wrapper">
                {categories.map((cat) => (
                  <div className="category-box">
                    <CategorySwipper category={cat} />
                  </div>
                ))}
              </div>
            )}
            {/* <div className="buttons">
            <img src={prev} alt="arrow" ref={navigationPrevRef} />
            <img src={next} alt="arrow" ref={navigationNextRef} />
          </div> */}
          </div>
        )}
      </div>
    </Container>
  );
};

export default OurProductsSwiper;
