import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { userRequest } from '../utils/ApiConfig';
import { handleAutoLogout } from '../utils/callBack';

export const getAlluserNotifications = createAsyncThunk(
  'notification/getAll',
  async ({ userId, page }, { dispatch }) => {
    try {
      const response = await userRequest.get(
        `/user/${userId}/notifications?page=1&per_page=${page * 10}`
      );
      const notifications = response.data;
      return notifications;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching notificattion');
      }
    }
  }
);
export const updateNotification = createAsyncThunk(
  'notification/updateNotification',
  async ({ userId, notificationId, page }, { dispatch }) => {
    try {
      await userRequest.put(`/user/${userId}/notification/${notificationId}`, {
        status: true,
      });
      dispatch(
        getAlluserNotifications({
          userId: userId,
          page: page,
        })
      );
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching notificattion');
      }
    }
  }
);
export const deleteNotification = createAsyncThunk(
  'notification/deleteNotification',
  async ({ userId, notificationId, page }, { dispatch }) => {
    try {
      await userRequest.delete(
        `/user/${userId}/notification/${notificationId}`
      );
      dispatch(
        getAlluserNotifications({
          userId: userId,
          page: page,
        })
      );
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching notificattion');
      }
    }
  }
);

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notifLoading: false,
    error: {
      status: false,
      message: null,
    },
    notifList: [],
  },
  reducers: {
    addToNotifList: (state, actions) => {
      const currentNotifications = state.notifList;
      state.notifList = [actions.payload, ...currentNotifications];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAlluserNotifications.pending, (state) => {
        state.notifLoading = true;
      })
      .addCase(getAlluserNotifications.fulfilled, (state, action) => {
        state.notifList = action.payload.notifications;
        state.notifLoading = false;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getAlluserNotifications.rejected, (state, action) => {
        state.notifLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Failed Loading notification',
        };
      })
      .addCase(updateNotification.pending, (state) => {
        state.notifLoading = true;
      })
      .addCase(updateNotification.rejected, (state, action) => {
        state.notifLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Failed updating notification',
        };
      })
      .addCase(deleteNotification.pending, (state) => {
        state.notifLoading = true;
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.notifLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Failed updating notification',
        };
      });
  },
});
export const { addToNotifList } = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;
