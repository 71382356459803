import React, { useEffect, useState } from 'react';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import './InsuranceComponent.scss';
import InsurancePopup from './InsurancePopup';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewDocument,
  deleteDocument,
  updateDocument,
} from '../../redux/DocumentsSlice';

const InsuranceComponent = () => {
  const [data, setData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const user = useSelector((state) => state.user.currentUser);
  const { list, isUpdated, isLoading } = useSelector(
    (state) => state.documents
  );
  const [insuranceData, setInsuranceData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    setInsuranceData(list.filter((list) => list.type === 'insurance'));
  }, [list]);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  // Function to find and update current default address
  const hadleUpdateDefaultAddress = (documentData) => {
    dispatch(
      updateDocument({
        userId: user._id,
        documentData: { ...documentData, isDefault: true },
      })
    );
  };

  const openEditAddress = (e, insuranceCard) => {
    e.stopPropagation();
    setEditMode(true);
    setData(insuranceCard);
    setOpen(true);
  };

  useEffect(() => {
    if (!open) {
      setEditMode(false);
      setData(null);
    }
  }, [open]);

  // handles update document by id
  const handleUpdate = () => {
    dispatch(
      updateDocument({
        userId: user._id,
        documentData: data,
      })
    );
  };
  // post new document by type to Database
  const handleAdd = (e) => {
    e.preventDefault();
    dispatch(
      addNewDocument({
        userId: user._id,
        documentData: { ...data, type: 'insurance' },
      })
    );
  };

  // delete document by id
  const handledelete = () => {
    dispatch(
      deleteDocument({
        userId: user._id,
        documentId: data._id,
      })
    );
  };

  useEffect(() => {
    setOpen(false);
  }, [isUpdated]);

  return (
    <div className={`insuranceComponent ${isLoading ? 'disabled' : ''}`}>
      <InsurancePopup
        handleClose={handleClose}
        open={open}
        edit={editMode}
        data={data}
        setData={setData}
        handleUpdate={handleUpdate}
        handleAdd={handleAdd}
        handledelete={handledelete}
        type={'insurance'}
      />
      <p className="section-title">Insurance information</p>
      {insuranceData.length ? (
        <div className="insurance-card-wrapper">
          {insuranceData.map((insuranceCard) => (
            <div
              key={insuranceCard._id}
              className={`payment-box ${
                insuranceCard.isDefault ? 'default' : ''
              }`}
              onClick={() => hadleUpdateDefaultAddress(insuranceCard)}
            >
              <div className="circular-checkbox">
                <DoneOutlinedIcon style={{ fontSize: '13px', color: '#fff' }} />
              </div>
              <div className="desc-box">
                <div className="top">
                  <p className="insurance-name">
                    {insuranceCard?.document_name || ''}
                  </p>
                  {insuranceCard.isDefault && (
                    <span className="badge">Default</span>
                  )}
                </div>
                <div className="bottom-div">
                  <div
                    className="img-wrapper"
                    style={{
                      backgroundImage: `url(${insuranceCard.attatchement_front_url})`,
                    }}
                  ></div>
                  <div
                    className="img-wrapper"
                    style={{
                      backgroundImage: `url(${insuranceCard.attatchement_back_url})`,
                    }}
                  ></div>
                </div>
              </div>
              <IconButton
                className="edit-bttn"
                onClick={(e) => openEditAddress(e, insuranceCard)}
              >
                <ModeEditOutlineOutlinedIcon style={{ fontSize: '20px' }} />
              </IconButton>
            </div>
          ))}
        </div>
      ) : (
        <div className="empty-div">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="83"
            height="82"
            viewBox="0 0 83 82"
            fill="none"
          >
            <path
              d="M60.8753 60.3623C55.6043 65.8883 48.8948 69.8323 41.5028 71.75C36.1837 70.3704 31.1963 67.9356 26.8369 64.5903C22.4774 61.2451 18.8349 57.0576 16.1258 52.2768C13.4167 47.496 11.6962 42.2194 11.0667 36.7605C10.4371 31.3017 10.9113 25.772 12.4611 20.5C15.1739 20.623 17.8697 20.4795 20.5142 20.0763M31.2015 16.9125C34.9501 15.221 38.4227 12.975 41.5028 10.25C49.4835 17.3108 59.8997 20.9871 70.5444 20.5C72.1082 25.82 72.5765 31.4019 71.9211 36.9081C71.2657 42.4143 69.5002 47.7304 66.7314 52.5347M10.75 10.25L72.25 71.75"
              stroke="#8F91A1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p>No Insurance Information found.</p>
        </div>
      )}
      <Button className="add-insurance-bttn" onClick={() => setOpen(true)}>
        {!insuranceData.length ? <AddIcon /> : ''}
        <span>
          {!insuranceData.length ? 'Add Insurance' : 'Add New Insurance'}
        </span>
      </Button>
    </div>
  );
};

export default InsuranceComponent;
