import React, { useEffect, useState } from 'react';
import { publicRequest } from '../../utils/ApiConfig';
import { Skeleton } from '@mui/material';
import { CMSURL } from '../../utils/config';

const ProductImage = ({ product, width }) => {
  const [productImage, setProductImage] = useState(null);
  const [productSku, setProductSku] = useState(null);

  useEffect(() => {
    const fetchProductImage = async () => {
      try {
        setProductImage(null);
        const response = await publicRequest(`/products/sku/${product?.sku}`);
        const productData = response.data;
        if (productData?.images) {
          setProductImage(productData?.images[0]?.url);
          setProductSku(productData?.name);
        } else {
          const imageGallery = productData.media_gallery_entries[0].file;
          setProductImage(
            `${CMSURL}/pub/media/catalog/product/${imageGallery}`
          );
          setProductSku(productData?.name);
        }
      } catch (err) {
        // console.log(err);
      }
    };
    if (product?.sku && (!productImage || product?.name !== productSku)) {
      fetchProductImage();
    }
  }, [product]);

  return (
    <>
      {productImage ? (
        <img
          src={productImage}
          alt={product?.name}
          style={{ maxWidth: width }}
        />
      ) : (
        <Skeleton style={{ minWidth: '75px', minHeight: '75px' }}></Skeleton>
      )}
    </>
  );
};

export default ProductImage;
