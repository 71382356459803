import React, { useState } from 'react';
import './OrderStatus.scss';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import OrderStepper from '../../components/DashboardComponents/OrderStepper';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import ReorderModal from './ReorderModal';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ProductImgWrapper from './OrderStatusImgWrapper';

const OrderStatus = ({ data }) => {
  const [expanded, setExpanded] = useState([0, 1]);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const [open, setOpen] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [orderId, setOrderId] = useState('');

  const handleReorderClick = (order) => {
    setOrderItems(order.items);
    setOrderId(order.increment_id);
    setOpen(true);
  };

  const handleExpand = (index) => {
    if (expanded.includes(index)) {
      const filtered = expanded.slice().filter((x) => x !== index);
      setExpanded(filtered);
    } else {
      setExpanded([...expanded, index]);
    }
  };

  return (
    <div className="orders-overview">
      <ReorderModal
        setOpen={setOpen}
        open={open}
        items={orderItems}
        orderId={orderId}
      />
      <div className="title-div">
        <div className="left">
          <ShoppingCartOutlinedIcon
            style={{ color: '#000', width: '30px', height: '30px' }}
          />
          <p>Orders Overview</p>
        </div>
        {pathname !== '/dashboard/orders' && (
          <Button
            className="view-all"
            onClick={() => navigate('/dashboard/orders')}
          >
            View All Orders
          </Button>
        )}
      </div>
      {data?.length > 0 ? (
        <div className="orders-boxes-wrapper">
          {data
            ?.filter(
              (order) =>
                order.status !== 'canceled' &&
                order.status !== 'pending_payment'
            )
            ?.slice(0, 2)
            ?.map((order, i) => (
              <div className="order-box" key={i}>
                <div
                  className="top-order-details-div"
                  onClick={() => handleExpand(i)}
                >
                  <p>Order Id# {order?.increment_id}</p>
                  <p style={{ fontWeight: 700 }}>${order?.grand_total}</p>
                  <span
                    className="drop-down-mobile"
                    style={{
                      transform: `rotate(${
                        expanded.includes(i) ? '180deg' : '0deg'
                      }`,
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </span>
                </div>
                <div className="bottom-div mobile">
                  <div className="img-container-order">
                    {order?.items.slice(0, 4).map((orderItems) => (
                      <ProductImgWrapper item={orderItems} />
                    ))}
                    <span className="rest-imges">
                      {order?.items?.length > 4
                        ? '+' + (order?.items?.length - 4)
                        : ''}
                    </span>
                  </div>
                </div>
                <div
                  className="bottom-div-delivery-status"
                  style={{
                    maxHeight: expanded.includes(i) ? '440px' : '0px',
                    overflow: 'hidden',
                  }}
                >
                  <OrderStepper status={order?.status} />
                </div>
                <div className="bottom-div">
                  <div className="img-container-order">
                    {order?.items?.slice(0, 4).map((orderItems) => (
                      <ProductImgWrapper item={orderItems} />
                    ))}
                    <span className="rest-imges">
                      {order?.items?.length > 4
                        ? '+' + (order?.items?.length - 4)
                        : ''}
                    </span>
                  </div>
                  <div className="button-div">
                    <Button
                      className="presc-details-bttn"
                      onClick={() =>
                        navigate(`/dashboard/order/${order?.entity_id}`)
                      }
                    >
                      <InsertDriveFileOutlinedIcon />
                      Details
                    </Button>
                    <Button
                      className="reorder-bttn"
                      onClick={() => handleReorderClick(order)}
                    >
                      Reorder
                    </Button>
                  </div>
                </div>
                <div className="bottom-div mobile">
                  <div className="button-div">
                    <Button
                      className="presc-details-bttn"
                      onClick={() =>
                        navigate(`/dashboard/order/${order?.entity_id}`)
                      }
                    >
                      <InsertDriveFileOutlinedIcon />
                      Details
                    </Button>
                    <Button
                      className="reorder-bttn"
                      onClick={() => handleReorderClick(order)}
                    >
                      Reorder
                    </Button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div className="empty-orders">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="48"
            viewBox="0 0 49 48"
            fill="none"
          >
            <path
              d="M46.7849 3.42847H27.9277"
              stroke="#8F91A1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M46.7849 13.7141H27.9277"
              stroke="#8F91A1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M46.7849 24H27.9277"
              stroke="#8F91A1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M46.7849 44.5713H27.9277"
              stroke="#8F91A1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M46.7849 34.2856H27.9277"
              stroke="#8F91A1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.21387 5.14275V18.857C2.21387 19.8038 2.98138 20.5713 3.92815 20.5713H17.6424C18.5892 20.5713 19.3567 19.8038 19.3567 18.857V5.14275C19.3567 4.19599 18.5892 3.42847 17.6424 3.42847H3.92815C2.98138 3.42847 2.21387 4.19599 2.21387 5.14275Z"
              stroke="#8F91A1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.21387 29.1428V42.857C2.21387 43.8037 2.98138 44.5713 3.92815 44.5713H17.6424C18.5892 44.5713 19.3567 43.8037 19.3567 42.857V29.1428C19.3567 28.196 18.5892 27.4285 17.6424 27.4285H3.92815C2.98138 27.4285 2.21387 28.196 2.21387 29.1428Z"
              stroke="#8F91A1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="empty-text">No past orders found.</p>
          <Button className="link-to-shop" onClick={() => navigate('/shop')}>
            Shop Now
          </Button>
        </div>
      )}
      {pathname !== '/dashboard/orders' && (
        <Button
          className="view-all-mobile"
          onClick={() => navigate('/dashboard/orders')}
        >
          View All Orders
        </Button>
      )}
    </div>
  );
};

export default OrderStatus;
