import React, { useState } from 'react';
import './DashboardNavigation.scss';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigationLinks from './NavigationLinks';
import { useSelector } from 'react-redux';
import noUserImg from '../../assets/noUser.svg';

const DashboardNavigation = () => {
  const [expansion, setExpansion] = useState(false);
  const user = useSelector((state) => state.user.currentUser);

  const getAge = (dob) => {
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };

  return (
    <div className="dasboard-Navigation">
      <div className="top-userInfo">
        <div className="user-top">
          <Avatar
            alt={user.firstname}
            src={user?.profile_img || noUserImg}
            sx={{ width: 70, height: 70 }}
          />
          <div className="text-wrapper">
            <p className="user-name">
              {user.firstname} {user.lastname}
            </p>
            <p className="user-email">{user.email}</p>
          </div>
        </div>
        {expansion && <Divider light />}

        <Accordion
          sx={{
            border: 'none',
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
          expanded={expansion}
          onChange={(e) => setExpansion(!expansion)}
        >
          <AccordionSummary
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            {expansion ? 'Less Details' : 'More Details'}
          </AccordionSummary>
          <AccordionDetails
            style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span className="main-txt">Age</span>
              <span className="sub-txt">
                {user.dob ? getAge(new Date(user.dob)) : 'NA'}
              </span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span className="main-txt">Phone number:</span>
              <span className="sub-txt">{user?.telephone || 'NA'}</span>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <NavigationLinks />
    </div>
  );
};

export default DashboardNavigation;
