import React, { useEffect } from 'react';
import './SinglePrescription.scss';
import DashboardNavigation from '../../../components/DashboardComponents/DashboardNavigation';
import { Alert, Box, Button, CircularProgress, Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useNavigate, useParams } from 'react-router-dom';
import NotesHistory from '../../../components/DashboardComponents/NotesHistory';
import SinglePrescriptionTable from '../../../components/DashboardComponents/SinglePrescriptionTable';
import { useDispatch, useSelector } from 'react-redux';
import { getPrescriptionById } from '../../../redux/PrescriptionSlice';

const SinglePrescription = () => {
  const { prescId } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);
  const { prescription, isLoading, error } = useSelector(
    (state) => state.prescription
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPrescription = () => {
      dispatch(
        getPrescriptionById({
          userId: user?._id,
          prescriptionId: prescId,
        })
      );
    };
    if (prescId) {
      fetchPrescription();
    }
  }, [prescId]);

  return (
    <div className="singlePrescription">
      <div className="main-conatiner">
        {isLoading || error.status ? (
          <Box
            style={{
              height: '50vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {!error.status ? (
              <>
                <CircularProgress color="success" />
                <span>Loading...</span>
              </>
            ) : (
              <Alert severity="error">Error! Prescription not found</Alert>
            )}
          </Box>
        ) : (
          <div className="wrapper">
            <DashboardNavigation />
            <div className="singlePrescription-right">
              <Button className="back-bttn" onClick={() => navigate(-1)}>
                <ArrowBackIcon />
                Back
              </Button>
              <div className="top-container">
                <div className="top-wrapper-left">
                  <h1>{prescription?.name}</h1>
                  <div className="desc-prescription">
                    <span className={`status ${prescription?.status}`}>
                      {prescription?.status}
                    </span>
                    <span className="presc-no">
                      {prescription?.number || ''}
                    </span>
                  </div>
                </div>
                <div className="button-right">
                  {/* <Button
                    className="refill-bttn"
                    onClick={() =>
                      navigate('/dashboard/prescription?prescription=refill')
                    }
                  >
                    Refill Your Prescription
                  </Button> */}
                  {prescription?.status === 'Pending Payment' && (
                    <Link
                      className="pay-bttn"
                      to={`/dashboard/prescription/order/${prescription?._id}`}
                    >
                      ${prescription?.order?.total} | pay now
                    </Link>
                  )}
                </div>
              </div>

              <SinglePrescriptionTable data={prescription?.basket_items} />
              <Divider style={{ width: '100%' }} />
              <NotesHistory prescId={prescription?._id} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SinglePrescription;
