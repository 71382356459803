import React, { useEffect, useState } from 'react';
import { IconButton, Modal } from '@mui/material';
import './Sidecart.scss';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteCartProduct,
  getUserCart,
  updateCartProduct,
} from '../redux/CartSlice';
import ProductImage from '../components/CartComponents/ProductImage';

const Sidecart = ({ open, handleClose }) => {
  const { items, cartData, cartLoading, guestCartId } = useSelector(
    (state) => state.cart
  );
  const user = useSelector((state) => state.user.currentUser);
  const [cartTotal, setCartTotal] = useState(0);
  const dispatch = useDispatch();
  const variants = {
    open: { opacity: 1, x: 0 },
    close: { opacity: 0.5, x: 100 },
  };

  //Get Cart when user sign in
  useEffect(() => {
    if (user || guestCartId) {
      dispatch(
        getUserCart({
          magentoUserId: user?.refrence_id || 0,
          cartId: guestCartId,
        })
      );
    }
  }, [user]);

  //gets cart total
  useEffect(() => {
    if (items?.length > 0) {
      const total = items.reduce(
        (prev, curr) => prev + curr.price * curr.qty,
        0
      );
      setCartTotal(total);
    } else {
      setCartTotal(0);
    }
  }, [items]);

  const handleUpdateProduct = async (data, quantity) => {
    let cartItem;
    if (user) {
      cartItem = {
        item_id: data.item_id,
        qty: quantity,
      };
    } else {
      cartItem = {
        item_id: data.item_id,
        qty: quantity,
        quote_id: guestCartId,
      };
    }
    if (quantity > 0) {
      dispatch(
        updateCartProduct({
          magentoUserId: user?.refrence_id,
          cartItem,
        })
      );
    } else {
      dispatch(
        deleteCartProduct({
          magentoUserId: user?.refrence_id,
          cartItem,
        })
      );
    }
  };

  const handleIncerement = (data) => {
    handleUpdateProduct(data, data.qty + 1);
  };
  const handleDelete = (data) => {
    let cartItem;
    if (user) {
      cartItem = {
        item_id: data.item_id,
      };
    } else {
      cartItem = {
        item_id: data.item_id,
        quote_id: guestCartId,
      };
    }
    dispatch(
      deleteCartProduct({
        magentoUserId: user?.refrence_id,
        cartItem,
      })
    );
  };

  const handleDecrement = (data) => {
    handleUpdateProduct(data, data.qty - 1);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/* Needs an edit in the states to show the close animation */}
      <motion.div
        variants={variants}
        initial={{ opacity: 0.5, x: 150 }}
        animate={open ? 'open' : 'close'}
        transition={{ ease: 'easeOut', duration: 0.3 }}
        className="Sidecartmodal"
      >
        <div className={`cart-wrap ${cartLoading ? 'disabled' : ''}`}>
          <div className="title">
            <h1>Your Cart</h1>
            <div className="closing">
              <span className="count">
                {cartData?.items_count ? cartData.items_count : 0} Items
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                onClick={handleClose}
              >
                <path
                  d="M27.9961 12L11.9961 28.0005"
                  stroke="#979797"
                  strokeWidth="1.28573"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.9961 12L27.9961 28.0005"
                  stroke="#979797"
                  strokeWidth="1.28573"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          {items?.length > 0 ? (
            <div className="cartwrapping">
              <div className="CartItems">
                {items?.length > 0 &&
                  items.map((product, index) => (
                    <div className="item" key={index}>
                      <ProductImage product={product} />

                      <div className="data">
                        <p className="name">{product?.name}</p>
                        <div className="info">
                          <p className="price">
                            ${(product?.price * product?.qty).toFixed(2)}
                          </p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                          >
                            <path d="M1 1L7 7M7 1L1 7" stroke="black" />
                          </svg>
                          <div className="input">
                            <input
                              type="text"
                              placeholder={product?.qty}
                              disabled
                            />
                            <div className="incbuttons">
                              <IconButton
                                onClick={() => handleIncerement(product)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="11"
                                  height="9"
                                  viewBox="0 0 11 6"
                                  fill="none"
                                >
                                  <path
                                    d="M9.5 5L5.5 1L1.5 5"
                                    stroke="white"
                                    strokeWidth="1.33333"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </IconButton>
                              <IconButton
                                onClick={() => handleDecrement(product)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="11"
                                  height="9"
                                  viewBox="0 0 11 6"
                                  fill="none"
                                >
                                  <path
                                    d="M9.5 1L5.5 5L1.5 1"
                                    stroke="white"
                                    strokeWidth="1.33333"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </IconButton>
                            </div>
                          </div>
                          <IconButton onClick={() => handleDelete(product)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="16"
                              viewBox="0 0 15 16"
                              fill="none"
                              className="delete"
                            >
                              <path
                                d="M1.875 4.25H13.125"
                                stroke="black"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M11.875 4.25V13C11.875 13.625 11.25 14.25 10.625 14.25H4.375C3.75 14.25 3.125 13.625 3.125 13V4.25"
                                stroke="black"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M5 4.25V3C5 2.375 5.625 1.75 6.25 1.75H8.75C9.375 1.75 10 2.375 10 3V4.25"
                                stroke="black"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="buttons">
                <div className="cost">
                  <div className="field border">
                    <p>Subtotal</p>
                    <p>${cartTotal.toFixed(2)}</p>
                  </div>
                  <div className="field border">
                    <p>Shipping</p>
                    <p>calculated at checkout</p>
                  </div>
                  <div className="field">
                    <p>Total</p>
                    <p>${cartTotal.toFixed(2)}</p>
                  </div>
                </div>
                {/* <div className="discount">
                  <input type="text" placeholder="Enter the discount code" />
                  <button className="apply">Apply</button>
                </div> */}
                <Link to="/cart" className="button" onClick={handleClose}>
                  {' '}
                  Checkout{' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <path
                      d="M6.5 12.5L10.5 8.5L6.5 4.5"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          ) : (
            <div className="textwrap">
              {' '}
              <h1 className="note">Your Cart is Currently Empty</h1>
              <Link to="/shop" className="button" onClick={handleClose}>
                Return To Shop
              </Link>
            </div>
          )}
        </div>
      </motion.div>
    </Modal>
  );
};

export default Sidecart;
