import React from 'react';
import './ThankYouPresc.scss';
import { Button } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AutomaticReminder from './AutomaticReminder';
import ThanksImg from '../../assets/thankyou.svg';

const ThankYouPresc = ({ prescId, text, prescOrder, orderMethod }) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const currentForm = searchParams.get('prescription');
  return (
    <div className="thank-you-presc-submittion">
      <img src={ThanksImg} alt="Successfull-img" className="thanks-img" />
      <div className="bottom-summary">
        <div className="top">
          <p className="title">
            {prescOrder
              ? orderMethod === 'Deliver'
                ? 'Payment Successful - Delivery in Progress!'
                : 'Payment Successful - Ready for Pickup!'
              : 'Prescription Sent!'}
          </p>
          <span
            className="prescriptionId"
            style={{ justifyContent: prescOrder ? 'center' : 'left' }}
          >
            <span>{prescOrder ? 'Order Id' : 'Ticket Id:'}</span>
            {prescId.map((id, i) => (
              <span>
                {id}
                {i + 1 !== prescId.length ? ', ' : ''}
              </span>
            ))}
          </span>
        </div>
        <span className="mssg">{text}</span>
        {!prescOrder ? (
          <Button className="returnbttn" onClick={() => navigate('/dashboard')}>
            Back to dashboard
          </Button>
        ) : (
          <div style={{ display: 'flex', gap: '34px' }}>
            <Button
              className="returnbttn"
              onClick={() => navigate('/dashboard')}
            >
              Check Prescription
            </Button>
            <Button
              className="returnbttn white"
              onClick={() => navigate('/dashboard')}
            >
              Back to dashboard
            </Button>
          </div>
        )}
      </div>
      {currentForm === 'refill' && <AutomaticReminder />}
    </div>
  );
};

export default ThankYouPresc;
