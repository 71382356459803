import React, { useEffect } from 'react';
import './Insurance.scss';
import DashboardNavigation from '../../../../../components/DashboardComponents/DashboardNavigation';
import InsuranceComponent from '../../../../../components/DashboardComponents/InsuranceComponent';
import NationalIdComponent from '../../../../../components/DashboardComponents/NationalIdComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDocuments, reset } from '../../../../../redux/DocumentsSlice';
import { Alert } from '@mui/material';

const SettingsInsurance = () => {
  const { error, isUpdated } = useSelector((state) => state.documents);
  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    const allUserDocuments = () => {
      dispatch(getAllDocuments({ userId: user._id }));
    };
    allUserDocuments();
  }, []);

  // makes the alert disapperas after 5 seconds in either cases error or success
  useEffect(() => {
    if (isUpdated || error.status) {
      setTimeout(() => {
        dispatch(reset());
      }, [5000]);
    }
  }, [isUpdated, error]);

  return (
    <div className="settingsInsurance">
      <div className="main-conatiner">
        {(error.status || isUpdated) && (
          <Alert
            severity={error.status ? 'error' : 'success'}
            style={{
              position: 'fixed',
              bottom: '16px',
              right: '16px',
              zIndex: '50',
            }}
          >
            {error.status ? error.message : 'documents updated successfully'}
          </Alert>
        )}
        <div className="wrapper">
          <DashboardNavigation />
          <div className="right-div-settingsInsurance">
            <NationalIdComponent />
            <InsuranceComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsInsurance;
