import React from 'react';
import './NotesMessage.scss';
import { Avatar, Button } from '@mui/material';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import { formatDate } from '../../utils/callBack';

const NotesMessage = ({ user, msg, setModelOpen }) => {
  //checks if same user
  const isME = (senderId) => {
    if (senderId === user._id) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="presc-message-container">
      <div className="presc-notes-message">
        <div className="top-user-details">
          <div className="user-left">
            <Avatar
              alt={
                isME(msg?.sender?._id || msg?.sender)
                  ? user?.firstname
                  : msg?.sender?.first_name
              }
              src={
                isME(msg?.sender?._id || msg?.sender)
                  ? user?.profile_img
                  : msg?.sender?.profile_img
              }
              style={{
                width: '41px',
                height: '41px',
                backgroundColor: '#219D50',
              }}
            />
            <p
              className={`user-name ${
                isME(msg?.sender?._id || msg?.sender) ? '' : 'doctor'
              }`}
            >
              {isME(msg?.sender?._id || msg?.sender)
                ? 'Me'
                : 'Dr. ' +
                  msg?.sender?.first_name +
                  ' ' +
                  msg?.sender?.last_name}
            </p>
          </div>
          <p className="message-date">{formatDate(msg?.createdAt)}</p>
        </div>
        <p className="message-text">{msg?.content}</p>
        {/* {!msg.sameUser && (
          <div className="reply-div">
            <Button className="reply-bttn" onClick={() => setModelOpen(true)}>
              Reply <ReplyOutlinedIcon />
            </Button>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default NotesMessage;
