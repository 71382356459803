import React, { useEffect, useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { MuiTelInput } from 'mui-tel-input';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { updateUser, updateUserPassword } from '../../redux/userSlice';
import { useDispatch } from 'react-redux';

const SecurityForms = ({ type, userData, handleChange, userNewData }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const dispatch = useDispatch();

  const handleUpdateUserTelephone = async () => {
    dispatch(
      updateUser({
        userId: userData._id,
        data: {
          telephone: userNewData.telephone,
        },
      })
    );
  };
  const handleUpdateUserPassword = async () => {
    dispatch(
      updateUserPassword({
        userId: userData._id,
        password: userNewData.current_password,
        newPassword: userNewData.new_password,
      })
    );
  };

  return (
    <div className="security-section">
      <p>{type}</p>
      <div className="form-wrapper">
        {type === 'Password' && (
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="current_password">Current Password</InputLabel>
            <OutlinedInput
              name="current_password"
              type={showPassword ? 'text' : 'password'}
              onChange={handleChange}
              value={userNewData?.current_password || ''}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Current Password"
            />
          </FormControl>
        )}
        {type === 'Password' && (
          <TextField
            className="input"
            label="New Password"
            type="password"
            name="new_password"
            onChange={handleChange}
            value={userNewData?.new_password || ''}
          />
        )}
        {type === 'Password' && (
          <TextField
            className="input"
            label="Confirm Password"
            name="confirm_password"
            onChange={handleChange}
            type="password"
            value={userNewData?.confirm_password || ''}
          />
        )}
        {type !== 'Password' && (
          <MuiTelInput
            name="telephone"
            value={userNewData?.telephone || userData?.telephone || ''}
            onChange={handleChange}
            label="Phone number"
            className="no-border-input"
            sx={{
              width: '100%',
              '& fieldset': { borderColor: '#DEDEDE !important' },
            }}
            InputLabelProps={{
              sx: {
                color: '#616161',
                fontWeight: 400,
                fontSize: '16px',
              },
            }}
            InputProps={{
              sx: {
                borderColor: '#DEDEDE',
                '&:hover fieldset': {
                  border: '1px solid #DEDEDE !important',
                },
                '&:focus-within fieldset, &:focus-visible fieldset': {
                  border: '1px solid #00588F !important',
                  boxShadow:
                    '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(32, 66, 137, 0.10)',
                },
              },
            }}
            defaultCountry="CA"
          />
        )}
        <Button
          className="save-changes"
          onClick={
            type === 'Password'
              ? handleUpdateUserPassword
              : handleUpdateUserTelephone
          }
          disabled={
            type !== 'Password'
              ? !userNewData?.telephone
              : !userNewData?.current_password ||
                userNewData?.new_password !== userNewData?.confirm_password ||
                userNewData?.new_password === ''
          }
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default SecurityForms;
