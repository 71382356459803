import React, { useEffect, useState } from 'react';
import './ShopMegaMenu.scss';
// import { publicRequest } from "../../utils/ApiRequests";
import { Link, useLocation } from 'react-router-dom';
// import { fetchWooCateg } from "../../utils/ApiFunctions";
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategories } from '../../redux/CategoriesSlice';
import { Box, CircularProgress } from '@mui/material';
import MegaMenuCategoryImage from '../MegaMenuCategoryImage';
import MegaMenyBestSeller from '../MegaMenuBestSeller';
import noImage from '../../assets/No_Image_Available.jpg';

const ShopMegaMenu = ({ shown, setMegaShown }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [allCategoiresImages, setAllCategoriesImages] = useState([]);
  const [categorySelectedId, setcategorySelectedId] = useState(null);
  const { categories, isLoading, error } = useSelector(
    (state) => state.category
  );

  useEffect(() => {
    setMegaShown(false);
  }, [location.pathname]);

  useEffect(() => {
    if (categories.length === 0 && !error.status && shown) {
      dispatch(getAllCategories());
    }
  }, [shown]);

  const getImageByCategoryId = (categoryId) => {
    const target = allCategoiresImages.find((cate) => cate.id === categoryId);
    if (target) {
      return target.img;
    } else {
      return false;
    }
  };

  return (
    <div
      className={`shop-mega-menu ${shown ? 'shown' : ''}`}
      onMouseLeave={() => setMegaShown(false)}
    >
      {isLoading ? (
        <Box
          sx={{
            height: '300px',
            display: 'flex',
          }}
        >
          <CircularProgress color="success" style={{ alignSelf: 'center' }} />
        </Box>
      ) : (
        <div className="top-link">
          <div className="megamenu-topwrapper">
            <div className="tabs-wrapper">
              <div className="tabs">
                {categories?.map((cat, index) => (
                  <div
                    className={`tab ${
                      categorySelectedId === cat.id ||
                      (!categorySelectedId && index === 0)
                        ? 'active'
                        : ''
                    }`}
                    key={cat.id}
                    onClick={() => setcategorySelectedId(cat.id)}
                  >
                    <MegaMenuCategoryImage
                      category={cat}
                      setAllCategoriesImages={setAllCategoriesImages}
                    />
                    <span className="cat-name"> {cat?.name}</span>
                  </div>
                ))}
              </div>
              {categories.map(
                (cat, index) =>
                  (categorySelectedId === cat.id ||
                    (!categorySelectedId && index === 0)) && (
                    <div className="tabpanel" key={cat.id}>
                      {cat.children_data.map((subCat) => (
                        <Link
                          className="subcat-link"
                          to={`/shop/${cat.id}/${subCat.id}`}
                          key={subCat.id}
                        >
                          {subCat.name}
                        </Link>
                      ))}
                      {allCategoiresImages?.length > 0 && (
                        <img
                          src={getImageByCategoryId(cat.id) || noImage}
                          alt={cat.name}
                          className="opacity-image"
                        />
                      )}
                    </div>
                  )
              )}
            </div>
            <MegaMenyBestSeller categoryId={categorySelectedId} />
          </div>
          {/* {!isLoading &&
            categories.map((cat) => (
              <div className="box" key={cat.id}>
                <span className="cat-title">{cat?.name}</span>
                <ul className="subcat">
                  {cat?.children_data?.map((subCat) => (
                    <li key={subCat.id}>
                      <Link to={`/shop/${cat?.id}/${subCat.id}`}>
                        {subCat.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))} */}
        </div>
      )}
      <div className="bottom-section">
        <div className="wrapping">
          <div className="text-wrappers-shop-mega">
            <span className="text">Comprehensive Product Selection</span>
            <span className="text">Professional Health Guidance</span>
            <span className="text">Secure and Convenient Transactions</span>
          </div>
          <Link to="shop" className="button">
            {' '}
            Shop All
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ShopMegaMenu;
