import React, { useEffect, useState } from 'react';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  useMediaQuery,
} from '@mui/material';
import './AddEditAddressModal.scss';
import { MuiTelInput } from 'mui-tel-input';
import { MagCountries } from '../../utils/MagCountriesList';
import { getRegionByCountryId } from '../../utils/callBack';

const AddEditAddressModal = ({
  open,
  handleClose,
  data,
  setData,
  addAddress,
  deleteAddress,
  editAddress,
  edit,
  billing,
  isLoading,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [regions, setRegions] = useState([]);
  const smallDisplay = useMediaQuery('(min-width:521px)');

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    if (name !== 'street') {
      setData({ ...data, [name]: value });
      if (event.target.name === 'country_id') {
        setData({
          ...data,
          [name]: value,
          region: { region_id: 0 },
          region_id: 0,
        });
      }
    } else {
      let streetArry = data?.street ? data.street.slice() : ['', ''];
      if (streetArry.length < 2) {
        streetArry.push('');
      }
      if (index === 0) {
        streetArry.shift();
        streetArry.unshift(event.target.value);
      } else {
        streetArry.pop();
        streetArry.push(event.target.value);
      }
      setData({ ...data, street: streetArry });
    }
  };

  useEffect(() => {
    if (data.country_id) {
      setRegions(getRegionByCountryId(data?.country_id || ''));
    }
  }, [data.country_id]);

  // Vlaidats all field are field out
  useEffect(() => {
    const validate = () => {
      if (
        data.firstname &&
        data.lastname &&
        (data.street ? data.street[0] : false) &&
        data.telephone &&
        data.country_id &&
        data.city &&
        (regions.length > 0 ? data?.region?.region_id : true) &&
        data.postcode
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };
    validate();
  }, [data, regions]);

  return (
    <Modal open={open} onClose={handleClose}>
      <form className="modal">
        <div className="top-part">
          <h1>
            {!edit
              ? billing
                ? `Add a billing address`
                : `Add a shipping address`
              : billing
              ? `Edit billing address`
              : `Edit shipping address`}
          </h1>
          <IconButton onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="41"
              viewBox="0 0 40 41"
              fill="none"
            >
              <path
                d="M27.9961 12.5L11.9961 28.5005"
                stroke="#979797"
                strokeWidth="1.28573"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.9961 12.5L27.9961 28.5005"
                stroke="#979797"
                strokeWidth="1.28573"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="inputs-wrapper">
          <div className="inputs-row">
            <input
              value={data.firstname}
              onChange={handleChange}
              name="firstname"
              placeholder="First Name"
            />
            <input
              value={data.lastname}
              onChange={handleChange}
              name="lastname"
              placeholder="Last Name"
            />
          </div>
          <div className="inputs-row">
            {/* <input
              onChange={handleChange}
              name="email"
              placeholder="E-mail Address"
              value={data.email}
            /> */}
            <MuiTelInput
              name="telephone"
              value={data.telephone}
              onChange={(value) => setData({ ...data, telephone: value })}
              className="no-border-input"
              sx={{
                width: '56%',
                '& fieldset': { borderColor: '#DEDEDE !important' },
              }}
              InputLabelProps={{
                sx: {
                  color: '#616161',
                  fontWeight: 400,
                  fontSize: '16px',
                },
              }}
              InputProps={{
                sx: {
                  borderColor: '#DEDEDE',
                  '&:hover fieldset': {
                    border: '1px solid #DEDEDE !important',
                  },
                  '&:focus-within fieldset, &:focus-visible fieldset': {
                    border: '1px solid #00588F !important',
                    boxShadow:
                      '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(32, 66, 137, 0.10)',
                  },
                },
              }}
              defaultCountry="CA"
            />
          </div>
          <div className="inputs-row">
            <FormControl
              style={{
                width: smallDisplay
                  ? 'calc(50% + 32px)'
                  : '-webkit-fill-available',
              }}
            >
              <InputLabel>Country</InputLabel>
              <Select
                name="country_id"
                value={data?.country_id || ''}
                label="Country"
                onChange={handleChange}
              >
                {MagCountries.map((country) => (
                  <MenuItem key={country.id} value={country.id}>
                    {country.full_name_english}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              style={{
                width: smallDisplay
                  ? 'calc(50% + 32px)'
                  : '-webkit-fill-available',
              }}
              disabled={regions.length === 0}
            >
              <InputLabel>Province</InputLabel>
              <Select
                name="region_id"
                value={data?.region?.region_id || ''}
                label="Province"
                onChange={(e) =>
                  setData({
                    ...data,
                    region: { region_id: e.target.value },
                    region_id: parseInt(e.target.value),
                  })
                }
              >
                {regions.map((region) => (
                  <MenuItem value={region?.id || ''}>{region?.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="inputs-row">
            <input
              value={data.city}
              onChange={handleChange}
              name="city"
              placeholder="Town / City"
            />
            <input
              value={data.postcode}
              onChange={handleChange}
              name="postcode"
              placeholder="Postal Code"
            />
          </div>
          <div className="inputs-row">
            <input
              value={data.street ? data?.street[0] : ''}
              onChange={(e) => handleChange(e, 0)}
              name="street"
              placeholder="Street Address 1"
            />
            <input
              value={data.street ? data?.street[1] : ''}
              onChange={(e) => handleChange(e, 1)}
              name="street"
              placeholder="Street Address 2"
            />
          </div>
        </div>
        <div className="buttons">
          {edit ? (
            <>
              <button onClick={editAddress}>Save Changes</button>
              <button className="delete" onClick={deleteAddress}>
                Delete Address
              </button>
            </>
          ) : (
            <>
              <button
                type="submit"
                onClick={addAddress}
                disabled={isLoading || disabled}
              >
                {billing ? `Add Billing Address` : `Add Shipping Address`}
              </button>
              <button className="white" onClick={handleClose}>
                Cancel
              </button>
            </>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default AddEditAddressModal;
