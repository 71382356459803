import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { publicRequest, userRequest } from '../utils/ApiConfig';
import { resetOrder } from './OrderSlice';
import { handleAutoLogout } from '../utils/callBack';

export const getUserCart = createAsyncThunk(
  'cart/getCart',
  async ({ magentoUserId, cartId }, { dispatch }) => {
    try {
      let cart;
      if (magentoUserId) {
        cart = await userRequest.get(`/user/${magentoUserId}/cart`);
      } else {
        cart = await publicRequest.get(`/guest/cart/${cartId}`);
      }
      dispatch(resetOrder());
      return cart.data;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching order');
      }
    }
  }
);
export const addTocart = createAsyncThunk(
  'cart/addTocart',
  async ({ magentoUserId, cartItem, cartId }, { dispatch }) => {
    try {
      if (magentoUserId) {
        await userRequest.post(`/user/${magentoUserId}/cart`, {
          cartItem: cartItem,
        });
      } else {
        await publicRequest.post(`/guest/cart`, {
          cartItem: cartItem,
        });
      }
      dispatch(getUserCart({ magentoUserId, cartId }));
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching order');
      }
    }
  }
);

export const createGuestCart = createAsyncThunk(
  'cart/guestcart',
  async ({ cartItem }, { dispatch }) => {
    try {
      const response = await publicRequest.post('/guest-carts', {});
      const cartId = response.data;
      dispatch(
        addTocart({
          cartItem: { ...cartItem, quote_id: cartId },
          cartId: cartId,
        })
      );
      return cartId;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching order');
      }
    }
  }
);

export const bulkAddTocart = createAsyncThunk(
  'cart/addToCartBulk',
  async ({ magentoUserId, cartItems }, { dispatch }) => {
    try {
      await userRequest.post(`/user/${magentoUserId}/cart/bulk`, {
        cartItems: cartItems,
      });
      dispatch(getUserCart({ magentoUserId }));
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed adding to cart');
      }
    }
  }
);
export const updateCartProduct = createAsyncThunk(
  'cart/updateCartproduct',
  async ({ magentoUserId, cartItem }, { dispatch }) => {
    try {
      if (magentoUserId) {
        await userRequest.put(`/user/${magentoUserId}/cart`, {
          cartItem: cartItem,
        });
      } else {
        await publicRequest.put(`/guest/cart/items`, {
          cartItem: cartItem,
        });
      }

      dispatch(getUserCart({ magentoUserId, cartId: cartItem.quote_id }));
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching order');
      }
    }
  }
);
export const deleteCartProduct = createAsyncThunk(
  'cart/deleteProductcart',
  async ({ magentoUserId, cartItem }, { dispatch }) => {
    try {
      if (magentoUserId) {
        await userRequest.delete(`/user/${magentoUserId}/cart`, {
          data: { cartItem: cartItem },
        });
      } else {
        await publicRequest.delete('/guest/cart/item', {
          data: { cartItem: cartItem },
        });
      }
      dispatch(getUserCart({ magentoUserId, cartId: cartItem.quote_id }));
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching order');
      }
    }
  }
);
export const deleteCartProductBulk = createAsyncThunk(
  'cart/deleteProductcartBulk',
  async ({ magentoUserId, cartItems, cartId }, { dispatch }) => {
    try {
      await userRequest.delete(`/user/${magentoUserId}/cart/bulk`, {
        data: { cartItems: cartItems, cartId: cartId },
      });
      dispatch(getUserCart({ magentoUserId }));
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed deleting products in cart');
      }
    }
  }
);

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    isSuccess: false,
    userModel: false,
    cartData: null,
    guestCartId: null,
    items: [],
    cartLoading: false,
    error: {
      status: false,
      message: null,
    },
  },
  reducers: {
    userModelOpen: (state) => {
      state.userModel = true;
    },
    userModelClose: (state) => {
      state.userModel = false;
    },
    clearCart: (state) => {
      state.cartData = null;
      state.items = [];
      state.guestCartId = null;
    },
    resetSuccess: (state) => {
      state.isSuccess = false;
      state.error = {
        status: false,
        message: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserCart.pending, (state) => {
        state.cartLoading = true;
      })
      .addCase(getUserCart.fulfilled, (state, action) => {
        state.cartLoading = false;
        state.error = {
          status: false,
          message: null,
        };
        state.cartData = action.payload;
        if (action.payload !== null) {
          state.items = action.payload.items;
        }
      })
      .addCase(getUserCart.rejected, (state, action) => {
        state.cartLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Unable fetching cart',
        };
      })
      .addCase(updateCartProduct.pending, (state) => {
        state.cartLoading = true;
      })
      .addCase(updateCartProduct.rejected, (state, action) => {
        state.cartLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Unable updating cart product',
        };
      })
      .addCase(deleteCartProduct.pending, (state) => {
        state.cartLoading = true;
      })
      .addCase(deleteCartProduct.rejected, (state, action) => {
        state.cartLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Unable deleting cart product',
        };
      })
      .addCase(addTocart.pending, (state) => {
        state.cartLoading = true;
      })
      .addCase(addTocart.fulfilled, (state) => {
        state.cartLoading = false;
        state.isSuccess = true;
      })
      .addCase(addTocart.rejected, (state, action) => {
        state.cartLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Unable adding cart product',
        };
      })
      .addCase(bulkAddTocart.pending, (state) => {
        state.cartLoading = true;
      })
      .addCase(bulkAddTocart.fulfilled, (state) => {
        state.cartLoading = false;
        state.error = {
          status: false,
          message: null,
        };
        state.isSuccess = true;
      })
      .addCase(bulkAddTocart.rejected, (state, action) => {
        state.cartLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Unable adding cart products',
        };
      })
      .addCase(deleteCartProductBulk.pending, (state) => {
        state.cartLoading = true;
      })
      .addCase(deleteCartProductBulk.fulfilled, (state) => {
        state.cartLoading = false;
        state.error = {
          status: false,
          message: null,
        };
        state.isSuccess = true;
      })
      .addCase(deleteCartProductBulk.rejected, (state, action) => {
        state.cartLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Unable deleting bulk cart products',
        };
      })
      .addCase(createGuestCart.fulfilled, (state, action) => {
        state.error = {
          status: false,
          message: null,
        };
        state.guestCartId = action.payload;
      })
      .addCase(createGuestCart.rejected, (state, action) => {
        state.error = {
          status: true,
          message: action.error.message || 'Unable deleting bulk cart products',
        };
      });
  },
});
export const { userModelOpen, userModelClose, clearCart, resetSuccess } =
  cartSlice.actions;
export const cartReducer = cartSlice.reducer;
