import {
  Autocomplete,
  Button,
  Checkbox,
  IconButton,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import './PrescriptionTransfer.scss';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { TransferPresForm } from './Prescriptionforms';
import { MuiTelInput } from 'mui-tel-input';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import Textarea from '@mui/joy/Textarea';
// import { TimePicker } from '@mui/x-date-pickers';
// import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import ThankYouPresc from './ThankYouPres';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Textarea } from '@mui/joy';
import { useDispatch, useSelector } from 'react-redux';
import { submitNewPrescription } from '../../redux/PrescriptionSlice';
import AddIcon from '@mui/icons-material/Add';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PrescriptionRefill = () => {
  // const [selectedPresc, setSelectedPresc] = useState([]);
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setdata] = useState({});
  const [medicationNames, setMedicationNames] = useState({});
  const [prescriptionNumbers, setPrescriptionNumbers] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);
  const { submittedId, isLoading } = useSelector((state) => state.prescription);
  const [prescriptionCount, setPrescriptionCount] = useState([0]);
  const joinedPrescNumbers = Object.values(prescriptionNumbers)
    .map((values) => values)
    .join(', ');
  const joinedMedicationNames = Object.values(medicationNames)
    .map((values) => values)
    .join(', ');

  // handles any input values changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setdata({ ...data, [name]: value });
  };

  // loop through all selected prescription for refill requests.
  const handleSubmitPres = async () => {
    setIsSubmitting(true);
    // for (let i = 0; i < selectedPresc.length; i++) {

    dispatch(
      submitNewPrescription({
        userId: user._id,
        prescData: {
          patient_fname: data?.firstname || user?.firstname,
          patient_lname: data?.lastname || user?.lastname,
          phone: data?.telephone || user?.telephone || '',
          type: 'Refill',
          name: joinedMedicationNames || 'N/A',
          prescription_numbers: joinedPrescNumbers,
        },
        message: data?.message || '',
      })
    );
    // }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (submittedId.length > 0) {
      setSuccess(true);
    }
  }, [submittedId]);

  // const handleSelectedChange = (value) => {
  //   setSelectedPresc(value);
  // };

  // Validates all required fields are fulfilled before proceeding with api request.
  useEffect(() => {
    const validate = () => {
      const prescriptionNumbersArray = Object.values(prescriptionNumbers).map(
        (values) => values
      );
      if (
        prescriptionNumbersArray?.length === prescriptionCount?.length &&
        !prescriptionNumbersArray.includes('') &&
        !prescriptionNumbersArray.includes(' ') &&
        (data?.telephone || user?.telephone)
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };
    if (data) {
      validate();
    }
  }, [data, prescriptionNumbers, prescriptionCount]);

  // useEffect(() => {
  //   if (prescription && selectedPresc.length === 0) {
  //     setSelectedPresc((prev) => [...prev, prescription]);
  //   }
  // }, []);

  return (
    <>
      {!success ? (
        <div
          className={`prescription-component ${
            isLoading || isSubmitting ? 'disabled' : ''
          }`}
        >
          <div className="prescription-form">
            <Button className="back-bttn" onClick={() => navigate(-1)}>
              <ArrowBackIcon />
              Back
            </Button>
            <div className="top-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="31"
                height="32"
                viewBox="0 0 31 32"
                fill="none"
              >
                <g clipPath="url(#clip0_3215_42809)">
                  <path
                    d="M18.084 4.375V9.54167C18.084 9.88424 18.2201 10.2128 18.4623 10.455C18.7045 10.6972 19.0331 10.8333 19.3757 10.8333H24.5423M18.084 4.375H9.04232C8.35718 4.375 7.70009 4.64717 7.21563 5.13164C6.73116 5.61611 6.45898 6.27319 6.45898 6.95833V17.2917M18.084 4.375L24.5423 10.8333M24.5423 10.8333V25.0417C24.5423 25.7268 24.2701 26.3839 23.7857 26.8684C23.3012 27.3528 22.6441 27.625 21.959 27.625H14.8548"
                    stroke="#1E1E1E"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.136 18.748L13.338 17.224H13.332V18.748H12V14.5H13.98C14.324 14.5 14.618 14.562 14.862 14.686C15.106 14.806 15.288 14.972 15.408 15.184C15.532 15.392 15.594 15.628 15.594 15.892C15.594 16.176 15.514 16.428 15.354 16.648C15.198 16.868 14.972 17.026 14.676 17.122L15.6 18.748H14.136ZM13.332 16.336H13.86C13.988 16.336 14.084 16.308 14.148 16.252C14.212 16.192 14.244 16.1 14.244 15.976C14.244 15.864 14.21 15.776 14.142 15.712C14.078 15.648 13.984 15.616 13.86 15.616H13.332V16.336Z"
                    fill="#1E1E1E"
                  />
                  <path
                    d="M18.7255 18.748L17.9455 17.644L17.2975 18.748H15.7795L17.1775 16.558L15.7195 14.5H17.2975L18.0475 15.562L18.6715 14.5H20.1895L18.8155 16.648L20.3035 18.748H18.7255Z"
                    fill="#1E1E1E"
                  />
                  <path
                    d="M9.81602 27.1944L12.916 24.1805M12.916 24.1805L9.81602 21.1667M12.916 24.1805H4.39102C3.56884 24.1805 2.78035 24.4981 2.19898 25.0633C1.61762 25.6285 1.29102 26.3951 1.29102 27.1944C1.29102 27.9938 1.61762 28.7604 2.19898 29.3256C2.78035 29.8908 3.56884 30.2083 4.39102 30.2083H5.16602"
                    stroke="#1E1E1E"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3215_42809">
                    <rect
                      width="31"
                      height="31"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p>Prescription Refill Request</p>
            </div>
            <span className="slogan">
              Need a refill? Complete this form to request a refill of your
              existing prescription quickly. We'll get it ready for you without
              any hassle.
            </span>
            <div className="form">
              <span className="form-title">Personal Information:</span>
              <div className="input-container">
                {TransferPresForm.map((input) =>
                  input.type === 'text'
                    ? !input.inVisible && (
                        <TextField
                          name={input.name}
                          value={data[input.name] || user[input.name]}
                          label={input.label}
                          className={`input ${input.size}`}
                          onChange={handleChange}
                          disabled={input.name === 'email'}
                        />
                      )
                    : input.type === 'phone' && (
                        <MuiTelInput
                          required
                          label={input.label}
                          className={`input ${input.size}`}
                          InputLabelProps={{
                            sx: {
                              color: '#616161',
                              fontWeight: 400,
                              fontSize: '16px',
                            },
                          }}
                          InputProps={{
                            sx: {
                              '&:hover fieldset': {
                                border: '1px solid #122146 !important',
                              },
                              '&:focus-within fieldset, &:focus-visible fieldset':
                                {
                                  border: '1px solid #122146 !important',
                                  boxShadow:
                                    '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(32, 66, 137, 0.10)',
                                },
                            },
                          }}
                          defaultCountry="CA"
                          value={data?.telephone || user?.telephone || ''}
                          onChange={(value) =>
                            setdata({ ...data, telephone: value })
                          }
                        />
                      )
                )}
                {/* <Autocomplete
                  isOptionEqualToValue={(option, value) =>
                    option._id === value?._id
                  }
                  className="input"
                  multiple
                  options={list}
                  value={selectedPresc}
                  getOptionSelected={(option, value) =>
                    option._id === value._id
                  }
                  getOptionDisabled={() => selectedPresc.length >= 4}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) => handleSelectedChange(value)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        sx={{
                          '&.Mui-checked': {
                            color: '#219D50',
                          },
                        }}
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={
                          selected || selectedPresc[0]?._id === option?._id
                        }
                      />
                      <span
                        style={{
                          color: '#1E1E1E',
                          fontFamily: 'Roboto',
                          fontSize: '14px',
                          fontWeight: 600,
                          lineHeight: '150%',
                        }}
                      >
                        {option.name + ' ' + (option?.number || '#N/A')}
                      </span>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Prescription Number(s)  (Add up to 4 prescriptions)"
                    />
                  )}
                /> */}
                {prescriptionCount?.map((pre, index) => (
                  <>
                    <p>Prescription {index + 1}</p>
                    <TextField
                      name={`prescription_name-${index + 1}`}
                      value={
                        medicationNames?.[`prescription_name-${index + 1}`] ||
                        ''
                      }
                      label="Medication Name"
                      className="input"
                      onChange={(e) =>
                        setMedicationNames({
                          ...medicationNames,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                    <TextField
                      name={`prescription_numbers-${index + 1}`}
                      value={
                        prescriptionNumbers?.[
                          `prescription_numbers-${index + 1}`
                        ] || ''
                      }
                      label="Prescription Number"
                      className="input"
                      onChange={(e) =>
                        setPrescriptionNumbers({
                          ...prescriptionNumbers,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </>
                ))}
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    className="add-bttn"
                    onClick={() => setPrescriptionCount((prev) => [...prev, 1])}
                  >
                    <AddIcon />
                    Add Prescription
                  </Button>
                </div>

                <Textarea
                  name="message"
                  value={data?.message || ''}
                  style={{
                    backgroundColor: '#ffff',
                    width: '100%',
                  }}
                  sx={{
                    '--Textarea-focusedHighlight': '#4C9B58 !important',
                    '&:focus-within': {
                      borderColor: '#4C9B58',
                    },
                  }}
                  placeholder="Additional Information or Requests (Include any specific requirements or questions you may have)"
                  minRows={4}
                  onChange={handleChange}
                />
              </div>
            </div>
            {/* <div className="form">
              <span className="form-title">Preferred Appointment Date:</span>
              <div className="input-container">
                {TransferPresThree.map((input) =>
                  input.type === 'text' ? (
                    <TextField
                      label={input.label}
                      className={`input ${input.size}`}
                    />
                  ) : input.type === 'date' ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={input.label}
                        className={`input ${input.size}`}
                      />
                    </LocalizationProvider>
                  ) : input.type === 'time' ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label={input.label}
                        className={`input ${input.size}`}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                      />
                    </LocalizationProvider>
                  ) : (
                    <Textarea
                      style={{ backgroundColor: '#ffff' }}
                      variant="outlined"
                      color="neutral"
                      className={`input ${input.size}`}
                      placeholder="Additional Information or Requests (Include any specific requirements or questions you may have)"
                      minRows={4}
                    />
                  )
                )}
              </div>
            </div> */}
          </div>
          <div className="submit-div">
            <Button className="cancel-bttn" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              className="submitForm"
              onClick={handleSubmitPres}
              disabled={disabled || isSubmitting}
            >
              Submit Prescription Refill
            </Button>
          </div>
        </div>
      ) : (
        <ThankYouPresc
          prescId={submittedId}
          text="Thank you for choosing City Pharmacy, your prescription is in process, and we will send you a notification for payment details of prescriptions."
        />
      )}
    </>
  );
};

export default PrescriptionRefill;
