import React from 'react';
import './LogoutPopup.scss';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button, IconButton } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCardById } from '../../redux/cardsSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  maxWidth: '504px',
  padding: '24px 16px 16px 16px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  borderRadius: '10px',
  background: '#FFF',
  width: '90%',
};

const DeleteUserCardModal = ({ setOpen, open, card }) => {
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);

  const handleDeleteCard = async (e) => {
    e.stopPropagation();
    await dispatch(
      deleteCardById({
        userId: currentUser?._id,
        cardId: card?._id,
      })
    );
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="top-logout-div">
          <p>Delete Payment Method</p>
          <IconButton
            style={{ width: '40px', height: '40px', fontSize: '40px' }}
            onClick={() => setOpen(false)}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </div>
        <div className="bottom-logout-wrapper">
          <div className="top">
            <p className="title">
              You are attempting to delete{' '}
              {card?.cardData?.ssl_card_number || 'N/A'} Payment Method
            </p>
            <span className="subtext">Are you sure?</span>
          </div>
          <div className="bottom-buttons">
            <Button className="logout" onClick={handleDeleteCard}>
              Delete Payment Method
            </Button>
            <Button className="cancel" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default DeleteUserCardModal;
