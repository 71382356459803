import React, { useEffect, useState } from 'react';
import './MegaMenuCategoryImage.scss';
import { publicRequest } from '../utils/ApiConfig';
import { CMSURL } from '../utils/config';
import { Skeleton } from '@mui/material';
import noImage from '../assets/No_Image_Available.jpg';

const MegaMenuCategoryImage = ({ category, setAllCategoriesImages }) => {
  const [loadingImg, setLoadingImg] = useState(false);
  const [categoryImage, setCategoryImage] = useState(null);

  useEffect(() => {
    const getCategoryById = async () => {
      try {
        setLoadingImg(true);
        const response = await publicRequest.get(`/categories/${category?.id}`);
        const categoryData = response.data;
        const catImage = categoryData.custom_attributes.find(
          (att) => att.attribute_code === 'image'
        );
        setCategoryImage(`${CMSURL}/${catImage?.value}`);
        setAllCategoriesImages((prev) => [
          ...prev,
          {
            id: category.id,
            img: `${CMSURL}/${catImage?.value}`,
          },
        ]);
        setLoadingImg(false);
      } catch (err) {
        setLoadingImg(false);
      }
    };
    if (!categoryImage && category?.id && !loadingImg) {
      getCategoryById();
    }
  }, [category, loadingImg]);
  return (
    <div className="megamenu-ctageoryimage">
      {loadingImg ? (
        <Skeleton variant="rectangular" width={'100%'} height={200} />
      ) : (
        <img src={categoryImage || noImage} alt={category?.name} />
      )}
    </div>
  );
};

export default MegaMenuCategoryImage;
