import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Select,
} from '@mui/material';
import React, { useState } from 'react';
import './PayPrescription.scss';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useNavigate } from 'react-router-dom';

const PayPrescriptionPopup = ({ open, handleClose }) => {
  const [selectedPresc, setSelectedPresc] = useState(0);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setSelectedPresc(e.target.value);
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="pay-prescription-popup">
        <div className="top-cont">
          <p>Pay your prescription</p>
          <IconButton onClick={handleClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </div>
        <div className="select-presc-cont">
          <p>Select the prescription you want to pay first!</p>
          <FormControl fullWidth>
            <Select
              onChange={handleChange}
              value={selectedPresc}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem disabled value={0}>
                <em>Select prescription</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Button
          className="pay-bttn"
          onClick={() =>
            navigate(`/dashboard/prescription/order/${selectedPresc}`)
          }
          disabled={!selectedPresc}
        >
          Pay | Prescription name{' '}
        </Button>
        <Button className="cancel-bttn" onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default PayPrescriptionPopup;
