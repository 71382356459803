import React from 'react';
import './PrescriptionReminder.scss';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { Button } from '@mui/material';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Link, useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils/callBack';

const PrescriptionReminder = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div className="prescription-reminder">
      <div className="title-div">
        <div className="left">
          <NotificationsNoneOutlinedIcon
            style={{ color: '#219D50', width: '30px', height: '30px' }}
          />
          <p>Prescription Reminder</p>
        </div>
      </div>
      <div className="boxesData">
        {data.slice(0, 3).map((presc, i) => (
          <div className="presc-data-box">
            <div className="presc-name">
              <p className="box-txt">{presc.name}</p>
              <div
                className="pres-status"
                style={{
                  visibility:
                    presc.status === 'Ready For Pickup' ? 'visible' : 'hidden',
                }}
              >
                {presc.status}
              </div>
            </div>
            <div className="text-cont">
              <p className="box-txt">Prescriper</p>
              <p className="box-txt">{formatDate(presc.createdAt)}</p>
            </div>

            <div className="bttn-wrapper">
              <Button
                className="payNow-bttn"
                style={{
                  visibility:
                    presc.status === 'Pending Payment' ? 'visible' : 'hidden',
                }}
                onClick={() =>
                  navigate(`/dashboard/prescription/order/${presc._id}`)
                }
              >
                ${presc?.order?.total} | pay now
              </Button>

              <Link
                className="presc-details-bttn"
                to={`/dashboard/prescription/${presc._id}`}
              >
                <InsertDriveFileOutlinedIcon />
                Details
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrescriptionReminder;
