import { logoutUser } from '../redux/userSlice';
import { publicRequest, userRequest } from './ApiConfig';
import { MagCountries } from './MagCountriesList';
import emailjs from '@emailjs/browser';

// user our server to store file and generate a fileurl
const getFileUrl = async (file) => {
  try {
    const form = new FormData();
    form.append('file', file);
    const response = await publicRequest.post('/fileupload', form);
    const fileUrl = response.data.location;
    return fileUrl;
  } catch (err) {
    return false;
  }
};

// user our server to store file and generate a fileurl
const deleteFile = async (file) => {
  try {
    await publicRequest.delete('/fileupload', {
      data: {
        file: file,
      },
    });
    return true;
  } catch (err) {
    return false;
  }
};
const getRegionByCountryId = (countryId) => {
  const targetCountry = MagCountries.find(
    (country) => country.id === countryId
  );
  if (targetCountry.available_regions) {
    return targetCountry.available_regions;
  } else {
    return [];
  }
};
const formatDate = (isoDateString) => {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const date = new Date(isoDateString);
  const formattedDate = date.toLocaleString('en-US', options);

  return formattedDate;
};

const getUserCardById = async (userId, cardId) => {
  try {
    const response = await userRequest(
      `/payments/user/${userId}/cards/${cardId}`
    );
    const cardData = response.data;
    const ssl_token = cardData.ssl_token;
    return ssl_token;
  } catch (err) {
    return false;
  }
};

const emailjsSend = async (data, templateID) => {
  try {
    await emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICEID,
      templateID,
      data,
      process.env.REACT_APP_EMAILJS_USERID
    );
    return true;
  } catch (err) {
    return false;
  }
};

const handleAutoLogout = (dispatch) => {
  // window.alert('session expired!');
  dispatch(logoutUser());
};

export {
  getFileUrl,
  getRegionByCountryId,
  formatDate,
  getUserCardById,
  handleAutoLogout,
  deleteFile,
  emailjsSend,
};
