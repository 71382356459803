import React from 'react';
import './OrderStepper.scss';
import { Step, StepLabel, Stepper } from '@mui/material';
import { styled } from '@mui/material/styles';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import Check from '@mui/icons-material/Check';

const OrderStepper = ({ status }) => {
  const steps = [
    'Order Placed',
    'Processing',
    'Shipped',
    'Out for Delivery',
    'Delivered',
  ];

  const getActiveStep = (status) => {
    switch (status) {
      case 'processing':
        return 1;
      case 'PRE_TRANSIT':
        return 2;
      case 'TRANSIT':
        return 3;
      case 'DELIVERED':
        return 4;
      default:
        return 0;
    }
  };

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#219D50',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#219D50',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    alignItems: 'center',
    height: 20,
    width: 20,
    justifyContent: 'center',
    borderRadius: '50%',
    background: 'currentColor',
    ...(ownerState.active && {
      color: '#219D50',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#fff',
      zIndex: 1,
      fontSize: 18,
      height: 20,
      width: 20,
      justifyContent: 'center',
      borderRadius: '50%',
      background: '#219D50',
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: '#fff',
    },
  }));

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  const IdentifyIcon = (step) => {
    switch (step) {
      case 'Order Placed':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              d="M11.6875 6.65818L5.3125 2.98193"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.875 11.3332V5.66652C14.8747 5.41809 14.8092 5.17409 14.6848 4.95901C14.5605 4.74392 14.3818 4.56531 14.1667 4.4411L9.20833 1.60777C8.99297 1.48343 8.74868 1.41797 8.5 1.41797C8.25132 1.41797 8.00703 1.48343 7.79167 1.60777L2.83333 4.4411C2.61819 4.56531 2.43949 4.74392 2.31516 4.95901C2.19083 5.17409 2.12525 5.41809 2.125 5.66652V11.3332C2.12525 11.5816 2.19083 11.8256 2.31516 12.0407C2.43949 12.2558 2.61819 12.4344 2.83333 12.5586L7.79167 15.3919C8.00703 15.5163 8.25132 15.5817 8.5 15.5817C8.74868 15.5817 8.99297 15.5163 9.20833 15.3919L14.1667 12.5586C14.3818 12.4344 14.5605 12.2558 14.6848 12.0407C14.8092 11.8256 14.8747 11.5816 14.875 11.3332Z"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.33008 4.9585L8.49966 8.50016L14.6692 4.9585"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.5 15.5833V8.5"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case 'Processing':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
          >
            <path
              d="M7.49984 4.29158C9.14639 3.62854 10.9889 3.64675 12.622 4.34219C14.2552 5.03764 15.5451 6.35336 16.2082 7.99992C16.8712 9.64647 16.853 11.489 16.1576 13.1221C15.4621 14.7552 14.1464 16.0452 12.4998 16.7083M12.4998 12.9999V17.1666H16.6665M4.69141 6.46643V6.47476M3.38281 9.6665V9.67484M3.85807 13.0833V13.0916M5.96663 15.8083V15.8166M9.1665 17.1165V17.1248"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case 'Shipped':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M11 9.5L16 12.5V18M11 9.5L16 6.5L21 9.5V15L16 18M11 9.5V15L16 18M16 12.5449L21 9.51489M7 9H2M7 12H4M7 15H6"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case 'Out for Delivery':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
          >
            <path
              d="M8.75 14.875H12.25V4.375H1.75V14.875H4.375"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.5 14.875H19.25V11.9525C19.2504 11.4927 19.1601 11.0374 18.9845 10.6125C18.8088 10.1876 18.5512 9.80153 18.2262 9.47625L16.625 7.875H12.25"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.25 14.875H13.125"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.5625 17.5C7.77062 17.5 8.75 16.5206 8.75 15.3125C8.75 14.1044 7.77062 13.125 6.5625 13.125C5.35438 13.125 4.375 14.1044 4.375 15.3125C4.375 16.5206 5.35438 17.5 6.5625 17.5Z"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.3125 17.5C16.5206 17.5 17.5 16.5206 17.5 15.3125C17.5 14.1044 16.5206 13.125 15.3125 13.125C14.1044 13.125 13.125 14.1044 13.125 15.3125C13.125 16.5206 14.1044 17.5 15.3125 17.5Z"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case 'Delivered':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
          >
            <path
              d="M16.5 8.8099V9.4999C16.4991 11.1172 15.9754 12.6909 15.007 13.9863C14.0386 15.2816 12.6775 16.2293 11.1265 16.6878C9.57557 17.1464 7.91794 17.0913 6.40085 16.5308C4.88376 15.9703 3.58849 14.9345 2.70822 13.5777C1.82795 12.2209 1.40984 10.6159 1.51626 9.00213C1.62267 7.38832 2.24791 5.85214 3.29871 4.6227C4.34951 3.39326 5.76959 2.53644 7.34714 2.18001C8.92469 1.82358 10.5752 1.98665 12.0525 2.6449"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.5 3.5L9 11.0075L6.75 8.7575"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      default:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
          >
            <path
              d="M16.5 8.8099V9.4999C16.4991 11.1172 15.9754 12.6909 15.007 13.9863C14.0386 15.2816 12.6775 16.2293 11.1265 16.6878C9.57557 17.1464 7.91794 17.0913 6.40085 16.5308C4.88376 15.9703 3.58849 14.9345 2.70822 13.5777C1.82795 12.2209 1.40984 10.6159 1.51626 9.00213C1.62267 7.38832 2.24791 5.85214 3.29871 4.6227C4.34951 3.39326 5.76959 2.53644 7.34714 2.18001C8.92469 1.82358 10.5752 1.98665 12.0525 2.6449"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.5 3.5L9 11.0075L6.75 8.7575"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
    }
  };

  return (
    <Stepper
      alternativeLabel
      activeStep={getActiveStep(status)}
      connector={<QontoConnector />}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={QontoStepIcon}>
            {IdentifyIcon(label)} {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default OrderStepper;
