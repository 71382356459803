import React, { useEffect, useState } from 'react';
import './PaymentBox.scss';
import { Alert, Button, IconButton } from '@mui/material';
import visaImng from '../../assets/visa.png';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
// import AddEditPaymentModal from './AddEditPaymentModal';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserCards,
  setDefaultCard,
  storeUserCard,
} from '../../redux/cardsSlice';
import { Evalon } from '../../utils/Evalon';
import DeleteUserCardModal from './DeleteUserCardModal';

const PaymentBox = ({ orderMethod, isPayInStore, setIsPayInStore }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [targetCard, setTargetCard] = useState(null);
  const { currentUser } = useSelector((state) => state.user);
  const { userCards, isLoading } = useSelector((state) => state.card);
  const [paymentError, setPaymentError] = useState(false);
  const dispatch = useDispatch();
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const converge = window.PayWithConverge;

  // Function to find and update current default address
  const hadleUpdateDefaultAddress = (card) => {
    if (card === 'inStore') {
      if (isPayInStore) {
        setIsPayInStore(false);
      } else {
        setIsPayInStore(true);
      }
    } else {
      setIsPayInStore(null);
      if (!card.isDefault) {
        dispatch(
          setDefaultCard({
            userId: currentUser._id,
            cardId: card?._id,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      dispatch(
        getUserCards({
          userId: currentUser?._id,
        })
      );
    }
  }, []);

  const addNewCard = (cardData) => {
    dispatch(
      storeUserCard({
        userId: currentUser._id,
        cardData,
      })
    );
  };

  const openLightBox = (token) => {
    let paymentFields = {
      ssl_txn_auth_token: token,
    };
    let callback = {
      onError: function (error) {
        setPaymentError(true);
      },
      onCancelled: function () {
        // console.log('cancelled', '');
      },
      onDeclined: function (response) {
        // console.log('declined', JSON.stringify(response, null, '\t'));
      },
      onApproval: function (response) {
        addNewCard(response);
      },
    };
    converge.open(paymentFields, callback);
  };

  const handleEvalonPayment = async () => {
    try {
      setIsLoadingPayment(true);
      const evalonPaymentToken = await Evalon.getPaymentToken(
        '0',
        currentUser.firstname,
        currentUser.lastname,
        null,
        'genCardToken'
      );
      if (evalonPaymentToken) {
        openLightBox(evalonPaymentToken);
      } else {
        setPaymentError(true);
      }
      setIsLoadingPayment(false);
    } catch (err) {
      setPaymentError(true);
    }
  };

  const handleOpenModal = (e, card) => {
    e.stopPropagation();

    setTargetCard(card);
    setDeleteModalOpen(true);
  };

  return (
    <div className="info-div">
      {paymentError && (
        <Alert
          severity="error"
          style={{
            position: 'fixed',
            top: '16px',
            right: '16px',
            zIndex: 100,
          }}
        >
          Payment Failed! Try Again
        </Alert>
      )}
      {/* <AddEditPaymentModal
        handleClose={handleClose}
        open={isOpen}
        data={data}
        setData={setData}
        handleEditPayment={handleEditAddress}
        edit={edit}
        handleSubmit={handleNewPayment}
        handleDeletePayment={handleDelete}
      /> */}
      <DeleteUserCardModal
        setOpen={setDeleteModalOpen}
        open={deleteModalOpen}
        card={targetCard}
      />
      <div className="info-top">
        <p>Payment Method</p>
        <Button
          className="useAnother-address"
          onClick={handleEvalonPayment}
          disabled={isLoadingPayment}
        >
          Add Another Method
        </Button>
      </div>
      <div className={`payments-boxes-wrapper ${isLoading ? 'disabled' : ''}`}>
        {(orderMethod?.name === 'Pick-up' || orderMethod === 'Pick-up') && (
          <div
            className={`payment-box ${isPayInStore ? 'default' : ''}`}
            onClick={() => hadleUpdateDefaultAddress('inStore')}
          >
            <div className="circular-checkbox">
              <DoneOutlinedIcon style={{ fontSize: '13px', color: '#fff' }} />
            </div>
            <div className="desc-box">
              <div className="top">
                <p className="card-number">Pay In-Store</p>
                {isPayInStore && <span className="badge">Default</span>}
              </div>
              <div className="bottom-div">
                <div className="card-details">
                  <p>Opt for in-store payment with delivery.</p>
                </div>
              </div>
            </div>
          </div>
        )}
        {userCards?.length
          ? userCards?.map((card) => (
              <div
                key={card?._id}
                className={`payment-box ${card?.isDefault ? 'default' : ''} ${
                  isPayInStore ? 'disabled' : ''
                }`}
                onClick={() => hadleUpdateDefaultAddress(card)}
              >
                <div className="circular-checkbox">
                  <DoneOutlinedIcon
                    style={{ fontSize: '13px', color: '#fff' }}
                  />
                </div>
                <div className="desc-box">
                  <div className="top">
                    <p className="card-number">
                      {card?.cardData?.ssl_card_number}
                    </p>

                    {card?.isDefault && <span className="badge">Default</span>}
                  </div>
                  <div className="bottom-div">
                    <div className="card-details">
                      <p>
                        {' '}
                        {card?.cardData?.ssl_first_name}{' '}
                        {card?.cardData?.ssl_last_name}
                      </p>
                      <p>{card?.cardData?.ssl_exp_date}</p>
                    </div>
                  </div>
                </div>
                <div className="absolute-div">
                  <div className="img-wrapper">
                    <img src={visaImng} alt="card-type" />
                  </div>
                  <IconButton className="edit-bttn">
                    <DeleteOutlineIcon
                      style={{ fontSize: '20px', color: '#CD1D1D' }}
                      onClick={(e) => handleOpenModal(e, card)}
                    />
                  </IconButton>
                </div>
              </div>
            ))
          : orderMethod !== 'Pick-up' && (
              <div className="empty-div-payment-box">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="49"
                  height="48"
                  viewBox="0 0 49 48"
                  fill="none"
                >
                  <path
                    d="M43.3567 7.71484H5.64244C3.74889 7.71484 2.21387 9.24988 2.21387 11.1434V36.8577C2.21387 38.7513 3.74889 40.2863 5.64244 40.2863H43.3567C45.2503 40.2863 46.7853 38.7513 46.7853 36.8577V11.1434C46.7853 9.24988 45.2503 7.71484 43.3567 7.71484Z"
                    stroke="#8F91A1"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.21387 19.7148H46.7853"
                    stroke="#8F91A1"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M33.0713 31.7148H38.2141"
                    stroke="#8F91A1"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p>There is not a Payment Method</p>
              </div>
            )}
      </div>
    </div>
  );
};

export default PaymentBox;
