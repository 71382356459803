import React, { useEffect, useState } from 'react';
import DashboardNavigation from '../../../../../components/DashboardComponents/DashboardNavigation';
import './Security.scss';
import { Alert, Button, TextField } from '@mui/material';
import EditEmailPopup from '../../../../../components/DashboardComponents/EditEmailPopup';
import SecurityForms from '../../../../../components/DashboardComponents/SecurityForms';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserInfo,
  resetState,
  updateUserEmail,
} from '../../../../../redux/userSlice';

const SettingsSecurity = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { currentUser, isUpdated, error, successMssg } = useSelector(
    (state) => state.user
  );
  const [userNewData, setUserNewData] = useState({});
  const dispatch = useDispatch();

  // handles all new user data to update
  const handleChange = (e) => {
    if (e.target?.name) {
      const { name, value } = e.target;
      setUserNewData({ ...userNewData, [name]: value });
    } else {
      setUserNewData({ ...userNewData, telephone: e });
    }
  };

  useEffect(() => {
    if (isUpdated || error.status) {
      setIsOpen(false);
      setTimeout(() => {
        dispatch(resetState());
      }, [5000]);
    }
  }, [isUpdated, error]);

  useEffect(() => {
    if (isUpdated) {
      setUserNewData({});
      setIsOpen(false);
    }
  }, [isUpdated]);

  const handleEmailChange = () => {
    dispatch(
      updateUserEmail({
        userId: currentUser._id,
        email: userNewData.email,
        name: currentUser.firstname,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getUserInfo({
        userId: currentUser._id,
      })
    );
  }, []);

  return (
    <div className="SettingsSecurity">
      <EditEmailPopup
        isOpen={isOpen}
        handleChange={handleChange}
        handleClose={() => setIsOpen(false)}
        userNewData={userNewData}
        handleEmailChange={handleEmailChange}
      />
      <div className="main-conatiner">
        <div className="wrapper">
          <DashboardNavigation />
          <div className="SettingsSecurity-right">
            {(error.status || isUpdated) && (
              <Alert
                severity={error.status ? 'error' : 'success'}
                style={{
                  position: 'fixed',
                  bottom: '16px',
                  right: '16px',
                  zIndex: '50',
                }}
              >
                {error.status
                  ? error.message
                  : successMssg
                  ? successMssg
                  : 'User Updated successfully'}
              </Alert>
            )}
            <SecurityForms
              type={'Password'}
              userData={currentUser}
              handleChange={handleChange}
              userNewData={userNewData}
            />
            <div className="security-section">
              <div className="top-wrapper">
                <div className="left">
                  <p>Email</p>
                  {!currentUser.isEmailVerified ? (
                    <span className="badge">Pending</span>
                  ) : (
                    <span className="badge verified">Verified</span>
                  )}
                </div>
                <Button
                  className="edit-email-bttn"
                  onClick={() => setIsOpen(true)}
                >
                  Edit
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_3613_37137)">
                      <path
                        d="M7.33301 2.66602H2.66634C2.31272 2.66602 1.97358 2.80649 1.72353 3.05654C1.47348 3.30659 1.33301 3.64573 1.33301 3.99935V13.3327C1.33301 13.6863 1.47348 14.0254 1.72353 14.2755C1.97358 14.5255 2.31272 14.666 2.66634 14.666H11.9997C12.3533 14.666 12.6924 14.5255 12.9425 14.2755C13.1925 14.0254 13.333 13.6863 13.333 13.3327V8.66602"
                        stroke="#219D50"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.333 1.66617C12.5982 1.40095 12.9579 1.25195 13.333 1.25195C13.7081 1.25195 14.0678 1.40095 14.333 1.66617C14.5982 1.93138 14.7472 2.29109 14.7472 2.66617C14.7472 3.04124 14.5982 3.40095 14.333 3.66617L7.99967 9.9995L5.33301 10.6662L5.99967 7.9995L12.333 1.66617Z"
                        stroke="#219D50"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3613_37137">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Button>
              </div>
              <div className="form-wrapper">
                <TextField
                  fullWidth
                  label="E-mail Address"
                  name="email"
                  type="email"
                  onChange={handleChange}
                  value={currentUser?.email || ''}
                  disabled
                />
              </div>
              <Button
                className="edit-email-bttn-mobile"
                onClick={() => setIsOpen(true)}
              >
                Edit
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g clipPath="url(#clip0_3613_37137)">
                    <path
                      d="M7.33301 2.66602H2.66634C2.31272 2.66602 1.97358 2.80649 1.72353 3.05654C1.47348 3.30659 1.33301 3.64573 1.33301 3.99935V13.3327C1.33301 13.6863 1.47348 14.0254 1.72353 14.2755C1.97358 14.5255 2.31272 14.666 2.66634 14.666H11.9997C12.3533 14.666 12.6924 14.5255 12.9425 14.2755C13.1925 14.0254 13.333 13.6863 13.333 13.3327V8.66602"
                      stroke="#219D50"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.333 1.66617C12.5982 1.40095 12.9579 1.25195 13.333 1.25195C13.7081 1.25195 14.0678 1.40095 14.333 1.66617C14.5982 1.93138 14.7472 2.29109 14.7472 2.66617C14.7472 3.04124 14.5982 3.40095 14.333 3.66617L7.99967 9.9995L5.33301 10.6662L5.99967 7.9995L12.333 1.66617Z"
                      stroke="#219D50"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3613_37137">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Button>
            </div>
            <SecurityForms
              type={'Phone number'}
              userData={currentUser}
              handleChange={handleChange}
              userNewData={userNewData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsSecurity;
