import React, { useEffect, useState } from 'react';
import DashboardNavigation from '../../../components/DashboardComponents/DashboardNavigation';
import './Prescription.scss';
import PrescriptionReminder from '../../../components/DashboardComponents/PrescriptionReminder';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SearchIcon from '@mui/icons-material/Search';
import { Alert, Button, CircularProgress } from '@mui/material';
import DashboardTable from '../../../components/DashboardComponents/DashboardTable';
import { useSearchParams } from 'react-router-dom';
import PrescriptionTransfer from '../../../components/DashboardComponents/PrescriptionTransfer';
import PrescriptionFill from '../../../components/DashboardComponents/PrescriptionFill';
import PrescriptionRefill from '../../../components/DashboardComponents/PrescriptionRefill';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllPrescriptions,
  resetPresc,
  resetSavedPresc,
} from '../../../redux/PrescriptionSlice';

const Prescription = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const currentForm = searchParams.get('prescription');
  const [reminders, setReminders] = useState([]);
  const user = useSelector((state) => state.user.currentUser);
  const { list, error, isLoading } = useSelector((state) => state.prescription);
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetPresc());
    dispatch(
      getAllPrescriptions({
        userId: user._id,
      })
    );
  }, []);

  useEffect(() => {
    if (error.status) {
      setTimeout(() => {
        dispatch(resetPresc());
      }, [5000]);
    }
  }, [error]);

  const handlePrescClick = (type) => {
    dispatch(resetSavedPresc());
    setSearchParams({ prescription: type });
  };

  useEffect(() => {
    const checkReminders = () => {
      setReminders(
        list.filter(
          (presc) =>
            presc.status === 'Pending Payment' ||
            presc.status === 'Ready For Pickup'
        )
      );
    };
    if (list) {
      checkReminders();
    }
  }, [list]);

  return (
    <div className="Prescriptions">
      <div className="main-conatiner">
        <div className="wrapper">
          <DashboardNavigation />
          {error.status && (
            <Alert
              severity="error"
              style={{
                position: 'fixed',
                bottom: '16px',
                right: '16px',
                zIndex: '50',
              }}
            >
              {error?.message || 'Failed submitting prescription'}
            </Alert>
          )}
          {!currentForm ? (
            !isLoading ? (
              <div className="right-div-prescription">
                {reminders?.length > 0 && (
                  <PrescriptionReminder data={reminders} />
                )}
                <div className="prescription-services">
                  <div className="title">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27"
                      height="26"
                      viewBox="0 0 27 26"
                      fill="none"
                    >
                      <path
                        d="M16.3125 2.16666H6.75C6.15326 2.16666 5.58097 2.39494 5.15901 2.80127C4.73705 3.20759 4.5 3.7587 4.5 4.33333V21.6667C4.5 22.2413 4.73705 22.7924 5.15901 23.1987C5.58097 23.6051 6.15326 23.8333 6.75 23.8333H20.25C20.8467 23.8333 21.419 23.6051 21.841 23.1987C22.2629 22.7924 22.5 22.2413 22.5 21.6667V8.125L16.3125 2.16666Z"
                        stroke="#1E1E1E"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.75 2.16666V8.66666H22.5"
                        stroke="#1E1E1E"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18 14.0833H9"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18 18.4167H9"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.25 9.75H9"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p>Prescription Services</p>
                  </div>
                  <div className="presc-services-boxes">
                    <Swiper
                      slidesPerView={'auto'}
                      spaceBetween={24}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="top-div">
                          <p>Submit Prescription</p>
                          <span>
                            Snap a pic of your prescription, and leave the rest
                            to our skilled pharmacists.
                          </span>
                        </div>
                        <Button
                          className="serv-box-bttn"
                          onClick={() => handlePrescClick('fill')}
                        >
                          Submit prescription online
                        </Button>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="top-div">
                          <p>Prescription Transfer</p>
                          <span
                            style={{ maxWidth: '140px', textAlign: 'center' }}
                          >
                            Rely on us for your prescription refills.
                          </span>
                        </div>
                        <Button
                          className="serv-box-bttn"
                          onClick={() => handlePrescClick('transfer')}
                        >
                          Transfer Your Prescription
                        </Button>
                      </SwiperSlide>{' '}
                      <SwiperSlide>
                        <div className="top-div">
                          <p>Prescription Refill</p>
                          <span>
                            Refill your prescriptions easily and have your
                            medications prepared right when needed
                          </span>
                        </div>
                        <Button
                          className="serv-box-bttn"
                          onClick={() => handlePrescClick('refill')}
                        >
                          Refill Your Prescription
                        </Button>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
                <div className="presc-history">
                  <div className="title">
                    <div className="left">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="26"
                        viewBox="0 0 27 26"
                        fill="none"
                      >
                        <path
                          d="M18 23.8333H20.25C20.8125 23.8333 21.375 23.6167 21.825 23.1833C22.275 22.75 22.5 22.2083 22.5 21.6667V8.12501L16.3125 2.16667H6.75C6.1875 2.16667 5.625 2.38334 5.175 2.81667C4.725 3.25001 4.5 3.79167 4.5 4.33334V7.58334"
                          stroke="#1E1E1E"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.75 2.16667V8.66667H22.5"
                          stroke="#1E1E1E"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9 23.8333C12.7279 23.8333 15.75 20.9232 15.75 17.3333C15.75 13.7435 12.7279 10.8333 9 10.8333C5.27208 10.8333 2.25 13.7435 2.25 17.3333C2.25 20.9232 5.27208 23.8333 9 23.8333Z"
                          stroke="#1E1E1E"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.6875 18.9583L9 17.6042V15.1667"
                          stroke="#1E1E1E"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p>Submitted Prescriptions</p>
                    </div>
                    <div className="search-div">
                      <input
                        placeholder="Search Prescription by name"
                        disabled={list.length === 0}
                        onChange={(e) => setQuery(e.target.value)}
                      />
                      <SearchIcon />
                    </div>
                  </div>

                  {list?.length > 0 ? (
                    <DashboardTable
                      data={list?.filter((presc) => {
                        return presc.name
                          .toLowerCase()
                          .includes(query.toLowerCase());
                      })}
                      perPage={9}
                      type={'prescription'}
                    />
                  ) : (
                    <div className="empty-orders">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="49"
                        height="48"
                        viewBox="0 0 49 48"
                        fill="none"
                      >
                        <path
                          d="M46.7849 3.42847H27.9277"
                          stroke="#8F91A1"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M46.7849 13.7141H27.9277"
                          stroke="#8F91A1"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M46.7849 24H27.9277"
                          stroke="#8F91A1"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M46.7849 44.5713H27.9277"
                          stroke="#8F91A1"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M46.7849 34.2856H27.9277"
                          stroke="#8F91A1"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.21387 5.14275V18.857C2.21387 19.8038 2.98138 20.5713 3.92815 20.5713H17.6424C18.5892 20.5713 19.3567 19.8038 19.3567 18.857V5.14275C19.3567 4.19599 18.5892 3.42847 17.6424 3.42847H3.92815C2.98138 3.42847 2.21387 4.19599 2.21387 5.14275Z"
                          stroke="#8F91A1"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.21387 29.1428V42.857C2.21387 43.8037 2.98138 44.5713 3.92815 44.5713H17.6424C18.5892 44.5713 19.3567 43.8037 19.3567 42.857V29.1428C19.3567 28.196 18.5892 27.4285 17.6424 27.4285H3.92815C2.98138 27.4285 2.21387 28.196 2.21387 29.1428Z"
                          stroke="#8F91A1"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className="empty-text">No past Prescriptions found.</p>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div
                className="right-div-prescription"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
                <span>Loading Prescriptions...</span>
              </div>
            )
          ) : (
            (currentForm === 'transfer' && <PrescriptionTransfer />) ||
            (currentForm === 'fill' && <PrescriptionFill />) ||
            (currentForm === 'refill' && <PrescriptionRefill />)
          )}
        </div>
      </div>
    </div>
  );
};

export default Prescription;
