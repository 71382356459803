import React, { useState } from 'react';
import './ReorderModal.scss';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button, CircularProgress, IconButton } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ReorderProductComponent from './ReorderProductComponent';
import { useDispatch, useSelector } from 'react-redux';
import { bulkAddTocart, deleteCartProductBulk } from '../../redux/CartSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  padding: '24px 31px 32px 31px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '22px',
  borderRadius: '10px',
  background: '#FFF',
  width: 'calc(90% - 62px)',
  maxWidth: '655px',
  height: '78%',
  maxHeight: '780px',
};

const ReorderModal = ({ setOpen, open, items, orderId }) => {
  const [inCartCount, setInCartCount] = useState(0);
  const user = useSelector((state) => state.user.currentUser);
  const { cartLoading, cartData } = useSelector((state) => state.cart);
  const [notExists, setNotExists] = useState(0);

  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
    setInCartCount(0);
    setNotExists(0);
  };

  const hanldeBulkAddTocart = () => {
    dispatch(
      bulkAddTocart({
        magentoUserId: user.refrence_id,
        cartItems: items,
      })
    );
  };

  const handleBulkDelete = async () => {
    await dispatch(
      deleteCartProductBulk({
        magentoUserId: user.refrence_id,
        cartItems: items,
        cartId: cartData?.id,
      })
    );
    setInCartCount(0);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="reorder-popup">
        {cartLoading && (
          <Box
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.374)',
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              zIndex: 100,
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress color="success" />
          </Box>
        )}
        <div className="top-div-reorder">
          <div className="texts-div">
            <p>Reorder Items from Order #{orderId}</p>
            <span>
              Quickly replenish your stock with items from a previous order.
            </span>
          </div>
          <IconButton
            style={{ width: '40px', height: '40px', fontSize: '40px' }}
            onClick={() => setOpen(false)}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </div>
        <div className="reorders-productsWrapper">
          {items?.map((product, i) => (
            <ReorderProductComponent
              productData={product}
              key={i}
              setInCartCount={setInCartCount}
              setNotExists={setNotExists}
            />
          ))}
        </div>
        <div className="reorder-bottom-div">
          <p>Add all items to cart for a quick and convenient reorder.</p>
          <div className="buttons-div">
            {inCartCount !== items?.length ? (
              <Button
                className="addToCart-reorder"
                onClick={hanldeBulkAddTocart}
                disabled={cartLoading || notExists > 0}
              >
                Add All to Cart
              </Button>
            ) : (
              <Button
                className="RemoveAllCart"
                onClick={handleBulkDelete}
                disabled={cartLoading}
              >
                Remove All from Cart
              </Button>
            )}
            <Button className="cancel-bttn" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ReorderModal;
