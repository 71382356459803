import React, { useEffect, useState } from 'react';
import './PrescriptionOrderData.scss';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import AddressPaymnetBox from './AddressPaymnetBox';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { publicRequest } from '../../utils/ApiConfig';
import { CircularProgress } from '@mui/material';
import Guesaddressbox from '../CartComponents/Guestaddressbox';
// import { gtaPostalCodes } from '../../utils/GTApostalCodes';

const PrescriptionOrderData = ({
  deliveryMethod,
  setDeliveryMethod,
  orderMethod,
  setOrderMethod,
  isPayInStore,
  setIsPayInStore,
  handleDate,
  pathname,
  data,
  setData,
}) => {
  const user = useSelector((state) => state.user.currentUser);
  const [DeliveryMethodData, setDeliveryMethodData] = useState([]);
  const [defaultShipping, setDefaultShipping] = useState({});
  const [defaultBilling, setDefaultBilling] = useState({});
  const [loadingShippingRates, setLoadingShippingRates] = useState(false);
  const FreeShipingModel = [
    {
      provider: 'Free Shipping',
      servicelevel: {
        name: '',
      },
      estimated_days: '1',
      amount: 0,
    },
  ];

  function isValidGtaPostalCode(postalCode) {
    // const formattedPostalCode = postalCode?.toUpperCase()?.substring(0, 3);
    // return gtaPostalCodes.includes(formattedPostalCode);
    const isValidPostal = postalCode?.startsWith('M');
    if (isValidPostal) {
      return true;
    } else {
      return false;
    }
  }

  // finds the default user address for billing and shipping
  useEffect(() => {
    if (user) {
      const defaultShip = user.addresses?.find((add) => add.default_shipping);
      const defaultBill = user.addresses?.find((add) => add.default_billing);
      setDefaultShipping(defaultShip);
      setDefaultBilling(defaultBill);
    }
  }, [user]);

  useEffect(() => {
    const getRates = async () => {
      try {
        setLoadingShippingRates(true);
        const response = await publicRequest.post('/getShippingRates', {
          address_to: defaultShipping,
        });
        const data = response.data;
        const rates = data.rates;
        const sortedRates = rates.sort(
          (a, b) => Number(a.amount) - Number(b.amount)
        );
        setDeliveryMethodData([...FreeShipingModel, ...sortedRates]);
        setLoadingShippingRates(false);
      } catch (err) {
        // setLoadingShippingRates(false);
      }
    };
    if (defaultShipping?.country_id) {
      getRates();
    } else {
      setDeliveryMethodData([]);
    }
  }, [defaultShipping]);

  useEffect(() => {
    if (orderMethod?.name !== 'Pick-up') {
      setIsPayInStore(null);
    }
  }, [orderMethod]);

  // checks if orderMethod is Deliver and no selected delivery method will assign the first choice
  useEffect(() => {
    if (pathname !== '/cart') {
      if (DeliveryMethodData && DeliveryMethodData.length > 0) {
        if (
          defaultShipping &&
          isValidGtaPostalCode(defaultShipping?.postcode)
        ) {
          setDeliveryMethod(DeliveryMethodData[0]);
        } else {
          setDeliveryMethod(DeliveryMethodData[1]);
        }
      } else {
        setDeliveryMethod(null);
      }
    }
  }, [orderMethod, DeliveryMethodData]);

  return (
    <div className={`presc-order-data ${pathname === '/cart' ? 'cart' : ''}`}>
      <Helmet>
        <script src="https://api.convergepay.com/hosted-payments/PayWithConverge.js"></script>

        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
      </Helmet>
      <div className={`container ${pathname === '/cart' ? 'cart' : ''}`}>
        {user && <p className="title">Address Details</p>}
        {!user && (
          <p className="title">
            {' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <rect
                width="24"
                height="24"
                transform="translate(0.5)"
                fill="white"
              />
              <path
                d="M3.5 6L9.5 3L15.5 6L21.5 3V18L15.5 21L9.5 18L3.5 21V6Z"
                stroke="black"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.5 3V18"
                stroke="black"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.5 6V21"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Address
          </p>
        )}
        {user ? (
          <div className="wrapper">
            <AddressPaymnetBox type={'Shipping'} />
            <AddressPaymnetBox type={'Billing'} />
          </div>
        ) : (
          <div className="wrapper">
            <Guesaddressbox data={data} setData={setData} />
          </div>
        )}
      </div>
      {pathname !== '/cart' && (
        <div className="container">
          <p className="title">Payment Confirmation</p>
          <div className="wrapper">
            <div className="delivery-container">
              <p>Order Method</p>
              <div className="options-boxes">
                <div
                  className={`deliveryMethod-box ${
                    orderMethod === 'Deliver' ? 'default' : ''
                  } ${DeliveryMethodData?.length === 0 ? 'disabled' : ''}`}
                  onClick={() => setOrderMethod('Deliver')}
                >
                  <div className="left">
                    <div className="circular-checkbox">
                      <DoneOutlinedIcon
                        style={{ fontSize: '13px', color: '#fff' }}
                      />
                    </div>
                    <span>Deliver</span>
                  </div>
                  <div className="right">
                    <LocalShippingOutlinedIcon />
                    <span className="cost">
                      +${deliveryMethod ? deliveryMethod?.amount : 0}
                    </span>
                  </div>
                </div>
                <div
                  className={`deliveryMethod-box ${
                    orderMethod === 'Pick-up' ? 'default' : ''
                  }`}
                  onClick={() => setOrderMethod('Pick-up')}
                >
                  <div className="left">
                    <div className="circular-checkbox">
                      <DoneOutlinedIcon
                        style={{ fontSize: '13px', color: '#fff' }}
                      />
                    </div>
                    <span>Pick-up</span>
                  </div>
                  <div className="right">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M16.5 9.4L7.5 4.21"
                        stroke="#1E1E1E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21 16V8C20.9996 7.64928 20.9071 7.30481 20.7315 7.00116C20.556 6.69752 20.3037 6.44536 20 6.27L13 2.27C12.696 2.09446 12.3511 2.00205 12 2.00205C11.6489 2.00205 11.304 2.09446 11 2.27L4 6.27C3.69626 6.44536 3.44398 6.69752 3.26846 7.00116C3.09294 7.30481 3.00036 7.64928 3 8V16C3.00036 16.3507 3.09294 16.6952 3.26846 16.9988C3.44398 17.3025 3.69626 17.5546 4 17.73L11 21.73C11.304 21.9055 11.6489 21.998 12 21.998C12.3511 21.998 12.696 21.9055 13 21.73L20 17.73C20.3037 17.5546 20.556 17.3025 20.7315 16.9988C20.9071 16.6952 20.9996 16.3507 21 16Z"
                        stroke="#1E1E1E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.28906 7L11.9991 12L20.7091 7"
                        stroke="#1E1E1E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 22V12"
                        stroke="#1E1E1E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="cost">$0</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`delivery-container ${
                orderMethod !== 'Deliver' ? 'disabled' : ''
              }`}
            >
              <p>Delivery Method</p>
              <div className="options-boxes">
                {loadingShippingRates ? (
                  <CircularProgress
                    color="success"
                    style={{ alignSelf: 'center' }}
                  />
                ) : DeliveryMethodData?.length > 0 ? (
                  DeliveryMethodData?.filter((meth) => {
                    if (
                      defaultShipping &&
                      isValidGtaPostalCode(defaultShipping?.postcode)
                    ) {
                      return meth;
                    } else {
                      return meth.provider !== 'Free Shipping';
                    }
                  }).map((del, i) => (
                    <div
                      key={i}
                      className={`deliveryMethod-box ${
                        deliveryMethod
                          ? deliveryMethod?.object_id === del?.object_id
                            ? 'default'
                            : ''
                          : ''
                      }`}
                      onClick={() => setDeliveryMethod(del)}
                    >
                      <div className="left">
                        <div className="circular-checkbox">
                          <DoneOutlinedIcon
                            style={{ fontSize: '13px', color: '#fff' }}
                          />
                        </div>
                        <span>
                          {' '}
                          {del?.provider &&
                            del?.provider + ' ' + del?.servicelevel?.name}
                        </span>
                      </div>
                      <div className="right">
                        <div className="img-wrapper" style={{ width: '43px' }}>
                          {del?.provider_image_75 && (
                            <img
                              src={del?.provider_image_75}
                              alt="shipping-logo"
                            />
                          )}
                        </div>
                        <span className="cost">
                          +${del?.amount}
                          <br></br>
                          <span style={{ color: '#000', fontWeight: '600' }}>
                            {del?.estimated_days > 1
                              ? del?.estimated_days + ' ' + 'days'
                              : del?.estimated_days + ' ' + 'day'}
                          </span>
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="empty-div-Addresspayment-box">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="49"
                      height="48"
                      viewBox="0 0 49 48"
                      fill="none"
                    >
                      <path
                        d="M39.9284 17.1434C39.9284 27.4291 24.4999 39.4291 24.4999 39.4291C24.4999 39.4291 9.07129 27.4291 9.07129 17.1434C9.07129 8.73999 16.0964 1.71484 24.4999 1.71484C32.9033 1.71484 39.9284 8.73999 39.9284 17.1434Z"
                        stroke="#8F91A1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M24.4993 22.2857C27.3396 22.2857 29.6422 19.9832 29.6422 17.1429C29.6422 14.3025 27.3396 12 24.4993 12C21.659 12 19.3564 14.3025 19.3564 17.1429C19.3564 19.9832 21.659 22.2857 24.4993 22.2857Z"
                        stroke="#8F91A1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M38.4789 34.2871H41.6424L46.7853 46.2871H2.21387L7.35672 34.2871H10.5203"
                        stroke="#8F91A1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p>Add shipping address</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {user && (
        <div className={`container ${pathname === '/cart' ? 'cart' : ''}`}>
          <div className="wrapper">
            <AddressPaymnetBox
              type={'Payment'}
              orderMethod={orderMethod}
              isPayInStore={isPayInStore}
              setIsPayInStore={setIsPayInStore}
            />
          </div>
        </div>
      )}
      {pathname !== '/cart' && (
        <div className="container">
          <p className="title">Preferred Date & Time</p>
          <div className="wrapper">
            <div className="input-box">
              <div className="label">
                {' '}
                <p>Date</p>
                <span>Optional</span>
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker onChange={(value) => handleDate(value, 'date')} />
              </LocalizationProvider>
            </div>
            <div className="input-box">
              <div className="label">
                <p>Time</p>
                <span>Optional</span>
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  name="time"
                  onChange={(value) => handleDate(value, 'time')}
                  fullWidth
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrescriptionOrderData;
