import React, { useEffect, useState } from 'react';
import './PrescriptionOrder.scss';
import DashboardNavigation from '../../../components/DashboardComponents/DashboardNavigation';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrescriptionOrderData from '../../../components/DashboardComponents/PrescriptionOrderData';
import ThankYouPresc from '../../../components/DashboardComponents/ThankYouPres';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPrescriptionById,
  updatePrescriptionOrder,
} from '../../../redux/PrescriptionSlice';
import { resetState } from '../../../redux/userSlice';
import { getUserCardById } from '../../../utils/callBack';
import { Evalon } from '../../../utils/Evalon';
import { Helmet } from 'react-helmet';
import Snackbar from '@mui/material/Snackbar';
import { storeUserCard } from '../../../redux/cardsSlice';

const PrescriptionOrder = () => {
  const { prescId } = useParams();
  const [isPayInStore, setIsPayInStore] = useState(null);
  const [orderMethod, setOrderMethod] = useState('Pick-up');
  const [deliveryMethod, setDeliveryMethod] = useState(null);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);
  const { prescription, isLoading, error, orderSuccess } = useSelector(
    (state) => state.prescription
  );
  const { userCards } = useSelector((state) => state.card);
  const addressError = useSelector((state) => state.user.error);
  const { isUpdated } = useSelector((state) => state.user);
  const [optionalData, setOptionalData] = useState({});
  const dispatch = useDispatch();
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [isPaymentError, setPaymentError] = useState(false);
  const [defaultShipping, setDefaultShipping] = useState({});
  const [defaultBilling, setDefaultBilling] = useState({});
  const [saveCard, setSaveCard] = useState(false);

  const converge = window.ConvergeEmbeddedPayment;
  const convergeLightBox = window.PayWithConverge;

  const handleDate = (value, name) => {
    if (name === 'time') {
      const currentDate = new Date(value);
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();
      const formattedTime = `${hours}:${minutes}`;
      setOptionalData({ ...optionalData, [name]: formattedTime });
    } else {
      setOptionalData({ ...optionalData, [name]: new Date(value) });
    }
  };

  // fetch prescription data populated with order data
  useEffect(() => {
    const fetchPrescription = () => {
      dispatch(
        getPrescriptionById({
          userId: user._id,
          prescriptionId: prescId,
        })
      );
    };
    if (prescId) {
      fetchPrescription();
    }
  }, [prescId]);

  useEffect(() => {
    if (user) {
      setDefaultShipping(user.addresses?.find((add) => add.default_shipping));
      setDefaultBilling(user.addresses?.find((add) => add.default_billing));
    }
  }, [user]);

  // handle submit order
  const handleSubmit = (transaction_id) => {
    dispatch(
      updatePrescriptionOrder({
        userId: user._id,
        prescriptionId: prescId,
        order: {
          method: orderMethod,
          delivery_method: {
            img:
              orderMethod !== 'Pick-up'
                ? deliveryMethod?.provider_image_75
                : '',
            name:
              orderMethod !== 'Pick-up'
                ? deliveryMethod?.provider +
                  ' ' +
                  deliveryMethod?.servicelevel?.name
                : '',
            cost: orderMethod !== 'Pick-up' ? deliveryMethod?.amount : 0,
          },
          shipping_address: defaultShipping,
          billing_address: defaultBilling,
          payment_method: isPayInStore ? 'pay-in-store' : 'card',
          prefered_date: optionalData?.date || '',
          prefered_time: optionalData?.time || '',
          transaction_id: transaction_id || '',
        },
      })
    );
  };

  // makes the alert disapperas after 5 seconds in either cases error or success
  useEffect(() => {
    if (isUpdated || addressError.status) {
      setTimeout(() => {
        dispatch(resetState());
      }, [5000]);
    }
  }, [isUpdated, error]);

  const processCheckoutJs = (token, cardToken) => {
    let paymentData = {
      ssl_txn_auth_token: token,
      ssl_token: cardToken,
      ssl_merchant_initiated_unscheduled: 'Y',
    };
    let callback = {
      onError: function (error) {
        // console.log(error);
        setIsLoadingPayment(false);
        setPaymentError(true);
      },
      onDeclined: function (response) {
        setIsLoadingPayment(false);
        setPaymentError(true);
      },
      onApproval: function (response) {
        handleSubmit(response['ssl_approval_code']);
        setIsLoadingPayment(false);
      },
    };
    converge.pay(paymentData, callback);
    return false;
  };
  const openLightBox = (token) => {
    let paymentFields = {
      ssl_txn_auth_token: token,
    };
    let callback = {
      onError: function (error) {
        setPaymentError(true);
        setIsLoadingPayment(false);
      },
      onCancelled: function () {
        setIsLoadingPayment(false);
      },
      onDeclined: function (response) {
        setIsLoadingPayment(false);
        setPaymentError(true);
      },
      onApproval: function (response) {
        handleSubmit(response['ssl_approval_code']);
        if (saveCard) {
          dispatch(
            storeUserCard({
              userId: user._id,
              cardData: response,
            })
          );
          setIsLoadingPayment(false);
        }
      },
    };
    convergeLightBox.open(paymentFields, callback);
  };

  const handleEvalonPayment = async () => {
    try {
      if (!isPayInStore) {
        setIsLoadingPayment(true);
        const defaultUserCard = userCards?.find(
          (card) => card.isDefault === true
        );
        const totalAmount = (
          prescription?.order?.total +
          (deliveryMethod && orderMethod === 'Deliver'
            ? Number(deliveryMethod?.amount)
            : 0)
        ).toFixed(2);

        if (defaultUserCard) {
          const cardToken = await getUserCardById(
            user._id,
            defaultUserCard._id
          );
          const evalonPaymentToken = await Evalon.getPaymentToken(
            totalAmount.toString(),
            user.firstname,
            user.lastname,
            prescription?.order?._id,
            '',
            'processToken'
          );
          if (evalonPaymentToken) {
            processCheckoutJs(evalonPaymentToken, cardToken);
          } else {
            setPaymentError(true);
            setIsLoadingPayment(false);
          }
        } else {
          const evalonPaymentToken = await Evalon.getPaymentToken(
            totalAmount.toString(),
            user.firstname,
            user.lastname,
            prescription?.order?._id,
            '',
            saveCard ? 'saleAndGetCardToken' : ''
          );
          openLightBox(evalonPaymentToken);
        }
      } else {
        handleSubmit('');
      }
    } catch (err) {
      setIsLoadingPayment(false);
      setPaymentError(true);
    }
  };
  return (
    <div className={`prescriptionOrder ${isLoadingPayment ? 'disabled' : ''}`}>
      <Helmet>
        <script src="https://api.convergepay.com/hosted-payments/Checkout.js"></script>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
      </Helmet>
      <div className="main-conatiner">
        <Snackbar
          open={isPaymentError}
          autoHideDuration={5000}
          onClose={() => setPaymentError(!isPaymentError)}
        >
          <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
            Error processing payment
          </Alert>
        </Snackbar>
        <div className="wrapper">
          <DashboardNavigation />
          {!orderSuccess ? (
            isLoading || error.status ? (
              <Box
                style={{
                  height: '50vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                {isLoading ? (
                  <>
                    <CircularProgress color="success" />
                    <span>Loading...</span>
                  </>
                ) : (
                  <Alert severity="error">{error.message}</Alert>
                )}
              </Box>
            ) : prescription?.status === 'Pending Payment' ? (
              <div className="prescriptionOrder-right">
                {(addressError.status || isUpdated || error.status) && (
                  <Alert
                    severity={addressError.status ? 'error' : 'success'}
                    style={{
                      position: 'fixed',
                      bottom: '16px',
                      right: '16px',
                      zIndex: '50',
                    }}
                  >
                    {addressError.status || error.status
                      ? addressError.message || error.message
                      : 'Address updated successfully'}
                  </Alert>
                )}
                <div className="top-wrapper">
                  <Button className="back-bttn" onClick={() => navigate(-1)}>
                    <ArrowBackIcon />
                    Back
                  </Button>
                  <div className="top-div">
                    <h1>{prescription?.name}</h1>
                    <span className={`pres-status ${prescription?.status}`}>
                      {prescription?.status}
                    </span>
                  </div>
                  <PrescriptionOrderData
                    isPayInStore={isPayInStore}
                    setIsPayInStore={setIsPayInStore}
                    deliveryMethod={deliveryMethod}
                    setDeliveryMethod={setDeliveryMethod}
                    orderMethod={orderMethod}
                    setOrderMethod={setOrderMethod}
                    handleDate={handleDate}
                  />
                </div>
                <div className="order-summary">
                  <div className="order-wrapper">
                    <div className="order-summary-left">
                      <p>Order Summary</p>
                      <div className="receipt-div">
                        <div className="receipt-item">
                          <span>Subtotal</span>
                          <span>${prescription?.order?.total || 0}</span>
                        </div>
                        <div className="receipt-item">
                          <span>Shipping cost</span>
                          <span>
                            {deliveryMethod && orderMethod === 'Deliver'
                              ? '$' + deliveryMethod?.amount
                              : '$0'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <Divider orientation="vertical" flexItem />
                    <div className="order-total-right">
                      <div className="subtotal">
                        <span>
                          Total Price<br></br>
                          <span className="subtext">(Included taxes)</span>
                        </span>
                        <span>
                          $
                          {(
                            prescription?.order?.total +
                            (deliveryMethod && orderMethod === 'Deliver'
                              ? Number(deliveryMethod?.amount)
                              : 0)
                          ).toFixed(2)}
                        </span>
                      </div>
                      {user &&
                        userCards &&
                        userCards?.length === 0 &&
                        !isPayInStore && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e) => setSaveCard(e.target.checked)}
                                sx={{
                                  color: 'green',
                                  '&.Mui-checked': {
                                    color: 'green',
                                  },
                                }}
                              />
                            }
                            sx={{
                              color: 'rgba(0, 0, 0, 0.813)',
                              fontSize: '12px',
                            }}
                            label="Save payment method after checkout"
                          />
                        )}
                      <Button
                        className="pay-presc-order-bttn"
                        onClick={handleEvalonPayment}
                        disabled={
                          isLoadingPayment ||
                          isLoading ||
                          // (orderMethod !== 'Pick-up' &&
                          //   userCards?.length === 0) ||
                          // (!isPayInStore && userCards?.length === 0) ||
                          !defaultBilling ||
                          !defaultShipping ||
                          (!deliveryMethod && orderMethod !== 'Pick-up') ||
                          (deliveryMethod &&
                            deliveryMethod?.length < 2 &&
                            orderMethod === 'Deliver')
                        }
                      >
                        Pay
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Box className="prescriptionOrder-right">
                <Alert severity="error">
                  Error! This prescription order is either processed or not yet
                  eligible for payments.
                </Alert>
              </Box>
            )
          ) : (
            <ThankYouPresc
              orderMethod={orderMethod}
              prescOrder={true}
              prescId={[prescription?.order._id]}
              text="Thank you for your purchase! Your payment has been successfully processed, and your order is now being prepared and ready for pickup at our store for delivery. We're excited to see you soon."
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PrescriptionOrder;
