const TransferPresForm = [
  {
    id: 11,
    name: 'name',
    label: 'Please enter the prescription name',
    size: '',
    type: 'text',
    inVisible: true,
  },
  {
    id: 1,
    name: 'firstname',
    label: 'First Name',
    size: 'short',
    type: 'text',
  },
  {
    id: 2,
    name: 'lastname',
    label: 'Last Name',
    size: 'short',
    type: 'text',
  },
  {
    id: 3,
    name: 'email',
    label: 'Email',
    size: 'short',
    type: 'text',
  },
  {
    id: 4,
    name: 'telephone',
    label: 'Phone',
    size: 'short',
    type: 'phone',
  },
];
const TransferPresTwo = [
  {
    id: 1,
    name: 'curr_pharmacy_name',
    label: 'Current Pharmacy Name',
    size: 'short',
    type: 'text',
  },
  {
    id: 2,
    name: 'curr_pharmacy_phone',
    label: 'Current Pharmacy Contact',
    size: 'short',
    type: 'phone',
  },
  {
    id: 5,
    name: 'curr_pharmacy_phone',
    label: 'Current Pharmacy Contact',
    size: 'short',
    type: 'checkbox',
  },
  {
    id: 3,
    name: 'medication_names',
    label: 'Medication Name(s)',
    size: '',
    type: 'text',
  },
  {
    id: 4,
    name: 'prescription_numbers',
    label: 'Prescription Number(s)',
    size: '',
    type: 'text',
  },
];

export { TransferPresForm, TransferPresTwo };
