import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputBase,
  Modal,
  Snackbar,
  Typography,
} from '@mui/material';
import './LoginSignUpModal.scss';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link, useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { resetError, userLogin, userRegister } from '../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const LoginSignUpModal = ({ open, handleClose, type }) => {
  const [mode, setMode] = useState('login');
  const [login, setLogin] = useState({});
  const [signup, setSignUp] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isConsentApproved, setConsentApproved] = useState(false);
  const [alertConsent, setAlertConsent] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser, loginError, isLoading, error, isRegistered } =
    useSelector((state) => state.user);
  const location = useLocation();

  useEffect(() => {
    if (type === 'login' || type === 'signup') {
      setMode(type);
      setSignUp({});
      setLogin({});
      setConsentApproved(false);
    }
  }, [type, open, handleClose]);

  const handleChangeLogin = (event) => {
    const { name, value } = event.target;
    if (name === 'remember_me') {
      setLogin({ ...login, remember_me: !login.remember_me });
    } else {
      setLogin({ ...login, [name]: value });
    }
  };

  const handleChangeSignup = (event) => {
    const { name, value } = event.target;
    setSignUp({ ...signup, [name]: value });
  };

  const validate = () => {
    if (mode !== 'login') {
      if (
        signup?.email &&
        signup?.firstname &&
        signup?.lastname &&
        signup?.password &&
        signup?.password === signup.confirm_password &&
        isConsentApproved
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      if (login.email) {
        if (login.password) {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
      }
    }
  };

  useEffect(() => {
    validate();
  }, [signup, login, mode, isConsentApproved]);

  const handleRegister = async () => {
    const { confirm_password, ...signupData } = signup;
    if (isConsentApproved) {
      await dispatch(userRegister(signupData));
      setSignUp({});
    } else {
      alertConsent(true);
    }

    // console.log(status);
  };
  const handleLogin = async () => {
    await dispatch(userLogin(login));
    setLogin({});
  };

  useEffect(() => {
    if (currentUser && open) {
      handleClose();
      if (!location.pathname.includes('/product/')) {
        navigate('/dashboard');
      }
    }
    if (isRegistered) {
      setTimeout(() => {
        setMode('login');
      }, [2000]);
    }
  }, [currentUser, isRegistered]);

  const handleErrorClose = () => {
    dispatch(resetError());
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="LoginSignupmodal">
        <div className="switcher">
          <button
            onClick={() => setMode('login')}
            className={mode === 'login' && 'selected'}
          >
            login
          </button>
          <button
            onClick={() => setMode('signup')}
            className={mode === 'signup' && 'selected'}
          >
            sign up
          </button>
        </div>
        <div className="form-wrapper">
          {mode === 'signup' ? (
            <>
              <h1>Sign Up</h1>
              <div className="form-row">
                <input
                  name="firstname"
                  value={signup?.firstname || ''}
                  onChange={handleChangeSignup}
                  placeholder="First Name*"
                />
                <input
                  name="lastname"
                  value={signup?.lastname || ''}
                  onChange={handleChangeSignup}
                  placeholder="Last Name*"
                />
              </div>
              <div className="form-row">
                <input
                  name="email"
                  value={signup?.email || ''}
                  onChange={handleChangeSignup}
                  placeholder="Email Address*"
                  type="email"
                />
              </div>
              <div className="form-row">
                <InputBase
                  className="mui-register-password"
                  onChange={handleChangeSignup}
                  placeholder="Password"
                  value={signup?.password || ''}
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              <div className="form-row" style={{ position: 'relative' }}>
                <InputBase
                  className="mui-register-password"
                  onChange={handleChangeSignup}
                  value={signup?.confirm_password || ''}
                  placeholder="Confirm Password"
                  name="confirm_password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmPassword((show) => !show)}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {signup?.confirm_password !== signup?.password &&
                  signup?.password !== '' &&
                  signup?.confirm_password &&
                  signup?.confirm_password !== '' && (
                    <span
                      style={{
                        color: 'red',
                        position: 'absolute',
                        top: '100%',
                      }}
                    >
                      Password Mismatch
                    </span>
                  )}
              </div>

              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                style={{ marginTop: '10%' }}
                open={error.status || isRegistered}
                autoHideDuration={5000}
                onClose={handleErrorClose}
              >
                {(error.status || isRegistered) && (
                  <Alert severity={error.status ? 'error' : 'success'}>
                    {error.status ? error.message : 'Registration Successful!'}
                  </Alert>
                )}
              </Snackbar>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={isConsentApproved}
                    onChange={(e) => setConsentApproved(e.target.checked)}
                    sx={{
                      marginTop: '12px',
                      color: 'green',
                      '&.Mui-checked': {
                        color: 'green',
                      },
                    }}
                  />
                }
                sx={{
                  color: 'rgba(0, 0, 0, 0.813)',
                }}
                label={
                  <Typography>
                    I consent to the collection and processing of my data as
                    described in the{' '}
                    <a
                      href="/consent"
                      target="_blank"
                      style={{ color: 'green', textDecoration: 'underline' }}
                    >
                      consent form
                    </a>
                    .
                  </Typography>
                }
              />
              {/* <p>
                Your personal data will be used to support your experience
                throughout this website, to manage access to your account, and
                for other purposes described in our privacy policy.
              </p> */}
              <div className="buttons">
                <button
                  disabled={isDisabled || isLoading}
                  onClick={handleRegister}
                >
                  Register
                </button>
                <button className="white-button" onClick={handleClose}>
                  Cancel
                </button>
                {alertConsent && (
                  <span style={{ alignSelf: 'center', color: 'red' }}>
                    Must Read and Accept Consent form before proceeding
                  </span>
                )}
              </div>
            </>
          ) : (
            <>
              <h1>Login</h1>
              <div className="form-row">
                <input
                  name="email"
                  onChange={handleChangeLogin}
                  value={login?.email || ''}
                  placeholder="Email Address*"
                  type="email"
                />
              </div>
              <Link
                to="/forget-password"
                className="forgot-password"
                onClick={handleClose}
              >
                <p>Forget your password?</p>
              </Link>
              <div className="form-row">
                <InputBase
                  className="mui-register-password"
                  onChange={handleChangeLogin}
                  name="password"
                  value={login?.password || ''}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              <div className="checkbox">
                <input
                  name="remember_me"
                  onChange={handleChangeLogin}
                  checked={login?.remember_me}
                  type="checkbox"
                />
                <p>Remember me</p>
              </div>

              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                style={{ marginTop: '10%' }}
                open={loginError?.status}
                autoHideDuration={5000}
                onClose={handleErrorClose}
              >
                {loginError?.status && (
                  <Alert severity="error">{loginError?.message}</Alert>
                )}
              </Snackbar>
              <div className="buttons">
                <button
                  onClick={handleLogin}
                  disabled={isDisabled || isLoading}
                >
                  Login
                </button>
                <button className="white-button" onClick={handleClose}>
                  Cancel
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default LoginSignUpModal;
