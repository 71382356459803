import React, { useEffect, useState } from 'react';
import './PatientDashboard.scss';
import DashboardNavigation from '../../../components/DashboardComponents/DashboardNavigation';
import { Button, CircularProgress, Divider } from '@mui/material';
import DashboardTable from '../../../components/DashboardComponents/DashboardTable';
import OrderStatus from '../../../components/DashboardComponents/OrderStatus';
import PrescriptionOverview from '../../../components/DashboardComponents/PrescriptionOverview';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOrdersByUserId } from '../../../redux/OrderSlice';
import { useNavigate } from 'react-router-dom';
import RecentViewProducts from '../../../components/DashboardComponents/RecentViewProducts';

const PatientDashboard = () => {
  const dispatch = useDispatch();
  const { isLoading, ordersList, count } = useSelector((state) => state.order);
  const [ordersItemsList, setOrdersItemsList] = useState([]);
  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      dispatch(
        getAllOrdersByUserId({
          magentoUserId: user.refrence_id,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (ordersList?.length > 0) {
      const itemsArray = ordersList.flatMap((order) => order.items);
      setOrdersItemsList(itemsArray);
    }
  }, [ordersList]);

  return (
    <div className="dashboard">
      <div className="main-conatiner">
        <div className="wrapper">
          <DashboardNavigation />
          {!isLoading ? (
            <div className="right-div">
              <PrescriptionOverview />
              <OrderStatus data={ordersList} />
              {count !== 0 && (
                <div className="reorder-section">
                  <div className="title-div">
                    <div className="left">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="31"
                        height="31"
                        viewBox="0 0 31 31"
                        fill="none"
                      >
                        <rect width="31" height="31" fill="white" />
                        <path
                          d="M19.375 18.0832L25.8333 11.6248L19.375 5.1665"
                          stroke="black"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M25.8337 11.625H12.2712C10.387 11.625 8.58004 12.3735 7.24775 13.7058C5.91546 15.0381 5.16699 16.845 5.16699 18.7292C5.16699 19.6621 5.35075 20.5859 5.70776 21.4478C6.06478 22.3097 6.58807 23.0929 7.24775 23.7526C8.58004 25.0849 10.387 25.8333 12.2712 25.8333H16.792"
                          stroke="black"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p>Order Again</p>
                    </div>
                    <Button
                      className="view-all"
                      onClick={() => navigate('/cart')}
                    >
                      View Cart
                    </Button>
                  </div>
                  <DashboardTable
                    data={ordersItemsList}
                    perPage={3}
                    type={'Products'}
                  />
                  <Button
                    className="mobile-bttn"
                    onClick={() => navigate('/cart')}
                  >
                    View Cart
                  </Button>
                </div>
              )}
              {count !== 0 && (
                <div className="appointments-section">
                  <div className="title-div">
                    <div className="left">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="31"
                        height="31"
                        viewBox="0 0 31 31"
                        fill="none"
                      >
                        <path
                          d="M14.3391 23.1945C10.2006 22.7979 6.71311 20.2314 3.87402 15.5C6.97402 10.3333 10.849 7.75 15.499 7.75C20.149 7.75 24.024 10.3333 27.124 15.5C26.8526 15.9532 26.567 16.3978 26.2676 16.833M19.374 24.5417L21.9574 27.125L27.124 21.9583M12.9157 15.5C12.9157 16.1851 13.1879 16.8422 13.6723 17.3267C14.1568 17.8112 14.8139 18.0833 15.499 18.0833C16.1842 18.0833 16.8412 17.8112 17.3257 17.3267C17.8102 16.8422 18.0824 16.1851 18.0824 15.5C18.0824 14.8149 17.8102 14.1578 17.3257 13.6733C16.8412 13.1888 16.1842 12.9167 15.499 12.9167C14.8139 12.9167 14.1568 13.1888 13.6723 13.6733C13.1879 14.1578 12.9157 14.8149 12.9157 15.5Z"
                          stroke="#219D50"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p>Recent Viewed</p>
                    </div>
                    <Button
                      className="view-all"
                      onClick={() => navigate('/shop')}
                    >
                      View All products
                    </Button>
                  </div>
                  <RecentViewProducts />
                  <Button
                    className="mobile-bttn appointment"
                    onClick={() => navigate('/shop')}
                  >
                    View All products
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div
              className="right-div"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
              <span>Loading Data</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PatientDashboard;
