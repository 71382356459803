import React, { useEffect, useRef, useState } from 'react';
import './NotesHistory.scss';
// import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
// import { Button } from '@mui/material';
import NotesMessage from './NotesMessage';
import WriteNotePopup from './WriteNotePopup';
import noMsg from '../../assets/nomsg.svg';
import sendIcon from '../../assets/sendIcon.svg';
import { IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChatByPrescId,
  sendNewMssg,
  updateMssg,
} from '../../redux/ChatSlice';
import { socket } from '../../socket';
import notificationSound from '../../assets/notifSound.wav';

const NotesHistory = ({ prescId }) => {
  const [modelOpen, setModelOpen] = useState(false);
  const user = useSelector((state) => state.user.currentUser);
  const chat = useSelector((state) => state.chat.conversation);
  const [newMssg, setNewMssg] = useState('');
  const dispatch = useDispatch();
  const conversationRef = useRef();
  const sound = new Audio(notificationSound);

  const handleMessage = async () => {
    dispatch(
      sendNewMssg({
        userId: user._id,
        chatId: chat._id,
        content: newMssg,
        recieverId: chat.admin_id,
      })
    );
    setNewMssg('');
  };

  // fetch chat by prescId
  useEffect(() => {
    const loadChat = () => {
      dispatch(
        getChatByPrescId({
          userId: user._id,
          prescriptionId: prescId,
        })
      );
    };
    if (prescId) {
      loadChat();
    }
  }, [prescId]);

  const chatIdRef = useRef(chat?._id);

  useEffect(() => {
    chatIdRef.current = chat?._id;
  }, [chat?._id]);

  useEffect(() => {
    const handleRecieveMssg = (msg) => {
      if (msg?.chat_id === chatIdRef?.current) {
        dispatch(
          getChatByPrescId({
            userId: user._id,
            prescriptionId: prescId,
          })
        );
        // dispatch(updateMssg(msg));
      }
    };

    socket.on('recieve message', handleRecieveMssg);

    return () => {
      socket.off('recieve message', handleRecieveMssg);
    };
  }, []);

  const handleClose = () => {
    setModelOpen(false);
  };

  // Scroll to the bottom when messages change
  useEffect(() => {
    conversationRef.current.scrollTo({
      top: conversationRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [chat]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleMessage();
    }
  };

  return (
    <div className="notes-history-section">
      <WriteNotePopup handleClose={handleClose} open={modelOpen} />
      <div className="top-container-notes">
        <p>Note history</p>
        {/* <Button className="write-note-bttn" onClick={() => setModelOpen(true)}>
          <ModeEditOutlineOutlinedIcon />
          <span>Write Your Note</span>
        </Button> */}
      </div>
      <div className="chat-container">
        <div className="chat-wrapper" ref={conversationRef}>
          {chat?.messages && chat.messages?.length ? (
            chat.messages.map((msg) => (
              <NotesMessage
                user={user}
                msg={msg}
                key={msg?._id}
                setModelOpen={setModelOpen}
              />
            ))
          ) : (
            <div className="empty-chat">
              <img src={noMsg} alt="no-msgIcon" />
              <span>No message found</span>
            </div>
          )}
        </div>
        <div className="send-msg-container">
          <div className="input-box">
            <input
              placeholder="Type message"
              value={newMssg}
              onChange={(e) => setNewMssg(e.target.value)}
              onKeyDown={handleKeyPress}
            />
            <IconButton
              className="sendBttn"
              style={{ width: '39px', height: '39px' }}
              onClick={handleMessage}
              disabled={newMssg === ''}
            >
              <img src={sendIcon} alt="send" />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesHistory;
