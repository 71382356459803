import { Button, IconButton, Modal, TextField } from '@mui/material';
import React from 'react';
import './EditEmailPopup.scss';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const EditEmailPopup = ({
  handleClose,
  isOpen,
  handleChange,
  userNewData,
  handleEmailChange,
}) => {
  return (
    <Modal onClose={handleClose} open={isOpen}>
      <div className="userEmail-popup">
        <div className="top-wrapper">
          <div className="top">
            <p className="title">Edit email address</p>
            <IconButton
              style={{ width: '40px', height: '40px', fontSize: '40px' }}
              onClick={handleClose}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </div>
          <span className="alert-text">
            Upon adding your new email, an activation email will be sent to your
            current email address. Simply open the email, and your new email
            will be activated automatically.
          </span>
        </div>
        <TextField
          fullWidth
          name="email"
          label="E-mail Address"
          type="email"
          onChange={handleChange}
        />
        <div className="bttn-div">
          <Button
            className="save-bttn"
            onClick={handleEmailChange}
            disabled={!userNewData.email}
          >
            Save changes
          </Button>
          <Button className="cancel-bttn" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditEmailPopup;
