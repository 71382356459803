import React from 'react';
import './Ops.scss';
import { Link } from 'react-router-dom';
import buzzle from '../../assets/404.svg';

const Contact = () => {
  return (
    <div className="OpsWrapper">
      <div className="SectionOne">
        <div className="wrap">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="256"
            height="33"
            viewBox="0 0 256 33"
            fill="none"
          >
            <g clipPath="url(#clip0_3743_60352)">
              <path
                d="M255.367 8.05859V13.2598H254.162L250.008 24.2647C248.972 27.0345 247.788 29.1065 246.456 30.4808C245.124 31.8762 243.369 32.574 241.191 32.574C240.303 32.574 239.404 32.4788 238.495 32.2885C237.586 32.1194 236.761 31.8762 236.021 31.5591V25.8188H240.398V26.1359C240.398 26.6645 240.673 26.9288 241.223 26.9288C241.709 26.9288 242.079 26.6962 242.333 26.2311L237.322 13.2598H236.117V8.05859H245.536V13.2598H244.458L246.107 18.2073L247.629 13.2598H246.551V8.05859H255.367Z"
                fill="#219D50"
              />
              <path
                d="M227.474 26.1365C224.45 26.1365 222.093 25.3543 220.401 23.7897C218.71 22.2039 217.864 19.8993 217.864 16.8759C217.864 13.8524 218.71 11.5584 220.401 9.99383C222.093 8.4081 224.44 7.61523 227.442 7.61523C228.901 7.61523 230.296 7.81609 231.628 8.21781C232.96 8.61953 233.996 9.07411 234.736 9.58154V15.861H229.282V15.2902C229.282 14.5924 229.134 14.085 228.838 13.7679C228.542 13.4296 228.076 13.2604 227.442 13.2604C226.216 13.2604 225.603 13.937 225.603 15.2902V18.0176C225.603 19.6668 226.543 20.4914 228.425 20.4914C230.18 20.4914 232.22 20.3011 234.546 19.9205V24.8997C233.827 25.2168 232.812 25.5023 231.502 25.756C230.212 26.0097 228.869 26.1365 227.474 26.1365Z"
                fill="#219D50"
              />
              <path
                d="M216.493 20.4914V25.6925H208.121V23.536C207.656 24.2125 207 24.8151 206.155 25.3437C205.33 25.8723 204.315 26.1365 203.11 26.1365C201.524 26.1365 200.277 25.7137 199.368 24.868C198.458 24.0222 198.004 22.8277 198.004 21.2842C198.004 17.2882 201.397 15.2373 208.184 15.1316C208.121 14.4127 207.846 13.9264 207.36 13.6727C206.873 13.3979 206.049 13.2604 204.886 13.2604C203.935 13.2604 202.909 13.3661 201.81 13.5776C200.731 13.7679 199.738 14.0322 198.828 14.3704V8.53496C199.949 8.2601 201.165 8.0381 202.476 7.86895C203.787 7.69981 205.066 7.61523 206.313 7.61523C209.463 7.61523 211.715 8.1861 213.068 9.32782C214.421 10.4695 215.098 12.235 215.098 14.6242V20.4914H216.493ZM208.121 18.2713C207.169 18.2713 206.429 18.3982 205.901 18.6519C205.393 18.9056 205.14 19.3285 205.14 19.9205C205.14 20.2799 205.245 20.5759 205.457 20.8085C205.689 21.0199 206.007 21.1256 206.408 21.1256C206.958 21.1256 207.381 20.8931 207.677 20.4279C207.973 19.9628 208.121 19.3073 208.121 18.4616V18.2713Z"
                fill="#219D50"
              />
              <path
                d="M196.513 20.4914V25.6925H186.491V20.4914H187.823V15.2902C187.823 14.5924 187.675 14.085 187.379 13.7679C187.083 13.4296 186.639 13.2604 186.047 13.2604C184.779 13.2604 184.144 13.937 184.144 15.2902V20.4914H185.476V25.6925H175.518V20.4914H176.85V15.2902C176.85 14.5924 176.702 14.085 176.406 13.7679C176.11 13.4296 175.666 13.2604 175.074 13.2604C173.806 13.2604 173.171 13.937 173.171 15.2902V20.4914H174.503V25.6925H164.481V20.4914H165.877V13.2604H164.481V8.05924H173.171V10.533C174.694 8.58782 176.596 7.61523 178.88 7.61523C180.17 7.61523 181.216 7.92181 182.02 8.53496C182.844 9.12696 183.426 9.97269 183.764 11.0721C184.525 9.97269 185.392 9.12696 186.364 8.53496C187.358 7.94295 188.521 7.64695 189.853 7.64695C191.608 7.64695 192.919 8.19667 193.786 9.29611C194.674 10.3955 195.118 11.9073 195.118 13.8313V20.4914H196.513Z"
                fill="#219D50"
              />
              <path
                d="M160.822 7.61523C161.245 7.61523 161.647 7.65752 162.028 7.74209C162.408 7.82667 162.725 7.93238 162.979 8.05924V14.1167C162.112 13.7573 161.182 13.5776 160.188 13.5776C158.856 13.5776 157.831 13.9264 157.112 14.6242C156.393 15.3219 156.034 16.3579 156.034 17.7322V20.4914H158.888V25.6925H147.344V20.4914H148.739V13.2604H147.344V8.05924H156.034V10.3744C156.626 9.4864 157.302 8.80982 158.063 8.34467C158.824 7.85838 159.744 7.61523 160.822 7.61523Z"
                fill="#219D50"
              />
              <path
                d="M145.783 20.4914V25.6925H137.41V23.536C136.945 24.2125 136.289 24.8151 135.444 25.3437C134.619 25.8723 133.604 26.1365 132.399 26.1365C130.813 26.1365 129.566 25.7137 128.657 24.868C127.748 24.0222 127.293 22.8277 127.293 21.2842C127.293 17.2882 130.686 15.2373 137.473 15.1316C137.41 14.4127 137.135 13.9264 136.649 13.6727C136.162 13.3979 135.338 13.2604 134.175 13.2604C133.224 13.2604 132.198 13.3661 131.099 13.5776C130.02 13.7679 129.027 14.0322 128.118 14.3704V8.53496C129.238 8.2601 130.454 8.0381 131.765 7.86895C133.076 7.69981 134.355 7.61523 135.602 7.61523C138.752 7.61523 141.004 8.1861 142.357 9.32782C143.711 10.4695 144.387 12.235 144.387 14.6242V20.4914H145.783ZM137.41 18.2713C136.458 18.2713 135.718 18.3982 135.19 18.6519C134.682 18.9056 134.429 19.3285 134.429 19.9205C134.429 20.2799 134.534 20.5759 134.746 20.8085C134.978 21.0199 135.296 21.1256 135.697 21.1256C136.247 21.1256 136.67 20.8931 136.966 20.4279C137.262 19.9628 137.41 19.3073 137.41 18.4616V18.2713Z"
                fill="#219D50"
              />
              <path
                d="M125.793 20.4903V25.6915H115.771V20.4903H117.103V15.2891C117.103 14.5914 116.955 14.084 116.659 13.7668C116.363 13.4286 115.919 13.2594 115.327 13.2594C114.058 13.2594 113.424 13.936 113.424 15.2891V20.4903H114.756V25.6915H104.734V20.4903H106.13V6.21877H104.734V1.01758H113.424V10.532C114.206 9.55937 115.052 8.82994 115.961 8.34365C116.87 7.85736 117.928 7.61421 119.133 7.61421C120.888 7.61421 122.199 8.16393 123.065 9.26337C123.953 10.3628 124.397 11.8745 124.397 13.7986V20.4903H125.793Z"
                fill="#219D50"
              />
              <path
                d="M80.9082 20.4903H82.4939V6.21877H80.9082V1.01758H94.8309C97.5584 1.01758 99.6409 1.60958 101.079 2.7936C102.516 3.95646 103.235 5.84877 103.235 8.47051C103.235 11.0922 102.516 12.9951 101.079 14.1791C99.6409 15.342 97.5584 15.9234 94.8309 15.9234H91.374V20.4903H94.4186V25.6915H80.9082V20.4903ZM92.4523 11.42C93.6786 11.42 94.2918 10.7434 94.2918 9.39023V8.2485C94.2918 7.55078 94.1332 7.04335 93.816 6.7262C93.52 6.38792 93.0655 6.21877 92.4523 6.21877H91.374V11.42H92.4523Z"
                fill="#219D50"
              />
              <path
                d="M69.4387 8.05859V13.2598H68.2335L64.0789 24.2647C63.0429 27.0345 61.8589 29.1065 60.5269 30.4808C59.1949 31.8762 57.44 32.574 55.2622 32.574C54.3742 32.574 53.4757 32.4788 52.5665 32.2885C51.6574 32.1194 50.8328 31.8762 50.0928 31.5591V25.8188H54.4694V26.1359C54.4694 26.6645 54.7442 26.9288 55.294 26.9288C55.7803 26.9288 56.1503 26.6962 56.404 26.2311L51.3931 13.2598H50.1879V8.05859H59.6071V13.2598H58.5289L60.178 18.2073L61.7003 13.2598H60.622V8.05859H69.4387Z"
                fill="#219D50"
              />
              <path
                d="M43.3958 26.136C41.1546 26.136 39.4632 25.5969 38.3214 24.5186C37.2008 23.4192 36.6406 21.5797 36.6406 19.0003V13.2599H35.2451V8.05873C36.0486 8.05873 36.6511 7.85787 37.0528 7.45615C37.4546 7.03329 37.6554 6.46243 37.6554 5.74356V4.31641H44.3789V8.05873H48.6604V13.2599H44.3789V18.4611C44.3789 19.1588 44.5269 19.6768 44.8229 20.0151C45.1401 20.3323 45.6052 20.4908 46.2184 20.4908C47.0218 20.4908 47.8992 20.3957 48.8507 20.2054V25.1846C48.2587 25.4172 47.4552 25.6286 46.4404 25.8189C45.4466 26.0303 44.4318 26.136 43.3958 26.136Z"
                fill="#219D50"
              />
              <path
                d="M25.6453 6.31391V1.01758H32.1151V6.31391H25.6453ZM24.123 25.6915V20.4903H25.5185V13.2594H24.123V8.05822H32.8128V20.4903H34.2083V25.6915H24.123Z"
                fill="#219D50"
              />
              <path
                d="M13.1284 26.1362C9.13232 26.1362 6.04543 25.1213 3.8677 23.0916C1.71111 21.0618 0.632812 17.8164 0.632812 13.3552C0.632812 4.83454 4.77685 0.574219 13.0649 0.574219C15.0735 0.574219 16.9341 0.817364 18.6467 1.30365C20.3804 1.78994 21.6701 2.32909 22.5159 2.9211V10.8497H14.9044V8.56629C14.9044 7.76286 14.7247 7.17085 14.3652 6.79028C14.0058 6.4097 13.4666 6.21941 12.7478 6.21941C12.0289 6.21941 11.4898 6.4097 11.1303 6.79028C10.7709 7.17085 10.5912 7.76286 10.5912 8.56629V18.1441C10.5912 18.9475 10.7709 19.5395 11.1303 19.9201C11.4898 20.3007 12.0289 20.491 12.7478 20.491C13.4666 20.491 14.0058 20.3007 14.3652 19.9201C14.7247 19.5395 14.9044 18.9475 14.9044 18.1441V15.5752H22.5159V23.7893C21.649 24.3813 20.3593 24.9204 18.6467 25.4067C16.9552 25.893 15.1158 26.1362 13.1284 26.1362Z"
                fill="#219D50"
              />
            </g>
            <defs>
              <clipPath id="clip0_3743_60352">
                <rect
                  width="254.734"
                  height="32"
                  fill="white"
                  transform="translate(0.632812 0.574219)"
                />
              </clipPath>
            </defs>
          </svg>
          <div className="textwrap">
            <img src={buzzle} alt="buzzle" />
            <p className="note">404</p>
            <p className="title">Looking for something?</p>
            <p className="desc">
              We can’t find the page that you looking for. Probably the link is
              broken.
            </p>
            <Link to="/" className="button">
              Go to homepage
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
