import React, { useState } from 'react';
import './AutomaticReminder.scss';
import { Box, Button, IconButton, Modal } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  width: '80%',
  maxWidth: '731px',
  padding: '24px',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '24px',
  borderRadius: '10px',
  background: '#FFF',
};

const AutomaticReminder = () => {
  const [open, setOpen] = useState(false);

  const handleAutoReminder = () => {
    //Api request and on success will setOpen = true
    setOpen(true);
  };

  return (
    <div className="automatocReminder-container">
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <div className="top-auto-reminder-popup">
            <IconButton
              style={{ width: '40px', height: '40px', fontSize: '40px' }}
              onClick={() => setOpen(false)}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </div>
          <div className="bottom-auto-reminder-popup">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="54"
              height="54"
              viewBox="0 0 54 54"
              fill="none"
            >
              <g clipPath="url(#clip0_1531_27328)">
                <path
                  d="M38.564 17.9125C38.2631 14.766 36.6765 11.8725 34.1533 9.86854C31.63 7.86458 28.3768 6.91431 25.1093 7.22679C21.8417 7.53927 18.8276 9.08889 16.7298 11.5348C14.6321 13.9806 13.6227 17.1224 13.9236 20.2689C15.2472 34.1102 9.4653 38.6539 9.4653 38.6539L46.426 35.1193C46.426 35.1193 39.8877 31.7538 38.564 17.9125Z"
                  stroke="#219D50"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M32.2531 44.4562C31.9494 45.09 31.4788 45.637 30.8884 46.0424C30.2981 46.4478 29.6087 46.6974 28.8892 46.7662C28.1697 46.835 27.4455 46.7206 26.789 46.4344C26.1325 46.1482 25.5668 45.7004 25.1484 45.1356"
                  stroke="#219D50"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.70878 21.0544C5.29277 16.7043 6.33304 12.4144 8.68096 8.79771"
                  stroke="#219D50"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M46.7765 17.1271C46.3673 12.8487 44.5282 8.8236 41.5352 5.65586"
                  stroke="#219D50"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1531_27328">
                  <rect
                    width="49.5058"
                    height="47.6722"
                    fill="white"
                    transform="translate(0.0898438 5.62853) rotate(-5.46263)"
                  />
                </clipPath>
              </defs>
            </svg>
            <p>Automatic Notification Will Be Sent</p>
          </div>
          <Button id="back-auto-reminder" onClick={() => setOpen(false)}>
            Back
          </Button>
        </Box>
      </Modal>
      <div className="left">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="26"
          viewBox="0 0 27 26"
          fill="none"
        >
          <path
            d="M20.25 8.66667C20.25 6.94276 19.5388 5.28946 18.273 4.07047C17.0071 2.85149 15.2902 2.16667 13.5 2.16667C11.7098 2.16667 9.9929 2.85149 8.72703 4.07047C7.46116 5.28946 6.75 6.94276 6.75 8.66667C6.75 16.25 3.375 18.4167 3.375 18.4167H23.625C23.625 18.4167 20.25 16.25 20.25 8.66667Z"
            stroke="#219D50"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.4472 22.75C15.2494 23.0783 14.9655 23.3509 14.6239 23.5403C14.2824 23.7298 13.8951 23.8295 13.5009 23.8295C13.1068 23.8295 12.7195 23.7298 12.3779 23.5403C12.0364 23.3509 11.7525 23.0783 11.5547 22.75"
            stroke="#219D50"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="text-wrap">
          <p>Automatic Refill Reminder!</p>
          <p>
            Set up automatic refill reminders to receive notifications when it's
            time to request a prescription refill.
          </p>
        </div>
      </div>
      <Button className="activateReminder" onClick={handleAutoReminder}>
        Set Automatic Reminder
      </Button>
    </div>
  );
};

export default AutomaticReminder;
