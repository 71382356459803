import React, { useEffect } from 'react';
import './HomeCategoryProductsSwiper.scss';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { FreeMode, Navigation, Controller } from 'swiper/modules';
import { Box, Divider, Rating, Skeleton, useMediaQuery } from '@mui/material';
import SwiperNavButton from '../SwiperNavButton';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { publicRequest } from '../../utils/ApiConfig';
import noImg from '../../assets/No_Image_Available.jpg';
import { useDispatch, useSelector } from 'react-redux';
import {
  addTocart,
  createGuestCart,
  userModelOpen,
} from '../../redux/CartSlice';
import RatingComponent from '../../components/ShopComponents/RatingComponent';

const HomeCategoryProductsSwiper = () => {
  const small = useMediaQuery('(max-width:600px)');
  const medium = useMediaQuery('(min-width:600px)');
  const large = useMediaQuery('(min-width:1200px)');
  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);
  const [data, setData] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { guestCartId } = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user.currentUser);

  const handleAddToCart = (e, product) => {
    e.stopPropagation();
    if (user) {
      dispatch(
        addTocart({
          magentoUserId: user.refrence_id,
          cartItem: {
            qty: 1,
            product_id: product?.id,
          },
        })
      );
    } else {
      if (guestCartId) {
        dispatch(
          addTocart({
            cartItem: {
              qty: 1,
              product_id: product?.id,
              quote_id: guestCartId,
            },
            cartId: guestCartId,
          })
        );
      } else {
        dispatch(
          createGuestCart({
            cartItem: {
              qty: 1,
              product_id: product?.id,
            },
            cartId: guestCartId,
          })
        );
      }
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setIsLoading(true);
        const response = await publicRequest('/products?page_size=40');
        const data = response.data;
        setData(data.products);
        setIsLoading(false);
      } catch (err) {}
    };
    fetchProducts();
  }, []);

  return (
    <div className="Hcategory-product-swiper-wrapper">
      <div className="products">
        <Swiper
          slidesPerView={large ? 5 : medium ? 3 : small && 2.2}
          spaceBetween={large ? 32 : medium ? 20 : small && 12}
          modules={[Navigation, FreeMode, Controller]}
          navigation={true}
          className="mySwiper"
          freeMode={true}
          speed={400}
          onSwiper={setFirstSwiper}
          controller={{ control: secondSwiper }}
        >
          <div className="top-section">
            <h1>Featured Products</h1>
            <div className="top-buttons">
              {large && (
                <Link className="button" to="/shop">
                  View more
                </Link>
              )}
              {large && (
                <Divider
                  sx={{ height: '30px', marginTop: '8px !important' }}
                  orientation="vertical"
                  flexItem
                />
              )}
              <div className="nav-buttons">
                <SwiperNavButton />
              </div>
            </div>
          </div>

          {data?.slice(0, 20)?.map((product) => (
            <SwiperSlide>
              {!isLoading ? (
                <div
                  className="inside-swiper"
                  onClick={() => navigate(`/product/${product?.id}`)}
                >
                  <div className="image-part">
                    <img
                      src={product?.images ? product?.images[1].url : noImg}
                      alt={product?.name}
                    />
                    <button onClick={(e) => handleAddToCart(e, product)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_2119_13622)">
                          <path
                            d="M5.33366 14.6663C5.70185 14.6663 6.00033 14.3679 6.00033 13.9997C6.00033 13.6315 5.70185 13.333 5.33366 13.333C4.96547 13.333 4.66699 13.6315 4.66699 13.9997C4.66699 14.3679 4.96547 14.6663 5.33366 14.6663Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.6667 14.6663C13.0349 14.6663 13.3333 14.3679 13.3333 13.9997C13.3333 13.6315 13.0349 13.333 12.6667 13.333C12.2985 13.333 12 13.6315 12 13.9997C12 14.3679 12.2985 14.6663 12.6667 14.6663Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1.36621 1.36621H2.69954L4.47288 9.64621C4.53793 9.94945 4.70666 10.2205 4.95002 10.4128C5.19338 10.605 5.49615 10.7064 5.80621 10.6995H12.3262C12.6297 10.6991 12.9239 10.5951 13.1602 10.4048C13.3966 10.2145 13.561 9.94923 13.6262 9.65288L14.7262 4.69954H3.41288"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2119_13622">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      Add
                    </button>
                  </div>
                  <div className="price-rating">
                    {/* <Rating
                      color="success"
                      name="simple-controlled"
                      value={product?.rating || 0}
                      readOnly
                    /> */}
                    <RatingComponent product={product} />

                    <p className="price">${product?.price_info?.final_price}</p>
                    <p>{product?.name}</p>
                  </div>
                </div>
              ) : (
                <Box sx={{ width: '100%' }}>
                  <Skeleton variant="rectangular" width={'100%'} height={200} />
                  <Box sx={{ pt: 0.5 }}>
                    <Skeleton />
                    <Skeleton width="60%" />
                  </Box>
                </Box>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper
          slidesPerView={large ? 5 : medium ? 3 : small && 2.2}
          spaceBetween={large ? 32 : medium ? 20 : small && 12}
          modules={[Navigation, FreeMode, Controller]}
          navigation={true}
          className="mySwiper"
          freeMode={true}
          speed={400}
          style={{ paddingTop: large ? '32px' : '24px' }}
          onSwiper={setSecondSwiper}
          controller={{ control: firstSwiper }}
        >
          {data?.slice(20, 40)?.map((product) => (
            <SwiperSlide>
              {!isLoading ? (
                <div
                  className="inside-swiper"
                  onClick={() => navigate(`/product/${product?.id}`)}
                >
                  <div className="image-part">
                    <img
                      src={product?.images ? product?.images[0].url : noImg}
                      alt={product?.name}
                    />
                    <button onClick={(e) => handleAddToCart(e, product)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_2119_13622)">
                          <path
                            d="M5.33366 14.6663C5.70185 14.6663 6.00033 14.3679 6.00033 13.9997C6.00033 13.6315 5.70185 13.333 5.33366 13.333C4.96547 13.333 4.66699 13.6315 4.66699 13.9997C4.66699 14.3679 4.96547 14.6663 5.33366 14.6663Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.6667 14.6663C13.0349 14.6663 13.3333 14.3679 13.3333 13.9997C13.3333 13.6315 13.0349 13.333 12.6667 13.333C12.2985 13.333 12 13.6315 12 13.9997C12 14.3679 12.2985 14.6663 12.6667 14.6663Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1.36621 1.36621H2.69954L4.47288 9.64621C4.53793 9.94945 4.70666 10.2205 4.95002 10.4128C5.19338 10.605 5.49615 10.7064 5.80621 10.6995H12.3262C12.6297 10.6991 12.9239 10.5951 13.1602 10.4048C13.3966 10.2145 13.561 9.94923 13.6262 9.65288L14.7262 4.69954H3.41288"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2119_13622">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      Add
                    </button>
                  </div>
                  <div className="price-rating">
                    <Rating
                      color="success"
                      name="simple-controlled"
                      value={product?.rating || 0}
                      readOnly
                    />
                    <p className="price">${product?.price_info?.final_price}</p>
                    <p>{product?.name}</p>
                  </div>
                </div>
              ) : (
                <Box sx={{ width: '100%' }}>
                  <Skeleton variant="rectangular" width={'100%'} height={200} />
                  <Box sx={{ pt: 0.5 }}>
                    <Skeleton />
                    <Skeleton width="60%" />
                  </Box>
                </Box>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default HomeCategoryProductsSwiper;
