import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Home from "./pages/Home/Home";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { CircularProgress } from "@mui/material";
import Layout from "./components/Layout/Layout";
import DashboardRoutes from "./pages/PatientDashboard/DasboardRoutes";
import Ops from "./pages/404/Ops";
import { resetSuccess } from "./redux/CartSlice";
import { resetWishlistSuccess } from "./redux/WishListSlice";
import { socket } from "./socket";
import WebFont from "webfontloader";

// import markerSDK from '@marker.io/browser';
// const widget = await markerSDK.loadWidget({
//   project: '660d74387b0d85c998391896',
// });

// Lazy-loaded components
const Consent = React.lazy(() => import("./pages/Consent/Consent"));
const About = React.lazy(() => import("./pages/About/About"));
const Contact = React.lazy(() => import("./pages/Contact/Contact"));
const Vaccine = React.lazy(() => import("./pages/Vaccine/Vaccine"));
const Testing = React.lazy(() => import("./pages/Testing/Testing"));
const Compression = React.lazy(() => import("./pages/Compression/Compression"));
const MinorAliments = React.lazy(() =>
  import("./pages/MinorAliments/MinorAliments")
);
const Shippingpolicy = React.lazy(() =>
  import("./pages/Shippingpolicy/Shippingpolicy")
);
const Privacypolicy = React.lazy(() =>
  import("./pages/Privacypolicy/Privacypolicy")
);
const Terms = React.lazy(() => import("./pages/Terms/Terms"));
const Fillprescription = React.lazy(() =>
  import("./pages/Prescription/FillPrescription")
);
const Transferprescription = React.lazy(() =>
  import("./pages/Prescription/Transferprescription")
);
const Refillprescription = React.lazy(() =>
  import("./pages/Prescription/Refillprescription")
);
const ForgetPassword = React.lazy(() =>
  import("./pages/ForgetPassword/ForgetPassword")
);
const PaymentSuccess = React.lazy(() =>
  import("./components/CartComponents/PaymentSuccess")
);
const AuthVerifyEmail = React.lazy(() =>
  import("./pages/AuthVerifyEmail/AuthVerifyEmail")
);
const ChangePassword = React.lazy(() =>
  import("./pages/ForgetPassword/Changepassword")
);
const Travel = React.lazy(() => import("./pages/TravelHealth/TravelHealth"));

const ConsultPharmacist = React.lazy(() =>
  import("./pages/ConsultPharmacist/ConsultPharmacist")
);
const Shop = React.lazy(() => import("./pages/Shop/Shop"));
const ShopCategories = React.lazy(() =>
  import("./pages/ShopCategories/ShopCategories")
);
const ShopSubCategories = React.lazy(() =>
  import("./pages/ShopSubCategories/ShopSubCategories")
);
const Product = React.lazy(() => import("./pages/Product/Product"));
const Cart = React.lazy(() => import("./pages/Cart/Cart"));

const lazyWithFallback = (Component) => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <CircularProgress />
        </div>
      }>
      <Component />
    </Suspense>
  );
};

function App() {
  const user = useSelector((state) => state.user.currentUser);
  const { isSuccess, error } = useSelector((state) => state.cart);
  const { isAddedToWish } = useSelector((state) => state.wishlist);
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      socket.connect();
      socket.emit("userConnected", user._id);
    } else {
      socket.disconnect();
    }
  }, [user]);

  useEffect(() => {
    if (isSuccess || isAddedToWish || error?.status) {
      setOpen(true);
    }
  }, [isSuccess, isAddedToWish, error]);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Roboto", "Inter", "Open Sans", "Gabarito"],
      },
    });
  }, []);

  function handleClose() {
    setOpen(false);
    dispatch(resetSuccess());
    dispatch(resetWishlistSuccess());
  }

  return (
    <div className="App">
      <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          severity={error?.status ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}>
          {error?.status
            ? error.message
            : isAddedToWish
            ? "product added to wishlist"
            : "Product added to cart!"}
        </Alert>
      </Snackbar>

      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" index element={<Home />} />
            <Route path="/404" element={<Ops />} />
            <Route path="/About" element={lazyWithFallback(About)} />
            <Route path="/Contact" element={lazyWithFallback(Contact)} />
            <Route path="/Vaccinations" element={lazyWithFallback(Vaccine)} />
            <Route path="/Testing" element={lazyWithFallback(Testing)} />
            <Route
              path="/Compression"
              element={lazyWithFallback(Compression)}
            />
            <Route
              path="/MinorAliments"
              element={lazyWithFallback(MinorAliments)}
            />
            <Route
              path="/Shipping_policy"
              element={lazyWithFallback(Shippingpolicy)}
            />
            <Route
              path="/Privacy_policy"
              element={lazyWithFallback(Privacypolicy)}
            />
            <Route path="/consent" element={lazyWithFallback(Consent)} />
            <Route path="/Terms" element={lazyWithFallback(Terms)} />
            <Route
              path="/Fillprescription"
              element={lazyWithFallback(Fillprescription)}
            />
            <Route
              path="/Transferprescription"
              element={lazyWithFallback(Transferprescription)}
            />
            <Route
              path="/Refillprescription"
              element={lazyWithFallback(Refillprescription)}
            />
            <Route
              path="/Consult_a_pharmacist"
              element={lazyWithFallback(ConsultPharmacist)}
            />
            <Route
              path="/forget-password"
              element={lazyWithFallback(ForgetPassword)}
            />
            <Route path="/Travel" element={lazyWithFallback(Travel)} />
            <Route path="/shop">
              <Route index element={lazyWithFallback(Shop)} />
              <Route path=":id" element={lazyWithFallback(ShopCategories)} />
              <Route
                path=":id/:subcategory"
                element={lazyWithFallback(ShopSubCategories)}
              />
            </Route>
            <Route
              path="/payment/success"
              element={lazyWithFallback(PaymentSuccess)}
            />
            <Route path="/product/:id" element={lazyWithFallback(Product)} />
            <Route path="/cart" element={lazyWithFallback(Cart)} />
            <Route
              path="/auth/verify/:token"
              element={lazyWithFallback(AuthVerifyEmail)}
            />
            <Route
              path="/reset/password/:token"
              element={lazyWithFallback(ChangePassword)}
            />
            <Route
              path="/dashboard/*"
              element={user ? <DashboardRoutes /> : <Navigate to="/" />}
            />
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
