import React, { useState } from 'react';
import './NavigationLinks.scss';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { Link, useLocation, useParams } from 'react-router-dom';
import LogoutPopup from './LogoutPopup';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const NavigationLinks = () => {
  const location = useLocation();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const pathname = location.pathname;
  const { orderId } = useParams();
  const [isLosgoutOpen, setIsLosgoutOpen] = useState(false);

  const handleShowSettings = (e) => {
    e.stopPropagation();
    setSettingsOpen(!settingsOpen);
  };

  return (
    <div className="bottom-user-cont-navigation-links">
      <LogoutPopup open={isLosgoutOpen} setOpen={setIsLosgoutOpen} />
      <Link
        to="/dashboard"
        className={`dashboard-nav-link ${
          pathname === '/dashboard' ? 'active' : ''
        }`}
      >
        <DashboardOutlinedIcon />
        Dashboard
      </Link>
      <Link
        to="/dashboard/prescription"
        className={`dashboard-nav-link ${
          pathname === '/dashboard/prescription' ? 'active' : ''
        }`}
      >
        <DescriptionOutlinedIcon />
        Prescriptions
      </Link>
      <Link
        to="/dashboard/minoraliments"
        className={`dashboard-nav-link ${
          pathname === '/dashboard/minoraliments' ? 'active' : ''
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <path
            d="M18 2.5L22 6.5"
            stroke="#393939"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17 7.5L20 4.5"
            stroke="#393939"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.0002 9.5L8.7002 19.8C7.7002 20.8 6.2002 20.8 5.3002 19.8L4.7002 19.2C3.7002 18.2 3.7002 16.7 4.7002 15.8L15.0002 5.5"
            stroke="#393939"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 11.5L13 15.5"
            stroke="#393939"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5 19.5L2 22.5"
            stroke="#393939"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 4.5L20 10.5"
            stroke="#393939"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Minor Aliments & Vaccinations
      </Link>
      <Link
        to="/dashboard/orders"
        className={`dashboard-nav-link ${
          pathname === '/dashboard/orders' ||
          pathname === `/dashboard/order/${orderId}`
            ? 'active'
            : ''
        }`}
      >
        {' '}
        <ShoppingCartOutlinedIcon />
        Orders
      </Link>
      <Link
        to="/dashboard/wishlist"
        className={`dashboard-nav-link ${
          pathname === '/dashboard/wishlist' ? 'active' : ''
        }`}
      >
        <FavoriteBorderOutlinedIcon />
        Wishlist
      </Link>
      <Accordion
        expanded={settingsOpen || pathname?.includes('settings')}
        onChange={handleShowSettings}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <SettingsOutlinedIcon />
          <Typography>Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Link
            to="/dashboard/settings/general"
            className={`dashboard-nav-link ${
              pathname === '/dashboard/settings/general' ? 'active' : ''
            }`}
          >
            <TuneOutlinedIcon />
            General
          </Link>
          <Link
            to="/dashboard/settings/address"
            className={`dashboard-nav-link ${
              pathname === '/dashboard/settings/address' ? 'active' : ''
            }`}
          >
            <LocationOnOutlinedIcon />
            Address
          </Link>
          <Link
            to="/dashboard/settings/payments"
            className={`dashboard-nav-link ${
              pathname === '/dashboard/settings/payments' ? 'active' : ''
            }`}
          >
            {' '}
            <PaymentOutlinedIcon />
            Payment method
          </Link>
          <Link
            to="/dashboard/settings/insurance"
            className={`dashboard-nav-link ${
              pathname === '/dashboard/settings/insurance' ? 'active' : ''
            }`}
          >
            {' '}
            <VerifiedUserOutlinedIcon />
            Insurance
          </Link>
          <Link
            to="/dashboard/settings/security"
            className={`dashboard-nav-link ${
              pathname === '/dashboard/settings/security' ? 'active' : ''
            }`}
          >
            <LockOutlinedIcon />
            Security
          </Link>
        </AccordionDetails>
      </Accordion>
      <Button className="button-logout" onClick={() => setIsLosgoutOpen(true)}>
        <LogoutOutlinedIcon style={{ color: 'F95457' }} />
        Logout
      </Button>
    </div>
  );
};

export default NavigationLinks;
