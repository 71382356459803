import { Button, Divider, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { publicRequest } from '../../utils/ApiConfig';
import noImg from '../../assets/No_Image_Available.jpg';
import { formatDate } from '../../utils/callBack';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteWishlistProduct,
  updateWishlistProduct,
} from '../../redux/WishListSlice';
import { addTocart } from '../../redux/CartSlice';
import { CMSURL } from '../../utils/config';

const TableproductComponent = ({ type, productData, isTablet }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const user = useSelector((state) => state.user.currentUser);
  const { cartLoading } = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  const handleAddToCart = (e, productId, qty) => {
    e.stopPropagation();
    dispatch(
      addTocart({
        magentoUserId: user.refrence_id,
        cartItem: {
          qty,
          product_id: productId,
        },
      })
    );
  };

  const updateProduct = (productId, quantity) => {
    dispatch(
      updateWishlistProduct({
        userId: user?._id,
        product_id: productId,
        quantity,
      })
    );
  };
  const handleDelete = (productId) => {
    dispatch(
      deleteWishlistProduct({
        userId: user?._id,
        product_id: productId,
      })
    );
  };

  const handleDecrement = (productId, quantity) => {
    if (quantity > 1) {
      updateProduct(productId, quantity - 1);
    } else {
      dispatch(
        deleteWishlistProduct({
          userId: user._id,
          product_id: productId,
        })
      );
    }
  };

  const handleIncremenet = (productId, quantity) => {
    updateProduct(productId, quantity + 1);
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setIsLoading(true);
        let response;
        if (productData?.product_id) {
          response = await publicRequest.get(
            `/products?product_id=${productData?.product_id}`
          );
          setProduct(response?.data?.products[0]);
        } else {
          response = await publicRequest.get(
            `/products/sku/${productData?.sku}`
          );
          setProduct(response.data);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };
    if (
      // product === null &&
      (productData?.product_id || productData?.item_id) &&
      type !== 'wishlistProducts'
    ) {
      fetchProduct();
    }
  }, [productData]);

  const getProductImage = () => {
    if (type !== 'wishlistProducts') {
      console.log(product);
      if (product) {
        return product?.images[0]?.url;
      } else {
        return noImg;
      }
    } else {
      if (productData?.images) {
        return productData?.images[0]?.url || noImg;
      } else {
        const imagePath = productData?.custom_attributes?.find(
          (attCode) => attCode?.attribute_code === 'image'
        )?.value;
        if (imagePath) {
          return CMSURL + '/pub/media/catalog/product' + imagePath;
        } else {
          return noImg;
        }
      }
    }
  };

  return (
    <>
      {!isTablet ? (
        <tr key={product?.product_id || productData?.id}>
          <td>
            <div className="product-info">
              <div className="img-wrapper">
                <img
                  src={isLoading ? noImg : getProductImage()}
                  alt="product-img"
                />
              </div>
              <div className="right-product-info">
                <p className={`${type}-p`}>{productData?.name || ''}</p>
                {type === 'wishlistProducts' && (
                  <span>{formatDate(productData?.created_at)}</span>
                )}
              </div>
            </div>
          </td>
          <td>
            <div style={{ display: 'flex', padding: '0px 32px' }}>
              <p className="price">
                $
                {productData?.base_price ||
                  productData?.price * productData?.qty_ordered ||
                  (productData?.price * productData?.quantity)?.toFixed(2) ||
                  0}
              </p>
            </div>
          </td>
          <td style={{ maxWidth: '160px' }}>
            {type === 'Products' || type === 'wishlistProducts' ? (
              <div style={{ display: 'flex', padding: '0px 32px' }}>
                <span className="stocking">
                  {/* {productData?.is_salable === '1'
                    ? */}
                  In Stock
                  {/* : 'Out Of Stock'} */}
                </span>
              </div>
            ) : (
              <div className="product-variant">
                {/* <div className="variantBox">
                      <span style={{ display: 'block' }}>
                        Color:{' '}
                        <span style={{ color: '#1E1E1E' }}>Pink</span>
                      </span>
                      <div
                        className="color-circle"
                        style={{ backgroundColor: '#C6B6C4' }}
                      ></div>
                    </div> */}
                <div className="variantBox">
                  <span style={{ display: 'block' }}>
                    Quantity:{' '}
                    <span style={{ color: '#1E1E1E' }}>
                      {productData?.qty_ordered}
                    </span>
                  </span>
                </div>
              </div>
            )}
          </td>
          {type === 'Products' ? (
            <td>
              <div className="bttn">
                <Button
                  className="add-to-cart-bttn"
                  onClick={(e) => handleAddToCart(e, product?.id, 1)}
                  disabled={cartLoading}
                >
                  Add To Cart
                </Button>
              </div>
            </td>
          ) : (
            type === 'wishlistProducts' && (
              <td style={{ paddingRight: '0px' }}>
                <div className="product-variant">
                  <div className="count-div">
                    <IconButton
                      className="quantity-bttn"
                      onClick={() =>
                        handleDecrement(productData?.id, productData?.quantity)
                      }
                    >
                      <RemoveIcon />
                    </IconButton>
                    <p className="quantity">{productData?.quantity}</p>
                    <IconButton
                      className="quantity-bttn"
                      onClick={() =>
                        handleIncremenet(productData?.id, productData?.quantity)
                      }
                    >
                      <AddIcon />
                    </IconButton>
                  </div>
                  <div className="bttn">
                    <Button
                      className="add-to-cart-bttn"
                      onClick={(e) =>
                        handleAddToCart(
                          e,
                          productData?.id,
                          productData?.quantity
                        )
                      }
                    >
                      Add To Cart
                    </Button>
                  </div>
                  <IconButton
                    className="delete-bttn"
                    onClick={() => handleDelete(productData?.id)}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                </div>
              </td>
            )
          )}
        </tr>
      ) : (
        <tr key={product?.product_id || product?.id}>
          <td>
            <div className="products-mobile-table">
              <div className="product-info">
                <div className="img-wrapper">
                  <img
                    src={isLoading ? noImg : getProductImage()}
                    alt="product-img"
                  />
                </div>
                <div className="right-product-info">
                  <p className={`${type}-p`}>{product?.name || ''}</p>
                  <div className="mobile-stocking-price">
                    <span>Instock</span>
                    <Divider
                      style={{
                        borderColor: 'black',
                        alignSelf: 'center',
                        height: '12px',
                      }}
                      orientation="vertical"
                      flexItem
                    />
                    <span>${product?.base_price || productData?.price}</span>
                  </div>
                  {type === 'wishlistProducts' && (
                    <span>{formatDate(productData?.created_at)}</span>
                  )}
                </div>
              </div>
              {type === 'wishlistProducts' && (
                <div className="product-variant">
                  <div className="count-div">
                    <IconButton
                      className="quantity-bttn"
                      onClick={() =>
                        handleDecrement(productData?.id, productData?.quantity)
                      }
                    >
                      <RemoveIcon />
                    </IconButton>
                    <p className="quantity">{productData?.quantity}</p>
                    <IconButton
                      className="quantity-bttn"
                      onClick={() =>
                        handleIncremenet(productData?.id, productData?.quantity)
                      }
                    >
                      <AddIcon />
                    </IconButton>
                  </div>
                  <div className="bttn">
                    <Button
                      className="add-to-cart-bttn"
                      onClick={(e) =>
                        handleAddToCart(
                          e,
                          productData?.id,
                          productData?.quantity
                        )
                      }
                    >
                      Add To Cart
                    </Button>
                  </div>
                  <IconButton
                    className="delete-bttn"
                    onClick={() => handleDelete(productData?.id)}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                </div>
              )}
              {type === 'Products' && (
                <div className="bttn">
                  <Button
                    className="add-to-cart-bttn"
                    style={{ width: 'calc(100% - 40px)' }}
                    onClick={(e) => handleAddToCart(e, product?.id, 1)}
                    disabled={cartLoading}
                  >
                    Add To Cart
                  </Button>
                </div>
              )}
              {type === 'ProductsDetails' && (
                <div className="product-variant">
                  {/* <div className="variantBox">
                        <span style={{ display: 'block' }}>
                          Color:{' '}
                          <span style={{ color: '#1E1E1E' }}>Pink</span>
                        </span>
                        <div
                          className="color-circle"
                          style={{ backgroundColor: '#C6B6C4' }}
                        ></div>
                      </div> */}
                  <div className="variantBox">
                    <span style={{ display: 'block' }}>
                      Quantity:{' '}
                      <span style={{ color: '#1E1E1E' }}>
                        {productData?.qty_ordered}
                      </span>
                    </span>
                  </div>
                </div>
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default TableproductComponent;
