import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { publicRequest, userRequest } from '../utils/ApiConfig';

export const getProductById = createAsyncThunk(
  'product/getProductById',
  async ({ productId }, { dispatch }) => {
    try {
      const product = await publicRequest.get(
        `/products?product_id=${productId}`
      );
      const productsdata = product.data.products[0];
      const respnseTwo = await publicRequest.get(`/products/${productId}`);
      const productDetails = respnseTwo.data;
      dispatch(
        getProductReviews({
          productId: productId,
        })
      );
      const joinedData = { ...productsdata, ...productDetails };
      dispatch(getProductsAttributes({ product: joinedData }));
      return joinedData;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching review');
      }
    }
  }
);

export const getProductReviews = createAsyncThunk(
  'product/reviews',
  async ({ productId }) => {
    try {
      const response = await publicRequest.get(`/product/${productId}/reviews`);
      const reviews = response.data;
      return reviews;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching order');
      }
    }
  }
);

export const getProductsAttributes = createAsyncThunk(
  'product/attributes',
  async ({ product }, { dispatch }) => {
    try {
      if (product.type === 'configurable') {
        const response = await publicRequest.get('/products-attributes');
        dispatch(getProductsVariants({ product }));
        const attributes = response.data;
        return attributes;
      } else {
        dispatch(getProductsVariants({ product }));
        return null;
      }
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching attributes');
      }
    }
  }
);
export const getProductsVariants = createAsyncThunk(
  'product/variants',
  async ({ product }) => {
    try {
      if (product.type === 'configurable') {
        const response = await publicRequest.get(
          `/product-variants/${product.sku}`
        );
        const variantsChildren = response.data;
        return variantsChildren;
      }
      return null;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching attributes');
      }
    }
  }
);

export const submitProductReview = createAsyncThunk(
  'product/SubmitReview',
  async ({ userId, data }, { dispatch }) => {
    try {
      await userRequest.post(`/customer/${userId}/product/reviews`, {
        firstName: data.firstName,
        lastName: data.lastName,
        product_id: data.product_id,
        value: data.value,
        comment: data.comment,
      });
      dispatch(
        getProductReviews({
          productId: data.product_id,
        })
      );
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching order');
      }
    }
  }
);

const productSlice = createSlice({
  name: 'product',
  initialState: {
    isLoading: false,
    isLoadingAttributes: false,
    loadingReview: false,
    reviewError: {
      status: false,
      message: null,
    },
    error: {
      status: false,
      message: null,
    },
    count: 0,
    singleProduct: null,
    reviews: null,
    attributes: null,
    variants: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductById.pending, (state) => {
        state.isLoading = true;
        state.variants = null;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.singleProduct = action.payload;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getProductById.rejected, (state) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: 'Something went Wrong!, please refresh the page',
        };
      })
      .addCase(getProductReviews.pending, (state) => {
        state.loadingReview = true;
      })
      .addCase(getProductReviews.fulfilled, (state, action) => {
        state.loadingReview = false;
        state.reviews = action.payload;
        state.reviewError = {
          status: false,
          message: null,
        };
      })
      .addCase(getProductReviews.rejected, (state, action) => {
        state.loadingReview = false;
        state.reviewError = {
          status: true,
          message: action.error.message || 'Unable fetching reviews',
        };
      })
      .addCase(submitProductReview.pending, (state) => {
        state.loadingReview = true;
      })
      .addCase(submitProductReview.fulfilled, (state, action) => {
        state.loadingReview = false;
        state.reviews = action.payload;
        state.reviewError = {
          status: false,
          message: null,
        };
      })
      .addCase(submitProductReview.rejected, (state, action) => {
        state.loadingReview = false;
        state.reviewError = {
          status: true,
          message: action.error.message || 'Unable fetching reviews',
        };
      })
      .addCase(getProductsAttributes.pending, (state) => {
        state.isLoadingAttributes = true;
      })
      .addCase(getProductsAttributes.fulfilled, (state, action) => {
        state.isLoadingAttributes = false;
        state.attributes = action.payload;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getProductsAttributes.rejected, (state) => {
        state.isLoadingAttributes = false;
        state.error = {
          status: true,
          message: 'Error Fetching product Attributes',
        };
      })
      .addCase(getProductsVariants.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductsVariants.fulfilled, (state, action) => {
        state.isLoading = false;
        state.variants = action.payload;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getProductsVariants.rejected, (state) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: 'Error Fetching product variants',
        };
      });
  },
});

export const productReducer = productSlice.reducer;
