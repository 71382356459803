import React, { useEffect, useState } from 'react';
import './Payments.scss';
import DashboardNavigation from '../../../../../components/DashboardComponents/DashboardNavigation';
import { Alert, Button, IconButton } from '@mui/material';
// import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import visaImng from '../../../../../assets/visa.png';
// import AddEditPaymentModal from '../../../../../components/DashboardComponents/AddEditPaymentModal';
import { Helmet } from 'react-helmet';
import { Evalon } from '../../../../../utils/Evalon';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserCards,
  setDefaultCard,
  storeUserCard,
} from '../../../../../redux/cardsSlice';
import DeleteUserCardModal from '../../../../../components/DashboardComponents/DeleteUserCardModal';

const SettingsPayments = () => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [targetCard, setTargetCard] = useState(null);
  const { currentUser } = useSelector((state) => state.user);
  const { userCards, isLoading } = useSelector((state) => state.card);
  const dispatch = useDispatch();
  const converge = window.PayWithConverge;
  const [paymentError, setPaymentError] = useState(false);
  const [isProcessingPayment, setIsPaymentProcessing] = useState(false);

  // Function to find and update current default address
  const hadleUpdateDefaultAddress = (card) => {
    if (!card.isDefault) {
      dispatch(
        setDefaultCard({
          userId: currentUser._id,
          cardId: card?._id,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      getUserCards({
        userId: currentUser._id,
      })
    );
  }, []);

  const addNewCard = (cardData) => {
    dispatch(
      storeUserCard({
        userId: currentUser._id,
        cardData,
      })
    );
  };

  const openLightBox = (token) => {
    let paymentFields = {
      ssl_txn_auth_token: token,
    };
    let callback = {
      onError: function (error) {
        setPaymentError(true);
      },
      onCancelled: function () {
        // console.log('cancelled', '');
      },
      onDeclined: function (response) {
        setPaymentError(true);
      },
      onApproval: function (response) {
        setIsPaymentProcessing(false);
        addNewCard(response);
      },
    };
    converge.open(paymentFields, callback);
  };

  const handleEvalonPayment = async () => {
    try {
      setIsPaymentProcessing(true);
      const evalonPaymentToken = await Evalon.getPaymentToken(
        '0',
        currentUser.firstname,
        currentUser.lastname,
        null,
        'genCardToken'
      );
      if (evalonPaymentToken) {
        openLightBox(evalonPaymentToken);
      } else {
        setPaymentError(true);
      }
    } catch (err) {
      setPaymentError(true);
    }
  };

  const handleOpenModal = (e, card) => {
    e.stopPropagation();
    setTargetCard(card);
    setDeleteModalOpen(true);
  };

  return (
    <div className="settingsPayments">
      <Helmet>
        <script src="https://api.convergepay.com/hosted-payments/PayWithConverge.js"></script>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
      </Helmet>
      {/* <AddEditPaymentModal
        handleClose={handleClose}
        open={isOpen}
        data={data}
        setData={setData}
        handleEditPayment={handleEditAddress}
        edit={edit}
        handleSubmit={handleNewPayment}
        handleDeletePayment={handleDelete}
      /> */}
      <DeleteUserCardModal
        setOpen={setDeleteModalOpen}
        open={deleteModalOpen}
        card={targetCard}
      />
      <div className="main-conatiner">
        {paymentError && (
          <Alert
            severity="error"
            style={{
              position: 'fixed',
              top: '16px',
              right: '16px',
              zIndex: 100,
            }}
          >
            Payment Failed! Try Again
          </Alert>
        )}
        <div className="wrapper">
          <DashboardNavigation />
          <div
            className={`right-div-settingsPayments ${
              isLoading ? 'disabled' : ''
            }`}
          >
            <p className="page-title">Payment Method</p>
            <div className="address-wrapper">
              <div className="address-section">
                {userCards?.length ? (
                  userCards.map((card) => (
                    <div
                      key={card.id}
                      className={`payment-box ${
                        card?.isDefault ? 'default' : ''
                      }`}
                      onClick={() => hadleUpdateDefaultAddress(card)}
                    >
                      <div className="circular-checkbox">
                        <DoneOutlinedIcon
                          style={{ fontSize: '13px', color: '#fff' }}
                        />
                      </div>
                      <div className="desc-box">
                        <div className="top">
                          <div className="img-wrapper">
                            <img src={visaImng} alt="card-type" />
                          </div>
                          {card?.isDefault && (
                            <span className="badge">Default</span>
                          )}
                        </div>
                        <div className="bottom-div">
                          <p className="card-number">
                            {card?.cardData?.ssl_card_number}
                          </p>
                          <div className="card-details">
                            <p>
                              {card?.cardData?.ssl_first_name}{' '}
                              {card?.cardData?.ssl_last_name}
                            </p>
                            <p>{card?.cardData?.ssl_exp_date}</p>
                          </div>
                        </div>
                      </div>
                      <IconButton className="edit-bttn">
                        <DeleteOutlineIcon
                          style={{ fontSize: '20px', color: '#CD1D1D' }}
                          onClick={(e) => handleOpenModal(e, card)}
                        />
                      </IconButton>
                    </div>
                  ))
                ) : (
                  <div className="empty-div">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="49"
                      height="48"
                      viewBox="0 0 49 48"
                      fill="none"
                    >
                      <path
                        d="M43.3567 7.71484H5.64244C3.74889 7.71484 2.21387 9.24988 2.21387 11.1434V36.8577C2.21387 38.7513 3.74889 40.2863 5.64244 40.2863H43.3567C45.2503 40.2863 46.7853 38.7513 46.7853 36.8577V11.1434C46.7853 9.24988 45.2503 7.71484 43.3567 7.71484Z"
                        stroke="#8F91A1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.21387 19.7148H46.7853"
                        stroke="#8F91A1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M33.0713 31.7148H38.2141"
                        stroke="#8F91A1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p>There is not a Payment Method</p>
                  </div>
                )}
              </div>
            </div>
            <Button
              className="add-payment-bttn"
              onClick={handleEvalonPayment}
              disabled={isProcessingPayment || isLoading}
            >
              <span>Add New Payment Method</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPayments;
