import { Rating, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { publicRequest } from '../../utils/ApiConfig';

const RatingComponent = ({ product }) => {
  const [rating, setRating] = useState(null);

  useEffect(() => {
    const fetchProductsReviews = async () => {
      try {
        const response = await publicRequest.get(
          `/product/${product?.id}/reviews/overall`
        );
        const data = response.data;
        setRating(data);
      } catch (err) {
        // console.log(err);
      }
    };
    if (product) {
      fetchProductsReviews();
    }
  }, []);

  return (
    <>
      {rating ? (
        <Rating
          color="success"
          name="simple-controlled"
          value={rating?.overall_rating}
          readOnly
        />
      ) : (
        <Skeleton style={{ width: '90%' }}></Skeleton>
      )}
    </>
  );
};

export default RatingComponent;
