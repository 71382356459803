import React, { useEffect, useState } from 'react';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import './NationalIdComponent.scss';
import InsurancePopup from './InsurancePopup';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewDocument,
  deleteDocument,
  updateDocument,
} from '../../redux/DocumentsSlice';

const NationalIdComponent = () => {
  const user = useSelector((state) => state.user.currentUser);
  const [photoIdData, setPhotoIdData] = useState([]);
  const { list, isUpdated } = useSelector((state) => state.documents);

  useEffect(() => {
    setPhotoIdData(list.filter((list) => list.type === 'nationalId'));
  }, [list]);

  const [data, setData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const openEditAddress = (e, photoIdData) => {
    setEditMode(true);
    e.stopPropagation();
    setData(photoIdData);
    setOpen(true);
  };

  useEffect(() => {
    if (!open) {
      setEditMode(false);
      setData(null);
    }
  }, [open]);

  const handleUpdate = () => {
    dispatch(
      updateDocument({
        userId: user._id,
        documentData: data,
      })
    );
  };
  // post new document by tupe to Database
  const handleAdd = (e) => {
    e.preventDefault();
    dispatch(
      addNewDocument({
        userId: user._id,
        documentData: { ...data, type: 'nationalId' },
      })
    );
  };

  // delete document by id
  const handledelete = () => {
    dispatch(
      deleteDocument({
        userId: user._id,
        documentId: data._id,
      })
    );
  };

  useEffect(() => {
    setOpen(false);
  }, [isUpdated]);

  return (
    <div className="nationalIdComponent">
      <InsurancePopup
        handleClose={handleClose}
        open={open}
        edit={editMode}
        data={data}
        setData={setData}
        handleUpdate={handleUpdate}
        handleAdd={handleAdd}
        handledelete={handledelete}
      />
      <p className="section-title">Photo ID</p>
      {photoIdData.length ? (
        photoIdData.map((natIonalId) => (
          <div className="nationalId-card-wrapper" key={natIonalId._id}>
            <div
              className={`nationalId-box ${
                natIonalId.isDefault ? 'default' : ''
              }`}
            >
              <div className="circular-checkbox">
                <DoneOutlinedIcon style={{ fontSize: '13px', color: '#fff' }} />
              </div>
              <div className="desc-box">
                <div className="top">
                  <p className="insurance-name">
                    {natIonalId?.document_name || ''}
                  </p>
                  {natIonalId.isDefault && (
                    <span className="badge">Default</span>
                  )}
                </div>
                <div className="bottom-div">
                  <div
                    className="img-wrapper"
                    style={{
                      backgroundImage: `url(${natIonalId?.attatchement_front_url})`,
                    }}
                  ></div>
                  <div
                    className="img-wrapper"
                    style={{
                      backgroundImage: `url(${natIonalId?.attatchement_back_url})`,
                    }}
                  ></div>
                </div>
              </div>
              <IconButton
                className="edit-bttn"
                onClick={(e) => openEditAddress(e, natIonalId)}
              >
                <ModeEditOutlineOutlinedIcon style={{ fontSize: '20px' }} />
              </IconButton>
            </div>
          </div>
        ))
      ) : (
        <div className="empty-div">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="83"
            height="82"
            viewBox="0 0 83 82"
            fill="none"
          >
            <path
              d="M28.4473 27.9772C29.1048 30.0821 30.2606 31.9973 31.8167 33.5599C33.3728 35.1225 35.2831 36.2864 37.3853 36.9528M49.3675 35.0907C50.9981 33.9403 52.357 32.4468 53.3487 30.7152C54.3404 28.9835 54.9408 27.0557 55.1079 25.0672C55.2749 23.0787 55.0044 21.0777 54.3155 19.2049C53.6265 17.3321 52.5358 15.6329 51.1199 14.2266C49.7041 12.8204 47.9975 11.7413 46.12 11.0651C44.2425 10.389 42.2398 10.1322 40.2524 10.3127C38.2651 10.4933 36.3414 11.1069 34.6166 12.1104C32.8917 13.1139 31.4075 14.4829 30.2683 16.1213M21 71.75V64.9167C21 61.292 22.4399 57.8159 25.0029 55.2529C27.5659 52.6899 31.042 51.25 34.6667 51.25H48.3333C49.741 51.25 51.1008 51.4618 52.3752 51.8582M61.3713 60.803C61.7813 62.1013 62 63.4851 62 64.9167V71.75M10.75 10.25L72.25 71.75"
              stroke="#8F91A1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p>No Photo ID found.</p>
        </div>
      )}
      {!photoIdData.length && (
        <Button className="add-nationalId-bttn" onClick={() => setOpen(true)}>
          <AddIcon /> <span>Add Photo ID</span>
        </Button>
      )}
    </div>
  );
};

export default NationalIdComponent;
