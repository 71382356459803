import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { userRequest } from '../utils/ApiConfig';
import { handleAutoLogout } from '../utils/callBack';

export const getUserWishlist = createAsyncThunk(
  'wishList/getList',
  async ({ userId }, { dispatch }) => {
    try {
      const wishlist = await userRequest.get(`/user/${userId}/wishlist`);
      return wishlist.data;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching order');
      }
    }
  }
);

export const addTowishlist = createAsyncThunk(
  'wishList/addProduct',
  async ({ userId, product, quantity }, { dispatch }) => {
    try {
      await userRequest.post(`/user/${userId}/wishlist`, {
        product: product,
        quantity: quantity,
      });
      dispatch(getUserWishlist({ userId: userId }));
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching order');
      }
    }
  }
);

export const updateWishlistProduct = createAsyncThunk(
  'wishList/updateproduct',
  async ({ userId, product_id, quantity }, { dispatch }) => {
    try {
      await userRequest.put(`/user/${userId}/wishlist`, {
        product_id: product_id,
        quantity: quantity,
      });
      dispatch(getUserWishlist({ userId: userId }));
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching order');
      }
    }
  }
);

export const deleteWishlistProduct = createAsyncThunk(
  'wishList/deleteproduct',
  async ({ userId, product_id }, { dispatch }) => {
    try {
      await userRequest.put(`/user/${userId}/wishlist/deleteItem`, {
        product_id: product_id,
      });
      dispatch(getUserWishlist({ userId: userId }));
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching order');
      }
    }
  }
);

const wishListSlice = createSlice({
  name: 'wishlist',
  initialState: {
    isAddedToWish: false,
    userModel: false,
    WishItems: [],
    loading: false,
    error: {
      status: false,
      message: null,
    },
  },
  reducers: {
    clearWishlist: (state) => {
      state.cartData = null;
      state.items = [];
    },
    resetWishlistSuccess: (state) => {
      state.isAddedToWish = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserWishlist.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserWishlist.fulfilled, (state, action) => {
        state.loading = false;
        state.error = {
          status: false,
          message: null,
        };
        if (action.payload) {
          state.WishItems = action.payload.products;
        } else {
          state.WishItems = [];
        }
      })
      .addCase(getUserWishlist.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          status: false,
          message: action.error.message || 'Unable fetching wishlist',
        };
      })
      .addCase(addTowishlist.fulfilled, (state, action) => {
        state.loading = false;
        state.error = {
          status: false,
          message: null,
        };
        state.isAddedToWish = true;
      })
      .addCase(addTowishlist.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          status: false,
          message: action.error.message || 'Unable adding product to wishlist',
        };
      })
      .addCase(updateWishlistProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateWishlistProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          status: false,
          message:
            action.error.message || 'Unable updating product in wishlist',
        };
      })
      .addCase(deleteWishlistProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteWishlistProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          status: false,
          message:
            action.error.message || 'Unable deleting product in wishlist',
        };
      });
  },
});
export const { resetWishlistSuccess, clearWishlist } = wishListSlice.actions;
export const wishlistReducer = wishListSlice.reducer;
