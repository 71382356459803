import React, { useEffect, useState } from 'react';
import { publicRequest } from '../../utils/ApiConfig';
import { Button, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import noImg from '../../assets/No_Image_Available.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { addTocart } from '../../redux/CartSlice';

const ReorderProductComponent = ({
  productData,
  setInCartCount,
  setNotExists,
}) => {
  const [isInCart, setIsInCart] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);
  const { items, cartLoading } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  useEffect(() => {
    if (items.length > 0 && productData?.sku) {
      const found = items.find((product) => product.sku === productData.sku);
      if (found) {
        setIsInCart(true);
        if (!isInCart) {
          setInCartCount((prev) => prev + 1);
        }
      } else {
        setIsInCart(false);
      }
    }
  }, [items, productData]);

  const handleAddToCart = (e, productId, qty) => {
    e.stopPropagation();
    dispatch(
      addTocart({
        magentoUserId: user.refrence_id,
        cartItem: {
          qty,
          product_id: productId,
        },
      })
    );
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setIsLoading(true);
        if (productData?.product_id) {
          const product = await publicRequest.get(
            `/products?product_id=${productData?.product_id}`
          );
          setProduct(product.data.products[0]);
        } else {
          const product = await publicRequest.get(
            `/products/sku/${productData?.sku}`
          );
          setProduct(product.data);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setNotExists((prev) => prev + 1);
      }
    };
    if (product === null && (productData?.product_id || productData?.sku)) {
      fetchProduct();
    }
  }, [productData]);
  return (
    <>
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          height={136}
          style={{ margin: '15px 0px' }}
        />
      ) : (
        <div className="reorder-product-box">
          <div className="img-wrapper">
            <img
              src={isLoading ? noImg : product?.images[0]?.url || noImg}
              alt="product-img"
            />
          </div>
          <div className="reorder-product-info">
            <p className="product-name">{product?.name || productData?.name}</p>
            <div className="reorder-productInfo">
              <div className="left">
                <span className="price">
                  ${product?.price_info?.final_price || productData?.price}
                </span>
              </div>
            </div>
            <div className="product-bttn-div">
              <Button
                className="product-detail"
                onClick={() => navigate(`/product/${product.id}`)}
                disabled={!product ? true : false}
              >
                Product details
              </Button>
              {isInCart ? (
                <Button className="product-added-to-cart" disabled>
                  Added to Cart
                </Button>
              ) : (
                <Button
                  className="product-add-to-cart"
                  disabled={!product ? true : false || cartLoading}
                  onClick={(e) =>
                    handleAddToCart(e, product?.id, productData?.qty_ordered)
                  }
                >
                  Reorder
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReorderProductComponent;
