import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import { userReducer } from './userSlice';
import { documentReducer } from './DocumentsSlice';
import { prescriptionReducer } from './PrescriptionSlice';
import { ChatReducer } from './ChatSlice';
import { orderReducer } from './OrderSlice';
import { productReducer } from './productSlice';
import { categoryReducer } from './CategoriesSlice';
import { cartReducer } from './CartSlice';
import { wishlistReducer } from './WishListSlice';
import { cardReducer } from './cardsSlice';
import { notificationReducer } from './NotificationSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [
    'documents',
    'product',
    'card',
    'category',
    'notification',
    'prescription',
  ],
};

const rootReducer = combineReducers({
  user: userReducer,
  documents: documentReducer,
  prescription: prescriptionReducer,
  chat: ChatReducer,
  order: orderReducer,
  product: productReducer,
  category: categoryReducer,
  cart: cartReducer,
  wishlist: wishlistReducer,
  card: cardReducer,
  notification: notificationReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
