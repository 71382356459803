import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './PrescriptionTransfer.scss';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import {
  TransferPresForm,
  // TransferPresThree,
  TransferPresTwo,
} from './Prescriptionforms';
import { MuiTelInput } from 'mui-tel-input';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import Textarea from '@mui/joy/Textarea';
// import { TimePicker } from '@mui/x-date-pickers';
// import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import ThankYouPresc from './ThankYouPres';
import { Textarea } from '@mui/joy';
import { useDispatch, useSelector } from 'react-redux';
import { submitNewPrescription } from '../../redux/PrescriptionSlice';

const PrescriptionTransfer = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [data, setdata] = useState({ name: 'N/A' });
  const user = useSelector((state) => state.user.currentUser);
  const { submittedId, isLoading } = useSelector((state) => state.prescription);
  const [isTransferEntireFile, setIsTransferEntoreFile] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setdata({ ...data, [name]: value });
  };

  const handleSubmitPres = async () => {
    const { telephone, ...dataWithoutTel } = data;
    dispatch(
      submitNewPrescription({
        userId: user._id,
        prescData: {
          patient_fname: data?.firstname || user?.firstname,
          patient_lname: data?.lastname || user.lastname,
          phone: data?.telephone || user?.telephone,
          type: 'Transfer',
          isTransferEntireFile,
          ...dataWithoutTel,
        },
        message: data?.message || '',
      })
    );
  };

  useEffect(() => {
    if (submittedId.length > 0) {
      setSuccess(true);
    }
  }, [submittedId]);

  useEffect(() => {
    const validate = () => {
      if (
        // data?.name &&
        (data?.telephone || user?.telephone) &&
        data?.curr_pharmacy_phone &&
        data.curr_pharmacy_name &&
        (isTransferEntireFile ||
          (data?.medication_names && data?.prescription_numbers))
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };
    if (data) {
      validate();
    }
  }, [data, isTransferEntireFile]);
  return (
    <>
      {!success ? (
        <div className="prescription-component">
          <div className="prescription-form">
            <Button className="back-bttn" onClick={() => navigate(-1)}>
              <ArrowBackIcon />
              Back
            </Button>
            <div className="top-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
              >
                <path
                  d="M18.084 3.875V9.04167C18.084 9.38424 18.2201 9.71278 18.4623 9.95501C18.7045 10.1972 19.0331 10.3333 19.3757 10.3333H24.5423M18.084 3.875H9.04232C8.35717 3.875 7.70009 4.14717 7.21562 4.63164C6.73116 5.11611 6.45898 5.77319 6.45898 6.45833V16.7917M18.084 3.875L24.5423 10.3333M24.5423 10.3333V24.5417C24.5423 25.2268 24.2701 25.8839 23.7857 26.3684C23.3012 26.8528 22.6441 27.125 21.959 27.125H14.8548M2.58398 24.5417H11.6257M11.6257 24.5417L7.75065 20.6667M11.6257 24.5417L7.75065 28.4167"
                  stroke="#1E1E1E"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.093 18.4037L12.0622 16.4352H12.0545V18.4037H10.334V12.9167H12.8915C13.3358 12.9167 13.7156 12.9967 14.0307 13.1569C14.3459 13.3119 14.581 13.5263 14.736 13.8002C14.8962 14.0688 14.9762 14.3737 14.9762 14.7147C14.9762 15.0815 14.8729 15.407 14.6662 15.6912C14.4647 15.9753 14.1728 16.1794 13.7905 16.3034L14.984 18.4037H13.093ZM12.0545 15.2882H12.7365C12.9018 15.2882 13.0258 15.252 13.1085 15.1797C13.1912 15.1022 13.2325 14.9833 13.2325 14.8232C13.2325 14.6785 13.1886 14.5648 13.1007 14.4822C13.0181 14.3995 12.8967 14.3582 12.7365 14.3582H12.0545V15.2882Z"
                  fill="#1E1E1E"
                />
                <path
                  d="M19.0211 18.4037L18.0136 16.9777L17.1766 18.4037H15.2159L17.0216 15.5749L15.1384 12.9167H17.1766L18.1454 14.2884L18.9514 12.9167H20.9121L19.1374 15.6912L21.0594 18.4037H19.0211Z"
                  fill="#1E1E1E"
                />
              </svg>
              <p>Transfer Your Prescription</p>
            </div>
            <span className="slogan">
              Transfer your prescription to our pharmacy for convenience and
              quality care. Fill out the form below, and we'll handle the rest.
            </span>
            <div className="form">
              <span className="form-title">Personal Information:</span>
              <div className="input-container">
                {TransferPresForm.map((input) =>
                  input.type === 'text'
                    ? input.name !== 'name' && (
                        <TextField
                          onChange={handleChange}
                          value={data[input.name] || user[input.name]}
                          name={input.name}
                          label={input.label}
                          disabled={input.name === 'email'}
                          className={`input ${input.size}`}
                        />
                      )
                    : !input.inVisible && (
                        <MuiTelInput
                          value={data?.telephone || user?.telephone || ''}
                          label={input.label}
                          className={`input ${input.size}`}
                          InputLabelProps={{
                            sx: {
                              color: '#616161',
                              fontWeight: 400,
                              fontSize: '16px',
                            },
                          }}
                          InputProps={{
                            sx: {
                              '&:hover fieldset': {
                                border: '1px solid #122146 !important',
                              },
                              '&:focus-within fieldset, &:focus-visible fieldset':
                                {
                                  border: '1px solid #122146 !important',
                                  boxShadow:
                                    '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(32, 66, 137, 0.10)',
                                },
                            },
                          }}
                          defaultCountry="CA"
                          onChange={(value) =>
                            setdata({ ...data, telephone: value })
                          }
                        />
                      )
                )}
              </div>
            </div>
            <div className="form">
              <span className="form-title">Prescription Details:</span>
              <div className="input-container">
                {TransferPresTwo.map((input) =>
                  input.type === 'text' ? (
                    <TextField
                      onChange={handleChange}
                      name={input.name}
                      value={data[input.name] || ''}
                      label={input.label}
                      className={`input ${input.size}`}
                      disabled={
                        (input.name === 'prescription_numbers' ||
                          input.name === 'medication_names') &&
                        isTransferEntireFile
                      }
                    />
                  ) : input.type === 'phone' ? (
                    <MuiTelInput
                      label={input.label}
                      className={`input ${input.size}`}
                      InputLabelProps={{
                        sx: {
                          color: '#616161',
                          fontWeight: 400,
                          fontSize: '16px',
                        },
                      }}
                      InputProps={{
                        sx: {
                          '&:hover fieldset': {
                            border: '1px solid #122146 !important',
                          },
                          '&:focus-within fieldset, &:focus-visible fieldset': {
                            border: '1px solid #122146 !important',
                            boxShadow:
                              '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(32, 66, 137, 0.10)',
                          },
                        },
                      }}
                      defaultCountry="CA"
                      value={data?.curr_pharmacy_phone || ''}
                      onChange={(value) =>
                        setdata({ ...data, curr_pharmacy_phone: value })
                      }
                    />
                  ) : (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isTransferEntireFile}
                          onChange={(e) =>
                            setIsTransferEntoreFile(e.target.checked)
                          }
                          sx={{
                            color: 'green',
                            '&.Mui-checked': {
                              color: 'green',
                            },
                          }}
                        />
                      }
                      sx={{
                        color: 'rgba(0, 0, 0, 0.813)',
                      }}
                      label="Transfer your entire file"
                    />
                  )
                )}
                <Textarea
                  onChange={handleChange}
                  name="message"
                  style={{
                    backgroundColor: '#ffff',
                    width: '100%',
                  }}
                  sx={{
                    '--Textarea-focusedHighlight': '#4C9B58 !important',
                    '&:focus-within': {
                      borderColor: '#4C9B58',
                    },
                  }}
                  placeholder="Additional Information or Requests (Include any specific requirements or questions you may have)"
                  minRows={4}
                />
              </div>
            </div>
            {/* <div className="form">
              <span className="form-title">Preferred Appointment Date:</span>
              <div className="input-container">
                {TransferPresThree.map((input) =>
                  input.type === 'text' ? (
                    <TextField
                      label={input.label}
                      className={`input ${input.size}`}
                    />
                  ) : input.type === 'date' ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={input.label}
                        className={`input ${input.size}`}
                      />
                    </LocalizationProvider>
                  ) : input.type === 'time' ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label={input.label}
                        className={`input ${input.size}`}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                      />
                    </LocalizationProvider>
                  ) : (
                    <Textarea
                      style={{ backgroundColor: '#ffff' }}
                      variant="outlined"
                      color="neutral"
                      className={`input ${input.size}`}
                      placeholder="Additional Information or Requests (Include any specific requirements or questions you may have)"
                      minRows={4}
                    />
                  )
                )}
              </div>
            </div> */}
          </div>
          <div className="submit-div">
            <Button className="cancel-bttn" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              className="submitForm"
              onClick={handleSubmitPres}
              disabled={disabled || isLoading}
            >
              Submit Prescription Transfer
            </Button>
          </div>
        </div>
      ) : (
        <ThankYouPresc
          prescId={submittedId}
          text="Thank you for choosing City Pharmacy, your prescription is in process, and we will send you a notification for payment details of prescriptions"
        />
      )}
    </>
  );
};

export default PrescriptionTransfer;
