import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { userRequest } from '../utils/ApiConfig';
import { handleAutoLogout } from '../utils/callBack';

export const getAllDocuments = createAsyncThunk(
  'documnets/AllDocuments',
  async ({ userId }, { dispatch }) => {
    try {
      const response = await userRequest.get(
        `/customer/${userId}/documents`,
        {}
      );
      return response.data;
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed adding new address');
      }
    }
  }
);

export const addNewDocument = createAsyncThunk(
  'documnets/addNewDocunment',
  async ({ userId, documentData }, { dispatch }) => {
    try {
      await userRequest.post(`/customer/${userId}/document`, documentData);
      dispatch(getAllDocuments({ userId }));
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed adding new document');
      }
    }
  }
);

export const deleteDocument = createAsyncThunk(
  'documnets/deleteDocument',
  async ({ userId, documentId }, { dispatch }) => {
    try {
      await userRequest.delete(
        `/customer/${userId}/document/${documentId}`,
        {}
      );
      dispatch(getAllDocuments({ userId }));
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error(`Failed deleteing document ${documentId}`);
      }
    }
  }
);

export const updateDocument = createAsyncThunk(
  'documnets/updateDocumentbyId',
  async ({ userId, documentData }, { dispatch }) => {
    try {
      await userRequest.put(
        `/customer/${userId}/document/${documentData._id}`,
        documentData
      );
      dispatch(getAllDocuments({ userId }));
    } catch (err) {
      if (err.response.data) {
        if (err.response.status === 401) {
          handleAutoLogout(dispatch);
        }
        throw new Error(err.response.data);
      } else {
        throw new Error(`Failed updating document ${documentData._id}`);
      }
    }
  }
);

const DocumentSlice = createSlice({
  name: 'documents',
  initialState: {
    list: [],
    isLoading: false,
    error: {
      status: false,
      message: null,
    },
    isUpdated: false,
  },
  reducers: {
    reset: (state) => {
      state.isUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDocuments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllDocuments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = action.payload;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getAllDocuments.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Failed Loading documents',
        };
      })
      .addCase(addNewDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewDocument.fulfilled, (state) => {
        state.isLoading = false;
        state.isUpdated = true;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(addNewDocument.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Failed adding document',
        };
      })
      .addCase(deleteDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDocument.fulfilled, (state) => {
        state.isLoading = false;
        state.isUpdated = true;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Failed deleting document',
        };
      })
      .addCase(updateDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDocument.fulfilled, (state) => {
        state.isLoading = false;
        state.isUpdated = true;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(updateDocument.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Failed updating document',
        };
      });
  },
});
export const { reset } = DocumentSlice.actions;
export const documentReducer = DocumentSlice.reducer;
