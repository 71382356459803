import React, { useEffect, useState } from 'react';
import './Orders.scss';
import DashboardNavigation from '../../../components/DashboardComponents/DashboardNavigation';
import OrderStatus from '../../../components/DashboardComponents/OrderStatus';
import DashboardTable from '../../../components/DashboardComponents/DashboardTable';
import SearchIcon from '@mui/icons-material/Search';
import { Alert, CircularProgress, MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOrdersByUserId } from '../../../redux/OrderSlice';

const Orders = () => {
  const [filterTab, setFilterTab] = useState('All');
  const [sorted, setSorted] = useState('This month');
  const dispatch = useDispatch();
  const { isLoading, ordersList, count, error } = useSelector(
    (state) => state.order
  );
  const [query, setQuery] = useState('');
  const user = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    dispatch(
      getAllOrdersByUserId({
        magentoUserId: user.refrence_id,
      })
    );
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    setSorted(value);
  };

  const getCount = (status) => {
    const orders = ordersList.filter((order) => order.status === status);
    return orders.length;
  };
  const filterOrders = (list) => {
    let newList = list.filter((order) => order.status !== 'pending_payment');
    if (filterTab !== 'All') {
      const filtered = list.filter((order) => order.status === filterTab);
      newList = filtered;
    }
    if (query !== '') {
      const filtered = newList.filter((order) =>
        order.increment_id.includes(query)
      );
      newList = filtered;
    }
    return newList;
  };

  return (
    <div className="orders">
      <div className="main-conatiner">
        <div className="wrapper">
          <DashboardNavigation />
          {!isLoading && !error.status ? (
            <div className="right-orders-div">
              <OrderStatus data={ordersList} />
              <div className="order-history">
                <div className="title-div">
                  <div className="left">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <path
                        d="M20 27.5H22.5C23.125 27.5 23.75 27.25 24.25 26.75C24.75 26.25 25 25.625 25 25V9.375L18.125 2.5H7.5C6.875 2.5 6.25 2.75 5.75 3.25C5.25 3.75 5 4.375 5 5V8.75"
                        stroke="#1E1E1E"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17.5 2.5V10H25"
                        stroke="#1E1E1E"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 27.5C14.1421 27.5 17.5 24.1421 17.5 20C17.5 15.8579 14.1421 12.5 10 12.5C5.85786 12.5 2.5 15.8579 2.5 20C2.5 24.1421 5.85786 27.5 10 27.5Z"
                        stroke="#1E1E1E"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.875 21.875L10 20.3125V17.5"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p>Order History</p>
                  </div>
                  <div className="search-div">
                    <input
                      placeholder="Search for order by id"
                      onChange={(e) => setQuery(e.target.value)}
                    />
                    <SearchIcon />
                  </div>
                </div>
                <div className="sorting-div">
                  <div className="sort-tabs">
                    <span
                      className={`tab ${filterTab === 'All' ? 'active' : ''}`}
                      onClick={() => setFilterTab('All')}
                    >
                      All ({count})
                    </span>
                    <span
                      className={`tab ${
                        filterTab === 'DELIVERED' ? 'active' : ''
                      }`}
                      onClick={() => setFilterTab('DELIVERED')}
                    >
                      Delivered ({getCount('DELIVERED')})
                    </span>
                    <span
                      className={`tab ${
                        filterTab === 'canceled' ? 'active' : ''
                      }`}
                      onClick={() => setFilterTab('canceled')}
                    >
                      Canceled ({getCount('canceled')})
                    </span>
                  </div>
                  <Select
                    value={sorted}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    className="select"
                  >
                    <MenuItem value={'All'}>All</MenuItem>
                    <MenuItem value={'This month'}>This month</MenuItem>
                    <MenuItem value={'This year'}>This year</MenuItem>
                  </Select>
                </div>
                <DashboardTable
                  data={filterOrders(ordersList)}
                  perPage={10}
                  type="OrderDetails"
                />
              </div>
            </div>
          ) : (
            <div
              className="right-orders-div"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {error.status ? (
                <Alert severity="error">
                  {error?.message || 'Error Loading Orders'}
                </Alert>
              ) : (
                <>
                  <CircularProgress />
                  <span>Loading Orders...</span>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Orders;
