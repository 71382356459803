import React, { useState } from 'react';
import './WriteNotePopup.scss';
import { Avatar, Button, IconButton, Modal } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Textarea } from '@mui/joy';

const WriteNotePopup = ({ handleClose, open }) => {
  const [isSent, setIsSent] = useState(false);

  const handleSendNote = () => {
    setIsSent(true);
  };

  const handleBack = () => {
    setIsSent(false);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleBack}>
      <div className={`writeNote-popup ${isSent ? 'success' : ''}`}>
        <div className="top-div">
          <div className="left-div">
            <p>Write Your Note To</p>
            <div className="doctor-badge">
              <Avatar />
              <span>Dr. Nora Connor</span>
            </div>
          </div>
          <IconButton
            style={{ width: '40px', height: '40px', fontSize: '40px' }}
            onClick={handleBack}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </div>
        <Textarea
          className="textarea"
          style={{
            backgroundColor: '#ffff',
            width: '100%',
          }}
          sx={{
            '--Textarea-focusedHighlight': '#4C9B58 !important',
            '&:focus-within': {
              borderColor: '#4C9B58',
            },
          }}
          minRows={4}
        />
        <div className="buttons-div">
          <Button className="send-chatMessage" onClick={handleSendNote}>
            Send Note through chat
          </Button>

          <Button className="cancel-bttn" onClick={handleClose}>
            Cancel
          </Button>
        </div>
        <div className="success-div">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="79"
            height="78"
            viewBox="0 0 79 78"
            fill="none"
          >
            <path
              d="M39.75 7.3125C22.2457 7.3125 8.0625 21.4957 8.0625 39C8.0625 56.5043 22.2457 70.6875 39.75 70.6875C57.2543 70.6875 71.4375 56.5043 71.4375 39C71.4375 21.4957 57.2543 7.3125 39.75 7.3125ZM34.8598 50.2277C34.4941 50.5934 33.9762 50.898 33.5191 50.898C33.0621 50.898 32.5441 50.5781 32.1633 50.2125L23.632 41.6813L26.3438 38.9695L33.5344 46.1602L52.5469 27.0105L55.2129 29.768L34.8598 50.2277Z"
              fill="#219D50"
            />
          </svg>
          <p>Your Note has been sent to the Pharmacy</p>
          <Button className="back-bttn" onClick={handleBack}>
            Back
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default WriteNotePopup;
