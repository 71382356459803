import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Divider } from '@mui/material';
import './PrescriptionOverview.scss';
import PayPrescriptionPopup from './PayPrescriptionpopup';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllPrescriptions,
  getPrescriptionById,
  resetPresc,
} from '../../redux/PrescriptionSlice';
import { formatDate } from '../../utils/callBack';
const PrescriptionOverview = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.user.currentUser);
  const { list } = useSelector((state) => state.prescription);
  const [readyPresc, setReadyPresc] = useState([]);
  const [refillPresc, setRefillPresc] = useState([]);

  const dispatch = useDispatch();

  // feches prescriptions
  useEffect(() => {
    if (user) {
      dispatch(resetPresc());
      dispatch(
        getAllPrescriptions({
          userId: user._id,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (list) {
      // filters prescrition that is availble for payment
      setReadyPresc(list.filter((pres) => pres.status === 'Pending Payment'));

      // filters prescrition that is refill_data has passed due or due today
      const today = new Date();
      const filteredList = list.filter((pres) => {
        const refillDate = new Date(pres?.refill_date);
        return refillDate <= today && !pres?.isRefilled;
      });

      setRefillPresc(filteredList);
    }
  }, [list]);

  // const handleRefillPresc = (pres) => {
  //   dispatch(
  //     getPrescriptionById({
  //       userId: user._id,
  //       prescriptionId: pres._id,
  //     })
  //   ).then(() => {
  //     navigate('/dashboard/prescription?prescription=refill');
  //   });
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="prescription-info-comp">
      <PayPrescriptionPopup handleClose={handleClose} open={open} />
      <div className="box">
        {/* <p className="presc-box-title">Accepted Prescriptions</p> */}
        <div className="box-bottom">
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <div className="circle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14 2V8H20"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15L5 17L9 13"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="number-info-cont">
              <p className="presc-count">Ready Prescriptions</p>
            </div>
          </div>

          <Button
            className="view-all"
            onClick={() => navigate('/dashboard/prescription')}
          >
            View All Prescriptions{' '}
          </Button>
        </div>
        <div className="scroll-div">
          {readyPresc.length ? (
            readyPresc.map((presc, i) => (
              <div className={`presc-components ${i % 2 == 0 ? 'filled' : ''}`}>
                <div className="presc-details">
                  <span className="name">{presc.name}</span>
                  <div className="desc">
                    <span>Prescriber</span>
                    <Divider orientation="vertical" flexItem />
                    <span>{formatDate(presc.createdAt)}</span>
                  </div>
                </div>
                <Button
                  className="pay-bttn"
                  onClick={() =>
                    navigate(`/dashboard/prescription/order/${presc._id}`)
                  }
                >
                  Pay Now
                </Button>
              </div>
            ))
          ) : (
            <div className="empty-div">
              <IoDocumentTextOutline className="icon" />
              <span>No past prescriptions found.</span>
            </div>
          )}
        </div>
      </div>
      {/* <div className="box">
        <p className="presc-box-title">Refill Prescriptions</p>
        <div className="box-bottom">
          <div className="circle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M4.5 22H18.5C19.0304 22 19.5391 21.7893 19.9142 21.4142C20.2893 21.0391 20.5 20.5304 20.5 20V7.5L15 2H6.5C5.96957 2 5.46086 2.21071 5.08579 2.58579C4.71071 2.96086 4.5 3.46957 4.5 4V11"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.5 2V8H20.5"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.5 18L13.5 15L10.5 12"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.5 18V17C4.5 16.4696 4.71071 15.9609 5.08579 15.5858C5.46086 15.2107 5.96957 15 6.5 15H12.5"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="number-info-cont">
            <p className="presc-count">Available Refill Prescriptions</p>
          </div>
        </div>
        <div className="scroll-div">
          {refillPresc.length > 0 ? (
            refillPresc.map((presc, i) => (
              <div className={`presc-components ${i % 2 == 0 ? 'filled' : ''}`}>
                <div className="presc-details">
                  <span className="name">{presc.name}</span>
                  <div className="desc">
                    <span>Prescriber</span>
                    <Divider orientation="vertical" flexItem />
                    <span>{formatDate(presc.createdAt)}</span>
                  </div>
                </div>
                <Button
                  className="refill-bttn"
                  onClick={() => handleRefillPresc(presc)}
                >
                  Refill now
                </Button>
              </div>
            ))
          ) : (
            <div className="empty-div">
              <IoDocumentTextOutline className="icon" />
              <span>No past prescriptions found.</span>
            </div>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default PrescriptionOverview;
