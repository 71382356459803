export const MagCountries = [
  // {
  //   id: 'AD',
  //   two_letter_abbreviation: 'AD',
  //   three_letter_abbreviation: 'AND',
  //   full_name_locale: 'Andorra',
  //   full_name_english: 'Andorra',
  // },
  // {
  //   id: 'AE',
  //   two_letter_abbreviation: 'AE',
  //   three_letter_abbreviation: 'ARE',
  //   full_name_locale: 'United Arab Emirates',
  //   full_name_english: 'United Arab Emirates',
  // },
  // {
  //   id: 'AF',
  //   two_letter_abbreviation: 'AF',
  //   three_letter_abbreviation: 'AFG',
  //   full_name_locale: 'Afghanistan',
  //   full_name_english: 'Afghanistan',
  // },
  // {
  //   id: 'AG',
  //   two_letter_abbreviation: 'AG',
  //   three_letter_abbreviation: 'ATG',
  //   full_name_locale: 'Antigua & Barbuda',
  //   full_name_english: 'Antigua & Barbuda',
  // },
  // {
  //   id: 'AI',
  //   two_letter_abbreviation: 'AI',
  //   three_letter_abbreviation: 'AIA',
  //   full_name_locale: 'Anguilla',
  //   full_name_english: 'Anguilla',
  // },
  // {
  //   id: 'AL',
  //   two_letter_abbreviation: 'AL',
  //   three_letter_abbreviation: 'ALB',
  //   full_name_locale: 'Albania',
  //   full_name_english: 'Albania',
  //   available_regions: [
  //     {
  //       id: '512',
  //       code: 'AL-01',
  //       name: 'Berat',
  //     },
  //     {
  //       id: '513',
  //       code: 'AL-09',
  //       name: 'Dibër',
  //     },
  //     {
  //       id: '514',
  //       code: 'AL-02',
  //       name: 'Durrës',
  //     },
  //     {
  //       id: '515',
  //       code: 'AL-03',
  //       name: 'Elbasan',
  //     },
  //     {
  //       id: '516',
  //       code: 'AL-04',
  //       name: 'Fier',
  //     },
  //     {
  //       id: '517',
  //       code: 'AL-05',
  //       name: 'Gjirokastër',
  //     },
  //     {
  //       id: '518',
  //       code: 'AL-06',
  //       name: 'Korçë',
  //     },
  //     {
  //       id: '519',
  //       code: 'AL-07',
  //       name: 'Kukës',
  //     },
  //     {
  //       id: '520',
  //       code: 'AL-08',
  //       name: 'Lezhë',
  //     },
  //     {
  //       id: '521',
  //       code: 'AL-10',
  //       name: 'Shkodër',
  //     },
  //     {
  //       id: '522',
  //       code: 'AL-11',
  //       name: 'Tiranë',
  //     },
  //     {
  //       id: '523',
  //       code: 'AL-12',
  //       name: 'Vlorë',
  //     },
  //   ],
  // },
  // {
  //   id: 'AM',
  //   two_letter_abbreviation: 'AM',
  //   three_letter_abbreviation: 'ARM',
  //   full_name_locale: 'Armenia',
  //   full_name_english: 'Armenia',
  // },
  // {
  //   id: 'AN',
  //   two_letter_abbreviation: 'AN',
  //   three_letter_abbreviation: 'ANT',
  //   full_name_locale: null,
  //   full_name_english: null,
  // },
  // {
  //   id: 'AO',
  //   two_letter_abbreviation: 'AO',
  //   three_letter_abbreviation: 'AGO',
  //   full_name_locale: 'Angola',
  //   full_name_english: 'Angola',
  // },
  // {
  //   id: 'AQ',
  //   two_letter_abbreviation: 'AQ',
  //   three_letter_abbreviation: 'ATA',
  //   full_name_locale: 'Antarctica',
  //   full_name_english: 'Antarctica',
  // },
  // {
  //   id: 'AR',
  //   two_letter_abbreviation: 'AR',
  //   three_letter_abbreviation: 'ARG',
  //   full_name_locale: 'Argentina',
  //   full_name_english: 'Argentina',
  //   available_regions: [
  //     {
  //       id: '525',
  //       code: 'AR-B',
  //       name: 'Buenos Aires',
  //     },
  //     {
  //       id: '526',
  //       code: 'AR-K',
  //       name: 'Catamarca',
  //     },
  //     {
  //       id: '527',
  //       code: 'AR-H',
  //       name: 'Chaco',
  //     },
  //     {
  //       id: '528',
  //       code: 'AR-U',
  //       name: 'Chubut',
  //     },
  //     {
  //       id: '524',
  //       code: 'AR-C',
  //       name: 'Ciudad Autónoma de Buenos Aires',
  //     },
  //     {
  //       id: '529',
  //       code: 'AR-X',
  //       name: 'Córdoba',
  //     },
  //     {
  //       id: '530',
  //       code: 'AR-W',
  //       name: 'Corrientes',
  //     },
  //     {
  //       id: '531',
  //       code: 'AR-E',
  //       name: 'Entre Ríos',
  //     },
  //     {
  //       id: '532',
  //       code: 'AR-P',
  //       name: 'Formosa',
  //     },
  //     {
  //       id: '533',
  //       code: 'AR-Y',
  //       name: 'Jujuy',
  //     },
  //     {
  //       id: '534',
  //       code: 'AR-L',
  //       name: 'La Pampa',
  //     },
  //     {
  //       id: '535',
  //       code: 'AR-F',
  //       name: 'La Rioja',
  //     },
  //     {
  //       id: '536',
  //       code: 'AR-M',
  //       name: 'Mendoza',
  //     },
  //     {
  //       id: '537',
  //       code: 'AR-N',
  //       name: 'Misiones',
  //     },
  //     {
  //       id: '538',
  //       code: 'AR-Q',
  //       name: 'Neuquén',
  //     },
  //     {
  //       id: '539',
  //       code: 'AR-R',
  //       name: 'Río Negro',
  //     },
  //     {
  //       id: '540',
  //       code: 'AR-A',
  //       name: 'Salta',
  //     },
  //     {
  //       id: '541',
  //       code: 'AR-J',
  //       name: 'San Juan',
  //     },
  //     {
  //       id: '542',
  //       code: 'AR-D',
  //       name: 'San Luis',
  //     },
  //     {
  //       id: '543',
  //       code: 'AR-Z',
  //       name: 'Santa Cruz',
  //     },
  //     {
  //       id: '544',
  //       code: 'AR-S',
  //       name: 'Santa Fe',
  //     },
  //     {
  //       id: '545',
  //       code: 'AR-G',
  //       name: 'Santiago del Estero',
  //     },
  //     {
  //       id: '546',
  //       code: 'AR-V',
  //       name: 'Tierra del Fuego',
  //     },
  //     {
  //       id: '547',
  //       code: 'AR-T',
  //       name: 'Tucumán',
  //     },
  //   ],
  // },
  // {
  //   id: 'AS',
  //   two_letter_abbreviation: 'AS',
  //   three_letter_abbreviation: 'ASM',
  //   full_name_locale: 'American Samoa',
  //   full_name_english: 'American Samoa',
  // },
  // {
  //   id: 'AT',
  //   two_letter_abbreviation: 'AT',
  //   three_letter_abbreviation: 'AUT',
  //   full_name_locale: 'Austria',
  //   full_name_english: 'Austria',
  //   available_regions: [
  //     {
  //       id: '102',
  //       code: 'BL',
  //       name: 'Burgenland',
  //     },
  //     {
  //       id: '99',
  //       code: 'KN',
  //       name: 'Kärnten',
  //     },
  //     {
  //       id: '96',
  //       code: 'NO',
  //       name: 'Niederösterreich',
  //     },
  //     {
  //       id: '97',
  //       code: 'OO',
  //       name: 'Oberösterreich',
  //     },
  //     {
  //       id: '98',
  //       code: 'SB',
  //       name: 'Salzburg',
  //     },
  //     {
  //       id: '100',
  //       code: 'ST',
  //       name: 'Steiermark',
  //     },
  //     {
  //       id: '101',
  //       code: 'TI',
  //       name: 'Tirol',
  //     },
  //     {
  //       id: '103',
  //       code: 'VB',
  //       name: 'Vorarlberg',
  //     },
  //     {
  //       id: '95',
  //       code: 'WI',
  //       name: 'Wien',
  //     },
  //   ],
  // },
  // {
  //   id: 'AU',
  //   two_letter_abbreviation: 'AU',
  //   three_letter_abbreviation: 'AUS',
  //   full_name_locale: 'Australia',
  //   full_name_english: 'Australia',
  //   available_regions: [
  //     {
  //       id: '605',
  //       code: 'ACT',
  //       name: 'Australian Capital Territory',
  //     },
  //     {
  //       id: '606',
  //       code: 'NSW',
  //       name: 'New South Wales',
  //     },
  //     {
  //       id: '612',
  //       code: 'NT',
  //       name: 'Northern Territory',
  //     },
  //     {
  //       id: '608',
  //       code: 'QLD',
  //       name: 'Queensland',
  //     },
  //     {
  //       id: '609',
  //       code: 'SA',
  //       name: 'South Australia',
  //     },
  //     {
  //       id: '610',
  //       code: 'TAS',
  //       name: 'Tasmania',
  //     },
  //     {
  //       id: '607',
  //       code: 'VIC',
  //       name: 'Victoria',
  //     },
  //     {
  //       id: '611',
  //       code: 'WA',
  //       name: 'Western Australia',
  //     },
  //   ],
  // },
  // {
  //   id: 'AW',
  //   two_letter_abbreviation: 'AW',
  //   three_letter_abbreviation: 'ABW',
  //   full_name_locale: 'Aruba',
  //   full_name_english: 'Aruba',
  // },
  // {
  //   id: 'AX',
  //   two_letter_abbreviation: 'AX',
  //   three_letter_abbreviation: 'ALA',
  //   full_name_locale: 'Åland Islands',
  //   full_name_english: 'Åland Islands',
  // },
  // {
  //   id: 'AZ',
  //   two_letter_abbreviation: 'AZ',
  //   three_letter_abbreviation: 'AZE',
  //   full_name_locale: 'Azerbaijan',
  //   full_name_english: 'Azerbaijan',
  // },
  // {
  //   id: 'BA',
  //   two_letter_abbreviation: 'BA',
  //   three_letter_abbreviation: 'BIH',
  //   full_name_locale: 'Bosnia & Herzegovina',
  //   full_name_english: 'Bosnia & Herzegovina',
  // },
  // {
  //   id: 'BB',
  //   two_letter_abbreviation: 'BB',
  //   three_letter_abbreviation: 'BRB',
  //   full_name_locale: 'Barbados',
  //   full_name_english: 'Barbados',
  // },
  // {
  //   id: 'BD',
  //   two_letter_abbreviation: 'BD',
  //   three_letter_abbreviation: 'BGD',
  //   full_name_locale: 'Bangladesh',
  //   full_name_english: 'Bangladesh',
  // },
  // {
  //   id: 'BE',
  //   two_letter_abbreviation: 'BE',
  //   three_letter_abbreviation: 'BEL',
  //   full_name_locale: 'Belgium',
  //   full_name_english: 'Belgium',
  //   available_regions: [
  //     {
  //       id: '620',
  //       code: 'VAN',
  //       name: 'Antwerpen',
  //     },
  //     {
  //       id: '621',
  //       code: 'WBR',
  //       name: 'Brabant wallon',
  //     },
  //     {
  //       id: '622',
  //       code: 'BRU',
  //       name: 'Brussels-Capital Region',
  //     },
  //     {
  //       id: '623',
  //       code: 'WHT',
  //       name: 'Hainaut',
  //     },
  //     {
  //       id: '625',
  //       code: 'WLG',
  //       name: 'Liège',
  //     },
  //     {
  //       id: '624',
  //       code: 'VLI',
  //       name: 'Limburg',
  //     },
  //     {
  //       id: '626',
  //       code: 'WLX',
  //       name: 'Luxembourg',
  //     },
  //     {
  //       id: '627',
  //       code: 'WNA',
  //       name: 'Namur',
  //     },
  //     {
  //       id: '628',
  //       code: 'VOV',
  //       name: 'Oost-Vlaanderen',
  //     },
  //     {
  //       id: '629',
  //       code: 'VBR',
  //       name: 'Vlaams-Brabant',
  //     },
  //     {
  //       id: '630',
  //       code: 'VWV',
  //       name: 'West-Vlaanderen',
  //     },
  //   ],
  // },
  // {
  //   id: 'BF',
  //   two_letter_abbreviation: 'BF',
  //   three_letter_abbreviation: 'BFA',
  //   full_name_locale: 'Burkina Faso',
  //   full_name_english: 'Burkina Faso',
  // },
  // {
  //   id: 'BG',
  //   two_letter_abbreviation: 'BG',
  //   three_letter_abbreviation: 'BGR',
  //   full_name_locale: 'Bulgaria',
  //   full_name_english: 'Bulgaria',
  //   available_regions: [
  //     {
  //       id: '640',
  //       code: 'BG-01',
  //       name: 'Blagoevgrad',
  //     },
  //     {
  //       id: '641',
  //       code: 'BG-02',
  //       name: 'Burgas',
  //     },
  //     {
  //       id: '647',
  //       code: 'BG-08',
  //       name: 'Dobrich',
  //     },
  //     {
  //       id: '646',
  //       code: 'BG-07',
  //       name: 'Gabrovo',
  //     },
  //     {
  //       id: '665',
  //       code: 'BG-26',
  //       name: 'Haskovo',
  //     },
  //     {
  //       id: '648',
  //       code: 'BG-09',
  //       name: 'Kardzhali',
  //     },
  //     {
  //       id: '649',
  //       code: 'BG-10',
  //       name: 'Kyustendil',
  //     },
  //     {
  //       id: '650',
  //       code: 'BG-11',
  //       name: 'Lovech',
  //     },
  //     {
  //       id: '651',
  //       code: 'BG-12',
  //       name: 'Montana',
  //     },
  //     {
  //       id: '652',
  //       code: 'BG-13',
  //       name: 'Pazardzhik',
  //     },
  //     {
  //       id: '653',
  //       code: 'BG-14',
  //       name: 'Pernik',
  //     },
  //     {
  //       id: '654',
  //       code: 'BG-15',
  //       name: 'Pleven',
  //     },
  //     {
  //       id: '655',
  //       code: 'BG-16',
  //       name: 'Plovdiv',
  //     },
  //     {
  //       id: '656',
  //       code: 'BG-17',
  //       name: 'Razgrad',
  //     },
  //     {
  //       id: '657',
  //       code: 'BG-18',
  //       name: 'Ruse',
  //     },
  //     {
  //       id: '666',
  //       code: 'BG-27',
  //       name: 'Shumen',
  //     },
  //     {
  //       id: '658',
  //       code: 'BG-19',
  //       name: 'Silistra',
  //     },
  //     {
  //       id: '659',
  //       code: 'BG-20',
  //       name: 'Sliven',
  //     },
  //     {
  //       id: '660',
  //       code: 'BG-21',
  //       name: 'Smolyan',
  //     },
  //     {
  //       id: '661',
  //       code: 'BG-22',
  //       name: 'Sofia City',
  //     },
  //     {
  //       id: '662',
  //       code: 'BG-23',
  //       name: 'Sofia Province',
  //     },
  //     {
  //       id: '663',
  //       code: 'BG-24',
  //       name: 'Stara Zagora',
  //     },
  //     {
  //       id: '664',
  //       code: 'BG-25',
  //       name: 'Targovishte',
  //     },
  //     {
  //       id: '642',
  //       code: 'BG-03',
  //       name: 'Varna',
  //     },
  //     {
  //       id: '643',
  //       code: 'BG-04',
  //       name: 'Veliko Tarnovo',
  //     },
  //     {
  //       id: '644',
  //       code: 'BG-05',
  //       name: 'Vidin',
  //     },
  //     {
  //       id: '645',
  //       code: 'BG-06',
  //       name: 'Vratsa',
  //     },
  //     {
  //       id: '667',
  //       code: 'BG-28',
  //       name: 'Yambol',
  //     },
  //   ],
  // },
  // {
  //   id: 'BH',
  //   two_letter_abbreviation: 'BH',
  //   three_letter_abbreviation: 'BHR',
  //   full_name_locale: 'Bahrain',
  //   full_name_english: 'Bahrain',
  // },
  // {
  //   id: 'BI',
  //   two_letter_abbreviation: 'BI',
  //   three_letter_abbreviation: 'BDI',
  //   full_name_locale: 'Burundi',
  //   full_name_english: 'Burundi',
  // },
  // {
  //   id: 'BJ',
  //   two_letter_abbreviation: 'BJ',
  //   three_letter_abbreviation: 'BEN',
  //   full_name_locale: 'Benin',
  //   full_name_english: 'Benin',
  // },
  // {
  //   id: 'BL',
  //   two_letter_abbreviation: 'BL',
  //   three_letter_abbreviation: 'BLM',
  //   full_name_locale: 'St. Barthélemy',
  //   full_name_english: 'St. Barthélemy',
  // },
  // {
  //   id: 'BM',
  //   two_letter_abbreviation: 'BM',
  //   three_letter_abbreviation: 'BMU',
  //   full_name_locale: 'Bermuda',
  //   full_name_english: 'Bermuda',
  // },
  // {
  //   id: 'BN',
  //   two_letter_abbreviation: 'BN',
  //   three_letter_abbreviation: 'BRN',
  //   full_name_locale: 'Brunei',
  //   full_name_english: 'Brunei',
  // },
  // {
  //   id: 'BO',
  //   two_letter_abbreviation: 'BO',
  //   three_letter_abbreviation: 'BOL',
  //   full_name_locale: 'Bolivia',
  //   full_name_english: 'Bolivia',
  //   available_regions: [
  //     {
  //       id: '632',
  //       code: 'BO-H',
  //       name: 'Chuquisaca',
  //     },
  //     {
  //       id: '631',
  //       code: 'BO-C',
  //       name: 'Cochabamba',
  //     },
  //     {
  //       id: '633',
  //       code: 'BO-B',
  //       name: 'El Beni',
  //     },
  //     {
  //       id: '634',
  //       code: 'BO-L',
  //       name: 'La Paz',
  //     },
  //     {
  //       id: '635',
  //       code: 'BO-O',
  //       name: 'Oruro',
  //     },
  //     {
  //       id: '636',
  //       code: 'BO-N',
  //       name: 'Pando',
  //     },
  //     {
  //       id: '637',
  //       code: 'BO-P',
  //       name: 'Potosí',
  //     },
  //     {
  //       id: '638',
  //       code: 'BO-S',
  //       name: 'Santa Cruz',
  //     },
  //     {
  //       id: '639',
  //       code: 'BO-T',
  //       name: 'Tarija',
  //     },
  //   ],
  // },
  // {
  //   id: 'BQ',
  //   two_letter_abbreviation: 'BQ',
  //   three_letter_abbreviation: 'BES',
  //   full_name_locale: 'Caribbean Netherlands',
  //   full_name_english: 'Caribbean Netherlands',
  // },
  // {
  //   id: 'BR',
  //   two_letter_abbreviation: 'BR',
  //   three_letter_abbreviation: 'BRA',
  //   full_name_locale: 'Brazil',
  //   full_name_english: 'Brazil',
  //   available_regions: [
  //     {
  //       id: '485',
  //       code: 'AC',
  //       name: 'Acre',
  //     },
  //     {
  //       id: '486',
  //       code: 'AL',
  //       name: 'Alagoas',
  //     },
  //     {
  //       id: '487',
  //       code: 'AP',
  //       name: 'Amapá',
  //     },
  //     {
  //       id: '488',
  //       code: 'AM',
  //       name: 'Amazonas',
  //     },
  //     {
  //       id: '489',
  //       code: 'BA',
  //       name: 'Bahia',
  //     },
  //     {
  //       id: '490',
  //       code: 'CE',
  //       name: 'Ceará',
  //     },
  //     {
  //       id: '511',
  //       code: 'DF',
  //       name: 'Distrito Federal',
  //     },
  //     {
  //       id: '491',
  //       code: 'ES',
  //       name: 'Espírito Santo',
  //     },
  //     {
  //       id: '492',
  //       code: 'GO',
  //       name: 'Goiás',
  //     },
  //     {
  //       id: '493',
  //       code: 'MA',
  //       name: 'Maranhão',
  //     },
  //     {
  //       id: '494',
  //       code: 'MT',
  //       name: 'Mato Grosso',
  //     },
  //     {
  //       id: '495',
  //       code: 'MS',
  //       name: 'Mato Grosso do Sul',
  //     },
  //     {
  //       id: '496',
  //       code: 'MG',
  //       name: 'Minas Gerais',
  //     },
  //     {
  //       id: '497',
  //       code: 'PA',
  //       name: 'Pará',
  //     },
  //     {
  //       id: '498',
  //       code: 'PB',
  //       name: 'Paraíba',
  //     },
  //     {
  //       id: '499',
  //       code: 'PR',
  //       name: 'Paraná',
  //     },
  //     {
  //       id: '500',
  //       code: 'PE',
  //       name: 'Pernambuco',
  //     },
  //     {
  //       id: '501',
  //       code: 'PI',
  //       name: 'Piauí',
  //     },
  //     {
  //       id: '502',
  //       code: 'RJ',
  //       name: 'Rio de Janeiro',
  //     },
  //     {
  //       id: '503',
  //       code: 'RN',
  //       name: 'Rio Grande do Norte',
  //     },
  //     {
  //       id: '504',
  //       code: 'RS',
  //       name: 'Rio Grande do Sul',
  //     },
  //     {
  //       id: '505',
  //       code: 'RO',
  //       name: 'Rondônia',
  //     },
  //     {
  //       id: '506',
  //       code: 'RR',
  //       name: 'Roraima',
  //     },
  //     {
  //       id: '507',
  //       code: 'SC',
  //       name: 'Santa Catarina',
  //     },
  //     {
  //       id: '508',
  //       code: 'SP',
  //       name: 'São Paulo',
  //     },
  //     {
  //       id: '509',
  //       code: 'SE',
  //       name: 'Sergipe',
  //     },
  //     {
  //       id: '510',
  //       code: 'TO',
  //       name: 'Tocantins',
  //     },
  //   ],
  // },
  // {
  //   id: 'BS',
  //   two_letter_abbreviation: 'BS',
  //   three_letter_abbreviation: 'BHS',
  //   full_name_locale: 'Bahamas',
  //   full_name_english: 'Bahamas',
  // },
  // {
  //   id: 'BT',
  //   two_letter_abbreviation: 'BT',
  //   three_letter_abbreviation: 'BTN',
  //   full_name_locale: 'Bhutan',
  //   full_name_english: 'Bhutan',
  // },
  // {
  //   id: 'BV',
  //   two_letter_abbreviation: 'BV',
  //   three_letter_abbreviation: 'BVT',
  //   full_name_locale: 'Bouvet Island',
  //   full_name_english: 'Bouvet Island',
  // },
  // {
  //   id: 'BW',
  //   two_letter_abbreviation: 'BW',
  //   three_letter_abbreviation: 'BWA',
  //   full_name_locale: 'Botswana',
  //   full_name_english: 'Botswana',
  // },
  // {
  //   id: 'BY',
  //   two_letter_abbreviation: 'BY',
  //   three_letter_abbreviation: 'BLR',
  //   full_name_locale: 'Belarus',
  //   full_name_english: 'Belarus',
  //   available_regions: [
  //     {
  //       id: '613',
  //       code: 'BY-BR',
  //       name: 'Bresckaja voblasć',
  //     },
  //     {
  //       id: '614',
  //       code: 'BY-HO',
  //       name: 'Homieĺskaja voblasć',
  //     },
  //     {
  //       id: '615',
  //       code: 'BY-HM',
  //       name: 'Horad Minsk',
  //     },
  //     {
  //       id: '616',
  //       code: 'BY-HR',
  //       name: 'Hrodzienskaja voblasć',
  //     },
  //     {
  //       id: '617',
  //       code: 'BY-MA',
  //       name: 'Mahilioŭskaja voblasć',
  //     },
  //     {
  //       id: '618',
  //       code: 'BY-MI',
  //       name: 'Minskaja voblasć',
  //     },
  //     {
  //       id: '619',
  //       code: 'BY-VI',
  //       name: 'Viciebskaja voblasć',
  //     },
  //   ],
  // },
  // {
  //   id: 'BZ',
  //   two_letter_abbreviation: 'BZ',
  //   three_letter_abbreviation: 'BLZ',
  //   full_name_locale: 'Belize',
  //   full_name_english: 'Belize',
  // },
  {
    id: 'CA',
    two_letter_abbreviation: 'CA',
    three_letter_abbreviation: 'CAN',
    full_name_locale: 'Canada',
    full_name_english: 'Canada',
    available_regions: [
      {
        id: '66',
        code: 'AB',
        name: 'Alberta',
      },
      {
        id: '67',
        code: 'BC',
        name: 'British Columbia',
      },
      {
        id: '68',
        code: 'MB',
        name: 'Manitoba',
      },
      {
        id: '70',
        code: 'NB',
        name: 'New Brunswick',
      },
      {
        id: '69',
        code: 'NL',
        name: 'Newfoundland and Labrador',
      },
      {
        id: '72',
        code: 'NT',
        name: 'Northwest Territories',
      },
      {
        id: '71',
        code: 'NS',
        name: 'Nova Scotia',
      },
      {
        id: '73',
        code: 'NU',
        name: 'Nunavut',
      },
      {
        id: '74',
        code: 'ON',
        name: 'Ontario',
      },
      {
        id: '75',
        code: 'PE',
        name: 'Prince Edward Island',
      },
      {
        id: '76',
        code: 'QC',
        name: 'Quebec',
      },
      {
        id: '77',
        code: 'SK',
        name: 'Saskatchewan',
      },
      {
        id: '78',
        code: 'YT',
        name: 'Yukon Territory',
      },
    ],
  },
  // {
  //   id: 'CC',
  //   two_letter_abbreviation: 'CC',
  //   three_letter_abbreviation: 'CCK',
  //   full_name_locale: 'Cocos (Keeling) Islands',
  //   full_name_english: 'Cocos (Keeling) Islands',
  // },
  // {
  //   id: 'CD',
  //   two_letter_abbreviation: 'CD',
  //   three_letter_abbreviation: 'COD',
  //   full_name_locale: 'Congo - Kinshasa',
  //   full_name_english: 'Congo - Kinshasa',
  // },
  // {
  //   id: 'CF',
  //   two_letter_abbreviation: 'CF',
  //   three_letter_abbreviation: 'CAF',
  //   full_name_locale: 'Central African Republic',
  //   full_name_english: 'Central African Republic',
  // },
  // {
  //   id: 'CG',
  //   two_letter_abbreviation: 'CG',
  //   three_letter_abbreviation: 'COG',
  //   full_name_locale: 'Congo - Brazzaville',
  //   full_name_english: 'Congo - Brazzaville',
  // },
  // {
  //   id: 'CH',
  //   two_letter_abbreviation: 'CH',
  //   three_letter_abbreviation: 'CHE',
  //   full_name_locale: 'Switzerland',
  //   full_name_english: 'Switzerland',
  //   available_regions: [
  //     {
  //       id: '104',
  //       code: 'AG',
  //       name: 'Aargau',
  //     },
  //     {
  //       id: '106',
  //       code: 'AR',
  //       name: 'Appenzell Ausserrhoden',
  //     },
  //     {
  //       id: '105',
  //       code: 'AI',
  //       name: 'Appenzell Innerrhoden',
  //     },
  //     {
  //       id: '108',
  //       code: 'BL',
  //       name: 'Basel-Landschaft',
  //     },
  //     {
  //       id: '109',
  //       code: 'BS',
  //       name: 'Basel-Stadt',
  //     },
  //     {
  //       id: '107',
  //       code: 'BE',
  //       name: 'Bern',
  //     },
  //     {
  //       id: '110',
  //       code: 'FR',
  //       name: 'Friburg',
  //     },
  //     {
  //       id: '111',
  //       code: 'GE',
  //       name: 'Geneva',
  //     },
  //     {
  //       id: '112',
  //       code: 'GL',
  //       name: 'Glarus',
  //     },
  //     {
  //       id: '113',
  //       code: 'GR',
  //       name: 'Graubünden',
  //     },
  //     {
  //       id: '114',
  //       code: 'JU',
  //       name: 'Jura',
  //     },
  //     {
  //       id: '115',
  //       code: 'LU',
  //       name: 'Lucerne',
  //     },
  //     {
  //       id: '116',
  //       code: 'NE',
  //       name: 'Neuchâtel',
  //     },
  //     {
  //       id: '117',
  //       code: 'NW',
  //       name: 'Nidwalden',
  //     },
  //     {
  //       id: '118',
  //       code: 'OW',
  //       name: 'Obwalden',
  //     },
  //     {
  //       id: '120',
  //       code: 'SH',
  //       name: 'Schaffhausen',
  //     },
  //     {
  //       id: '122',
  //       code: 'SZ',
  //       name: 'Schwyz',
  //     },
  //     {
  //       id: '121',
  //       code: 'SO',
  //       name: 'Solothurn',
  //     },
  //     {
  //       id: '119',
  //       code: 'SG',
  //       name: 'St. Gallen',
  //     },
  //     {
  //       id: '123',
  //       code: 'TG',
  //       name: 'Thurgau',
  //     },
  //     {
  //       id: '124',
  //       code: 'TI',
  //       name: 'Ticino',
  //     },
  //     {
  //       id: '125',
  //       code: 'UR',
  //       name: 'Uri',
  //     },
  //     {
  //       id: '126',
  //       code: 'VD',
  //       name: 'Vaud',
  //     },
  //     {
  //       id: '127',
  //       code: 'VS',
  //       name: 'Wallis',
  //     },
  //     {
  //       id: '128',
  //       code: 'ZG',
  //       name: 'Zug',
  //     },
  //     {
  //       id: '129',
  //       code: 'ZH',
  //       name: 'Zürich',
  //     },
  //   ],
  // },
  // {
  //   id: 'CI',
  //   two_letter_abbreviation: 'CI',
  //   three_letter_abbreviation: 'CIV',
  //   full_name_locale: 'Côte d’Ivoire',
  //   full_name_english: 'Côte d’Ivoire',
  // },
  // {
  //   id: 'CK',
  //   two_letter_abbreviation: 'CK',
  //   three_letter_abbreviation: 'COK',
  //   full_name_locale: 'Cook Islands',
  //   full_name_english: 'Cook Islands',
  // },
  // {
  //   id: 'CL',
  //   two_letter_abbreviation: 'CL',
  //   three_letter_abbreviation: 'CHL',
  //   full_name_locale: 'Chile',
  //   full_name_english: 'Chile',
  //   available_regions: [
  //     {
  //       id: '668',
  //       code: 'CL-AI',
  //       name: 'Aisén del General Carlos Ibañez del Campo',
  //     },
  //     {
  //       id: '669',
  //       code: 'CL-AN',
  //       name: 'Antofagasta',
  //     },
  //     {
  //       id: '670',
  //       code: 'CL-AP',
  //       name: 'Arica y Parinacota',
  //     },
  //     {
  //       id: '672',
  //       code: 'CL-AT',
  //       name: 'Atacama',
  //     },
  //     {
  //       id: '673',
  //       code: 'CL-BI',
  //       name: 'Biobío',
  //     },
  //     {
  //       id: '674',
  //       code: 'CL-CO',
  //       name: 'Coquimbo',
  //     },
  //     {
  //       id: '671',
  //       code: 'CL-AR',
  //       name: 'La Araucanía',
  //     },
  //     {
  //       id: '675',
  //       code: 'CL-LI',
  //       name: "Libertador General Bernardo O'Higgins",
  //     },
  //     {
  //       id: '676',
  //       code: 'CL-LL',
  //       name: 'Los Lagos',
  //     },
  //     {
  //       id: '677',
  //       code: 'CL-LR',
  //       name: 'Los Ríos',
  //     },
  //     {
  //       id: '678',
  //       code: 'CL-MA',
  //       name: 'Magallanes',
  //     },
  //     {
  //       id: '679',
  //       code: 'CL-ML',
  //       name: 'Maule',
  //     },
  //     {
  //       id: '680',
  //       code: 'CL-NB',
  //       name: 'Ñuble',
  //     },
  //     {
  //       id: '681',
  //       code: 'CL-RM',
  //       name: 'Región Metropolitana de Santiago',
  //     },
  //     {
  //       id: '682',
  //       code: 'CL-TA',
  //       name: 'Tarapacá',
  //     },
  //     {
  //       id: '683',
  //       code: 'CL-VS',
  //       name: 'Valparaíso',
  //     },
  //   ],
  // },
  // {
  //   id: 'CM',
  //   two_letter_abbreviation: 'CM',
  //   three_letter_abbreviation: 'CMR',
  //   full_name_locale: 'Cameroon',
  //   full_name_english: 'Cameroon',
  // },
  // {
  //   id: 'CN',
  //   two_letter_abbreviation: 'CN',
  //   three_letter_abbreviation: 'CHN',
  //   full_name_locale: 'China',
  //   full_name_english: 'China',
  //   available_regions: [
  //     {
  //       id: '684',
  //       code: 'CN-AH',
  //       name: 'Anhui Sheng',
  //     },
  //     {
  //       id: '685',
  //       code: 'CN-BJ',
  //       name: 'Beijing Shi',
  //     },
  //     {
  //       id: '686',
  //       code: 'CN-CQ',
  //       name: 'Chongqing Shi',
  //     },
  //     {
  //       id: '687',
  //       code: 'CN-FJ',
  //       name: 'Fujian Sheng',
  //     },
  //     {
  //       id: '688',
  //       code: 'CN-GS',
  //       name: 'Gansu Sheng',
  //     },
  //     {
  //       id: '689',
  //       code: 'CN-GD',
  //       name: 'Guangdong Sheng',
  //     },
  //     {
  //       id: '690',
  //       code: 'CN-GX',
  //       name: 'Guangxi Zhuangzu Zizhiqu',
  //     },
  //     {
  //       id: '691',
  //       code: 'CN-GZ',
  //       name: 'Guizhou Sheng',
  //     },
  //     {
  //       id: '692',
  //       code: 'CN-HI',
  //       name: 'Hainan Sheng',
  //     },
  //     {
  //       id: '693',
  //       code: 'CN-HE',
  //       name: 'Hebei Sheng',
  //     },
  //     {
  //       id: '694',
  //       code: 'CN-HL',
  //       name: 'Heilongjiang Sheng',
  //     },
  //     {
  //       id: '695',
  //       code: 'CN-HA',
  //       name: 'Henan Sheng',
  //     },
  //     {
  //       id: '696',
  //       code: 'CN-HK',
  //       name: 'Hong Kong SAR',
  //     },
  //     {
  //       id: '697',
  //       code: 'CN-HB',
  //       name: 'Hubei Sheng',
  //     },
  //     {
  //       id: '698',
  //       code: 'CN-HN',
  //       name: 'Hunan Sheng',
  //     },
  //     {
  //       id: '699',
  //       code: 'CN-JS',
  //       name: 'Jiangsu Sheng',
  //     },
  //     {
  //       id: '700',
  //       code: 'CN-JX',
  //       name: 'Jiangxi Sheng',
  //     },
  //     {
  //       id: '701',
  //       code: 'CN-JL',
  //       name: 'Jilin Sheng',
  //     },
  //     {
  //       id: '702',
  //       code: 'CN-LN',
  //       name: 'Liaoning Sheng',
  //     },
  //     {
  //       id: '703',
  //       code: 'CN-MO',
  //       name: 'Macao SAR',
  //     },
  //     {
  //       id: '704',
  //       code: 'CN-NM',
  //       name: 'Nei Mongol Zizhiqu',
  //     },
  //     {
  //       id: '705',
  //       code: 'CN-NX',
  //       name: 'Ningxia Huizi Zizhiqu',
  //     },
  //     {
  //       id: '706',
  //       code: 'CN-QH',
  //       name: 'Qinghai Sheng',
  //     },
  //     {
  //       id: '707',
  //       code: 'CN-SN',
  //       name: 'Shaanxi Sheng',
  //     },
  //     {
  //       id: '708',
  //       code: 'CN-SD',
  //       name: 'Shandong Sheng',
  //     },
  //     {
  //       id: '709',
  //       code: 'CN-SH',
  //       name: 'Shanghai Shi',
  //     },
  //     {
  //       id: '710',
  //       code: 'CN-SX',
  //       name: 'Shanxi Sheng',
  //     },
  //     {
  //       id: '711',
  //       code: 'CN-SC',
  //       name: 'Sichuan Sheng',
  //     },
  //     {
  //       id: '712',
  //       code: 'CN-TW',
  //       name: 'Taiwan Sheng',
  //     },
  //     {
  //       id: '713',
  //       code: 'CN-TJ',
  //       name: 'Tianjin Shi',
  //     },
  //     {
  //       id: '714',
  //       code: 'CN-XJ',
  //       name: 'Xinjiang Uygur Zizhiqu',
  //     },
  //     {
  //       id: '715',
  //       code: 'CN-XZ',
  //       name: 'Xizang Zizhiqu',
  //     },
  //     {
  //       id: '716',
  //       code: 'CN-YN',
  //       name: 'Yunnan Sheng',
  //     },
  //     {
  //       id: '717',
  //       code: 'CN-ZJ',
  //       name: 'Zhejiang Sheng',
  //     },
  //   ],
  // },
  // {
  //   id: 'CO',
  //   two_letter_abbreviation: 'CO',
  //   three_letter_abbreviation: 'COL',
  //   full_name_locale: 'Colombia',
  //   full_name_english: 'Colombia',
  //   available_regions: [
  //     {
  //       id: '718',
  //       code: 'CO-AMA',
  //       name: 'Amazonas',
  //     },
  //     {
  //       id: '719',
  //       code: 'CO-ANT',
  //       name: 'Antioquia',
  //     },
  //     {
  //       id: '720',
  //       code: 'CO-ARA',
  //       name: 'Arauca',
  //     },
  //     {
  //       id: '721',
  //       code: 'CO-ATL',
  //       name: 'Atlántico',
  //     },
  //     {
  //       id: '722',
  //       code: 'CO-BOL',
  //       name: 'Bolívar',
  //     },
  //     {
  //       id: '723',
  //       code: 'CO-BOY',
  //       name: 'Boyacá',
  //     },
  //     {
  //       id: '724',
  //       code: 'CO-CAL',
  //       name: 'Caldas',
  //     },
  //     {
  //       id: '725',
  //       code: 'CO-CAQ',
  //       name: 'Caquetá',
  //     },
  //     {
  //       id: '726',
  //       code: 'CO-CAS',
  //       name: 'Casanare',
  //     },
  //     {
  //       id: '727',
  //       code: 'CO-CAU',
  //       name: 'Cauca',
  //     },
  //     {
  //       id: '728',
  //       code: 'CO-CES',
  //       name: 'Cesar',
  //     },
  //     {
  //       id: '729',
  //       code: 'CO-CHO',
  //       name: 'Chocó',
  //     },
  //     {
  //       id: '730',
  //       code: 'CO-COR',
  //       name: 'Córdoba',
  //     },
  //     {
  //       id: '731',
  //       code: 'CO-CUN',
  //       name: 'Cundinamarca',
  //     },
  //     {
  //       id: '732',
  //       code: 'CO-GUA',
  //       name: 'Guainía',
  //     },
  //     {
  //       id: '733',
  //       code: 'CO-GUV',
  //       name: 'Guaviare',
  //     },
  //     {
  //       id: '734',
  //       code: 'CO-HUL',
  //       name: 'Huila',
  //     },
  //     {
  //       id: '735',
  //       code: 'CO-LAG',
  //       name: 'La Guajira',
  //     },
  //     {
  //       id: '736',
  //       code: 'CO-MAG',
  //       name: 'Magdalena',
  //     },
  //     {
  //       id: '737',
  //       code: 'CO-MET',
  //       name: 'Meta',
  //     },
  //     {
  //       id: '738',
  //       code: 'CO-NAR',
  //       name: 'Nariño',
  //     },
  //     {
  //       id: '739',
  //       code: 'CO-NSA',
  //       name: 'Norte de Santander',
  //     },
  //     {
  //       id: '740',
  //       code: 'CO-PUT',
  //       name: 'Putumayo',
  //     },
  //     {
  //       id: '741',
  //       code: 'CO-QUI',
  //       name: 'Quindío',
  //     },
  //     {
  //       id: '742',
  //       code: 'CO-RIS',
  //       name: 'Risaralda',
  //     },
  //     {
  //       id: '743',
  //       code: 'CO-SAP',
  //       name: 'San Andrés y Providencia',
  //     },
  //     {
  //       id: '744',
  //       code: 'CO-SAN',
  //       name: 'Santander',
  //     },
  //     {
  //       id: '745',
  //       code: 'CO-SUC',
  //       name: 'Sucre',
  //     },
  //     {
  //       id: '746',
  //       code: 'CO-TOL',
  //       name: 'Tolima',
  //     },
  //     {
  //       id: '747',
  //       code: 'CO-VAC',
  //       name: 'Valle del Cauca',
  //     },
  //     {
  //       id: '748',
  //       code: 'CO-VAU',
  //       name: 'Vaupés',
  //     },
  //     {
  //       id: '749',
  //       code: 'CO-VID',
  //       name: 'Vichada',
  //     },
  //   ],
  // },
  // {
  //   id: 'CR',
  //   two_letter_abbreviation: 'CR',
  //   three_letter_abbreviation: 'CRI',
  //   full_name_locale: 'Costa Rica',
  //   full_name_english: 'Costa Rica',
  // },
  // {
  //   id: 'CU',
  //   two_letter_abbreviation: 'CU',
  //   three_letter_abbreviation: 'CUB',
  //   full_name_locale: 'Cuba',
  //   full_name_english: 'Cuba',
  // },
  // {
  //   id: 'CV',
  //   two_letter_abbreviation: 'CV',
  //   three_letter_abbreviation: 'CPV',
  //   full_name_locale: 'Cape Verde',
  //   full_name_english: 'Cape Verde',
  // },
  // {
  //   id: 'CW',
  //   two_letter_abbreviation: 'CW',
  //   three_letter_abbreviation: 'CUW',
  //   full_name_locale: 'Curaçao',
  //   full_name_english: 'Curaçao',
  // },
  // {
  //   id: 'CX',
  //   two_letter_abbreviation: 'CX',
  //   three_letter_abbreviation: 'CXR',
  //   full_name_locale: 'Christmas Island',
  //   full_name_english: 'Christmas Island',
  // },
  // {
  //   id: 'CY',
  //   two_letter_abbreviation: 'CY',
  //   three_letter_abbreviation: 'CYP',
  //   full_name_locale: 'Cyprus',
  //   full_name_english: 'Cyprus',
  // },
  // {
  //   id: 'CZ',
  //   two_letter_abbreviation: 'CZ',
  //   three_letter_abbreviation: 'CZE',
  //   full_name_locale: 'Czechia',
  //   full_name_english: 'Czechia',
  //   available_regions: [
  //     {
  //       id: '752',
  //       code: 'CZ-31',
  //       name: 'Jihočeský kraj',
  //     },
  //     {
  //       id: '760',
  //       code: 'CZ-64',
  //       name: 'Jihomoravský kraj',
  //     },
  //     {
  //       id: '754',
  //       code: 'CZ-41',
  //       name: 'Karlovarský kraj',
  //     },
  //     {
  //       id: '759',
  //       code: 'CZ-63',
  //       name: 'Kraj Vysočina',
  //     },
  //     {
  //       id: '757',
  //       code: 'CZ-52',
  //       name: 'Královéhradecký kraj',
  //     },
  //     {
  //       id: '756',
  //       code: 'CZ-51',
  //       name: 'Liberecký kraj',
  //     },
  //     {
  //       id: '763',
  //       code: 'CZ-80',
  //       name: 'Moravskoslezský kraj',
  //     },
  //     {
  //       id: '761',
  //       code: 'CZ-71',
  //       name: 'Olomoucký kraj',
  //     },
  //     {
  //       id: '758',
  //       code: 'CZ-53',
  //       name: 'Pardubický kraj',
  //     },
  //     {
  //       id: '753',
  //       code: 'CZ-32',
  //       name: 'Plzeňský kraj',
  //     },
  //     {
  //       id: '750',
  //       code: 'CZ-10',
  //       name: 'Praha, Hlavní město',
  //     },
  //     {
  //       id: '751',
  //       code: 'CZ-20',
  //       name: 'Středočeský kraj',
  //     },
  //     {
  //       id: '755',
  //       code: 'CZ-42',
  //       name: 'Ústecký kraj',
  //     },
  //     {
  //       id: '762',
  //       code: 'CZ-72',
  //       name: 'Zlínský kraj',
  //     },
  //   ],
  // },
  // {
  //   id: 'DE',
  //   two_letter_abbreviation: 'DE',
  //   three_letter_abbreviation: 'DEU',
  //   full_name_locale: 'Germany',
  //   full_name_english: 'Germany',
  //   available_regions: [
  //     {
  //       id: '80',
  //       code: 'BAW',
  //       name: 'Baden-Württemberg',
  //     },
  //     {
  //       id: '81',
  //       code: 'BAY',
  //       name: 'Bayern',
  //     },
  //     {
  //       id: '82',
  //       code: 'BER',
  //       name: 'Berlin',
  //     },
  //     {
  //       id: '83',
  //       code: 'BRG',
  //       name: 'Brandenburg',
  //     },
  //     {
  //       id: '84',
  //       code: 'BRE',
  //       name: 'Bremen',
  //     },
  //     {
  //       id: '85',
  //       code: 'HAM',
  //       name: 'Hamburg',
  //     },
  //     {
  //       id: '86',
  //       code: 'HES',
  //       name: 'Hessen',
  //     },
  //     {
  //       id: '87',
  //       code: 'MEC',
  //       name: 'Mecklenburg-Vorpommern',
  //     },
  //     {
  //       id: '79',
  //       code: 'NDS',
  //       name: 'Niedersachsen',
  //     },
  //     {
  //       id: '88',
  //       code: 'NRW',
  //       name: 'Nordrhein-Westfalen',
  //     },
  //     {
  //       id: '89',
  //       code: 'RHE',
  //       name: 'Rheinland-Pfalz',
  //     },
  //     {
  //       id: '90',
  //       code: 'SAR',
  //       name: 'Saarland',
  //     },
  //     {
  //       id: '91',
  //       code: 'SAS',
  //       name: 'Sachsen',
  //     },
  //     {
  //       id: '92',
  //       code: 'SAC',
  //       name: 'Sachsen-Anhalt',
  //     },
  //     {
  //       id: '93',
  //       code: 'SCN',
  //       name: 'Schleswig-Holstein',
  //     },
  //     {
  //       id: '94',
  //       code: 'THE',
  //       name: 'Thüringen',
  //     },
  //   ],
  // },
  // {
  //   id: 'DJ',
  //   two_letter_abbreviation: 'DJ',
  //   three_letter_abbreviation: 'DJI',
  //   full_name_locale: 'Djibouti',
  //   full_name_english: 'Djibouti',
  // },
  // {
  //   id: 'DK',
  //   two_letter_abbreviation: 'DK',
  //   three_letter_abbreviation: 'DNK',
  //   full_name_locale: 'Denmark',
  //   full_name_english: 'Denmark',
  //   available_regions: [
  //     {
  //       id: '764',
  //       code: 'DK-84',
  //       name: 'Hovedstaden',
  //     },
  //     {
  //       id: '765',
  //       code: 'DK-82',
  //       name: 'Midtjylland',
  //     },
  //     {
  //       id: '766',
  //       code: 'DK-81',
  //       name: 'Nordjylland',
  //     },
  //     {
  //       id: '767',
  //       code: 'DK-85',
  //       name: 'Sjælland',
  //     },
  //     {
  //       id: '768',
  //       code: 'DK-83',
  //       name: 'Syddanmark',
  //     },
  //   ],
  // },
  // {
  //   id: 'DM',
  //   two_letter_abbreviation: 'DM',
  //   three_letter_abbreviation: 'DMA',
  //   full_name_locale: 'Dominica',
  //   full_name_english: 'Dominica',
  // },
  // {
  //   id: 'DO',
  //   two_letter_abbreviation: 'DO',
  //   three_letter_abbreviation: 'DOM',
  //   full_name_locale: 'Dominican Republic',
  //   full_name_english: 'Dominican Republic',
  // },
  // {
  //   id: 'DZ',
  //   two_letter_abbreviation: 'DZ',
  //   three_letter_abbreviation: 'DZA',
  //   full_name_locale: 'Algeria',
  //   full_name_english: 'Algeria',
  // },
  // {
  //   id: 'EC',
  //   two_letter_abbreviation: 'EC',
  //   three_letter_abbreviation: 'ECU',
  //   full_name_locale: 'Ecuador',
  //   full_name_english: 'Ecuador',
  //   available_regions: [
  //     {
  //       id: '769',
  //       code: 'EC-A',
  //       name: 'Azuay',
  //     },
  //     {
  //       id: '770',
  //       code: 'EC-B',
  //       name: 'Bolívar',
  //     },
  //     {
  //       id: '771',
  //       code: 'EC-F',
  //       name: 'Cañar',
  //     },
  //     {
  //       id: '772',
  //       code: 'EC-C',
  //       name: 'Carchi',
  //     },
  //     {
  //       id: '773',
  //       code: 'EC-H',
  //       name: 'Chimborazo',
  //     },
  //     {
  //       id: '774',
  //       code: 'EC-X',
  //       name: 'Cotopaxi',
  //     },
  //     {
  //       id: '775',
  //       code: 'EC-O',
  //       name: 'El Oro',
  //     },
  //     {
  //       id: '776',
  //       code: 'EC-E',
  //       name: 'Esmeraldas',
  //     },
  //     {
  //       id: '777',
  //       code: 'EC-W',
  //       name: 'Galápagos',
  //     },
  //     {
  //       id: '778',
  //       code: 'EC-G',
  //       name: 'Guayas',
  //     },
  //     {
  //       id: '779',
  //       code: 'EC-I',
  //       name: 'Imbabura',
  //     },
  //     {
  //       id: '780',
  //       code: 'EC-L',
  //       name: 'Loja',
  //     },
  //     {
  //       id: '781',
  //       code: 'EC-R',
  //       name: 'Los Ríos',
  //     },
  //     {
  //       id: '782',
  //       code: 'EC-M',
  //       name: 'Manabí',
  //     },
  //     {
  //       id: '783',
  //       code: 'EC-S',
  //       name: 'Morona Santiago',
  //     },
  //     {
  //       id: '784',
  //       code: 'EC-N',
  //       name: 'Napo',
  //     },
  //     {
  //       id: '785',
  //       code: 'EC-D',
  //       name: 'Orellana',
  //     },
  //     {
  //       id: '786',
  //       code: 'EC-Y',
  //       name: 'Pastaza',
  //     },
  //     {
  //       id: '787',
  //       code: 'EC-P',
  //       name: 'Pichincha',
  //     },
  //     {
  //       id: '788',
  //       code: 'EC-SE',
  //       name: 'Santa Elena',
  //     },
  //     {
  //       id: '789',
  //       code: 'EC-SD',
  //       name: 'Santo Domingo de los Tsáchilas',
  //     },
  //     {
  //       id: '790',
  //       code: 'EC-U',
  //       name: 'Sucumbíos',
  //     },
  //     {
  //       id: '791',
  //       code: 'EC-T',
  //       name: 'Tungurahua',
  //     },
  //     {
  //       id: '792',
  //       code: 'EC-Z',
  //       name: 'Zamora Chinchipe',
  //     },
  //   ],
  // },
  // {
  //   id: 'EE',
  //   two_letter_abbreviation: 'EE',
  //   three_letter_abbreviation: 'EST',
  //   full_name_locale: 'Estonia',
  //   full_name_english: 'Estonia',
  //   available_regions: [
  //     {
  //       id: '340',
  //       code: 'EE-37',
  //       name: 'Harjumaa',
  //     },
  //     {
  //       id: '341',
  //       code: 'EE-39',
  //       name: 'Hiiumaa',
  //     },
  //     {
  //       id: '342',
  //       code: 'EE-44',
  //       name: 'Ida-Virumaa',
  //     },
  //     {
  //       id: '344',
  //       code: 'EE-51',
  //       name: 'Järvamaa',
  //     },
  //     {
  //       id: '343',
  //       code: 'EE-49',
  //       name: 'Jõgevamaa',
  //     },
  //     {
  //       id: '346',
  //       code: 'EE-59',
  //       name: 'Lääne-Virumaa',
  //     },
  //     {
  //       id: '345',
  //       code: 'EE-57',
  //       name: 'Läänemaa',
  //     },
  //     {
  //       id: '348',
  //       code: 'EE-67',
  //       name: 'Pärnumaa',
  //     },
  //     {
  //       id: '347',
  //       code: 'EE-65',
  //       name: 'Põlvamaa',
  //     },
  //     {
  //       id: '349',
  //       code: 'EE-70',
  //       name: 'Raplamaa',
  //     },
  //     {
  //       id: '350',
  //       code: 'EE-74',
  //       name: 'Saaremaa',
  //     },
  //     {
  //       id: '351',
  //       code: 'EE-78',
  //       name: 'Tartumaa',
  //     },
  //     {
  //       id: '352',
  //       code: 'EE-82',
  //       name: 'Valgamaa',
  //     },
  //     {
  //       id: '353',
  //       code: 'EE-84',
  //       name: 'Viljandimaa',
  //     },
  //     {
  //       id: '354',
  //       code: 'EE-86',
  //       name: 'Võrumaa',
  //     },
  //   ],
  // },
  // {
  //   id: 'EG',
  //   two_letter_abbreviation: 'EG',
  //   three_letter_abbreviation: 'EGY',
  //   full_name_locale: 'Egypt',
  //   full_name_english: 'Egypt',
  // },
  // {
  //   id: 'EH',
  //   two_letter_abbreviation: 'EH',
  //   three_letter_abbreviation: 'ESH',
  //   full_name_locale: 'Western Sahara',
  //   full_name_english: 'Western Sahara',
  // },
  // {
  //   id: 'ER',
  //   two_letter_abbreviation: 'ER',
  //   three_letter_abbreviation: 'ERI',
  //   full_name_locale: 'Eritrea',
  //   full_name_english: 'Eritrea',
  // },
  // {
  //   id: 'ES',
  //   two_letter_abbreviation: 'ES',
  //   three_letter_abbreviation: 'ESP',
  //   full_name_locale: 'Spain',
  //   full_name_english: 'Spain',
  //   available_regions: [
  //     {
  //       id: '130',
  //       code: 'A Coruсa',
  //       name: 'A Coruña',
  //     },
  //     {
  //       id: '131',
  //       code: 'Alava',
  //       name: 'Alava',
  //     },
  //     {
  //       id: '132',
  //       code: 'Albacete',
  //       name: 'Albacete',
  //     },
  //     {
  //       id: '133',
  //       code: 'Alicante',
  //       name: 'Alicante',
  //     },
  //     {
  //       id: '134',
  //       code: 'Almeria',
  //       name: 'Almeria',
  //     },
  //     {
  //       id: '135',
  //       code: 'Asturias',
  //       name: 'Asturias',
  //     },
  //     {
  //       id: '136',
  //       code: 'Avila',
  //       name: 'Avila',
  //     },
  //     {
  //       id: '137',
  //       code: 'Badajoz',
  //       name: 'Badajoz',
  //     },
  //     {
  //       id: '138',
  //       code: 'Baleares',
  //       name: 'Baleares',
  //     },
  //     {
  //       id: '139',
  //       code: 'Barcelona',
  //       name: 'Barcelona',
  //     },
  //     {
  //       id: '140',
  //       code: 'Burgos',
  //       name: 'Burgos',
  //     },
  //     {
  //       id: '141',
  //       code: 'Caceres',
  //       name: 'Caceres',
  //     },
  //     {
  //       id: '142',
  //       code: 'Cadiz',
  //       name: 'Cadiz',
  //     },
  //     {
  //       id: '143',
  //       code: 'Cantabria',
  //       name: 'Cantabria',
  //     },
  //     {
  //       id: '144',
  //       code: 'Castellon',
  //       name: 'Castellon',
  //     },
  //     {
  //       id: '145',
  //       code: 'Ceuta',
  //       name: 'Ceuta',
  //     },
  //     {
  //       id: '146',
  //       code: 'Ciudad Real',
  //       name: 'Ciudad Real',
  //     },
  //     {
  //       id: '147',
  //       code: 'Cordoba',
  //       name: 'Cordoba',
  //     },
  //     {
  //       id: '148',
  //       code: 'Cuenca',
  //       name: 'Cuenca',
  //     },
  //     {
  //       id: '149',
  //       code: 'Girona',
  //       name: 'Girona',
  //     },
  //     {
  //       id: '150',
  //       code: 'Granada',
  //       name: 'Granada',
  //     },
  //     {
  //       id: '151',
  //       code: 'Guadalajara',
  //       name: 'Guadalajara',
  //     },
  //     {
  //       id: '152',
  //       code: 'Guipuzcoa',
  //       name: 'Guipuzcoa',
  //     },
  //     {
  //       id: '153',
  //       code: 'Huelva',
  //       name: 'Huelva',
  //     },
  //     {
  //       id: '154',
  //       code: 'Huesca',
  //       name: 'Huesca',
  //     },
  //     {
  //       id: '155',
  //       code: 'Jaen',
  //       name: 'Jaen',
  //     },
  //     {
  //       id: '156',
  //       code: 'La Rioja',
  //       name: 'La Rioja',
  //     },
  //     {
  //       id: '157',
  //       code: 'Las Palmas',
  //       name: 'Las Palmas',
  //     },
  //     {
  //       id: '158',
  //       code: 'Leon',
  //       name: 'Leon',
  //     },
  //     {
  //       id: '159',
  //       code: 'Lleida',
  //       name: 'Lleida',
  //     },
  //     {
  //       id: '160',
  //       code: 'Lugo',
  //       name: 'Lugo',
  //     },
  //     {
  //       id: '161',
  //       code: 'Madrid',
  //       name: 'Madrid',
  //     },
  //     {
  //       id: '162',
  //       code: 'Malaga',
  //       name: 'Malaga',
  //     },
  //     {
  //       id: '163',
  //       code: 'Melilla',
  //       name: 'Melilla',
  //     },
  //     {
  //       id: '164',
  //       code: 'Murcia',
  //       name: 'Murcia',
  //     },
  //     {
  //       id: '165',
  //       code: 'Navarra',
  //       name: 'Navarra',
  //     },
  //     {
  //       id: '166',
  //       code: 'Ourense',
  //       name: 'Ourense',
  //     },
  //     {
  //       id: '167',
  //       code: 'Palencia',
  //       name: 'Palencia',
  //     },
  //     {
  //       id: '168',
  //       code: 'Pontevedra',
  //       name: 'Pontevedra',
  //     },
  //     {
  //       id: '169',
  //       code: 'Salamanca',
  //       name: 'Salamanca',
  //     },
  //     {
  //       id: '170',
  //       code: 'Santa Cruz de Tenerife',
  //       name: 'Santa Cruz de Tenerife',
  //     },
  //     {
  //       id: '171',
  //       code: 'Segovia',
  //       name: 'Segovia',
  //     },
  //     {
  //       id: '172',
  //       code: 'Sevilla',
  //       name: 'Sevilla',
  //     },
  //     {
  //       id: '173',
  //       code: 'Soria',
  //       name: 'Soria',
  //     },
  //     {
  //       id: '174',
  //       code: 'Tarragona',
  //       name: 'Tarragona',
  //     },
  //     {
  //       id: '175',
  //       code: 'Teruel',
  //       name: 'Teruel',
  //     },
  //     {
  //       id: '176',
  //       code: 'Toledo',
  //       name: 'Toledo',
  //     },
  //     {
  //       id: '177',
  //       code: 'Valencia',
  //       name: 'Valencia',
  //     },
  //     {
  //       id: '178',
  //       code: 'Valladolid',
  //       name: 'Valladolid',
  //     },
  //     {
  //       id: '179',
  //       code: 'Vizcaya',
  //       name: 'Vizcaya',
  //     },
  //     {
  //       id: '180',
  //       code: 'Zamora',
  //       name: 'Zamora',
  //     },
  //     {
  //       id: '181',
  //       code: 'Zaragoza',
  //       name: 'Zaragoza',
  //     },
  //   ],
  // },
  // {
  //   id: 'ET',
  //   two_letter_abbreviation: 'ET',
  //   three_letter_abbreviation: 'ETH',
  //   full_name_locale: 'Ethiopia',
  //   full_name_english: 'Ethiopia',
  // },
  // {
  //   id: 'FI',
  //   two_letter_abbreviation: 'FI',
  //   three_letter_abbreviation: 'FIN',
  //   full_name_locale: 'Finland',
  //   full_name_english: 'Finland',
  //   available_regions: [
  //     {
  //       id: '339',
  //       code: 'Ahvenanmaa',
  //       name: 'Ahvenanmaa',
  //     },
  //     {
  //       id: '333',
  //       code: 'Etelä-Karjala',
  //       name: 'Etelä-Karjala',
  //     },
  //     {
  //       id: '326',
  //       code: 'Etelä-Pohjanmaa',
  //       name: 'Etelä-Pohjanmaa',
  //     },
  //     {
  //       id: '325',
  //       code: 'Etelä-Savo',
  //       name: 'Etelä-Savo',
  //     },
  //     {
  //       id: '337',
  //       code: 'Itä-Uusimaa',
  //       name: 'Itä-Uusimaa',
  //     },
  //     {
  //       id: '322',
  //       code: 'Kainuu',
  //       name: 'Kainuu',
  //     },
  //     {
  //       id: '335',
  //       code: 'Kanta-Häme',
  //       name: 'Kanta-Häme',
  //     },
  //     {
  //       id: '330',
  //       code: 'Keski-Pohjanmaa',
  //       name: 'Keski-Pohjanmaa',
  //     },
  //     {
  //       id: '331',
  //       code: 'Keski-Suomi',
  //       name: 'Keski-Suomi',
  //     },
  //     {
  //       id: '338',
  //       code: 'Kymenlaakso',
  //       name: 'Kymenlaakso',
  //     },
  //     {
  //       id: '320',
  //       code: 'Lappi',
  //       name: 'Lappi',
  //     },
  //     {
  //       id: '334',
  //       code: 'Päijät-Häme',
  //       name: 'Päijät-Häme',
  //     },
  //     {
  //       id: '328',
  //       code: 'Pirkanmaa',
  //       name: 'Pirkanmaa',
  //     },
  //     {
  //       id: '327',
  //       code: 'Pohjanmaa',
  //       name: 'Pohjanmaa',
  //     },
  //     {
  //       id: '323',
  //       code: 'Pohjois-Karjala',
  //       name: 'Pohjois-Karjala',
  //     },
  //     {
  //       id: '321',
  //       code: 'Pohjois-Pohjanmaa',
  //       name: 'Pohjois-Pohjanmaa',
  //     },
  //     {
  //       id: '324',
  //       code: 'Pohjois-Savo',
  //       name: 'Pohjois-Savo',
  //     },
  //     {
  //       id: '329',
  //       code: 'Satakunta',
  //       name: 'Satakunta',
  //     },
  //     {
  //       id: '336',
  //       code: 'Uusimaa',
  //       name: 'Uusimaa',
  //     },
  //     {
  //       id: '332',
  //       code: 'Varsinais-Suomi',
  //       name: 'Varsinais-Suomi',
  //     },
  //   ],
  // },
  // {
  //   id: 'FJ',
  //   two_letter_abbreviation: 'FJ',
  //   three_letter_abbreviation: 'FJI',
  //   full_name_locale: 'Fiji',
  //   full_name_english: 'Fiji',
  // },
  // {
  //   id: 'FK',
  //   two_letter_abbreviation: 'FK',
  //   three_letter_abbreviation: 'FLK',
  //   full_name_locale: 'Falkland Islands',
  //   full_name_english: 'Falkland Islands',
  // },
  // {
  //   id: 'FM',
  //   two_letter_abbreviation: 'FM',
  //   three_letter_abbreviation: 'FSM',
  //   full_name_locale: 'Micronesia',
  //   full_name_english: 'Micronesia',
  // },
  // {
  //   id: 'FO',
  //   two_letter_abbreviation: 'FO',
  //   three_letter_abbreviation: 'FRO',
  //   full_name_locale: 'Faroe Islands',
  //   full_name_english: 'Faroe Islands',
  // },
  // {
  //   id: 'FR',
  //   two_letter_abbreviation: 'FR',
  //   three_letter_abbreviation: 'FRA',
  //   full_name_locale: 'France',
  //   full_name_english: 'France',
  //   available_regions: [
  //     {
  //       id: '182',
  //       code: '1',
  //       name: 'Ain',
  //     },
  //     {
  //       id: '183',
  //       code: '2',
  //       name: 'Aisne',
  //     },
  //     {
  //       id: '184',
  //       code: '3',
  //       name: 'Allier',
  //     },
  //     {
  //       id: '185',
  //       code: '4',
  //       name: 'Alpes-de-Haute-Provence',
  //     },
  //     {
  //       id: '187',
  //       code: '6',
  //       name: 'Alpes-Maritimes',
  //     },
  //     {
  //       id: '188',
  //       code: '7',
  //       name: 'Ardèche',
  //     },
  //     {
  //       id: '189',
  //       code: '8',
  //       name: 'Ardennes',
  //     },
  //     {
  //       id: '190',
  //       code: '9',
  //       name: 'Ariège',
  //     },
  //     {
  //       id: '191',
  //       code: '10',
  //       name: 'Aube',
  //     },
  //     {
  //       id: '192',
  //       code: '11',
  //       name: 'Aude',
  //     },
  //     {
  //       id: '193',
  //       code: '12',
  //       name: 'Aveyron',
  //     },
  //     {
  //       id: '249',
  //       code: '67',
  //       name: 'Bas-Rhin',
  //     },
  //     {
  //       id: '194',
  //       code: '13',
  //       name: 'Bouches-du-Rhône',
  //     },
  //     {
  //       id: '195',
  //       code: '14',
  //       name: 'Calvados',
  //     },
  //     {
  //       id: '196',
  //       code: '15',
  //       name: 'Cantal',
  //     },
  //     {
  //       id: '197',
  //       code: '16',
  //       name: 'Charente',
  //     },
  //     {
  //       id: '198',
  //       code: '17',
  //       name: 'Charente-Maritime',
  //     },
  //     {
  //       id: '199',
  //       code: '18',
  //       name: 'Cher',
  //     },
  //     {
  //       id: '200',
  //       code: '19',
  //       name: 'Corrèze',
  //     },
  //     {
  //       id: '201',
  //       code: '2A',
  //       name: 'Corse-du-Sud',
  //     },
  //     {
  //       id: '203',
  //       code: '21',
  //       name: "Côte-d'Or",
  //     },
  //     {
  //       id: '204',
  //       code: '22',
  //       name: "Côtes-d'Armor",
  //     },
  //     {
  //       id: '205',
  //       code: '23',
  //       name: 'Creuse',
  //     },
  //     {
  //       id: '261',
  //       code: '79',
  //       name: 'Deux-Sèvres',
  //     },
  //     {
  //       id: '206',
  //       code: '24',
  //       name: 'Dordogne',
  //     },
  //     {
  //       id: '207',
  //       code: '25',
  //       name: 'Doubs',
  //     },
  //     {
  //       id: '208',
  //       code: '26',
  //       name: 'Drôme',
  //     },
  //     {
  //       id: '273',
  //       code: '91',
  //       name: 'Essonne',
  //     },
  //     {
  //       id: '209',
  //       code: '27',
  //       name: 'Eure',
  //     },
  //     {
  //       id: '210',
  //       code: '28',
  //       name: 'Eure-et-Loir',
  //     },
  //     {
  //       id: '211',
  //       code: '29',
  //       name: 'Finistère',
  //     },
  //     {
  //       id: '212',
  //       code: '30',
  //       name: 'Gard',
  //     },
  //     {
  //       id: '214',
  //       code: '32',
  //       name: 'Gers',
  //     },
  //     {
  //       id: '215',
  //       code: '33',
  //       name: 'Gironde',
  //     },
  //     {
  //       id: '250',
  //       code: '68',
  //       name: 'Haut-Rhin',
  //     },
  //     {
  //       id: '202',
  //       code: '2B',
  //       name: 'Haute-Corse',
  //     },
  //     {
  //       id: '213',
  //       code: '31',
  //       name: 'Haute-Garonne',
  //     },
  //     {
  //       id: '225',
  //       code: '43',
  //       name: 'Haute-Loire',
  //     },
  //     {
  //       id: '234',
  //       code: '52',
  //       name: 'Haute-Marne',
  //     },
  //     {
  //       id: '252',
  //       code: '70',
  //       name: 'Haute-Saône',
  //     },
  //     {
  //       id: '256',
  //       code: '74',
  //       name: 'Haute-Savoie',
  //     },
  //     {
  //       id: '269',
  //       code: '87',
  //       name: 'Haute-Vienne',
  //     },
  //     {
  //       id: '186',
  //       code: '5',
  //       name: 'Hautes-Alpes',
  //     },
  //     {
  //       id: '247',
  //       code: '65',
  //       name: 'Hautes-Pyrénées',
  //     },
  //     {
  //       id: '274',
  //       code: '92',
  //       name: 'Hauts-de-Seine',
  //     },
  //     {
  //       id: '216',
  //       code: '34',
  //       name: 'Hérault',
  //     },
  //     {
  //       id: '217',
  //       code: '35',
  //       name: 'Ille-et-Vilaine',
  //     },
  //     {
  //       id: '218',
  //       code: '36',
  //       name: 'Indre',
  //     },
  //     {
  //       id: '219',
  //       code: '37',
  //       name: 'Indre-et-Loire',
  //     },
  //     {
  //       id: '220',
  //       code: '38',
  //       name: 'Isère',
  //     },
  //     {
  //       id: '221',
  //       code: '39',
  //       name: 'Jura',
  //     },
  //     {
  //       id: '222',
  //       code: '40',
  //       name: 'Landes',
  //     },
  //     {
  //       id: '223',
  //       code: '41',
  //       name: 'Loir-et-Cher',
  //     },
  //     {
  //       id: '224',
  //       code: '42',
  //       name: 'Loire',
  //     },
  //     {
  //       id: '226',
  //       code: '44',
  //       name: 'Loire-Atlantique',
  //     },
  //     {
  //       id: '227',
  //       code: '45',
  //       name: 'Loiret',
  //     },
  //     {
  //       id: '228',
  //       code: '46',
  //       name: 'Lot',
  //     },
  //     {
  //       id: '229',
  //       code: '47',
  //       name: 'Lot-et-Garonne',
  //     },
  //     {
  //       id: '230',
  //       code: '48',
  //       name: 'Lozère',
  //     },
  //     {
  //       id: '231',
  //       code: '49',
  //       name: 'Maine-et-Loire',
  //     },
  //     {
  //       id: '232',
  //       code: '50',
  //       name: 'Manche',
  //     },
  //     {
  //       id: '233',
  //       code: '51',
  //       name: 'Marne',
  //     },
  //     {
  //       id: '235',
  //       code: '53',
  //       name: 'Mayenne',
  //     },
  //     {
  //       id: '236',
  //       code: '54',
  //       name: 'Meurthe-et-Moselle',
  //     },
  //     {
  //       id: '237',
  //       code: '55',
  //       name: 'Meuse',
  //     },
  //     {
  //       id: '238',
  //       code: '56',
  //       name: 'Morbihan',
  //     },
  //     {
  //       id: '239',
  //       code: '57',
  //       name: 'Moselle',
  //     },
  //     {
  //       id: '240',
  //       code: '58',
  //       name: 'Nièvre',
  //     },
  //     {
  //       id: '241',
  //       code: '59',
  //       name: 'Nord',
  //     },
  //     {
  //       id: '242',
  //       code: '60',
  //       name: 'Oise',
  //     },
  //     {
  //       id: '243',
  //       code: '61',
  //       name: 'Orne',
  //     },
  //     {
  //       id: '257',
  //       code: '75',
  //       name: 'Paris',
  //     },
  //     {
  //       id: '244',
  //       code: '62',
  //       name: 'Pas-de-Calais',
  //     },
  //     {
  //       id: '245',
  //       code: '63',
  //       name: 'Puy-de-Dôme',
  //     },
  //     {
  //       id: '246',
  //       code: '64',
  //       name: 'Pyrénées-Atlantiques',
  //     },
  //     {
  //       id: '248',
  //       code: '66',
  //       name: 'Pyrénées-Orientales',
  //     },
  //     {
  //       id: '251',
  //       code: '69',
  //       name: 'Rhône',
  //     },
  //     {
  //       id: '253',
  //       code: '71',
  //       name: 'Saône-et-Loire',
  //     },
  //     {
  //       id: '254',
  //       code: '72',
  //       name: 'Sarthe',
  //     },
  //     {
  //       id: '255',
  //       code: '73',
  //       name: 'Savoie',
  //     },
  //     {
  //       id: '259',
  //       code: '77',
  //       name: 'Seine-et-Marne',
  //     },
  //     {
  //       id: '258',
  //       code: '76',
  //       name: 'Seine-Maritime',
  //     },
  //     {
  //       id: '275',
  //       code: '93',
  //       name: 'Seine-Saint-Denis',
  //     },
  //     {
  //       id: '262',
  //       code: '80',
  //       name: 'Somme',
  //     },
  //     {
  //       id: '263',
  //       code: '81',
  //       name: 'Tarn',
  //     },
  //     {
  //       id: '264',
  //       code: '82',
  //       name: 'Tarn-et-Garonne',
  //     },
  //     {
  //       id: '272',
  //       code: '90',
  //       name: 'Territoire-de-Belfort',
  //     },
  //     {
  //       id: '277',
  //       code: '95',
  //       name: "Val-d'Oise",
  //     },
  //     {
  //       id: '276',
  //       code: '94',
  //       name: 'Val-de-Marne',
  //     },
  //     {
  //       id: '265',
  //       code: '83',
  //       name: 'Var',
  //     },
  //     {
  //       id: '266',
  //       code: '84',
  //       name: 'Vaucluse',
  //     },
  //     {
  //       id: '267',
  //       code: '85',
  //       name: 'Vendée',
  //     },
  //     {
  //       id: '268',
  //       code: '86',
  //       name: 'Vienne',
  //     },
  //     {
  //       id: '270',
  //       code: '88',
  //       name: 'Vosges',
  //     },
  //     {
  //       id: '271',
  //       code: '89',
  //       name: 'Yonne',
  //     },
  //     {
  //       id: '260',
  //       code: '78',
  //       name: 'Yvelines',
  //     },
  //   ],
  // },
  // {
  //   id: 'GA',
  //   two_letter_abbreviation: 'GA',
  //   three_letter_abbreviation: 'GAB',
  //   full_name_locale: 'Gabon',
  //   full_name_english: 'Gabon',
  // },
  // {
  //   id: 'GB',
  //   two_letter_abbreviation: 'GB',
  //   three_letter_abbreviation: 'GBR',
  //   full_name_locale: 'United Kingdom',
  //   full_name_english: 'United Kingdom',
  // },
  // {
  //   id: 'GD',
  //   two_letter_abbreviation: 'GD',
  //   three_letter_abbreviation: 'GRD',
  //   full_name_locale: 'Grenada',
  //   full_name_english: 'Grenada',
  // },
  // {
  //   id: 'GE',
  //   two_letter_abbreviation: 'GE',
  //   three_letter_abbreviation: 'GEO',
  //   full_name_locale: 'Georgia',
  //   full_name_english: 'Georgia',
  // },
  // {
  //   id: 'GF',
  //   two_letter_abbreviation: 'GF',
  //   three_letter_abbreviation: 'GUF',
  //   full_name_locale: 'French Guiana',
  //   full_name_english: 'French Guiana',
  // },
  // {
  //   id: 'GG',
  //   two_letter_abbreviation: 'GG',
  //   three_letter_abbreviation: 'GGY',
  //   full_name_locale: 'Guernsey',
  //   full_name_english: 'Guernsey',
  // },
  // {
  //   id: 'GH',
  //   two_letter_abbreviation: 'GH',
  //   three_letter_abbreviation: 'GHA',
  //   full_name_locale: 'Ghana',
  //   full_name_english: 'Ghana',
  // },
  // {
  //   id: 'GI',
  //   two_letter_abbreviation: 'GI',
  //   three_letter_abbreviation: 'GIB',
  //   full_name_locale: 'Gibraltar',
  //   full_name_english: 'Gibraltar',
  // },
  // {
  //   id: 'GL',
  //   two_letter_abbreviation: 'GL',
  //   three_letter_abbreviation: 'GRL',
  //   full_name_locale: 'Greenland',
  //   full_name_english: 'Greenland',
  // },
  // {
  //   id: 'GM',
  //   two_letter_abbreviation: 'GM',
  //   three_letter_abbreviation: 'GMB',
  //   full_name_locale: 'Gambia',
  //   full_name_english: 'Gambia',
  // },
  // {
  //   id: 'GN',
  //   two_letter_abbreviation: 'GN',
  //   three_letter_abbreviation: 'GIN',
  //   full_name_locale: 'Guinea',
  //   full_name_english: 'Guinea',
  // },
  // {
  //   id: 'GP',
  //   two_letter_abbreviation: 'GP',
  //   three_letter_abbreviation: 'GLP',
  //   full_name_locale: 'Guadeloupe',
  //   full_name_english: 'Guadeloupe',
  // },
  // {
  //   id: 'GQ',
  //   two_letter_abbreviation: 'GQ',
  //   three_letter_abbreviation: 'GNQ',
  //   full_name_locale: 'Equatorial Guinea',
  //   full_name_english: 'Equatorial Guinea',
  // },
  // {
  //   id: 'GR',
  //   two_letter_abbreviation: 'GR',
  //   three_letter_abbreviation: 'GRC',
  //   full_name_locale: 'Greece',
  //   full_name_english: 'Greece',
  //   available_regions: [
  //     {
  //       id: '806',
  //       code: 'GR-69',
  //       name: 'Ágion Óros',
  //     },
  //     {
  //       id: '793',
  //       code: 'GR-A',
  //       name: 'Anatolikí Makedonía kai Thráki',
  //     },
  //     {
  //       id: '794',
  //       code: 'GR-I',
  //       name: 'Attikí',
  //     },
  //     {
  //       id: '795',
  //       code: 'GR-G',
  //       name: 'Dytikí Elláda',
  //     },
  //     {
  //       id: '796',
  //       code: 'GR-C',
  //       name: 'Dytikí Makedonía',
  //     },
  //     {
  //       id: '797',
  //       code: 'GR-F',
  //       name: 'Ionía Nísia',
  //     },
  //     {
  //       id: '798',
  //       code: 'GR-D',
  //       name: 'Ípeiros',
  //     },
  //     {
  //       id: '799',
  //       code: 'GR-B',
  //       name: 'Kentrikí Makedonía',
  //     },
  //     {
  //       id: '800',
  //       code: 'GR-M',
  //       name: 'Kríti',
  //     },
  //     {
  //       id: '801',
  //       code: 'GR-L',
  //       name: 'Nótio Aigaío',
  //     },
  //     {
  //       id: '802',
  //       code: 'GR-J',
  //       name: 'Pelopónnisos',
  //     },
  //     {
  //       id: '803',
  //       code: 'GR-H',
  //       name: 'Stereá Elláda',
  //     },
  //     {
  //       id: '804',
  //       code: 'GR-E',
  //       name: 'Thessalía',
  //     },
  //     {
  //       id: '805',
  //       code: 'GR-K',
  //       name: 'Vóreio Aigaío',
  //     },
  //   ],
  // },
  // {
  //   id: 'GS',
  //   two_letter_abbreviation: 'GS',
  //   three_letter_abbreviation: 'SGS',
  //   full_name_locale: 'South Georgia & South Sandwich Islands',
  //   full_name_english: 'South Georgia & South Sandwich Islands',
  // },
  // {
  //   id: 'GT',
  //   two_letter_abbreviation: 'GT',
  //   three_letter_abbreviation: 'GTM',
  //   full_name_locale: 'Guatemala',
  //   full_name_english: 'Guatemala',
  // },
  // {
  //   id: 'GU',
  //   two_letter_abbreviation: 'GU',
  //   three_letter_abbreviation: 'GUM',
  //   full_name_locale: 'Guam',
  //   full_name_english: 'Guam',
  // },
  // {
  //   id: 'GW',
  //   two_letter_abbreviation: 'GW',
  //   three_letter_abbreviation: 'GNB',
  //   full_name_locale: 'Guinea-Bissau',
  //   full_name_english: 'Guinea-Bissau',
  // },
  // {
  //   id: 'GY',
  //   two_letter_abbreviation: 'GY',
  //   three_letter_abbreviation: 'GUY',
  //   full_name_locale: 'Guyana',
  //   full_name_english: 'Guyana',
  //   available_regions: [
  //     {
  //       id: '807',
  //       code: 'GY-BA',
  //       name: 'Barima-Waini',
  //     },
  //     {
  //       id: '808',
  //       code: 'GY-CU',
  //       name: 'Cuyuni-Mazaruni',
  //     },
  //     {
  //       id: '809',
  //       code: 'GY-DE',
  //       name: 'Demerara-Mahaica',
  //     },
  //     {
  //       id: '810',
  //       code: 'GY-EB',
  //       name: 'East Berbice-Corentyne',
  //     },
  //     {
  //       id: '811',
  //       code: 'GY-ES',
  //       name: 'Essequibo Islands-West Demerara',
  //     },
  //     {
  //       id: '812',
  //       code: 'GY-MA',
  //       name: 'Mahaica-Berbice',
  //     },
  //     {
  //       id: '813',
  //       code: 'GY-PM',
  //       name: 'Pomeroon-Supenaam',
  //     },
  //     {
  //       id: '814',
  //       code: 'GY-PT',
  //       name: 'Potaro-Siparuni',
  //     },
  //     {
  //       id: '815',
  //       code: 'GY-UD',
  //       name: 'Upper Demerara-Berbice',
  //     },
  //     {
  //       id: '816',
  //       code: 'GY-UT',
  //       name: 'Upper Takutu-Upper Essequibo',
  //     },
  //   ],
  // },
  // {
  //   id: 'HK',
  //   two_letter_abbreviation: 'HK',
  //   three_letter_abbreviation: 'HKG',
  //   full_name_locale: 'Hong Kong SAR China',
  //   full_name_english: 'Hong Kong SAR China',
  // },
  // {
  //   id: 'HM',
  //   two_letter_abbreviation: 'HM',
  //   three_letter_abbreviation: 'HMD',
  //   full_name_locale: 'Heard & McDonald Islands',
  //   full_name_english: 'Heard & McDonald Islands',
  // },
  // {
  //   id: 'HN',
  //   two_letter_abbreviation: 'HN',
  //   three_letter_abbreviation: 'HND',
  //   full_name_locale: 'Honduras',
  //   full_name_english: 'Honduras',
  // },
  // {
  //   id: 'HR',
  //   two_letter_abbreviation: 'HR',
  //   three_letter_abbreviation: 'HRV',
  //   full_name_locale: 'Croatia',
  //   full_name_english: 'Croatia',
  //   available_regions: [
  //     {
  //       id: '554',
  //       code: 'HR-07',
  //       name: 'Bjelovarsko-bilogorska županija',
  //     },
  //     {
  //       id: '559',
  //       code: 'HR-12',
  //       name: 'Brodsko-posavska županija',
  //     },
  //     {
  //       id: '566',
  //       code: 'HR-19',
  //       name: 'Dubrovačko-neretvanska županija',
  //     },
  //     {
  //       id: '568',
  //       code: 'HR-21',
  //       name: 'Grad Zagreb',
  //     },
  //     {
  //       id: '565',
  //       code: 'HR-18',
  //       name: 'Istarska županija',
  //     },
  //     {
  //       id: '551',
  //       code: 'HR-04',
  //       name: 'Karlovačka županija',
  //     },
  //     {
  //       id: '553',
  //       code: 'HR-06',
  //       name: 'Koprivničko-križevačka županija',
  //     },
  //     {
  //       id: '549',
  //       code: 'HR-02',
  //       name: 'Krapinsko-zagorska županija',
  //     },
  //     {
  //       id: '556',
  //       code: 'HR-09',
  //       name: 'Ličko-senjska županija',
  //     },
  //     {
  //       id: '567',
  //       code: 'HR-20',
  //       name: 'Međimurska županija',
  //     },
  //     {
  //       id: '561',
  //       code: 'HR-14',
  //       name: 'Osječko-baranjska županija',
  //     },
  //     {
  //       id: '558',
  //       code: 'HR-11',
  //       name: 'Požeško-slavonska županija',
  //     },
  //     {
  //       id: '555',
  //       code: 'HR-08',
  //       name: 'Primorsko-goranska županija',
  //     },
  //     {
  //       id: '562',
  //       code: 'HR-15',
  //       name: 'Šibensko-kninska županija',
  //     },
  //     {
  //       id: '550',
  //       code: 'HR-03',
  //       name: 'Sisačko-moslavačka županija',
  //     },
  //     {
  //       id: '564',
  //       code: 'HR-17',
  //       name: 'Splitsko-dalmatinska županija',
  //     },
  //     {
  //       id: '552',
  //       code: 'HR-05',
  //       name: 'Varaždinska županija',
  //     },
  //     {
  //       id: '557',
  //       code: 'HR-10',
  //       name: 'Virovitičko-podravska županija',
  //     },
  //     {
  //       id: '563',
  //       code: 'HR-16',
  //       name: 'Vukovarsko-srijemska županija',
  //     },
  //     {
  //       id: '560',
  //       code: 'HR-13',
  //       name: 'Zadarska županija',
  //     },
  //     {
  //       id: '548',
  //       code: 'HR-01',
  //       name: 'Zagrebačka županija',
  //     },
  //   ],
  // },
  // {
  //   id: 'HT',
  //   two_letter_abbreviation: 'HT',
  //   three_letter_abbreviation: 'HTI',
  //   full_name_locale: 'Haiti',
  //   full_name_english: 'Haiti',
  // },
  // {
  //   id: 'HU',
  //   two_letter_abbreviation: 'HU',
  //   three_letter_abbreviation: 'HUN',
  //   full_name_locale: 'Hungary',
  //   full_name_english: 'Hungary',
  // },
  // {
  //   id: 'ID',
  //   two_letter_abbreviation: 'ID',
  //   three_letter_abbreviation: 'IDN',
  //   full_name_locale: 'Indonesia',
  //   full_name_english: 'Indonesia',
  // },
  // {
  //   id: 'IE',
  //   two_letter_abbreviation: 'IE',
  //   three_letter_abbreviation: 'IRL',
  //   full_name_locale: 'Ireland',
  //   full_name_english: 'Ireland',
  // },
  // {
  //   id: 'IL',
  //   two_letter_abbreviation: 'IL',
  //   three_letter_abbreviation: 'ISR',
  //   full_name_locale: 'Israel',
  //   full_name_english: 'Israel',
  // },
  // {
  //   id: 'IM',
  //   two_letter_abbreviation: 'IM',
  //   three_letter_abbreviation: 'IMN',
  //   full_name_locale: 'Isle of Man',
  //   full_name_english: 'Isle of Man',
  // },
  // {
  //   id: 'IN',
  //   two_letter_abbreviation: 'IN',
  //   three_letter_abbreviation: 'IND',
  //   full_name_locale: 'India',
  //   full_name_english: 'India',
  //   available_regions: [
  //     {
  //       id: '569',
  //       code: 'AN',
  //       name: 'Andaman and Nicobar Islands',
  //     },
  //     {
  //       id: '570',
  //       code: 'AP',
  //       name: 'Andhra Pradesh',
  //     },
  //     {
  //       id: '571',
  //       code: 'AR',
  //       name: 'Arunachal Pradesh',
  //     },
  //     {
  //       id: '572',
  //       code: 'AS',
  //       name: 'Assam',
  //     },
  //     {
  //       id: '573',
  //       code: 'BR',
  //       name: 'Bihar',
  //     },
  //     {
  //       id: '574',
  //       code: 'CH',
  //       name: 'Chandigarh',
  //     },
  //     {
  //       id: '575',
  //       code: 'CT',
  //       name: 'Chhattisgarh',
  //     },
  //     {
  //       id: '576',
  //       code: 'DN',
  //       name: 'Dadra and Nagar Haveli',
  //     },
  //     {
  //       id: '577',
  //       code: 'DD',
  //       name: 'Daman and Diu',
  //     },
  //     {
  //       id: '578',
  //       code: 'DL',
  //       name: 'Delhi',
  //     },
  //     {
  //       id: '579',
  //       code: 'GA',
  //       name: 'Goa',
  //     },
  //     {
  //       id: '580',
  //       code: 'GJ',
  //       name: 'Gujarat',
  //     },
  //     {
  //       id: '581',
  //       code: 'HR',
  //       name: 'Haryana',
  //     },
  //     {
  //       id: '582',
  //       code: 'HP',
  //       name: 'Himachal Pradesh',
  //     },
  //     {
  //       id: '583',
  //       code: 'JK',
  //       name: 'Jammu and Kashmir',
  //     },
  //     {
  //       id: '584',
  //       code: 'JH',
  //       name: 'Jharkhand',
  //     },
  //     {
  //       id: '585',
  //       code: 'KA',
  //       name: 'Karnataka',
  //     },
  //     {
  //       id: '586',
  //       code: 'KL',
  //       name: 'Kerala',
  //     },
  //     {
  //       id: '587',
  //       code: 'LD',
  //       name: 'Lakshadweep',
  //     },
  //     {
  //       id: '588',
  //       code: 'MP',
  //       name: 'Madhya Pradesh',
  //     },
  //     {
  //       id: '589',
  //       code: 'MH',
  //       name: 'Maharashtra',
  //     },
  //     {
  //       id: '590',
  //       code: 'MN',
  //       name: 'Manipur',
  //     },
  //     {
  //       id: '591',
  //       code: 'ML',
  //       name: 'Meghalaya',
  //     },
  //     {
  //       id: '592',
  //       code: 'MZ',
  //       name: 'Mizoram',
  //     },
  //     {
  //       id: '593',
  //       code: 'NL',
  //       name: 'Nagaland',
  //     },
  //     {
  //       id: '594',
  //       code: 'OR',
  //       name: 'Odisha',
  //     },
  //     {
  //       id: '595',
  //       code: 'PY',
  //       name: 'Puducherry',
  //     },
  //     {
  //       id: '596',
  //       code: 'PB',
  //       name: 'Punjab',
  //     },
  //     {
  //       id: '597',
  //       code: 'RJ',
  //       name: 'Rajasthan',
  //     },
  //     {
  //       id: '598',
  //       code: 'SK',
  //       name: 'Sikkim',
  //     },
  //     {
  //       id: '599',
  //       code: 'TN',
  //       name: 'Tamil Nadu',
  //     },
  //     {
  //       id: '600',
  //       code: 'TG',
  //       name: 'Telangana',
  //     },
  //     {
  //       id: '601',
  //       code: 'TR',
  //       name: 'Tripura',
  //     },
  //     {
  //       id: '602',
  //       code: 'UP',
  //       name: 'Uttar Pradesh',
  //     },
  //     {
  //       id: '603',
  //       code: 'UT',
  //       name: 'Uttarakhand',
  //     },
  //     {
  //       id: '604',
  //       code: 'WB',
  //       name: 'West Bengal',
  //     },
  //   ],
  // },
  // {
  //   id: 'IO',
  //   two_letter_abbreviation: 'IO',
  //   three_letter_abbreviation: 'IOT',
  //   full_name_locale: 'British Indian Ocean Territory',
  //   full_name_english: 'British Indian Ocean Territory',
  // },
  // {
  //   id: 'IQ',
  //   two_letter_abbreviation: 'IQ',
  //   three_letter_abbreviation: 'IRQ',
  //   full_name_locale: 'Iraq',
  //   full_name_english: 'Iraq',
  // },
  // {
  //   id: 'IR',
  //   two_letter_abbreviation: 'IR',
  //   three_letter_abbreviation: 'IRN',
  //   full_name_locale: 'Iran',
  //   full_name_english: 'Iran',
  // },
  // {
  //   id: 'IS',
  //   two_letter_abbreviation: 'IS',
  //   three_letter_abbreviation: 'ISL',
  //   full_name_locale: 'Iceland',
  //   full_name_english: 'Iceland',
  //   available_regions: [
  //     {
  //       id: '823',
  //       code: 'IS-07',
  //       name: 'Austurland',
  //     },
  //     {
  //       id: '817',
  //       code: 'IS-01',
  //       name: 'Höfuðborgarsvæði',
  //     },
  //     {
  //       id: '822',
  //       code: 'IS-06',
  //       name: 'Norðurland eystra',
  //     },
  //     {
  //       id: '821',
  //       code: 'IS-05',
  //       name: 'Norðurland vestra',
  //     },
  //     {
  //       id: '824',
  //       code: 'IS-08',
  //       name: 'Suðurland',
  //     },
  //     {
  //       id: '818',
  //       code: 'IS-02',
  //       name: 'Suðurnes',
  //     },
  //     {
  //       id: '820',
  //       code: 'IS-04',
  //       name: 'Vestfirðir',
  //     },
  //     {
  //       id: '819',
  //       code: 'IS-03',
  //       name: 'Vesturland',
  //     },
  //   ],
  // },
  // {
  //   id: 'IT',
  //   two_letter_abbreviation: 'IT',
  //   three_letter_abbreviation: 'ITA',
  //   full_name_locale: 'Italy',
  //   full_name_english: 'Italy',
  //   available_regions: [
  //     {
  //       id: '825',
  //       code: 'AG',
  //       name: 'Agrigento',
  //     },
  //     {
  //       id: '826',
  //       code: 'AL',
  //       name: 'Alessandria',
  //     },
  //     {
  //       id: '827',
  //       code: 'AN',
  //       name: 'Ancona',
  //     },
  //     {
  //       id: '828',
  //       code: 'AO',
  //       name: 'Aosta',
  //     },
  //     {
  //       id: '830',
  //       code: 'AR',
  //       name: 'Arezzo',
  //     },
  //     {
  //       id: '831',
  //       code: 'AP',
  //       name: 'Ascoli-Piceno',
  //     },
  //     {
  //       id: '832',
  //       code: 'AT',
  //       name: 'Asti',
  //     },
  //     {
  //       id: '833',
  //       code: 'AV',
  //       name: 'Avellino',
  //     },
  //     {
  //       id: '834',
  //       code: 'BA',
  //       name: 'Bari',
  //     },
  //     {
  //       id: '835',
  //       code: 'BT',
  //       name: 'Barletta-Andria-Trani',
  //     },
  //     {
  //       id: '836',
  //       code: 'BL',
  //       name: 'Belluno',
  //     },
  //     {
  //       id: '837',
  //       code: 'BN',
  //       name: 'Benevento',
  //     },
  //     {
  //       id: '838',
  //       code: 'BG',
  //       name: 'Bergamo',
  //     },
  //     {
  //       id: '839',
  //       code: 'BI',
  //       name: 'Biella',
  //     },
  //     {
  //       id: '840',
  //       code: 'BO',
  //       name: 'Bologna',
  //     },
  //     {
  //       id: '841',
  //       code: 'BZ',
  //       name: 'Bolzano',
  //     },
  //     {
  //       id: '842',
  //       code: 'BS',
  //       name: 'Brescia',
  //     },
  //     {
  //       id: '843',
  //       code: 'BR',
  //       name: 'Brindisi',
  //     },
  //     {
  //       id: '844',
  //       code: 'CA',
  //       name: 'Cagliari',
  //     },
  //     {
  //       id: '845',
  //       code: 'CL',
  //       name: 'Caltanissetta',
  //     },
  //     {
  //       id: '846',
  //       code: 'CB',
  //       name: 'Campobasso',
  //     },
  //     {
  //       id: '847',
  //       code: 'CI',
  //       name: 'Carbonia Iglesias',
  //     },
  //     {
  //       id: '848',
  //       code: 'CE',
  //       name: 'Caserta',
  //     },
  //     {
  //       id: '849',
  //       code: 'CT',
  //       name: 'Catania',
  //     },
  //     {
  //       id: '850',
  //       code: 'CZ',
  //       name: 'Catanzaro',
  //     },
  //     {
  //       id: '851',
  //       code: 'CH',
  //       name: 'Chieti',
  //     },
  //     {
  //       id: '852',
  //       code: 'CO',
  //       name: 'Como',
  //     },
  //     {
  //       id: '853',
  //       code: 'CS',
  //       name: 'Cosenza',
  //     },
  //     {
  //       id: '854',
  //       code: 'CR',
  //       name: 'Cremona',
  //     },
  //     {
  //       id: '855',
  //       code: 'KR',
  //       name: 'Crotone',
  //     },
  //     {
  //       id: '856',
  //       code: 'CN',
  //       name: 'Cuneo',
  //     },
  //     {
  //       id: '857',
  //       code: 'EN',
  //       name: 'Enna',
  //     },
  //     {
  //       id: '858',
  //       code: 'FM',
  //       name: 'Fermo',
  //     },
  //     {
  //       id: '859',
  //       code: 'FE',
  //       name: 'Ferrara',
  //     },
  //     {
  //       id: '860',
  //       code: 'FI',
  //       name: 'Firenze',
  //     },
  //     {
  //       id: '861',
  //       code: 'FG',
  //       name: 'Foggia',
  //     },
  //     {
  //       id: '862',
  //       code: 'FC',
  //       name: 'Forli-Cesena',
  //     },
  //     {
  //       id: '863',
  //       code: 'FR',
  //       name: 'Frosinone',
  //     },
  //     {
  //       id: '864',
  //       code: 'GE',
  //       name: 'Genova',
  //     },
  //     {
  //       id: '865',
  //       code: 'GO',
  //       name: 'Gorizia',
  //     },
  //     {
  //       id: '866',
  //       code: 'GR',
  //       name: 'Grosseto',
  //     },
  //     {
  //       id: '867',
  //       code: 'IM',
  //       name: 'Imperia',
  //     },
  //     {
  //       id: '868',
  //       code: 'IS',
  //       name: 'Isernia',
  //     },
  //     {
  //       id: '829',
  //       code: 'AQ',
  //       name: "L'Aquila",
  //     },
  //     {
  //       id: '869',
  //       code: 'SP',
  //       name: 'La-Spezia',
  //     },
  //     {
  //       id: '870',
  //       code: 'LT',
  //       name: 'Latina',
  //     },
  //     {
  //       id: '871',
  //       code: 'LE',
  //       name: 'Lecce',
  //     },
  //     {
  //       id: '872',
  //       code: 'LC',
  //       name: 'Lecco',
  //     },
  //     {
  //       id: '873',
  //       code: 'LI',
  //       name: 'Livorno',
  //     },
  //     {
  //       id: '874',
  //       code: 'LO',
  //       name: 'Lodi',
  //     },
  //     {
  //       id: '875',
  //       code: 'LU',
  //       name: 'Lucca',
  //     },
  //     {
  //       id: '876',
  //       code: 'MC',
  //       name: 'Macerata',
  //     },
  //     {
  //       id: '877',
  //       code: 'MN',
  //       name: 'Mantova',
  //     },
  //     {
  //       id: '878',
  //       code: 'MS',
  //       name: 'Massa-Carrara',
  //     },
  //     {
  //       id: '879',
  //       code: 'MT',
  //       name: 'Matera',
  //     },
  //     {
  //       id: '880',
  //       code: 'VS',
  //       name: 'Medio Campidano',
  //     },
  //     {
  //       id: '881',
  //       code: 'ME',
  //       name: 'Messina',
  //     },
  //     {
  //       id: '882',
  //       code: 'MI',
  //       name: 'Milano',
  //     },
  //     {
  //       id: '883',
  //       code: 'MO',
  //       name: 'Modena',
  //     },
  //     {
  //       id: '884',
  //       code: 'MB',
  //       name: 'Monza-Brianza',
  //     },
  //     {
  //       id: '885',
  //       code: 'NA',
  //       name: 'Napoli',
  //     },
  //     {
  //       id: '886',
  //       code: 'NO',
  //       name: 'Novara',
  //     },
  //     {
  //       id: '887',
  //       code: 'NU',
  //       name: 'Nuoro',
  //     },
  //     {
  //       id: '888',
  //       code: 'OG',
  //       name: 'Ogliastra',
  //     },
  //     {
  //       id: '889',
  //       code: 'OT',
  //       name: 'Olbia Tempio',
  //     },
  //     {
  //       id: '890',
  //       code: 'OR',
  //       name: 'Oristano',
  //     },
  //     {
  //       id: '891',
  //       code: 'PD',
  //       name: 'Padova',
  //     },
  //     {
  //       id: '892',
  //       code: 'PA',
  //       name: 'Palermo',
  //     },
  //     {
  //       id: '893',
  //       code: 'PR',
  //       name: 'Parma',
  //     },
  //     {
  //       id: '894',
  //       code: 'PV',
  //       name: 'Pavia',
  //     },
  //     {
  //       id: '895',
  //       code: 'PG',
  //       name: 'Perugia',
  //     },
  //     {
  //       id: '896',
  //       code: 'PU',
  //       name: 'Pesaro-Urbino',
  //     },
  //     {
  //       id: '897',
  //       code: 'PE',
  //       name: 'Pescara',
  //     },
  //     {
  //       id: '898',
  //       code: 'PC',
  //       name: 'Piacenza',
  //     },
  //     {
  //       id: '899',
  //       code: 'PI',
  //       name: 'Pisa',
  //     },
  //     {
  //       id: '900',
  //       code: 'PT',
  //       name: 'Pistoia',
  //     },
  //     {
  //       id: '901',
  //       code: 'PN',
  //       name: 'Pordenone',
  //     },
  //     {
  //       id: '902',
  //       code: 'PZ',
  //       name: 'Potenza',
  //     },
  //     {
  //       id: '903',
  //       code: 'PO',
  //       name: 'Prato',
  //     },
  //     {
  //       id: '904',
  //       code: 'RG',
  //       name: 'Ragusa',
  //     },
  //     {
  //       id: '905',
  //       code: 'RA',
  //       name: 'Ravenna',
  //     },
  //     {
  //       id: '906',
  //       code: 'RC',
  //       name: 'Reggio-Calabria',
  //     },
  //     {
  //       id: '907',
  //       code: 'RE',
  //       name: 'Reggio-Emilia',
  //     },
  //     {
  //       id: '908',
  //       code: 'RI',
  //       name: 'Rieti',
  //     },
  //     {
  //       id: '909',
  //       code: 'RN',
  //       name: 'Rimini',
  //     },
  //     {
  //       id: '910',
  //       code: 'RM',
  //       name: 'Roma',
  //     },
  //     {
  //       id: '911',
  //       code: 'RO',
  //       name: 'Rovigo',
  //     },
  //     {
  //       id: '912',
  //       code: 'SA',
  //       name: 'Salerno',
  //     },
  //     {
  //       id: '913',
  //       code: 'SS',
  //       name: 'Sassari',
  //     },
  //     {
  //       id: '914',
  //       code: 'SV',
  //       name: 'Savona',
  //     },
  //     {
  //       id: '915',
  //       code: 'SI',
  //       name: 'Siena',
  //     },
  //     {
  //       id: '916',
  //       code: 'SR',
  //       name: 'Siracusa',
  //     },
  //     {
  //       id: '917',
  //       code: 'SO',
  //       name: 'Sondrio',
  //     },
  //     {
  //       id: '918',
  //       code: 'TA',
  //       name: 'Taranto',
  //     },
  //     {
  //       id: '919',
  //       code: 'TE',
  //       name: 'Teramo',
  //     },
  //     {
  //       id: '920',
  //       code: 'TR',
  //       name: 'Terni',
  //     },
  //     {
  //       id: '921',
  //       code: 'TO',
  //       name: 'Torino',
  //     },
  //     {
  //       id: '922',
  //       code: 'TP',
  //       name: 'Trapani',
  //     },
  //     {
  //       id: '923',
  //       code: 'TN',
  //       name: 'Trento',
  //     },
  //     {
  //       id: '924',
  //       code: 'TV',
  //       name: 'Treviso',
  //     },
  //     {
  //       id: '925',
  //       code: 'TS',
  //       name: 'Trieste',
  //     },
  //     {
  //       id: '926',
  //       code: 'UD',
  //       name: 'Udine',
  //     },
  //     {
  //       id: '927',
  //       code: 'VA',
  //       name: 'Varese',
  //     },
  //     {
  //       id: '928',
  //       code: 'VE',
  //       name: 'Venezia',
  //     },
  //     {
  //       id: '929',
  //       code: 'VB',
  //       name: 'Verbania',
  //     },
  //     {
  //       id: '930',
  //       code: 'VC',
  //       name: 'Vercelli',
  //     },
  //     {
  //       id: '931',
  //       code: 'VR',
  //       name: 'Verona',
  //     },
  //     {
  //       id: '932',
  //       code: 'VV',
  //       name: 'Vibo-Valentia',
  //     },
  //     {
  //       id: '933',
  //       code: 'VI',
  //       name: 'Vicenza',
  //     },
  //     {
  //       id: '934',
  //       code: 'VT',
  //       name: 'Viterbo',
  //     },
  //   ],
  // },
  // {
  //   id: 'JE',
  //   two_letter_abbreviation: 'JE',
  //   three_letter_abbreviation: 'JEY',
  //   full_name_locale: 'Jersey',
  //   full_name_english: 'Jersey',
  // },
  // {
  //   id: 'JM',
  //   two_letter_abbreviation: 'JM',
  //   three_letter_abbreviation: 'JAM',
  //   full_name_locale: 'Jamaica',
  //   full_name_english: 'Jamaica',
  // },
  // {
  //   id: 'JO',
  //   two_letter_abbreviation: 'JO',
  //   three_letter_abbreviation: 'JOR',
  //   full_name_locale: 'Jordan',
  //   full_name_english: 'Jordan',
  // },
  // {
  //   id: 'JP',
  //   two_letter_abbreviation: 'JP',
  //   three_letter_abbreviation: 'JPN',
  //   full_name_locale: 'Japan',
  //   full_name_english: 'Japan',
  // },
  // {
  //   id: 'KE',
  //   two_letter_abbreviation: 'KE',
  //   three_letter_abbreviation: 'KEN',
  //   full_name_locale: 'Kenya',
  //   full_name_english: 'Kenya',
  // },
  // {
  //   id: 'KG',
  //   two_letter_abbreviation: 'KG',
  //   three_letter_abbreviation: 'KGZ',
  //   full_name_locale: 'Kyrgyzstan',
  //   full_name_english: 'Kyrgyzstan',
  // },
  // {
  //   id: 'KH',
  //   two_letter_abbreviation: 'KH',
  //   three_letter_abbreviation: 'KHM',
  //   full_name_locale: 'Cambodia',
  //   full_name_english: 'Cambodia',
  // },
  // {
  //   id: 'KI',
  //   two_letter_abbreviation: 'KI',
  //   three_letter_abbreviation: 'KIR',
  //   full_name_locale: 'Kiribati',
  //   full_name_english: 'Kiribati',
  // },
  // {
  //   id: 'KM',
  //   two_letter_abbreviation: 'KM',
  //   three_letter_abbreviation: 'COM',
  //   full_name_locale: 'Comoros',
  //   full_name_english: 'Comoros',
  // },
  // {
  //   id: 'KN',
  //   two_letter_abbreviation: 'KN',
  //   three_letter_abbreviation: 'KNA',
  //   full_name_locale: 'St. Kitts & Nevis',
  //   full_name_english: 'St. Kitts & Nevis',
  // },
  // {
  //   id: 'KP',
  //   two_letter_abbreviation: 'KP',
  //   three_letter_abbreviation: 'PRK',
  //   full_name_locale: 'North Korea',
  //   full_name_english: 'North Korea',
  // },
  // {
  //   id: 'KR',
  //   two_letter_abbreviation: 'KR',
  //   three_letter_abbreviation: 'KOR',
  //   full_name_locale: 'South Korea',
  //   full_name_english: 'South Korea',
  // },
  // {
  //   id: 'KW',
  //   two_letter_abbreviation: 'KW',
  //   three_letter_abbreviation: 'KWT',
  //   full_name_locale: 'Kuwait',
  //   full_name_english: 'Kuwait',
  // },
  // {
  //   id: 'KY',
  //   two_letter_abbreviation: 'KY',
  //   three_letter_abbreviation: 'CYM',
  //   full_name_locale: 'Cayman Islands',
  //   full_name_english: 'Cayman Islands',
  // },
  // {
  //   id: 'KZ',
  //   two_letter_abbreviation: 'KZ',
  //   three_letter_abbreviation: 'KAZ',
  //   full_name_locale: 'Kazakhstan',
  //   full_name_english: 'Kazakhstan',
  // },
  // {
  //   id: 'LA',
  //   two_letter_abbreviation: 'LA',
  //   three_letter_abbreviation: 'LAO',
  //   full_name_locale: 'Laos',
  //   full_name_english: 'Laos',
  // },
  // {
  //   id: 'LB',
  //   two_letter_abbreviation: 'LB',
  //   three_letter_abbreviation: 'LBN',
  //   full_name_locale: 'Lebanon',
  //   full_name_english: 'Lebanon',
  // },
  // {
  //   id: 'LC',
  //   two_letter_abbreviation: 'LC',
  //   three_letter_abbreviation: 'LCA',
  //   full_name_locale: 'St. Lucia',
  //   full_name_english: 'St. Lucia',
  // },
  // {
  //   id: 'LI',
  //   two_letter_abbreviation: 'LI',
  //   three_letter_abbreviation: 'LIE',
  //   full_name_locale: 'Liechtenstein',
  //   full_name_english: 'Liechtenstein',
  // },
  // {
  //   id: 'LK',
  //   two_letter_abbreviation: 'LK',
  //   three_letter_abbreviation: 'LKA',
  //   full_name_locale: 'Sri Lanka',
  //   full_name_english: 'Sri Lanka',
  // },
  // {
  //   id: 'LR',
  //   two_letter_abbreviation: 'LR',
  //   three_letter_abbreviation: 'LBR',
  //   full_name_locale: 'Liberia',
  //   full_name_english: 'Liberia',
  // },
  // {
  //   id: 'LS',
  //   two_letter_abbreviation: 'LS',
  //   three_letter_abbreviation: 'LSO',
  //   full_name_locale: 'Lesotho',
  //   full_name_english: 'Lesotho',
  // },
  // {
  //   id: 'LT',
  //   two_letter_abbreviation: 'LT',
  //   three_letter_abbreviation: 'LTU',
  //   full_name_locale: 'Lithuania',
  //   full_name_english: 'Lithuania',
  //   available_regions: [
  //     {
  //       id: '475',
  //       code: 'LT-AL',
  //       name: 'Alytaus Apskritis',
  //     },
  //     {
  //       id: '476',
  //       code: 'LT-KU',
  //       name: 'Kauno Apskritis',
  //     },
  //     {
  //       id: '477',
  //       code: 'LT-KL',
  //       name: 'Klaipėdos Apskritis',
  //     },
  //     {
  //       id: '478',
  //       code: 'LT-MR',
  //       name: 'Marijampolės Apskritis',
  //     },
  //     {
  //       id: '479',
  //       code: 'LT-PN',
  //       name: 'Panevėžio Apskritis',
  //     },
  //     {
  //       id: '480',
  //       code: 'LT-SA',
  //       name: 'Šiaulių Apskritis',
  //     },
  //     {
  //       id: '481',
  //       code: 'LT-TA',
  //       name: 'Tauragės Apskritis',
  //     },
  //     {
  //       id: '482',
  //       code: 'LT-TE',
  //       name: 'Telšių Apskritis',
  //     },
  //     {
  //       id: '483',
  //       code: 'LT-UT',
  //       name: 'Utenos Apskritis',
  //     },
  //     {
  //       id: '484',
  //       code: 'LT-VL',
  //       name: 'Vilniaus Apskritis',
  //     },
  //   ],
  // },
  // {
  //   id: 'LU',
  //   two_letter_abbreviation: 'LU',
  //   three_letter_abbreviation: 'LUX',
  //   full_name_locale: 'Luxembourg',
  //   full_name_english: 'Luxembourg',
  // },
  // {
  //   id: 'LV',
  //   two_letter_abbreviation: 'LV',
  //   three_letter_abbreviation: 'LVA',
  //   full_name_locale: 'Latvia',
  //   full_name_english: 'Latvia',
  //   available_regions: [
  //     {
  //       id: '471',
  //       code: 'Ādažu novads',
  //       name: 'Ādažu novads',
  //     },
  //     {
  //       id: '366',
  //       code: 'Aglonas novads',
  //       name: 'Aglonas novads',
  //     },
  //     {
  //       id: '367',
  //       code: 'LV-AI',
  //       name: 'Aizkraukles novads',
  //     },
  //     {
  //       id: '368',
  //       code: 'Aizputes novads',
  //       name: 'Aizputes novads',
  //     },
  //     {
  //       id: '369',
  //       code: 'Aknīstes novads',
  //       name: 'Aknīstes novads',
  //     },
  //     {
  //       id: '370',
  //       code: 'Alojas novads',
  //       name: 'Alojas novads',
  //     },
  //     {
  //       id: '371',
  //       code: 'Alsungas novads',
  //       name: 'Alsungas novads',
  //     },
  //     {
  //       id: '372',
  //       code: 'LV-AL',
  //       name: 'Alūksnes novads',
  //     },
  //     {
  //       id: '373',
  //       code: 'Amatas novads',
  //       name: 'Amatas novads',
  //     },
  //     {
  //       id: '374',
  //       code: 'Apes novads',
  //       name: 'Apes novads',
  //     },
  //     {
  //       id: '375',
  //       code: 'Auces novads',
  //       name: 'Auces novads',
  //     },
  //     {
  //       id: '376',
  //       code: 'Babītes novads',
  //       name: 'Babītes novads',
  //     },
  //     {
  //       id: '377',
  //       code: 'Baldones novads',
  //       name: 'Baldones novads',
  //     },
  //     {
  //       id: '378',
  //       code: 'Baltinavas novads',
  //       name: 'Baltinavas novads',
  //     },
  //     {
  //       id: '379',
  //       code: 'LV-BL',
  //       name: 'Balvu novads',
  //     },
  //     {
  //       id: '380',
  //       code: 'LV-BU',
  //       name: 'Bauskas novads',
  //     },
  //     {
  //       id: '381',
  //       code: 'Beverīnas novads',
  //       name: 'Beverīnas novads',
  //     },
  //     {
  //       id: '382',
  //       code: 'Brocēnu novads',
  //       name: 'Brocēnu novads',
  //     },
  //     {
  //       id: '383',
  //       code: 'Burtnieku novads',
  //       name: 'Burtnieku novads',
  //     },
  //     {
  //       id: '384',
  //       code: 'Carnikavas novads',
  //       name: 'Carnikavas novads',
  //     },
  //     {
  //       id: '387',
  //       code: 'LV-CE',
  //       name: 'Cēsu novads',
  //     },
  //     {
  //       id: '385',
  //       code: 'Cesvaines novads',
  //       name: 'Cesvaines novads',
  //     },
  //     {
  //       id: '386',
  //       code: 'Ciblas novads',
  //       name: 'Ciblas novads',
  //     },
  //     {
  //       id: '388',
  //       code: 'Dagdas novads',
  //       name: 'Dagdas novads',
  //     },
  //     {
  //       id: '355',
  //       code: 'LV-DGV',
  //       name: 'Daugavpils',
  //     },
  //     {
  //       id: '389',
  //       code: 'LV-DA',
  //       name: 'Daugavpils novads',
  //     },
  //     {
  //       id: '390',
  //       code: 'LV-DO',
  //       name: 'Dobeles novads',
  //     },
  //     {
  //       id: '391',
  //       code: 'Dundagas novads',
  //       name: 'Dundagas novads',
  //     },
  //     {
  //       id: '392',
  //       code: 'Durbes novads',
  //       name: 'Durbes novads',
  //     },
  //     {
  //       id: '393',
  //       code: 'Engures novads',
  //       name: 'Engures novads',
  //     },
  //     {
  //       id: '472',
  //       code: 'Ērgļu novads',
  //       name: 'Ērgļu novads',
  //     },
  //     {
  //       id: '394',
  //       code: 'Garkalnes novads',
  //       name: 'Garkalnes novads',
  //     },
  //     {
  //       id: '395',
  //       code: 'Grobiņas novads',
  //       name: 'Grobiņas novads',
  //     },
  //     {
  //       id: '396',
  //       code: 'LV-GU',
  //       name: 'Gulbenes novads',
  //     },
  //     {
  //       id: '397',
  //       code: 'Iecavas novads',
  //       name: 'Iecavas novads',
  //     },
  //     {
  //       id: '398',
  //       code: 'Ikšķiles novads',
  //       name: 'Ikšķiles novads',
  //     },
  //     {
  //       id: '399',
  //       code: 'Ilūkstes novads',
  //       name: 'Ilūkstes novads',
  //     },
  //     {
  //       id: '400',
  //       code: 'Inčukalna novads',
  //       name: 'Inčukalna novads',
  //     },
  //     {
  //       id: '401',
  //       code: 'Jaunjelgavas novads',
  //       name: 'Jaunjelgavas novads',
  //     },
  //     {
  //       id: '402',
  //       code: 'Jaunpiebalgas novads',
  //       name: 'Jaunpiebalgas novads',
  //     },
  //     {
  //       id: '403',
  //       code: 'Jaunpils novads',
  //       name: 'Jaunpils novads',
  //     },
  //     {
  //       id: '357',
  //       code: 'Jēkabpils',
  //       name: 'Jēkabpils',
  //     },
  //     {
  //       id: '405',
  //       code: 'LV-JK',
  //       name: 'Jēkabpils novads',
  //     },
  //     {
  //       id: '356',
  //       code: 'LV-JEL',
  //       name: 'Jelgava',
  //     },
  //     {
  //       id: '404',
  //       code: 'LV-JL',
  //       name: 'Jelgavas novads',
  //     },
  //     {
  //       id: '358',
  //       code: 'LV-JUR',
  //       name: 'Jūrmala',
  //     },
  //     {
  //       id: '406',
  //       code: 'Kandavas novads',
  //       name: 'Kandavas novads',
  //     },
  //     {
  //       id: '412',
  //       code: 'Kārsavas novads',
  //       name: 'Kārsavas novads',
  //     },
  //     {
  //       id: '473',
  //       code: 'Ķeguma novads',
  //       name: 'Ķeguma novads',
  //     },
  //     {
  //       id: '474',
  //       code: 'Ķekavas novads',
  //       name: 'Ķekavas novads',
  //     },
  //     {
  //       id: '407',
  //       code: 'Kokneses novads',
  //       name: 'Kokneses novads',
  //     },
  //     {
  //       id: '410',
  //       code: 'LV-KR',
  //       name: 'Krāslavas novads',
  //     },
  //     {
  //       id: '408',
  //       code: 'Krimuldas novads',
  //       name: 'Krimuldas novads',
  //     },
  //     {
  //       id: '409',
  //       code: 'Krustpils novads',
  //       name: 'Krustpils novads',
  //     },
  //     {
  //       id: '411',
  //       code: 'LV-KU',
  //       name: 'Kuldīgas novads',
  //     },
  //     {
  //       id: '413',
  //       code: 'Lielvārdes novads',
  //       name: 'Lielvārdes novads',
  //     },
  //     {
  //       id: '359',
  //       code: 'LV-LPX',
  //       name: 'Liepāja',
  //     },
  //     {
  //       id: '360',
  //       code: 'LV-LE',
  //       name: 'Liepājas novads',
  //     },
  //     {
  //       id: '417',
  //       code: 'Līgatnes novads',
  //       name: 'Līgatnes novads',
  //     },
  //     {
  //       id: '414',
  //       code: 'LV-LM',
  //       name: 'Limbažu novads',
  //     },
  //     {
  //       id: '418',
  //       code: 'Līvānu novads',
  //       name: 'Līvānu novads',
  //     },
  //     {
  //       id: '415',
  //       code: 'Lubānas novads',
  //       name: 'Lubānas novads',
  //     },
  //     {
  //       id: '416',
  //       code: 'LV-LU',
  //       name: 'Ludzas novads',
  //     },
  //     {
  //       id: '419',
  //       code: 'LV-MA',
  //       name: 'Madonas novads',
  //     },
  //     {
  //       id: '421',
  //       code: 'Mālpils novads',
  //       name: 'Mālpils novads',
  //     },
  //     {
  //       id: '422',
  //       code: 'Mārupes novads',
  //       name: 'Mārupes novads',
  //     },
  //     {
  //       id: '420',
  //       code: 'Mazsalacas novads',
  //       name: 'Mazsalacas novads',
  //     },
  //     {
  //       id: '423',
  //       code: 'Naukšēnu novads',
  //       name: 'Naukšēnu novads',
  //     },
  //     {
  //       id: '424',
  //       code: 'Neretas novads',
  //       name: 'Neretas novads',
  //     },
  //     {
  //       id: '425',
  //       code: 'Nīcas novads',
  //       name: 'Nīcas novads',
  //     },
  //     {
  //       id: '426',
  //       code: 'LV-OG',
  //       name: 'Ogres novads',
  //     },
  //     {
  //       id: '427',
  //       code: 'Olaines novads',
  //       name: 'Olaines novads',
  //     },
  //     {
  //       id: '428',
  //       code: 'Ozolnieku novads',
  //       name: 'Ozolnieku novads',
  //     },
  //     {
  //       id: '432',
  //       code: 'Pārgaujas novads',
  //       name: 'Pārgaujas novads',
  //     },
  //     {
  //       id: '433',
  //       code: 'Pāvilostas novads',
  //       name: 'Pāvilostas novads',
  //     },
  //     {
  //       id: '434',
  //       code: 'Pļaviņu novads',
  //       name: 'Pļaviņu novads',
  //     },
  //     {
  //       id: '429',
  //       code: 'LV-PR',
  //       name: 'Preiļu novads',
  //     },
  //     {
  //       id: '430',
  //       code: 'Priekules novads',
  //       name: 'Priekules novads',
  //     },
  //     {
  //       id: '431',
  //       code: 'Priekuļu novads',
  //       name: 'Priekuļu novads',
  //     },
  //     {
  //       id: '435',
  //       code: 'Raunas novads',
  //       name: 'Raunas novads',
  //     },
  //     {
  //       id: '361',
  //       code: 'LV-REZ',
  //       name: 'Rēzekne',
  //     },
  //     {
  //       id: '442',
  //       code: 'LV-RE',
  //       name: 'Rēzeknes novads',
  //     },
  //     {
  //       id: '436',
  //       code: 'Riebiņu novads',
  //       name: 'Riebiņu novads',
  //     },
  //     {
  //       id: '362',
  //       code: 'LV-RIX',
  //       name: 'Rīga',
  //     },
  //     {
  //       id: '363',
  //       code: 'LV-RI',
  //       name: 'Rīgas novads',
  //     },
  //     {
  //       id: '437',
  //       code: 'Rojas novads',
  //       name: 'Rojas novads',
  //     },
  //     {
  //       id: '438',
  //       code: 'Ropažu novads',
  //       name: 'Ropažu novads',
  //     },
  //     {
  //       id: '439',
  //       code: 'Rucavas novads',
  //       name: 'Rucavas novads',
  //     },
  //     {
  //       id: '440',
  //       code: 'Rugāju novads',
  //       name: 'Rugāju novads',
  //     },
  //     {
  //       id: '443',
  //       code: 'Rūjienas novads',
  //       name: 'Rūjienas novads',
  //     },
  //     {
  //       id: '441',
  //       code: 'Rundāles novads',
  //       name: 'Rundāles novads',
  //     },
  //     {
  //       id: '444',
  //       code: 'Salacgrīvas novads',
  //       name: 'Salacgrīvas novads',
  //     },
  //     {
  //       id: '445',
  //       code: 'Salas novads',
  //       name: 'Salas novads',
  //     },
  //     {
  //       id: '446',
  //       code: 'Salaspils novads',
  //       name: 'Salaspils novads',
  //     },
  //     {
  //       id: '447',
  //       code: 'LV-SA',
  //       name: 'Saldus novads',
  //     },
  //     {
  //       id: '448',
  //       code: 'Saulkrastu novads',
  //       name: 'Saulkrastu novads',
  //     },
  //     {
  //       id: '455',
  //       code: 'Sējas novads',
  //       name: 'Sējas novads',
  //     },
  //     {
  //       id: '449',
  //       code: 'Siguldas novads',
  //       name: 'Siguldas novads',
  //     },
  //     {
  //       id: '451',
  //       code: 'Skrīveru novads',
  //       name: 'Skrīveru novads',
  //     },
  //     {
  //       id: '450',
  //       code: 'Skrundas novads',
  //       name: 'Skrundas novads',
  //     },
  //     {
  //       id: '452',
  //       code: 'Smiltenes novads',
  //       name: 'Smiltenes novads',
  //     },
  //     {
  //       id: '453',
  //       code: 'Stopiņu novads',
  //       name: 'Stopiņu novads',
  //     },
  //     {
  //       id: '454',
  //       code: 'Strenču novads',
  //       name: 'Strenču novads',
  //     },
  //     {
  //       id: '456',
  //       code: 'LV-TA',
  //       name: 'Talsu novads',
  //     },
  //     {
  //       id: '458',
  //       code: 'Tērvetes novads',
  //       name: 'Tērvetes novads',
  //     },
  //     {
  //       id: '457',
  //       code: 'LV-TU',
  //       name: 'Tukuma novads',
  //     },
  //     {
  //       id: '459',
  //       code: 'Vaiņodes novads',
  //       name: 'Vaiņodes novads',
  //     },
  //     {
  //       id: '460',
  //       code: 'LV-VK',
  //       name: 'Valkas novads',
  //     },
  //     {
  //       id: '364',
  //       code: 'Valmiera',
  //       name: 'Valmiera',
  //     },
  //     {
  //       id: '461',
  //       code: 'LV-VM',
  //       name: 'Valmieras novads',
  //     },
  //     {
  //       id: '462',
  //       code: 'Varakļānu novads',
  //       name: 'Varakļānu novads',
  //     },
  //     {
  //       id: '469',
  //       code: 'Vārkavas novads',
  //       name: 'Vārkavas novads',
  //     },
  //     {
  //       id: '463',
  //       code: 'Vecpiebalgas novads',
  //       name: 'Vecpiebalgas novads',
  //     },
  //     {
  //       id: '464',
  //       code: 'Vecumnieku novads',
  //       name: 'Vecumnieku novads',
  //     },
  //     {
  //       id: '365',
  //       code: 'LV-VEN',
  //       name: 'Ventspils',
  //     },
  //     {
  //       id: '465',
  //       code: 'LV-VE',
  //       name: 'Ventspils novads',
  //     },
  //     {
  //       id: '466',
  //       code: 'Viesītes novads',
  //       name: 'Viesītes novads',
  //     },
  //     {
  //       id: '467',
  //       code: 'Viļakas novads',
  //       name: 'Viļakas novads',
  //     },
  //     {
  //       id: '468',
  //       code: 'Viļānu novads',
  //       name: 'Viļānu novads',
  //     },
  //     {
  //       id: '470',
  //       code: 'Zilupes novads',
  //       name: 'Zilupes novads',
  //     },
  //   ],
  // },
  // {
  //   id: 'LY',
  //   two_letter_abbreviation: 'LY',
  //   three_letter_abbreviation: 'LBY',
  //   full_name_locale: 'Libya',
  //   full_name_english: 'Libya',
  // },
  // {
  //   id: 'MA',
  //   two_letter_abbreviation: 'MA',
  //   three_letter_abbreviation: 'MAR',
  //   full_name_locale: 'Morocco',
  //   full_name_english: 'Morocco',
  // },
  // {
  //   id: 'MC',
  //   two_letter_abbreviation: 'MC',
  //   three_letter_abbreviation: 'MCO',
  //   full_name_locale: 'Monaco',
  //   full_name_english: 'Monaco',
  // },
  // {
  //   id: 'MD',
  //   two_letter_abbreviation: 'MD',
  //   three_letter_abbreviation: 'MDA',
  //   full_name_locale: 'Moldova',
  //   full_name_english: 'Moldova',
  // },
  // {
  //   id: 'ME',
  //   two_letter_abbreviation: 'ME',
  //   three_letter_abbreviation: 'MNE',
  //   full_name_locale: 'Montenegro',
  //   full_name_english: 'Montenegro',
  // },
  // {
  //   id: 'MF',
  //   two_letter_abbreviation: 'MF',
  //   three_letter_abbreviation: 'MAF',
  //   full_name_locale: 'St. Martin',
  //   full_name_english: 'St. Martin',
  // },
  // {
  //   id: 'MG',
  //   two_letter_abbreviation: 'MG',
  //   three_letter_abbreviation: 'MDG',
  //   full_name_locale: 'Madagascar',
  //   full_name_english: 'Madagascar',
  // },
  // {
  //   id: 'MH',
  //   two_letter_abbreviation: 'MH',
  //   three_letter_abbreviation: 'MHL',
  //   full_name_locale: 'Marshall Islands',
  //   full_name_english: 'Marshall Islands',
  // },
  // {
  //   id: 'MK',
  //   two_letter_abbreviation: 'MK',
  //   three_letter_abbreviation: 'MKD',
  //   full_name_locale: 'North Macedonia',
  //   full_name_english: 'North Macedonia',
  // },
  // {
  //   id: 'ML',
  //   two_letter_abbreviation: 'ML',
  //   three_letter_abbreviation: 'MLI',
  //   full_name_locale: 'Mali',
  //   full_name_english: 'Mali',
  // },
  // {
  //   id: 'MM',
  //   two_letter_abbreviation: 'MM',
  //   three_letter_abbreviation: 'MMR',
  //   full_name_locale: 'Myanmar (Burma)',
  //   full_name_english: 'Myanmar (Burma)',
  // },
  // {
  //   id: 'MN',
  //   two_letter_abbreviation: 'MN',
  //   three_letter_abbreviation: 'MNG',
  //   full_name_locale: 'Mongolia',
  //   full_name_english: 'Mongolia',
  // },
  // {
  //   id: 'MO',
  //   two_letter_abbreviation: 'MO',
  //   three_letter_abbreviation: 'MAC',
  //   full_name_locale: 'Macao SAR China',
  //   full_name_english: 'Macao SAR China',
  // },
  // {
  //   id: 'MP',
  //   two_letter_abbreviation: 'MP',
  //   three_letter_abbreviation: 'MNP',
  //   full_name_locale: 'Northern Mariana Islands',
  //   full_name_english: 'Northern Mariana Islands',
  // },
  // {
  //   id: 'MQ',
  //   two_letter_abbreviation: 'MQ',
  //   three_letter_abbreviation: 'MTQ',
  //   full_name_locale: 'Martinique',
  //   full_name_english: 'Martinique',
  // },
  // {
  //   id: 'MR',
  //   two_letter_abbreviation: 'MR',
  //   three_letter_abbreviation: 'MRT',
  //   full_name_locale: 'Mauritania',
  //   full_name_english: 'Mauritania',
  // },
  // {
  //   id: 'MS',
  //   two_letter_abbreviation: 'MS',
  //   three_letter_abbreviation: 'MSR',
  //   full_name_locale: 'Montserrat',
  //   full_name_english: 'Montserrat',
  // },
  // {
  //   id: 'MT',
  //   two_letter_abbreviation: 'MT',
  //   three_letter_abbreviation: 'MLT',
  //   full_name_locale: 'Malta',
  //   full_name_english: 'Malta',
  // },
  // {
  //   id: 'MU',
  //   two_letter_abbreviation: 'MU',
  //   three_letter_abbreviation: 'MUS',
  //   full_name_locale: 'Mauritius',
  //   full_name_english: 'Mauritius',
  // },
  // {
  //   id: 'MV',
  //   two_letter_abbreviation: 'MV',
  //   three_letter_abbreviation: 'MDV',
  //   full_name_locale: 'Maldives',
  //   full_name_english: 'Maldives',
  // },
  // {
  //   id: 'MW',
  //   two_letter_abbreviation: 'MW',
  //   three_letter_abbreviation: 'MWI',
  //   full_name_locale: 'Malawi',
  //   full_name_english: 'Malawi',
  // },
  // {
  //   id: 'MX',
  //   two_letter_abbreviation: 'MX',
  //   three_letter_abbreviation: 'MEX',
  //   full_name_locale: 'Mexico',
  //   full_name_english: 'Mexico',
  //   available_regions: [
  //     {
  //       id: '935',
  //       code: 'AGU',
  //       name: 'Aguascalientes',
  //     },
  //     {
  //       id: '936',
  //       code: 'BCN',
  //       name: 'Baja California',
  //     },
  //     {
  //       id: '937',
  //       code: 'BCS',
  //       name: 'Baja California Sur',
  //     },
  //     {
  //       id: '938',
  //       code: 'CAM',
  //       name: 'Campeche',
  //     },
  //     {
  //       id: '939',
  //       code: 'CHP',
  //       name: 'Chiapas',
  //     },
  //     {
  //       id: '940',
  //       code: 'CHH',
  //       name: 'Chihuahua',
  //     },
  //     {
  //       id: '941',
  //       code: 'CMX',
  //       name: 'Ciudad de México',
  //     },
  //     {
  //       id: '942',
  //       code: 'COA',
  //       name: 'Coahuila',
  //     },
  //     {
  //       id: '943',
  //       code: 'COL',
  //       name: 'Colima',
  //     },
  //     {
  //       id: '944',
  //       code: 'DUR',
  //       name: 'Durango',
  //     },
  //     {
  //       id: '945',
  //       code: 'MEX',
  //       name: 'Estado de México',
  //     },
  //     {
  //       id: '946',
  //       code: 'GUA',
  //       name: 'Guanajuato',
  //     },
  //     {
  //       id: '947',
  //       code: 'GRO',
  //       name: 'Guerrero',
  //     },
  //     {
  //       id: '948',
  //       code: 'HID',
  //       name: 'Hidalgo',
  //     },
  //     {
  //       id: '949',
  //       code: 'JAL',
  //       name: 'Jalisco',
  //     },
  //     {
  //       id: '950',
  //       code: 'MIC',
  //       name: 'Michoacán',
  //     },
  //     {
  //       id: '951',
  //       code: 'MOR',
  //       name: 'Morelos',
  //     },
  //     {
  //       id: '952',
  //       code: 'NAY',
  //       name: 'Nayarit',
  //     },
  //     {
  //       id: '953',
  //       code: 'NLE',
  //       name: 'Nuevo León',
  //     },
  //     {
  //       id: '954',
  //       code: 'OAX',
  //       name: 'Oaxaca',
  //     },
  //     {
  //       id: '955',
  //       code: 'PUE',
  //       name: 'Puebla',
  //     },
  //     {
  //       id: '956',
  //       code: 'QUE',
  //       name: 'Querétaro',
  //     },
  //     {
  //       id: '957',
  //       code: 'ROO',
  //       name: 'Quintana Roo',
  //     },
  //     {
  //       id: '958',
  //       code: 'SLP',
  //       name: 'San Luis Potosí',
  //     },
  //     {
  //       id: '959',
  //       code: 'SIN',
  //       name: 'Sinaloa',
  //     },
  //     {
  //       id: '960',
  //       code: 'SON',
  //       name: 'Sonora',
  //     },
  //     {
  //       id: '961',
  //       code: 'TAB',
  //       name: 'Tabasco',
  //     },
  //     {
  //       id: '962',
  //       code: 'TAM',
  //       name: 'Tamaulipas',
  //     },
  //     {
  //       id: '963',
  //       code: 'TLA',
  //       name: 'Tlaxcala',
  //     },
  //     {
  //       id: '964',
  //       code: 'VER',
  //       name: 'Veracruz',
  //     },
  //     {
  //       id: '965',
  //       code: 'YUC',
  //       name: 'Yucatán',
  //     },
  //     {
  //       id: '966',
  //       code: 'ZAC',
  //       name: 'Zacatecas',
  //     },
  //   ],
  // },
  // {
  //   id: 'MY',
  //   two_letter_abbreviation: 'MY',
  //   three_letter_abbreviation: 'MYS',
  //   full_name_locale: 'Malaysia',
  //   full_name_english: 'Malaysia',
  // },
  // {
  //   id: 'MZ',
  //   two_letter_abbreviation: 'MZ',
  //   three_letter_abbreviation: 'MOZ',
  //   full_name_locale: 'Mozambique',
  //   full_name_english: 'Mozambique',
  // },
  // {
  //   id: 'NA',
  //   two_letter_abbreviation: 'NA',
  //   three_letter_abbreviation: 'NAM',
  //   full_name_locale: 'Namibia',
  //   full_name_english: 'Namibia',
  // },
  // {
  //   id: 'NC',
  //   two_letter_abbreviation: 'NC',
  //   three_letter_abbreviation: 'NCL',
  //   full_name_locale: 'New Caledonia',
  //   full_name_english: 'New Caledonia',
  // },
  // {
  //   id: 'NE',
  //   two_letter_abbreviation: 'NE',
  //   three_letter_abbreviation: 'NER',
  //   full_name_locale: 'Niger',
  //   full_name_english: 'Niger',
  // },
  // {
  //   id: 'NF',
  //   two_letter_abbreviation: 'NF',
  //   three_letter_abbreviation: 'NFK',
  //   full_name_locale: 'Norfolk Island',
  //   full_name_english: 'Norfolk Island',
  // },
  // {
  //   id: 'NG',
  //   two_letter_abbreviation: 'NG',
  //   three_letter_abbreviation: 'NGA',
  //   full_name_locale: 'Nigeria',
  //   full_name_english: 'Nigeria',
  // },
  // {
  //   id: 'NI',
  //   two_letter_abbreviation: 'NI',
  //   three_letter_abbreviation: 'NIC',
  //   full_name_locale: 'Nicaragua',
  //   full_name_english: 'Nicaragua',
  // },
  // {
  //   id: 'NL',
  //   two_letter_abbreviation: 'NL',
  //   three_letter_abbreviation: 'NLD',
  //   full_name_locale: 'Netherlands',
  //   full_name_english: 'Netherlands',
  // },
  // {
  //   id: 'NO',
  //   two_letter_abbreviation: 'NO',
  //   three_letter_abbreviation: 'NOR',
  //   full_name_locale: 'Norway',
  //   full_name_english: 'Norway',
  // },
  // {
  //   id: 'NP',
  //   two_letter_abbreviation: 'NP',
  //   three_letter_abbreviation: 'NPL',
  //   full_name_locale: 'Nepal',
  //   full_name_english: 'Nepal',
  // },
  // {
  //   id: 'NR',
  //   two_letter_abbreviation: 'NR',
  //   three_letter_abbreviation: 'NRU',
  //   full_name_locale: 'Nauru',
  //   full_name_english: 'Nauru',
  // },
  // {
  //   id: 'NU',
  //   two_letter_abbreviation: 'NU',
  //   three_letter_abbreviation: 'NIU',
  //   full_name_locale: 'Niue',
  //   full_name_english: 'Niue',
  // },
  // {
  //   id: 'NZ',
  //   two_letter_abbreviation: 'NZ',
  //   three_letter_abbreviation: 'NZL',
  //   full_name_locale: 'New Zealand',
  //   full_name_english: 'New Zealand',
  // },
  // {
  //   id: 'OM',
  //   two_letter_abbreviation: 'OM',
  //   three_letter_abbreviation: 'OMN',
  //   full_name_locale: 'Oman',
  //   full_name_english: 'Oman',
  // },
  // {
  //   id: 'PA',
  //   two_letter_abbreviation: 'PA',
  //   three_letter_abbreviation: 'PAN',
  //   full_name_locale: 'Panama',
  //   full_name_english: 'Panama',
  // },
  // {
  //   id: 'PE',
  //   two_letter_abbreviation: 'PE',
  //   three_letter_abbreviation: 'PER',
  //   full_name_locale: 'Peru',
  //   full_name_english: 'Peru',
  //   available_regions: [
  //     {
  //       id: '986',
  //       code: 'PE-AMA',
  //       name: 'Amazonas',
  //     },
  //     {
  //       id: '987',
  //       code: 'PE-ANC',
  //       name: 'Ancash',
  //     },
  //     {
  //       id: '988',
  //       code: 'PE-APU',
  //       name: 'Apurímac',
  //     },
  //     {
  //       id: '989',
  //       code: 'PE-ARE',
  //       name: 'Arequipa',
  //     },
  //     {
  //       id: '990',
  //       code: 'PE-AYA',
  //       name: 'Ayacucho',
  //     },
  //     {
  //       id: '991',
  //       code: 'PE-CAJ',
  //       name: 'Cajamarca',
  //     },
  //     {
  //       id: '992',
  //       code: 'PE-CUS',
  //       name: 'Cusco',
  //     },
  //     {
  //       id: '993',
  //       code: 'PE-CAL',
  //       name: 'El Callao',
  //     },
  //     {
  //       id: '994',
  //       code: 'PE-HUV',
  //       name: 'Huancavelica',
  //     },
  //     {
  //       id: '995',
  //       code: 'PE-HUC',
  //       name: 'Huánuco',
  //     },
  //     {
  //       id: '996',
  //       code: 'PE-ICA',
  //       name: 'Ica',
  //     },
  //     {
  //       id: '997',
  //       code: 'PE-JUN',
  //       name: 'Junín',
  //     },
  //     {
  //       id: '998',
  //       code: 'PE-LAL',
  //       name: 'La Libertad',
  //     },
  //     {
  //       id: '999',
  //       code: 'PE-LAM',
  //       name: 'Lambayeque',
  //     },
  //     {
  //       id: '1000',
  //       code: 'PE-LIM',
  //       name: 'Lima',
  //     },
  //     {
  //       id: '1001',
  //       code: 'PE-LOR',
  //       name: 'Loreto',
  //     },
  //     {
  //       id: '1002',
  //       code: 'PE-MDD',
  //       name: 'Madre de Dios',
  //     },
  //     {
  //       id: '1003',
  //       code: 'PE-MOQ',
  //       name: 'Moquegua',
  //     },
  //     {
  //       id: '985',
  //       code: 'PE-LMA',
  //       name: 'Municipalidad Metropolitana de Lima',
  //     },
  //     {
  //       id: '1004',
  //       code: 'PE-PAS',
  //       name: 'Pasco',
  //     },
  //     {
  //       id: '1005',
  //       code: 'PE-PIU',
  //       name: 'Piura',
  //     },
  //     {
  //       id: '1006',
  //       code: 'PE-PUN',
  //       name: 'Puno',
  //     },
  //     {
  //       id: '1007',
  //       code: 'PE-SAM',
  //       name: 'San Martín',
  //     },
  //     {
  //       id: '1008',
  //       code: 'PE-TAC',
  //       name: 'Tacna',
  //     },
  //     {
  //       id: '1009',
  //       code: 'PE-TUM',
  //       name: 'Tumbes',
  //     },
  //     {
  //       id: '1010',
  //       code: 'PE-UCA',
  //       name: 'Ucayali',
  //     },
  //   ],
  // },
  // {
  //   id: 'PF',
  //   two_letter_abbreviation: 'PF',
  //   three_letter_abbreviation: 'PYF',
  //   full_name_locale: 'French Polynesia',
  //   full_name_english: 'French Polynesia',
  // },
  // {
  //   id: 'PG',
  //   two_letter_abbreviation: 'PG',
  //   three_letter_abbreviation: 'PNG',
  //   full_name_locale: 'Papua New Guinea',
  //   full_name_english: 'Papua New Guinea',
  // },
  // {
  //   id: 'PH',
  //   two_letter_abbreviation: 'PH',
  //   three_letter_abbreviation: 'PHL',
  //   full_name_locale: 'Philippines',
  //   full_name_english: 'Philippines',
  // },
  // {
  //   id: 'PK',
  //   two_letter_abbreviation: 'PK',
  //   three_letter_abbreviation: 'PAK',
  //   full_name_locale: 'Pakistan',
  //   full_name_english: 'Pakistan',
  // },
  // {
  //   id: 'PL',
  //   two_letter_abbreviation: 'PL',
  //   three_letter_abbreviation: 'POL',
  //   full_name_locale: 'Poland',
  //   full_name_english: 'Poland',
  //   available_regions: [
  //     {
  //       id: '1011',
  //       code: 'PL-02',
  //       name: 'dolnośląskie',
  //     },
  //     {
  //       id: '1012',
  //       code: 'PL-04',
  //       name: 'kujawsko-pomorskie',
  //     },
  //     {
  //       id: '1013',
  //       code: 'PL-06',
  //       name: 'lubelskie',
  //     },
  //     {
  //       id: '1014',
  //       code: 'PL-08',
  //       name: 'lubuskie',
  //     },
  //     {
  //       id: '1017',
  //       code: 'PL-14',
  //       name: 'mazowieckie',
  //     },
  //     {
  //       id: '1016',
  //       code: 'PL-12',
  //       name: 'małopolskie',
  //     },
  //     {
  //       id: '1018',
  //       code: 'PL-16',
  //       name: 'opolskie',
  //     },
  //     {
  //       id: '1019',
  //       code: 'PL-18',
  //       name: 'podkarpackie',
  //     },
  //     {
  //       id: '1020',
  //       code: 'PL-20',
  //       name: 'podlaskie',
  //     },
  //     {
  //       id: '1021',
  //       code: 'PL-22',
  //       name: 'pomorskie',
  //     },
  //     {
  //       id: '1022',
  //       code: 'PL-24',
  //       name: 'śląskie',
  //     },
  //     {
  //       id: '1023',
  //       code: 'PL-26',
  //       name: 'świętokrzyskie',
  //     },
  //     {
  //       id: '1024',
  //       code: 'PL-28',
  //       name: 'warmińsko-mazurskie',
  //     },
  //     {
  //       id: '1025',
  //       code: 'PL-30',
  //       name: 'wielkopolskie',
  //     },
  //     {
  //       id: '1026',
  //       code: 'PL-32',
  //       name: 'zachodniopomorskie',
  //     },
  //     {
  //       id: '1015',
  //       code: 'PL-10',
  //       name: 'łódzkie',
  //     },
  //   ],
  // },
  // {
  //   id: 'PM',
  //   two_letter_abbreviation: 'PM',
  //   three_letter_abbreviation: 'SPM',
  //   full_name_locale: 'St. Pierre & Miquelon',
  //   full_name_english: 'St. Pierre & Miquelon',
  // },
  // {
  //   id: 'PN',
  //   two_letter_abbreviation: 'PN',
  //   three_letter_abbreviation: 'PCN',
  //   full_name_locale: 'Pitcairn Islands',
  //   full_name_english: 'Pitcairn Islands',
  // },
  // {
  //   id: 'PS',
  //   two_letter_abbreviation: 'PS',
  //   three_letter_abbreviation: 'PSE',
  //   full_name_locale: 'Palestinian Territories',
  //   full_name_english: 'Palestinian Territories',
  // },
  // {
  //   id: 'PT',
  //   two_letter_abbreviation: 'PT',
  //   three_letter_abbreviation: 'PRT',
  //   full_name_locale: 'Portugal',
  //   full_name_english: 'Portugal',
  //   available_regions: [
  //     {
  //       id: '1027',
  //       code: 'PT-01',
  //       name: 'Aveiro',
  //     },
  //     {
  //       id: '1028',
  //       code: 'PT-02',
  //       name: 'Beja',
  //     },
  //     {
  //       id: '1029',
  //       code: 'PT-03',
  //       name: 'Braga',
  //     },
  //     {
  //       id: '1030',
  //       code: 'PT-04',
  //       name: 'Bragança',
  //     },
  //     {
  //       id: '1031',
  //       code: 'PT-05',
  //       name: 'Castelo Branco',
  //     },
  //     {
  //       id: '1032',
  //       code: 'PT-06',
  //       name: 'Coimbra',
  //     },
  //     {
  //       id: '1033',
  //       code: 'PT-07',
  //       name: 'Évora',
  //     },
  //     {
  //       id: '1034',
  //       code: 'PT-08',
  //       name: 'Faro',
  //     },
  //     {
  //       id: '1035',
  //       code: 'PT-09',
  //       name: 'Guarda',
  //     },
  //     {
  //       id: '1036',
  //       code: 'PT-10',
  //       name: 'Leiria',
  //     },
  //     {
  //       id: '1037',
  //       code: 'PT-11',
  //       name: 'Lisboa',
  //     },
  //     {
  //       id: '1038',
  //       code: 'PT-12',
  //       name: 'Portalegre',
  //     },
  //     {
  //       id: '1039',
  //       code: 'PT-13',
  //       name: 'Porto',
  //     },
  //     {
  //       id: '1046',
  //       code: 'PT-30',
  //       name: 'Região Autónoma da Madeira',
  //     },
  //     {
  //       id: '1045',
  //       code: 'PT-20',
  //       name: 'Região Autónoma dos Açores',
  //     },
  //     {
  //       id: '1040',
  //       code: 'PT-14',
  //       name: 'Santarém',
  //     },
  //     {
  //       id: '1041',
  //       code: 'PT-15',
  //       name: 'Setúbal',
  //     },
  //     {
  //       id: '1042',
  //       code: 'PT-16',
  //       name: 'Viana do Castelo',
  //     },
  //     {
  //       id: '1043',
  //       code: 'PT-17',
  //       name: 'Vila Real',
  //     },
  //     {
  //       id: '1044',
  //       code: 'PT-18',
  //       name: 'Viseu',
  //     },
  //   ],
  // },
  // {
  //   id: 'PW',
  //   two_letter_abbreviation: 'PW',
  //   three_letter_abbreviation: 'PLW',
  //   full_name_locale: 'Palau',
  //   full_name_english: 'Palau',
  // },
  // {
  //   id: 'PY',
  //   two_letter_abbreviation: 'PY',
  //   three_letter_abbreviation: 'PRY',
  //   full_name_locale: 'Paraguay',
  //   full_name_english: 'Paraguay',
  //   available_regions: [
  //     {
  //       id: '968',
  //       code: 'PY-16',
  //       name: 'Alto Paraguay',
  //     },
  //     {
  //       id: '969',
  //       code: 'PY-10',
  //       name: 'Alto Paraná',
  //     },
  //     {
  //       id: '970',
  //       code: 'PY-13',
  //       name: 'Amambay',
  //     },
  //     {
  //       id: '967',
  //       code: 'PY-ASU',
  //       name: 'Asunción',
  //     },
  //     {
  //       id: '971',
  //       code: 'PY-19',
  //       name: 'Boquerón',
  //     },
  //     {
  //       id: '972',
  //       code: 'PY-5',
  //       name: 'Caaguazú',
  //     },
  //     {
  //       id: '973',
  //       code: 'PY-6',
  //       name: 'Caazapá',
  //     },
  //     {
  //       id: '974',
  //       code: 'PY-14',
  //       name: 'Canindeyú',
  //     },
  //     {
  //       id: '975',
  //       code: 'PY-11',
  //       name: 'Central',
  //     },
  //     {
  //       id: '976',
  //       code: 'PY-1',
  //       name: 'Concepción',
  //     },
  //     {
  //       id: '977',
  //       code: 'PY-3',
  //       name: 'Cordillera',
  //     },
  //     {
  //       id: '978',
  //       code: 'PY-4',
  //       name: 'Guairá',
  //     },
  //     {
  //       id: '979',
  //       code: 'PY-7',
  //       name: 'Itapúa',
  //     },
  //     {
  //       id: '980',
  //       code: 'PY-8',
  //       name: 'Misiones',
  //     },
  //     {
  //       id: '981',
  //       code: 'PY-12',
  //       name: 'Ñeembucú',
  //     },
  //     {
  //       id: '982',
  //       code: 'PY-9',
  //       name: 'Paraguarí',
  //     },
  //     {
  //       id: '983',
  //       code: 'PY-15',
  //       name: 'Presidente Hayes',
  //     },
  //     {
  //       id: '984',
  //       code: 'PY-2',
  //       name: 'San Pedro',
  //     },
  //   ],
  // },
  // {
  //   id: 'QA',
  //   two_letter_abbreviation: 'QA',
  //   three_letter_abbreviation: 'QAT',
  //   full_name_locale: 'Qatar',
  //   full_name_english: 'Qatar',
  // },
  // {
  //   id: 'RE',
  //   two_letter_abbreviation: 'RE',
  //   three_letter_abbreviation: 'REU',
  //   full_name_locale: 'Réunion',
  //   full_name_english: 'Réunion',
  // },
  // {
  //   id: 'RO',
  //   two_letter_abbreviation: 'RO',
  //   three_letter_abbreviation: 'ROU',
  //   full_name_locale: 'Romania',
  //   full_name_english: 'Romania',
  //   available_regions: [
  //     {
  //       id: '278',
  //       code: 'AB',
  //       name: 'Alba',
  //     },
  //     {
  //       id: '279',
  //       code: 'AR',
  //       name: 'Arad',
  //     },
  //     {
  //       id: '280',
  //       code: 'AG',
  //       name: 'Argeş',
  //     },
  //     {
  //       id: '281',
  //       code: 'BC',
  //       name: 'Bacău',
  //     },
  //     {
  //       id: '282',
  //       code: 'BH',
  //       name: 'Bihor',
  //     },
  //     {
  //       id: '283',
  //       code: 'BN',
  //       name: 'Bistriţa-Năsăud',
  //     },
  //     {
  //       id: '284',
  //       code: 'BT',
  //       name: 'Botoşani',
  //     },
  //     {
  //       id: '286',
  //       code: 'BR',
  //       name: 'Brăila',
  //     },
  //     {
  //       id: '285',
  //       code: 'BV',
  //       name: 'Braşov',
  //     },
  //     {
  //       id: '287',
  //       code: 'B',
  //       name: 'Bucureşti',
  //     },
  //     {
  //       id: '288',
  //       code: 'BZ',
  //       name: 'Buzău',
  //     },
  //     {
  //       id: '290',
  //       code: 'CL',
  //       name: 'Călăraşi',
  //     },
  //     {
  //       id: '289',
  //       code: 'CS',
  //       name: 'Caraş-Severin',
  //     },
  //     {
  //       id: '291',
  //       code: 'CJ',
  //       name: 'Cluj',
  //     },
  //     {
  //       id: '292',
  //       code: 'CT',
  //       name: 'Constanţa',
  //     },
  //     {
  //       id: '293',
  //       code: 'CV',
  //       name: 'Covasna',
  //     },
  //     {
  //       id: '294',
  //       code: 'DB',
  //       name: 'Dâmboviţa',
  //     },
  //     {
  //       id: '295',
  //       code: 'DJ',
  //       name: 'Dolj',
  //     },
  //     {
  //       id: '296',
  //       code: 'GL',
  //       name: 'Galaţi',
  //     },
  //     {
  //       id: '297',
  //       code: 'GR',
  //       name: 'Giurgiu',
  //     },
  //     {
  //       id: '298',
  //       code: 'GJ',
  //       name: 'Gorj',
  //     },
  //     {
  //       id: '299',
  //       code: 'HR',
  //       name: 'Harghita',
  //     },
  //     {
  //       id: '300',
  //       code: 'HD',
  //       name: 'Hunedoara',
  //     },
  //     {
  //       id: '301',
  //       code: 'IL',
  //       name: 'Ialomiţa',
  //     },
  //     {
  //       id: '302',
  //       code: 'IS',
  //       name: 'Iaşi',
  //     },
  //     {
  //       id: '303',
  //       code: 'IF',
  //       name: 'Ilfov',
  //     },
  //     {
  //       id: '304',
  //       code: 'MM',
  //       name: 'Maramureş',
  //     },
  //     {
  //       id: '305',
  //       code: 'MH',
  //       name: 'Mehedinţi',
  //     },
  //     {
  //       id: '306',
  //       code: 'MS',
  //       name: 'Mureş',
  //     },
  //     {
  //       id: '307',
  //       code: 'NT',
  //       name: 'Neamţ',
  //     },
  //     {
  //       id: '308',
  //       code: 'OT',
  //       name: 'Olt',
  //     },
  //     {
  //       id: '309',
  //       code: 'PH',
  //       name: 'Prahova',
  //     },
  //     {
  //       id: '311',
  //       code: 'SJ',
  //       name: 'Sălaj',
  //     },
  //     {
  //       id: '310',
  //       code: 'SM',
  //       name: 'Satu-Mare',
  //     },
  //     {
  //       id: '312',
  //       code: 'SB',
  //       name: 'Sibiu',
  //     },
  //     {
  //       id: '313',
  //       code: 'SV',
  //       name: 'Suceava',
  //     },
  //     {
  //       id: '314',
  //       code: 'TR',
  //       name: 'Teleorman',
  //     },
  //     {
  //       id: '315',
  //       code: 'TM',
  //       name: 'Timiş',
  //     },
  //     {
  //       id: '316',
  //       code: 'TL',
  //       name: 'Tulcea',
  //     },
  //     {
  //       id: '318',
  //       code: 'VL',
  //       name: 'Vâlcea',
  //     },
  //     {
  //       id: '317',
  //       code: 'VS',
  //       name: 'Vaslui',
  //     },
  //     {
  //       id: '319',
  //       code: 'VN',
  //       name: 'Vrancea',
  //     },
  //   ],
  // },
  // {
  //   id: 'RS',
  //   two_letter_abbreviation: 'RS',
  //   three_letter_abbreviation: 'SRB',
  //   full_name_locale: 'Serbia',
  //   full_name_english: 'Serbia',
  // },
  // {
  //   id: 'RU',
  //   two_letter_abbreviation: 'RU',
  //   three_letter_abbreviation: 'RUS',
  //   full_name_locale: 'Russia',
  //   full_name_english: 'Russia',
  // },
  // {
  //   id: 'RW',
  //   two_letter_abbreviation: 'RW',
  //   three_letter_abbreviation: 'RWA',
  //   full_name_locale: 'Rwanda',
  //   full_name_english: 'Rwanda',
  // },
  // {
  //   id: 'SA',
  //   two_letter_abbreviation: 'SA',
  //   three_letter_abbreviation: 'SAU',
  //   full_name_locale: 'Saudi Arabia',
  //   full_name_english: 'Saudi Arabia',
  // },
  // {
  //   id: 'SB',
  //   two_letter_abbreviation: 'SB',
  //   three_letter_abbreviation: 'SLB',
  //   full_name_locale: 'Solomon Islands',
  //   full_name_english: 'Solomon Islands',
  // },
  // {
  //   id: 'SC',
  //   two_letter_abbreviation: 'SC',
  //   three_letter_abbreviation: 'SYC',
  //   full_name_locale: 'Seychelles',
  //   full_name_english: 'Seychelles',
  // },
  // {
  //   id: 'SD',
  //   two_letter_abbreviation: 'SD',
  //   three_letter_abbreviation: 'SDN',
  //   full_name_locale: 'Sudan',
  //   full_name_english: 'Sudan',
  // },
  // {
  //   id: 'SE',
  //   two_letter_abbreviation: 'SE',
  //   three_letter_abbreviation: 'SWE',
  //   full_name_locale: 'Sweden',
  //   full_name_english: 'Sweden',
  //   available_regions: [
  //     {
  //       id: '1057',
  //       code: 'SE-K',
  //       name: 'Blekinge län',
  //     },
  //     {
  //       id: '1058',
  //       code: 'SE-W',
  //       name: 'Dalarnas län',
  //     },
  //     {
  //       id: '1060',
  //       code: 'SE-X',
  //       name: 'Gävleborgs län',
  //     },
  //     {
  //       id: '1059',
  //       code: 'SE-I',
  //       name: 'Gotlands län',
  //     },
  //     {
  //       id: '1061',
  //       code: 'SE-N',
  //       name: 'Hallands län',
  //     },
  //     {
  //       id: '1062',
  //       code: 'SE-Z',
  //       name: 'Jämtlands län',
  //     },
  //     {
  //       id: '1063',
  //       code: 'SE-F',
  //       name: 'Jönköpings län',
  //     },
  //     {
  //       id: '1064',
  //       code: 'SE-H',
  //       name: 'Kalmar län',
  //     },
  //     {
  //       id: '1065',
  //       code: 'SE-G',
  //       name: 'Kronobergs län',
  //     },
  //     {
  //       id: '1066',
  //       code: 'SE-BD',
  //       name: 'Norrbottens län',
  //     },
  //     {
  //       id: '1076',
  //       code: 'SE-T',
  //       name: 'Örebro län',
  //     },
  //     {
  //       id: '1077',
  //       code: 'SE-E',
  //       name: 'Östergötlands län',
  //     },
  //     {
  //       id: '1067',
  //       code: 'SE-M',
  //       name: 'Skåne län',
  //     },
  //     {
  //       id: '1069',
  //       code: 'SE-D',
  //       name: 'Södermanlands län',
  //     },
  //     {
  //       id: '1068',
  //       code: 'SE-AB',
  //       name: 'Stockholms län',
  //     },
  //     {
  //       id: '1070',
  //       code: 'SE-C',
  //       name: 'Uppsala län',
  //     },
  //     {
  //       id: '1071',
  //       code: 'SE-S',
  //       name: 'Värmlands län',
  //     },
  //     {
  //       id: '1072',
  //       code: 'SE-AC',
  //       name: 'Västerbottens län',
  //     },
  //     {
  //       id: '1073',
  //       code: 'SE-Y',
  //       name: 'Västernorrlands län',
  //     },
  //     {
  //       id: '1074',
  //       code: 'SE-U',
  //       name: 'Västmanlands län',
  //     },
  //     {
  //       id: '1075',
  //       code: 'SE-O',
  //       name: 'Västra Götalands län',
  //     },
  //   ],
  // },
  // {
  //   id: 'SG',
  //   two_letter_abbreviation: 'SG',
  //   three_letter_abbreviation: 'SGP',
  //   full_name_locale: 'Singapore',
  //   full_name_english: 'Singapore',
  // },
  // {
  //   id: 'SH',
  //   two_letter_abbreviation: 'SH',
  //   three_letter_abbreviation: 'SHN',
  //   full_name_locale: 'St. Helena',
  //   full_name_english: 'St. Helena',
  // },
  // {
  //   id: 'SI',
  //   two_letter_abbreviation: 'SI',
  //   three_letter_abbreviation: 'SVN',
  //   full_name_locale: 'Slovenia',
  //   full_name_english: 'Slovenia',
  // },
  // {
  //   id: 'SJ',
  //   two_letter_abbreviation: 'SJ',
  //   three_letter_abbreviation: 'SJM',
  //   full_name_locale: 'Svalbard & Jan Mayen',
  //   full_name_english: 'Svalbard & Jan Mayen',
  // },
  // {
  //   id: 'SK',
  //   two_letter_abbreviation: 'SK',
  //   three_letter_abbreviation: 'SVK',
  //   full_name_locale: 'Slovakia',
  //   full_name_english: 'Slovakia',
  // },
  // {
  //   id: 'SL',
  //   two_letter_abbreviation: 'SL',
  //   three_letter_abbreviation: 'SLE',
  //   full_name_locale: 'Sierra Leone',
  //   full_name_english: 'Sierra Leone',
  // },
  // {
  //   id: 'SM',
  //   two_letter_abbreviation: 'SM',
  //   three_letter_abbreviation: 'SMR',
  //   full_name_locale: 'San Marino',
  //   full_name_english: 'San Marino',
  // },
  // {
  //   id: 'SN',
  //   two_letter_abbreviation: 'SN',
  //   three_letter_abbreviation: 'SEN',
  //   full_name_locale: 'Senegal',
  //   full_name_english: 'Senegal',
  // },
  // {
  //   id: 'SO',
  //   two_letter_abbreviation: 'SO',
  //   three_letter_abbreviation: 'SOM',
  //   full_name_locale: 'Somalia',
  //   full_name_english: 'Somalia',
  // },
  // {
  //   id: 'SR',
  //   two_letter_abbreviation: 'SR',
  //   three_letter_abbreviation: 'SUR',
  //   full_name_locale: 'Suriname',
  //   full_name_english: 'Suriname',
  //   available_regions: [
  //     {
  //       id: '1047',
  //       code: 'SR-BR',
  //       name: 'Brokopondo',
  //     },
  //     {
  //       id: '1048',
  //       code: 'SR-CM',
  //       name: 'Commewijne',
  //     },
  //     {
  //       id: '1049',
  //       code: 'SR-CR',
  //       name: 'Coronie',
  //     },
  //     {
  //       id: '1050',
  //       code: 'SR-MA',
  //       name: 'Marowijne',
  //     },
  //     {
  //       id: '1051',
  //       code: 'SR-NI',
  //       name: 'Nickerie',
  //     },
  //     {
  //       id: '1052',
  //       code: 'SR-PR',
  //       name: 'Para',
  //     },
  //     {
  //       id: '1053',
  //       code: 'SR-PM',
  //       name: 'Paramaribo',
  //     },
  //     {
  //       id: '1054',
  //       code: 'SR-SA',
  //       name: 'Saramacca',
  //     },
  //     {
  //       id: '1055',
  //       code: 'SR-SI',
  //       name: 'Sipaliwini',
  //     },
  //     {
  //       id: '1056',
  //       code: 'SR-WA',
  //       name: 'Wanica',
  //     },
  //   ],
  // },
  // {
  //   id: 'ST',
  //   two_letter_abbreviation: 'ST',
  //   three_letter_abbreviation: 'STP',
  //   full_name_locale: 'São Tomé & Príncipe',
  //   full_name_english: 'São Tomé & Príncipe',
  // },
  // {
  //   id: 'SV',
  //   two_letter_abbreviation: 'SV',
  //   three_letter_abbreviation: 'SLV',
  //   full_name_locale: 'El Salvador',
  //   full_name_english: 'El Salvador',
  // },
  // {
  //   id: 'SX',
  //   two_letter_abbreviation: 'SX',
  //   three_letter_abbreviation: 'SXM',
  //   full_name_locale: 'Sint Maarten',
  //   full_name_english: 'Sint Maarten',
  // },
  // {
  //   id: 'SY',
  //   two_letter_abbreviation: 'SY',
  //   three_letter_abbreviation: 'SYR',
  //   full_name_locale: 'Syria',
  //   full_name_english: 'Syria',
  // },
  // {
  //   id: 'SZ',
  //   two_letter_abbreviation: 'SZ',
  //   three_letter_abbreviation: 'SWZ',
  //   full_name_locale: 'Eswatini',
  //   full_name_english: 'Eswatini',
  // },
  // {
  //   id: 'TC',
  //   two_letter_abbreviation: 'TC',
  //   three_letter_abbreviation: 'TCA',
  //   full_name_locale: 'Turks & Caicos Islands',
  //   full_name_english: 'Turks & Caicos Islands',
  // },
  // {
  //   id: 'TD',
  //   two_letter_abbreviation: 'TD',
  //   three_letter_abbreviation: 'TCD',
  //   full_name_locale: 'Chad',
  //   full_name_english: 'Chad',
  // },
  // {
  //   id: 'TF',
  //   two_letter_abbreviation: 'TF',
  //   three_letter_abbreviation: 'ATF',
  //   full_name_locale: 'French Southern Territories',
  //   full_name_english: 'French Southern Territories',
  // },
  // {
  //   id: 'TG',
  //   two_letter_abbreviation: 'TG',
  //   three_letter_abbreviation: 'TGO',
  //   full_name_locale: 'Togo',
  //   full_name_english: 'Togo',
  // },
  // {
  //   id: 'TH',
  //   two_letter_abbreviation: 'TH',
  //   three_letter_abbreviation: 'THA',
  //   full_name_locale: 'Thailand',
  //   full_name_english: 'Thailand',
  // },
  // {
  //   id: 'TJ',
  //   two_letter_abbreviation: 'TJ',
  //   three_letter_abbreviation: 'TJK',
  //   full_name_locale: 'Tajikistan',
  //   full_name_english: 'Tajikistan',
  // },
  // {
  //   id: 'TK',
  //   two_letter_abbreviation: 'TK',
  //   three_letter_abbreviation: 'TKL',
  //   full_name_locale: 'Tokelau',
  //   full_name_english: 'Tokelau',
  // },
  // {
  //   id: 'TL',
  //   two_letter_abbreviation: 'TL',
  //   three_letter_abbreviation: 'TLS',
  //   full_name_locale: 'Timor-Leste',
  //   full_name_english: 'Timor-Leste',
  // },
  // {
  //   id: 'TM',
  //   two_letter_abbreviation: 'TM',
  //   three_letter_abbreviation: 'TKM',
  //   full_name_locale: 'Turkmenistan',
  //   full_name_english: 'Turkmenistan',
  // },
  // {
  //   id: 'TN',
  //   two_letter_abbreviation: 'TN',
  //   three_letter_abbreviation: 'TUN',
  //   full_name_locale: 'Tunisia',
  //   full_name_english: 'Tunisia',
  // },
  // {
  //   id: 'TO',
  //   two_letter_abbreviation: 'TO',
  //   three_letter_abbreviation: 'TON',
  //   full_name_locale: 'Tonga',
  //   full_name_english: 'Tonga',
  // },
  // {
  //   id: 'TR',
  //   two_letter_abbreviation: 'TR',
  //   three_letter_abbreviation: 'TUR',
  //   full_name_locale: 'Turkey',
  //   full_name_english: 'Turkey',
  // },
  // {
  //   id: 'TT',
  //   two_letter_abbreviation: 'TT',
  //   three_letter_abbreviation: 'TTO',
  //   full_name_locale: 'Trinidad & Tobago',
  //   full_name_english: 'Trinidad & Tobago',
  // },
  // {
  //   id: 'TV',
  //   two_letter_abbreviation: 'TV',
  //   three_letter_abbreviation: 'TUV',
  //   full_name_locale: 'Tuvalu',
  //   full_name_english: 'Tuvalu',
  // },
  // {
  //   id: 'TW',
  //   two_letter_abbreviation: 'TW',
  //   three_letter_abbreviation: 'TWN',
  //   full_name_locale: 'Taiwan, Province of China',
  //   full_name_english: 'Taiwan, Province of China',
  // },
  // {
  //   id: 'TZ',
  //   two_letter_abbreviation: 'TZ',
  //   three_letter_abbreviation: 'TZA',
  //   full_name_locale: 'Tanzania',
  //   full_name_english: 'Tanzania',
  // },
  // {
  //   id: 'UA',
  //   two_letter_abbreviation: 'UA',
  //   three_letter_abbreviation: 'UKR',
  //   full_name_locale: 'Ukraine',
  //   full_name_english: 'Ukraine',
  // },
  // {
  //   id: 'UG',
  //   two_letter_abbreviation: 'UG',
  //   three_letter_abbreviation: 'UGA',
  //   full_name_locale: 'Uganda',
  //   full_name_english: 'Uganda',
  // },
  // {
  //   id: 'UM',
  //   two_letter_abbreviation: 'UM',
  //   three_letter_abbreviation: 'UMI',
  //   full_name_locale: 'U.S. Outlying Islands',
  //   full_name_english: 'U.S. Outlying Islands',
  // },
  // {
  //   id: 'US',
  //   two_letter_abbreviation: 'US',
  //   three_letter_abbreviation: 'USA',
  //   full_name_locale: 'United States',
  //   full_name_english: 'United States',
  //   available_regions: [
  //     {
  //       id: '1',
  //       code: 'AL',
  //       name: 'Alabama',
  //     },
  //     {
  //       id: '2',
  //       code: 'AK',
  //       name: 'Alaska',
  //     },
  //     {
  //       id: '3',
  //       code: 'AS',
  //       name: 'American Samoa',
  //     },
  //     {
  //       id: '4',
  //       code: 'AZ',
  //       name: 'Arizona',
  //     },
  //     {
  //       id: '5',
  //       code: 'AR',
  //       name: 'Arkansas',
  //     },
  //     {
  //       id: '6',
  //       code: 'AE',
  //       name: 'Armed Forces Africa',
  //     },
  //     {
  //       id: '7',
  //       code: 'AA',
  //       name: 'Armed Forces Americas',
  //     },
  //     {
  //       id: '8',
  //       code: 'AE',
  //       name: 'Armed Forces Canada',
  //     },
  //     {
  //       id: '9',
  //       code: 'AE',
  //       name: 'Armed Forces Europe',
  //     },
  //     {
  //       id: '10',
  //       code: 'AE',
  //       name: 'Armed Forces Middle East',
  //     },
  //     {
  //       id: '11',
  //       code: 'AP',
  //       name: 'Armed Forces Pacific',
  //     },
  //     {
  //       id: '12',
  //       code: 'CA',
  //       name: 'California',
  //     },
  //     {
  //       id: '13',
  //       code: 'CO',
  //       name: 'Colorado',
  //     },
  //     {
  //       id: '14',
  //       code: 'CT',
  //       name: 'Connecticut',
  //     },
  //     {
  //       id: '15',
  //       code: 'DE',
  //       name: 'Delaware',
  //     },
  //     {
  //       id: '16',
  //       code: 'DC',
  //       name: 'District of Columbia',
  //     },
  //     {
  //       id: '17',
  //       code: 'FM',
  //       name: 'Federated States Of Micronesia',
  //     },
  //     {
  //       id: '18',
  //       code: 'FL',
  //       name: 'Florida',
  //     },
  //     {
  //       id: '19',
  //       code: 'GA',
  //       name: 'Georgia',
  //     },
  //     {
  //       id: '20',
  //       code: 'GU',
  //       name: 'Guam',
  //     },
  //     {
  //       id: '21',
  //       code: 'HI',
  //       name: 'Hawaii',
  //     },
  //     {
  //       id: '22',
  //       code: 'ID',
  //       name: 'Idaho',
  //     },
  //     {
  //       id: '23',
  //       code: 'IL',
  //       name: 'Illinois',
  //     },
  //     {
  //       id: '24',
  //       code: 'IN',
  //       name: 'Indiana',
  //     },
  //     {
  //       id: '25',
  //       code: 'IA',
  //       name: 'Iowa',
  //     },
  //     {
  //       id: '26',
  //       code: 'KS',
  //       name: 'Kansas',
  //     },
  //     {
  //       id: '27',
  //       code: 'KY',
  //       name: 'Kentucky',
  //     },
  //     {
  //       id: '28',
  //       code: 'LA',
  //       name: 'Louisiana',
  //     },
  //     {
  //       id: '29',
  //       code: 'ME',
  //       name: 'Maine',
  //     },
  //     {
  //       id: '30',
  //       code: 'MH',
  //       name: 'Marshall Islands',
  //     },
  //     {
  //       id: '31',
  //       code: 'MD',
  //       name: 'Maryland',
  //     },
  //     {
  //       id: '32',
  //       code: 'MA',
  //       name: 'Massachusetts',
  //     },
  //     {
  //       id: '33',
  //       code: 'MI',
  //       name: 'Michigan',
  //     },
  //     {
  //       id: '34',
  //       code: 'MN',
  //       name: 'Minnesota',
  //     },
  //     {
  //       id: '35',
  //       code: 'MS',
  //       name: 'Mississippi',
  //     },
  //     {
  //       id: '36',
  //       code: 'MO',
  //       name: 'Missouri',
  //     },
  //     {
  //       id: '37',
  //       code: 'MT',
  //       name: 'Montana',
  //     },
  //     {
  //       id: '38',
  //       code: 'NE',
  //       name: 'Nebraska',
  //     },
  //     {
  //       id: '39',
  //       code: 'NV',
  //       name: 'Nevada',
  //     },
  //     {
  //       id: '40',
  //       code: 'NH',
  //       name: 'New Hampshire',
  //     },
  //     {
  //       id: '41',
  //       code: 'NJ',
  //       name: 'New Jersey',
  //     },
  //     {
  //       id: '42',
  //       code: 'NM',
  //       name: 'New Mexico',
  //     },
  //     {
  //       id: '43',
  //       code: 'NY',
  //       name: 'New York',
  //     },
  //     {
  //       id: '44',
  //       code: 'NC',
  //       name: 'North Carolina',
  //     },
  //     {
  //       id: '45',
  //       code: 'ND',
  //       name: 'North Dakota',
  //     },
  //     {
  //       id: '46',
  //       code: 'MP',
  //       name: 'Northern Mariana Islands',
  //     },
  //     {
  //       id: '47',
  //       code: 'OH',
  //       name: 'Ohio',
  //     },
  //     {
  //       id: '48',
  //       code: 'OK',
  //       name: 'Oklahoma',
  //     },
  //     {
  //       id: '49',
  //       code: 'OR',
  //       name: 'Oregon',
  //     },
  //     {
  //       id: '50',
  //       code: 'PW',
  //       name: 'Palau',
  //     },
  //     {
  //       id: '51',
  //       code: 'PA',
  //       name: 'Pennsylvania',
  //     },
  //     {
  //       id: '52',
  //       code: 'PR',
  //       name: 'Puerto Rico',
  //     },
  //     {
  //       id: '53',
  //       code: 'RI',
  //       name: 'Rhode Island',
  //     },
  //     {
  //       id: '54',
  //       code: 'SC',
  //       name: 'South Carolina',
  //     },
  //     {
  //       id: '55',
  //       code: 'SD',
  //       name: 'South Dakota',
  //     },
  //     {
  //       id: '56',
  //       code: 'TN',
  //       name: 'Tennessee',
  //     },
  //     {
  //       id: '57',
  //       code: 'TX',
  //       name: 'Texas',
  //     },
  //     {
  //       id: '58',
  //       code: 'UT',
  //       name: 'Utah',
  //     },
  //     {
  //       id: '59',
  //       code: 'VT',
  //       name: 'Vermont',
  //     },
  //     {
  //       id: '60',
  //       code: 'VI',
  //       name: 'Virgin Islands',
  //     },
  //     {
  //       id: '61',
  //       code: 'VA',
  //       name: 'Virginia',
  //     },
  //     {
  //       id: '62',
  //       code: 'WA',
  //       name: 'Washington',
  //     },
  //     {
  //       id: '63',
  //       code: 'WV',
  //       name: 'West Virginia',
  //     },
  //     {
  //       id: '64',
  //       code: 'WI',
  //       name: 'Wisconsin',
  //     },
  //     {
  //       id: '65',
  //       code: 'WY',
  //       name: 'Wyoming',
  //     },
  //   ],
  // },
  // {
  //   id: 'UY',
  //   two_letter_abbreviation: 'UY',
  //   three_letter_abbreviation: 'URY',
  //   full_name_locale: 'Uruguay',
  //   full_name_english: 'Uruguay',
  //   available_regions: [
  //     {
  //       id: '1078',
  //       code: 'UY-AR',
  //       name: 'Artigas',
  //     },
  //     {
  //       id: '1079',
  //       code: 'UY-CA',
  //       name: 'Canelones',
  //     },
  //     {
  //       id: '1080',
  //       code: 'UY-CL',
  //       name: 'Cerro Largo',
  //     },
  //     {
  //       id: '1081',
  //       code: 'UY-CO',
  //       name: 'Colonia',
  //     },
  //     {
  //       id: '1082',
  //       code: 'UY-DU',
  //       name: 'Durazno',
  //     },
  //     {
  //       id: '1083',
  //       code: 'UY-FS',
  //       name: 'Flores',
  //     },
  //     {
  //       id: '1084',
  //       code: 'UY-FD',
  //       name: 'Florida',
  //     },
  //     {
  //       id: '1085',
  //       code: 'UY-LA',
  //       name: 'Lavalleja',
  //     },
  //     {
  //       id: '1086',
  //       code: 'UY-MA',
  //       name: 'Maldonado',
  //     },
  //     {
  //       id: '1087',
  //       code: 'UY-MO',
  //       name: 'Montevideo',
  //     },
  //     {
  //       id: '1088',
  //       code: 'UY-PA',
  //       name: 'Paysandu',
  //     },
  //     {
  //       id: '1089',
  //       code: 'UY-RN',
  //       name: 'Río Negro',
  //     },
  //     {
  //       id: '1090',
  //       code: 'UY-RV',
  //       name: 'Rivera',
  //     },
  //     {
  //       id: '1091',
  //       code: 'UY-RO',
  //       name: 'Rocha',
  //     },
  //     {
  //       id: '1092',
  //       code: 'UY-SA',
  //       name: 'Salto',
  //     },
  //     {
  //       id: '1093',
  //       code: 'UY-SJ',
  //       name: 'San José',
  //     },
  //     {
  //       id: '1094',
  //       code: 'UY-SO',
  //       name: 'Soriano',
  //     },
  //     {
  //       id: '1095',
  //       code: 'UY-TA',
  //       name: 'Tacuarembó',
  //     },
  //     {
  //       id: '1096',
  //       code: 'UY-TT',
  //       name: 'Treinta y Tres',
  //     },
  //   ],
  // },
  // {
  //   id: 'UZ',
  //   two_letter_abbreviation: 'UZ',
  //   three_letter_abbreviation: 'UZB',
  //   full_name_locale: 'Uzbekistan',
  //   full_name_english: 'Uzbekistan',
  // },
  // {
  //   id: 'VA',
  //   two_letter_abbreviation: 'VA',
  //   three_letter_abbreviation: 'VAT',
  //   full_name_locale: 'Vatican City',
  //   full_name_english: 'Vatican City',
  // },
  // {
  //   id: 'VC',
  //   two_letter_abbreviation: 'VC',
  //   three_letter_abbreviation: 'VCT',
  //   full_name_locale: 'St. Vincent & Grenadines',
  //   full_name_english: 'St. Vincent & Grenadines',
  // },
  // {
  //   id: 'VE',
  //   two_letter_abbreviation: 'VE',
  //   three_letter_abbreviation: 'VEN',
  //   full_name_locale: 'Venezuela',
  //   full_name_english: 'Venezuela',
  //   available_regions: [
  //     {
  //       id: '1099',
  //       code: 'VE-Z',
  //       name: 'Amazonas',
  //     },
  //     {
  //       id: '1100',
  //       code: 'VE-B',
  //       name: 'Anzoátegui',
  //     },
  //     {
  //       id: '1101',
  //       code: 'VE-C',
  //       name: 'Apure',
  //     },
  //     {
  //       id: '1102',
  //       code: 'VE-D',
  //       name: 'Aragua',
  //     },
  //     {
  //       id: '1103',
  //       code: 'VE-E',
  //       name: 'Barinas',
  //     },
  //     {
  //       id: '1104',
  //       code: 'VE-F',
  //       name: 'Bolívar',
  //     },
  //     {
  //       id: '1105',
  //       code: 'VE-G',
  //       name: 'Carabobo',
  //     },
  //     {
  //       id: '1106',
  //       code: 'VE-H',
  //       name: 'Cojedes',
  //     },
  //     {
  //       id: '1107',
  //       code: 'VE-Y',
  //       name: 'Delta Amacuro',
  //     },
  //     {
  //       id: '1097',
  //       code: 'VE-W',
  //       name: 'Dependencias Federales',
  //     },
  //     {
  //       id: '1098',
  //       code: 'VE-A',
  //       name: 'Distrito Capital',
  //     },
  //     {
  //       id: '1108',
  //       code: 'VE-I',
  //       name: 'Falcón',
  //     },
  //     {
  //       id: '1109',
  //       code: 'VE-J',
  //       name: 'Guárico',
  //     },
  //     {
  //       id: '1110',
  //       code: 'VE-K',
  //       name: 'Lara',
  //     },
  //     {
  //       id: '1111',
  //       code: 'VE-L',
  //       name: 'Mérida',
  //     },
  //     {
  //       id: '1112',
  //       code: 'VE-M',
  //       name: 'Miranda',
  //     },
  //     {
  //       id: '1113',
  //       code: 'VE-N',
  //       name: 'Monagas',
  //     },
  //     {
  //       id: '1114',
  //       code: 'VE-O',
  //       name: 'Nueva Esparta',
  //     },
  //     {
  //       id: '1115',
  //       code: 'VE-P',
  //       name: 'Portuguesa',
  //     },
  //     {
  //       id: '1116',
  //       code: 'VE-R',
  //       name: 'Sucre',
  //     },
  //     {
  //       id: '1117',
  //       code: 'VE-S',
  //       name: 'Táchira',
  //     },
  //     {
  //       id: '1118',
  //       code: 'VE-T',
  //       name: 'Trujillo',
  //     },
  //     {
  //       id: '1119',
  //       code: 'VE-X',
  //       name: 'Vargas',
  //     },
  //     {
  //       id: '1120',
  //       code: 'VE-U',
  //       name: 'Yaracuy',
  //     },
  //     {
  //       id: '1121',
  //       code: 'VE-V',
  //       name: 'Zulia',
  //     },
  //   ],
  // },
  // {
  //   id: 'VG',
  //   two_letter_abbreviation: 'VG',
  //   three_letter_abbreviation: 'VGB',
  //   full_name_locale: 'British Virgin Islands',
  //   full_name_english: 'British Virgin Islands',
  // },
  // {
  //   id: 'VI',
  //   two_letter_abbreviation: 'VI',
  //   three_letter_abbreviation: 'VIR',
  //   full_name_locale: 'U.S. Virgin Islands',
  //   full_name_english: 'U.S. Virgin Islands',
  // },
  // {
  //   id: 'VN',
  //   two_letter_abbreviation: 'VN',
  //   three_letter_abbreviation: 'VNM',
  //   full_name_locale: 'Vietnam',
  //   full_name_english: 'Vietnam',
  // },
  // {
  //   id: 'VU',
  //   two_letter_abbreviation: 'VU',
  //   three_letter_abbreviation: 'VUT',
  //   full_name_locale: 'Vanuatu',
  //   full_name_english: 'Vanuatu',
  // },
  // {
  //   id: 'WF',
  //   two_letter_abbreviation: 'WF',
  //   three_letter_abbreviation: 'WLF',
  //   full_name_locale: 'Wallis & Futuna',
  //   full_name_english: 'Wallis & Futuna',
  // },
  // {
  //   id: 'WS',
  //   two_letter_abbreviation: 'WS',
  //   three_letter_abbreviation: 'WSM',
  //   full_name_locale: 'Samoa',
  //   full_name_english: 'Samoa',
  // },
  // {
  //   id: 'XK',
  //   two_letter_abbreviation: 'XK',
  //   three_letter_abbreviation: 'XKX',
  //   full_name_locale: 'Kosovo',
  //   full_name_english: 'Kosovo',
  // },
  // {
  //   id: 'YE',
  //   two_letter_abbreviation: 'YE',
  //   three_letter_abbreviation: 'YEM',
  //   full_name_locale: 'Yemen',
  //   full_name_english: 'Yemen',
  // },
  // {
  //   id: 'YT',
  //   two_letter_abbreviation: 'YT',
  //   three_letter_abbreviation: 'MYT',
  //   full_name_locale: 'Mayotte',
  //   full_name_english: 'Mayotte',
  // },
  // {
  //   id: 'ZA',
  //   two_letter_abbreviation: 'ZA',
  //   three_letter_abbreviation: 'ZAF',
  //   full_name_locale: 'South Africa',
  //   full_name_english: 'South Africa',
  // },
  // {
  //   id: 'ZM',
  //   two_letter_abbreviation: 'ZM',
  //   three_letter_abbreviation: 'ZMB',
  //   full_name_locale: 'Zambia',
  //   full_name_english: 'Zambia',
  // },
  // {
  //   id: 'ZW',
  //   two_letter_abbreviation: 'ZW',
  //   three_letter_abbreviation: 'ZWE',
  //   full_name_locale: 'Zimbabwe',
  //   full_name_english: 'Zimbabwe',
  // },
];
