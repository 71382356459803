import React, { useEffect, useState } from 'react';
import './Address.scss';
import DashboardNavigation from '../../../../../components/DashboardComponents/DashboardNavigation';
import { Alert, Button, Divider, IconButton } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import AddEditAddressModal from '../../../../../components/DashboardComponents/AddEditAddressModal';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import {
  addnewUserAddress,
  deleteUserAddress,
  resetState,
  updateUserAddress,
} from '../../../../../redux/userSlice';

const SettingsAddress = () => {
  const [data, setData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isBillingAddress, setIsBillingAddress] = useState(null);
  const [shippingAddress, setshippingAddress] = useState([]);
  const [billingAddress, setbillingAddress] = useState([]);
  const handleClose = () => {
    setIsOpen(false);
  };
  const { currentUser, error, isUpdated, isLoading } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();

  // assigns targetid in setEdit, sets data with current edited data and setisBilling true or false based on edited address type
  const openEditAddress = (e, address, isBiliing) => {
    e.stopPropagation();
    setEditMode(true);
    setData(address);
    setIsBillingAddress(isBiliing);
    setIsOpen(true);
  };

  useEffect(() => {
    const userShippingAdd = currentUser.addresses.filter(
      (add) => add.prefix === 'shipping'
    );
    setshippingAddress(userShippingAdd);
    const userBillingAdd = currentUser.addresses.filter(
      (add) => add.prefix === 'billing'
    );
    setbillingAddress(userBillingAdd);
  }, [isUpdated, isOpen]);

  const handleDelete = (e) => {
    e.preventDefault();
    dispatch(
      deleteUserAddress({
        userRefId: currentUser.refrence_id,
        addressId: data.id,
      })
    );
  };

  const handleAddNewAddress = (e) => {
    e.preventDefault();
    dispatch(
      addnewUserAddress({
        userRefId: currentUser.refrence_id,
        data,
        isBillingAddress,
      })
    );
  };

  const handleOpenAddnewAddress = (isBiliing) => {
    setIsBillingAddress(isBiliing);
    setIsOpen(true);
  };

  // Edit target address and update it with the edited data
  const handleEditAddress = (e) => {
    e.preventDefault();
    dispatch(
      updateUserAddress({
        userRefId: currentUser.refrence_id,
        data,
      })
    );
  };

  // Function to find and update current default address
  const hadleUpdateDefaultAddress = (address, isBilling) => {
    dispatch(
      updateUserAddress({
        userRefId: currentUser.refrence_id,
        data: {
          ...address,
          default_billing: true,
          default_shipping: !isBilling,
          default_billing: isBilling,
        },
      })
    );
  };

  useEffect(() => {
    if (!isOpen) {
      setData({});
      setEditMode(false);
      setIsBillingAddress(null);
    }
  }, [isOpen]);

  // makes the alert disapperas after 5 seconds in either cases error or success
  useEffect(() => {
    if (isUpdated || error.status) {
      setIsOpen(false);
      setTimeout(() => {
        dispatch(resetState());
      }, [5000]);
    }
  }, [isUpdated, error]);

  return (
    <div className="settingsAddress">
      <AddEditAddressModal
        setData={setData}
        data={data}
        edit={editMode}
        open={isOpen}
        handleClose={handleClose}
        editAddress={handleEditAddress}
        billing={isBillingAddress}
        deleteAddress={handleDelete}
        addAddress={handleAddNewAddress}
        isLoading={isLoading}
      />
      <div className="main-conatiner">
        <div className="wrapper">
          <DashboardNavigation />
          <div
            className={`right-div-settingsAddress ${
              isLoading ? 'disabled' : ''
            }`}
          >
            {(error.status || isUpdated) && (
              <Alert
                severity={error.status ? 'error' : 'success'}
                style={{
                  position: 'fixed',
                  bottom: '16px',
                  right: '16px',
                  zIndex: '50',
                }}
              >
                {error.status ? error.message : 'Address updated successfully'}
              </Alert>
            )}
            <p className="page-title">Address</p>
            <div className="address-wrapper">
              <span className="sect-title">Shipping address</span>
              <div className="address-section">
                {shippingAddress.length ? (
                  shippingAddress.map((address) => (
                    <div
                      key={address.id}
                      className={`address-box ${
                        address.default_shipping ? 'default' : ''
                      }`}
                      onClick={() => hadleUpdateDefaultAddress(address, false)}
                    >
                      <div className="circular-checkbox">
                        <DoneOutlinedIcon
                          style={{ fontSize: '13px', color: '#fff' }}
                        />
                      </div>
                      <div className="desc-box">
                        <div className="top">
                          <p>
                            {address.firstname} {address.lastname}
                          </p>
                          {address.default_shipping && (
                            <span className="badge">Default</span>
                          )}
                        </div>
                        <p className="add-street">
                          {[
                            address.street[0],
                            address.city,
                            address?.region.region,
                            address.postcode,
                            address.country_id,
                          ].join(', ')}
                        </p>
                      </div>
                      <IconButton
                        className="edit-bttn"
                        onClick={(e) => openEditAddress(e, address, false)}
                      >
                        <ModeEditOutlineOutlinedIcon />
                      </IconButton>
                    </div>
                  ))
                ) : (
                  <div className="empty-div">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="49"
                      height="48"
                      viewBox="0 0 49 48"
                      fill="none"
                    >
                      <path
                        d="M39.9284 17.1434C39.9284 27.4291 24.4999 39.4291 24.4999 39.4291C24.4999 39.4291 9.07129 27.4291 9.07129 17.1434C9.07129 8.73999 16.0964 1.71484 24.4999 1.71484C32.9033 1.71484 39.9284 8.73999 39.9284 17.1434Z"
                        stroke="#8F91A1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M24.4993 22.2857C27.3396 22.2857 29.6422 19.9832 29.6422 17.1429C29.6422 14.3025 27.3396 12 24.4993 12C21.659 12 19.3564 14.3025 19.3564 17.1429C19.3564 19.9832 21.659 22.2857 24.4993 22.2857Z"
                        stroke="#8F91A1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M38.4789 34.2871H41.6424L46.7853 46.2871H2.21387L7.35672 34.2871H10.5203"
                        stroke="#8F91A1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p>There is not a shipping address</p>
                  </div>
                )}
              </div>
            </div>
            <Button
              className="add-address-bttn"
              onClick={() => handleOpenAddnewAddress(false)}
            >
              <AddIcon style={{ fontSize: '18px' }} />
              <span> Add New Shipping Address</span>
            </Button>
            <Divider sx={{ width: '100%' }} />
            <div className="address-wrapper">
              <span className="sect-title">Billing address</span>
              <div className="address-section">
                {billingAddress.length ? (
                  billingAddress.map((address) => (
                    <div
                      key={address.id}
                      className={`address-box ${
                        address.default_billing ? 'default' : ''
                      }`}
                      onClick={() => hadleUpdateDefaultAddress(address, true)}
                    >
                      <div className="circular-checkbox">
                        <DoneOutlinedIcon
                          style={{ fontSize: '13px', color: '#fff' }}
                        />
                      </div>
                      <div className="desc-box">
                        <div className="top">
                          <p>
                            {address.firstname} {address.lastname}
                          </p>
                          {address.default_billing && (
                            <span className="badge">Default</span>
                          )}
                        </div>
                        <p className="add-street">
                          {[
                            address.street[0],
                            address.city,
                            address?.region.region,
                            address.postcode,
                            address.country_id,
                          ].join(', ')}
                        </p>
                      </div>
                      <IconButton
                        className="edit-bttn"
                        onClick={(e) => openEditAddress(e, address, true)}
                      >
                        <ModeEditOutlineOutlinedIcon />
                      </IconButton>
                    </div>
                  ))
                ) : (
                  <div className="empty-div">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="49"
                      height="48"
                      viewBox="0 0 49 48"
                      fill="none"
                    >
                      <path
                        d="M39.9284 17.1434C39.9284 27.4291 24.4999 39.4291 24.4999 39.4291C24.4999 39.4291 9.07129 27.4291 9.07129 17.1434C9.07129 8.73999 16.0964 1.71484 24.4999 1.71484C32.9033 1.71484 39.9284 8.73999 39.9284 17.1434Z"
                        stroke="#8F91A1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M24.4993 22.2857C27.3396 22.2857 29.6422 19.9832 29.6422 17.1429C29.6422 14.3025 27.3396 12 24.4993 12C21.659 12 19.3564 14.3025 19.3564 17.1429C19.3564 19.9832 21.659 22.2857 24.4993 22.2857Z"
                        stroke="#8F91A1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M38.4789 34.2871H41.6424L46.7853 46.2871H2.21387L7.35672 34.2871H10.5203"
                        stroke="#8F91A1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p>There is not a billing address</p>
                  </div>
                )}
              </div>
            </div>
            <Button
              className="add-address-bttn"
              onClick={() => handleOpenAddnewAddress(true)}
            >
              <AddIcon style={{ fontSize: '18px' }} />
              <span> Add New Billing Address</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsAddress;
