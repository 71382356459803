import {
  Button,
  IconButton,
  InputAdornment,
  InputBase,
  Skeleton,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
// import bannerImg from '../assets/bannerrsearch.png';
// import sampleProduct from '../assets/sampleProduct.png';
import { useNavigate, useParams } from 'react-router-dom';
import { publicRequest } from '../utils/ApiConfig';
// import ProductImage from './CartComponents/ProductImage';
import SearchIcon from '@mui/icons-material/Search';
import './SearchBarComponent.scss';
import { useSelector } from 'react-redux';

const SearchBarComponent = ({ short }) => {
  const scrollableDivRef = useRef(null); // Ref to the scrollable div
  const navigate = useNavigate();
  const [searchInView, setSearchInView] = useState(false);
  const [query, setQuery] = useState(' ');
  const [products, setProducts] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [getQuery, setGetQuery] = useState(false);
  const [end, setEnd] = useState(false);
  const [error, setError] = useState(false);
  const serachResultRef = useRef();
  const { id } = useParams();
  const { categories } = useSelector((state) => state.category);

  useEffect(() => {
    const fetchProductsByName = async () => {
      try {
        setisLoading(true);
        const response = await publicRequest.get(
          `/products?name=${query}&page=${page}&page_size=4`
        );
        const data = response.data;
        const items = data.products;
        if (items.length === 0) {
          setEnd(true);
        }
        if (getQuery) {
          setProducts(items);
        } else {
          setProducts((prev) => [...prev, ...items]);
        }
        setisLoading(false);
      } catch (err) {
        setisLoading(false);
        setError(true);
      }
    };
    if (searchInView) {
      fetchProductsByName();
    }
  }, [query, searchInView, page]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPage(1);
          setProducts([]);
          setSearchInView(false);
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(serachResultRef);

  // const handleNavigate = () => {
  //   navigate('/shop');
  //   setSearchInView(false);
  // };

  const handleSearch = () => {
    const isFound = categories.find((cate) => cate.id === parseInt(id));
    if (isFound) {
      navigate(`/shop/${id}/${isFound?.children_data[0]?.id}?search=${query}`);
    } else {
      const randomCat = categories[0];
      navigate(
        `/shop/${randomCat.id}/${randomCat?.children_data[0]?.id}?search=${query}`
      );
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && categories && categories?.length > 0) {
      handleSearch();
    }
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
    setGetQuery(true);
    setPage(1);
    setEnd(false);
  };

  const handleProductClick = (productId) => {
    setSearchInView(false);
    navigate(`/product/${productId}`);
  };

  const handleScroll = () => {
    const div = scrollableDivRef.current;
    if (div) {
      const { scrollTop, scrollHeight, clientHeight } = div;
      const isAtEnd = scrollTop + clientHeight >= scrollHeight - 5;
      if (isAtEnd && !isLoading && !end) {
        setPage((prev) => prev + 1);
        setGetQuery(false);
      }
    }
  };
  return (
    <div className="searchbarwrap" ref={serachResultRef}>
      <InputBase
        className="mui-register-password"
        type="text"
        onKeyDown={handleKeyPress}
        onFocus={() => setSearchInView(true)}
        onChange={handleChange}
        // disabled={isLoading}
        placeholder="Search among products"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              disabled={categories && categories?.length === 0}
              edge="end"
              aria-label="search button"
              onClick={handleSearch}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      {searchInView && (
        <div className={`search-result-div ${short ? 'short' : ''}`}>
          {/* <div className="top-banner-livesearch">
            <div className="img-wrapper">
              <img
                src={bannerImg}
                alt="banner-img"
                className="img-banner-search"
              />
            </div>
            <div className="text-wrapper">
              <div className="top">
                <span className="title">New Arrivals</span>
                <span className="subtext">Find your medicine</span>
              </div>
              <Button className="explore-search" onClick={handleNavigate}>
                Explore Now
              </Button>
            </div>
          </div> */}
          <div
            className="live-search-scroll-div"
            ref={scrollableDivRef}
            onScroll={handleScroll}
          >
            {
              products?.length > 0
                ? products.map((product, i) => (
                    <div
                      className="search-product-box"
                      key={product?.id}
                      onClick={() => handleProductClick(product?.id)}
                    >
                      <div className="img-wrapper">
                        <img
                          src={product?.images ? product?.images[0]?.url : ''}
                          alt={product?.name}
                        />
                        {/* // <ProductImage product={product} width={'110px'} /> */}
                      </div>
                      <span className="searchproduct-name">
                        {product?.name}
                      </span>
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
                            stroke="#616161"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.9996 13.9996L11.0996 11.0996"
                            stroke="#616161"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  ))
                : !isLoading && <span>No items found</span>

              // <Box style={{ padding: '20px 0px' }}>
              //   <CircularProgress color="success" style={{ opacity: 0.5 }} />
              //   <span style={{ display: 'block', width: '100%' }}>
              //     Loading...
              //   </span>
              // </Box>
            }
            {isLoading &&
              [0, 1, 2].map(() => (
                <Skeleton
                  style={{ minHeight: '75px', padding: '0px', margin: '0px' }}
                ></Skeleton>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBarComponent;
