import React from 'react';
import './SinglePrescriptiontable.scss';
import { useMediaQuery } from '@mui/material';

const SinglePrescriptionTable = ({ data }) => {
  const isMobile = useMediaQuery('(max-width:1100px)');

  return (
    <div className="singlePresscriptionTable">
      {data?.length ? (
        <table>
          <thead>
            <tr>
              <td>
                <p>Medication</p>
              </td>
              <td>
                <p>Final Price</p>
              </td>
              <td>
                <p> Pharmacy Notes</p>
              </td>
            </tr>
          </thead>
          <tbody>
            {data.map((presc) =>
              !isMobile ? (
                <tr key={presc._id}>
                  <td>
                    <p className="presc-name">{presc?.medication_name}</p>
                  </td>
                  <td>
                    <p className="presc-price">${presc?.price}</p>
                  </td>
                  <td>
                    <p className="presc-details">{presc?.dosage}</p>
                  </td>
                </tr>
              ) : (
                <tr key={presc._id}>
                  <td>
                    <div className="prescription-data-mobile">
                      <div className="medication-top">
                        <p className="medication-name">
                          {presc?.medication_name}
                        </p>
                        <p className="presc-price">${presc?.price}</p>
                      </div>
                      <p className="presc-details">{presc?.dosage}</p>
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      ) : (
        <div className="empty-single_prescription">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="49"
            viewBox="0 0 49 49"
            fill="none"
          >
            <g clipPath="url(#clip0_3358_90967)">
              <path
                d="M46.5368 3.92859H27.6797"
                stroke="#8F91A1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M46.5368 14.2144H27.6797"
                stroke="#8F91A1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M46.5368 24.5H27.6797"
                stroke="#8F91A1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M46.5368 45.0714H27.6797"
                stroke="#8F91A1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M46.5368 34.7858H27.6797"
                stroke="#8F91A1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.96484 5.64287V19.3572C1.96484 20.3039 2.73236 21.0714 3.67913 21.0714H17.3934C18.3402 21.0714 19.1077 20.3039 19.1077 19.3572V5.64287C19.1077 4.69611 18.3402 3.92859 17.3934 3.92859H3.67913C2.73236 3.92859 1.96484 4.69611 1.96484 5.64287Z"
                stroke="#8F91A1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.96484 29.6429V43.3572C1.96484 44.3038 2.73236 45.0714 3.67913 45.0714H17.3934C18.3402 45.0714 19.1077 44.3038 19.1077 43.3572V29.6429C19.1077 28.6961 18.3402 27.9286 17.3934 27.9286H3.67913C2.73236 27.9286 1.96484 28.6961 1.96484 29.6429Z"
                stroke="#8F91A1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_3358_90967">
                <rect
                  width="48"
                  height="48"
                  fill="white"
                  transform="translate(0.25 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          <span>
            Your prescription is currently being processed. Once the pharmacist
            approves your prescription, you will be able to view the ordered
            drugs here and proceed with the payment for your prescription.
          </span>
        </div>
      )}
    </div>
  );
};

export default SinglePrescriptionTable;
