import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { publicRequest } from '../utils/ApiConfig';

export const getAllCategories = createAsyncThunk(
  'category/getAllcategories',
  async () => {
    try {
      const categories = await publicRequest.get('/categories');
      return categories.data;
    } catch (err) {
      if (err.response.data) {
        throw new Error(err.response.data);
      } else {
        throw new Error('Failed fetching order');
      }
    }
  }
);

const categorySlice = createSlice({
  name: 'category',
  initialState: {
    isLoading: false,
    error: {
      status: false,
      message: null,
    },
    categories: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        const allCategories = action.payload;
        const filterOnlyEnabled = allCategories.filter(
          (categ) => categ.is_active
        );
        state.categories = filterOnlyEnabled;
        state.error = {
          status: false,
          message: null,
        };
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          status: true,
          message: action.error.message || 'Unable fetching orders',
        };
      });
  },
});

export const categoryReducer = categorySlice.reducer;
