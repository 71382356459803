import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Divider } from '@mui/material';
import productImg from '../../assets/productImg.png';
import { userRequest } from '../../utils/ApiConfig';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../../utils/callBack';
import { addTocart } from '../../redux/CartSlice';
import { useNavigate } from 'react-router-dom';

const RecentViewProducts = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state) => state.user.currentUser);
  const { cartLoading } = useSelector((state) => state.cart);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchRecentViewProducts = async () => {
      try {
        setIsLoading(true);
        const response = await userRequest.get(`/user/${user._id}/recentView`);
        const recentViewProducts = response.data;
        if (recentViewProducts) {
          setProducts(recentViewProducts.products);
        }
        setIsLoading(false);
      } catch (err) {
        // console.log(err);
      }
    };
    if (products.length === 0 && user) {
      fetchRecentViewProducts();
    }
  }, []);

  const handleAddToCart = async (e, productSku) => {
    e.stopPropagation();
    await dispatch(
      addTocart({
        magentoUserId: user.refrence_id,
        cartItem: {
          qty: 1,
          sku: productSku,
        },
      })
    );
  };

  return (
    <div className="product-boxes-wrapper">
      {!isLoading ? (
        products.map((product, index) => (
          <div className="product-box-recently" key={index}>
            <div className="img-wrapper">
              <img
                src={product?.images ? product?.images[0].url : ''}
                alt="product-img"
              />
            </div>
            <div className="bottom-reviewed-div">
              <div className="left">
                <p className="product-name">{product?.name}</p>
                <p className="product-details">
                  {formatDate(product?.created_at)}
                </p>
              </div>
              <div className="right">
                <p className="product-price">
                  $
                  {product?.type === 'configurable'
                    ? product?.price_info?.minimal_price
                    : product?.price}
                </p>
                <p className="product-stocking">In Stock</p>
              </div>
            </div>
            <Divider light sx={{ width: '100%' }} />
            <div className="quantity-product-box">
              {product.type !== 'configurable' ? (
                <Button
                  className="reorder-bttn"
                  onClick={(e) => handleAddToCart(e, product?.sku)}
                  disabled={cartLoading}
                >
                  Reorder
                </Button>
              ) : (
                <Button
                  className="reorder-bttn"
                  onClick={() => navigate(`/product/${product?.id}`)}
                  disabled={cartLoading}
                >
                  See Options
                </Button>
              )}
            </div>
          </div>
        ))
      ) : (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <CircularProgress color="success" />
          <span>Loading...</span>
        </Box>
      )}
    </div>
  );
};

export default RecentViewProducts;
