import React, { useEffect, useState } from 'react';
import { Container, useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { FreeMode, Navigation } from 'swiper/modules';
import './TestimonialsSwiper.scss';
import next from '../../assets/Button - Next slide.png';
import prev from '../../assets/Left Arrow.png';
import { Rating } from '@mui/material';

const TestimonialsSwiper = ({ testimonail, count }) => {
  const small = useMediaQuery('(max-width:600px)');
  const medium = useMediaQuery('(min-width:600px)');
  const large = useMediaQuery('(min-width:1200px)');
  const [loaded, setLoaded] = useState(false);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  useEffect(() => {
    if ((navigationNextRef, navigationPrevRef)) {
      setLoaded(true);
    }
  }, [navigationNextRef, navigationPrevRef]);

  return (
    <Container
      sx={{
        maxWidth: '1440px !important',
        paddingX: { xs: '0px !important', md: 'auto' },
      }}
    >
      <div className="TestimonialsSwiper">
        <div className="testimonails">
          {loaded && (
            <Swiper
              slidesPerView={large ? count : medium ? 2 : small && 1}
              spaceBetween={large ? 40 : medium ? 20 : small && 12}
              modules={[FreeMode, Navigation]}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }}
              className="mySwiper"
              freeMode={true}
              speed={400}
            >
              {testimonail.map((testimonail) => (
                <SwiperSlide>
                  <div className="box">
                    <p className="note">{testimonail.note}</p>
                    <div className="author">
                      <img src={testimonail.photo} alt={testimonail.author} />
                      <div className="name">
                        <div className="stars">
                          <Rating
                            color="success"
                            name="simple-controlled"
                            value={testimonail.stars}
                          />
                        </div>
                        <p>{testimonail.author}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
          <div className="buttons">
            <img src={prev} alt="arrow" ref={navigationPrevRef} />
            <img src={next} alt="arrow" ref={navigationNextRef} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TestimonialsSwiper;
