import React, { useState } from 'react';
import './InsurancePopup.scss';
import Modal from '@mui/material/Modal';
import {
  Alert,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Snackbar,
  TextField,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { deleteFile, getFileUrl } from '../../utils/callBack';
import { useSelector } from 'react-redux';

const InsurancePopup = ({
  open,
  handleClose,
  edit,
  data,
  setData,
  handleAdd,
  handleUpdate,
  handledelete,
  type,
}) => {
  const { isLoading } = useSelector((state) => state.documents);
  // handles uploading spinner
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadingTwo, setIsUploadingTwo] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  // handles name change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  // handles file upload and assign it to the state
  const handleFileUpload = async (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    if (name === 'attatchement_back_url') {
      setIsUploading(true);
    } else {
      setIsUploadingTwo(true);
    }
    const fileUrl = await getFileUrl(file);
    if (fileUrl) {
      setData({ ...data, [name]: fileUrl });
    } else {
      setUploadError(true);
    }
    if (name === 'attatchement_back_url') {
      setIsUploading(false);
    } else {
      setIsUploadingTwo(false);
    }
  };

  const handleDelete = async (file) => {
    const res = await deleteFile(file);
    if (res) {
      let newData;
      if (data.attatchement_back_url === file) {
        newData = { ...data, attatchement_back_url: false };
      } else {
        newData = { ...data, attatchement_front_url: false };
      }
      setData(newData);
    } else {
      return null;
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {type === 'insurance' ? (
        <div className="insurancePopupModal">
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={uploadError}
            autoHideDuration={6000}
            onClose={() => setUploadError(false)}
          >
            <Alert severity="error" variant="filled">
              One or more files are not supported only (jpg, png, jpeg, webp, )
            </Alert>
          </Snackbar>
          <div className="top-inusrance-div">
            <p>Add insurance</p>
            <IconButton
              style={{ width: '40px', height: '40px', fontSize: '40px' }}
              onClick={handleClose}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </div>
          <TextField
            value={data?.document_name || ''}
            label="Please enter the insurance name"
            variant="outlined"
            name="document_name"
            onChange={handleChange}
            fullWidth
            disabled={isUploading || isUploadingTwo}
          />

          <div className="img-container-upload">
            <div className="insuranceImgBox">
              <div className="img-wrapper">
                <div className="top-div">
                  <FileUploadOutlinedIcon style={{ color: '#219D50' }} />
                  <p>Upload front photo of the insurance card</p>
                </div>
                {!data?.attatchement_front_url ? (
                  <div className="bottom">
                    {isUploadingTwo ? (
                      <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                      </Box>
                    ) : (
                      <Button
                        component="label"
                        variant="contained"
                        className="upload-bttn"
                      >
                        Upload file
                        <input
                          type="file"
                          name="attatchement_front_url"
                          className="input-file"
                          onChange={handleFileUpload}
                          disabled={isUploading}
                        />
                      </Button>
                    )}
                    <span className="filename">No file chosen</span>
                  </div>
                ) : (
                  <div className="bottom">
                    <div
                      className="file-preview"
                      style={{
                        backgroundImage: `url(${
                          data?.attatchement_front_url || ''
                        })`,
                      }}
                    >
                      <div className="overlay-div">
                        <IconButton
                          className="deleteImg"
                          onClick={() =>
                            handleDelete(data?.attatchement_front_url)
                          }
                        >
                          <DeleteOutlinedIcon
                            fontSize="inherit"
                            style={{ color: '#F83B3F' }}
                          />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <span>
                Please ensure your uploaded insurance card is legible and valid.
                Accepted file formats: jpg, png, jpeg, webp
              </span>
            </div>
            <div className="insuranceImgBox">
              <div className="img-wrapper">
                <div className="top-div">
                  <FileUploadOutlinedIcon style={{ color: '#219D50' }} />
                  <p>Upload back photo of the insurance card</p>
                </div>
                {!data?.attatchement_back_url ? (
                  <div className="bottom">
                    {isUploading ? (
                      <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                      </Box>
                    ) : (
                      <Button
                        component="label"
                        variant="contained"
                        className="upload-bttn"
                      >
                        Upload file
                        <input
                          type="file"
                          name="attatchement_back_url"
                          className="input-file"
                          onChange={handleFileUpload}
                          disabled={isUploadingTwo}
                        />
                      </Button>
                    )}
                    <span className="filename">No file chosen</span>
                  </div>
                ) : (
                  <div className="bottom">
                    <div
                      className="file-preview"
                      style={{
                        backgroundImage: `url(${
                          data?.attatchement_back_url || ''
                        })`,
                      }}
                    >
                      <div className="overlay-div">
                        <IconButton
                          className="deleteImg"
                          onClick={() =>
                            handleDelete(data?.attatchement_back_url)
                          }
                        >
                          <DeleteOutlinedIcon
                            fontSize="inherit"
                            style={{ color: '#F83B3F' }}
                          />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <span>
                Please ensure your uploaded insurance card is legible and valid.
                Accepted file formats: jpg, png, jpeg, webp
              </span>
            </div>
          </div>
          <div className="bttns-wrapper">
            <Button
              className="add-insu"
              onClick={edit === false ? handleAdd : handleUpdate}
              disabled={
                !data?.document_name ||
                !data?.attatchement_back_url ||
                !data?.attatchement_front_url ||
                isLoading
              }
            >
              {edit === false ? 'Add' : 'update'} Insurance Information
            </Button>
            {edit !== false && (
              <Button className="delete-insu" onClick={handledelete}>
                Delete
              </Button>
            )}
            <Button className="cancel" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <div className="insurancePopupModal">
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={uploadError}
            autoHideDuration={6000}
            onClose={() => setUploadError(false)}
          >
            <Alert severity="error" variant="filled">
              One or more files are not supported only (jpg, png, jpeg, webp, )
            </Alert>
          </Snackbar>
          <div className="top-inusrance-div">
            <p>Add Photo ID</p>
            <IconButton
              style={{ width: '40px', height: '40px', fontSize: '40px' }}
              onClick={handleClose}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </div>
          <TextField
            value={data?.document_name}
            name="document_name"
            label="Please enter the ID name"
            variant="outlined"
            onChange={handleChange}
            disabled={isUploading || isUploadingTwo || isLoading}
            fullWidth
          />

          <div className="img-container-upload">
            <div className="insuranceImgBox">
              <div className="img-wrapper">
                <div className="top-div">
                  <FileUploadOutlinedIcon style={{ color: '#219D50' }} />
                  <p>Upload front photo of the ID</p>
                </div>
                {!data?.attatchement_front_url ? (
                  <div className="bottom">
                    {isUploadingTwo ? (
                      <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                      </Box>
                    ) : (
                      <Button
                        component="label"
                        variant="contained"
                        className="upload-bttn"
                        disabled={isUploading || isLoading}
                      >
                        Upload file
                        <input
                          type="file"
                          name="attatchement_front_url"
                          className="input-file"
                          onChange={handleFileUpload}
                        />
                      </Button>
                    )}
                    <span className="filename">
                      {isUploadingTwo ? 'File Uploading' : 'No file chosen'}
                    </span>
                  </div>
                ) : (
                  <div className="bottom">
                    <div
                      className="file-preview"
                      style={{
                        backgroundImage: `url(${
                          data?.attatchement_front_url || ''
                        })`,
                      }}
                    >
                      <div className="overlay-div">
                        <IconButton
                          className="deleteImg"
                          onClick={() =>
                            handleDelete(data?.attatchement_front_url)
                          }
                        >
                          <DeleteOutlinedIcon
                            fontSize="inherit"
                            style={{ color: '#F83B3F' }}
                          />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <span>
                Please ensure your uploaded ID is legible and valid. Accepted
                file formats: jpg, png, jpeg, webp
              </span>
            </div>
            <div className="insuranceImgBox">
              <div className="img-wrapper">
                <div className="top-div">
                  <FileUploadOutlinedIcon style={{ color: '#219D50' }} />
                  <p>Upload back photo of the ID</p>
                </div>
                {!data?.attatchement_back_url ? (
                  <div className="bottom">
                    {isUploading ? (
                      <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                      </Box>
                    ) : (
                      <Button
                        component="label"
                        variant="contained"
                        className="upload-bttn"
                        disabled={isUploadingTwo || isLoading}
                      >
                        Upload file
                        <input
                          type="file"
                          name="attatchement_back_url"
                          className="input-file"
                          onChange={handleFileUpload}
                        />
                      </Button>
                    )}
                    <span className="filename">
                      {isUploading ? 'Uploading File' : 'No file chosen'}
                    </span>
                  </div>
                ) : (
                  <div className="bottom">
                    <div
                      className="file-preview"
                      style={{
                        backgroundImage: `url(${
                          data?.attatchement_back_url || ''
                        })`,
                      }}
                    >
                      <div className="overlay-div">
                        <IconButton
                          className="deleteImg"
                          onClick={() =>
                            handleDelete(data?.attatchement_back_url)
                          }
                        >
                          <DeleteOutlinedIcon
                            fontSize="inherit"
                            style={{ color: '#F83B3F' }}
                          />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <span>
                Please ensure your uploaded ID is legible and valid. Accepted
                file formats: jpg, png, jpeg, webp
              </span>
            </div>
          </div>
          <div className="bttns-wrapper">
            <Button
              className="add-insu"
              onClick={edit === false ? handleAdd : handleUpdate}
              disabled={
                !data?.document_name ||
                !data?.attatchement_front_url ||
                !data?.attatchement_back_url ||
                isLoading
              }
            >
              {edit === false ? 'Add' : 'update'} Photo iD
            </Button>
            {edit !== false && (
              <Button className="delete-insu" onClick={handledelete}>
                Delete
              </Button>
            )}
            <Button className="cancel" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default InsurancePopup;
