import React, { useState } from 'react';
import './DashboardTable.scss';
import { Button, Divider, Pagination, useMediaQuery } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ReorderModal from './ReorderModal';
import { formatDate } from '../../utils/callBack';
import TableproductComponent from './TableProductComponent';
// import WriteNotePopup from './WriteNotePopup';

const DashboardTable = ({ data, perPage, type }) => {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  // const [openWriteNote, setOpenWriteNote] = useState(false);
  const isTablet = useMediaQuery('(max-width:1100px)');
  const isMobile = useMediaQuery('(max-width:920px)');
  const [orderItems, setOrderItems] = useState([]);
  const [orderId, setOrderId] = useState('');

  const handleReorderClick = (order) => {
    setOrderItems(order.items);
    setOrderId(order.increment_id);
    setOpen(true);
  };

  return (
    <div className="tableComponent-dashboard">
      {/* <WriteNotePopup
        open={openWriteNote}
        handleClose={() => setOpenWriteNote(false)}
      /> */}
      <ReorderModal
        setOpen={setOpen}
        open={open}
        items={orderItems}
        orderId={orderId}
      />{' '}
      <table>
        <thead>
          {type === 'Products' ||
          type === 'ProductsDetails' ||
          type === 'wishlistProducts' ? (
            <tr>
              <td>Product</td>
              <td>
                <p style={{ padding: '0px 32px' }}>Price</p>
              </td>
              <td>
                <p style={{ padding: '0px 32px' }}>
                  {type === 'Products' || type === 'wishlistProducts'
                    ? 'Status'
                    : 'Actions'}
                </p>
              </td>
              {(type === 'Products' || type === 'wishlistProducts') && (
                <td></td>
              )}
            </tr>
          ) : type === 'OrderDetails' ? (
            <tr>
              <td>Order Number</td>
              <td>
                <p style={{ padding: '0px 32px' }}>Ship to</p>
              </td>
              <td>
                <p style={{ padding: '0px 32px' }}>status</p>
              </td>
              <td>
                <p style={{ padding: '0px 32px' }}>Order Placed</p>
              </td>
              <td>
                <p style={{ padding: '0px 32px' }}>Total</p>
              </td>
              <td>
                <p style={{ padding: '0px 32px' }}>Action</p>
              </td>
            </tr>
          ) : (
            <tr>
              <td>Prescription Name</td>
              <td>
                <p>Type</p>
              </td>
              <td>
                <p>Status</p>
              </td>
              <td>
                <p>Actions</p>
              </td>
            </tr>
          )}
        </thead>
        <tbody>
          {data
            ?.slice(page === 1 ? 0 : (page - 1) * perPage, perPage * page)
            ?.map((product, i) =>
              type === 'Products' ||
              type === 'ProductsDetails' ||
              type === 'wishlistProducts' ? (
                <TableproductComponent
                  type={type}
                  isTablet={isTablet}
                  productData={product}
                />
              ) : type === 'OrderDetails' ? (
                !isMobile ? (
                  <tr key={i}>
                    <td>
                      <p className="bold-p">#{product?.increment_id}</p>
                    </td>
                    <td>
                      <p className="bold-p">
                        {product?.extension_attributes?.shipping_assignments[0]
                          ?.shipping?.address?.country_id +
                          ', ' +
                          product?.extension_attributes?.shipping_assignments[0]
                            ?.shipping?.address?.city}
                      </p>
                    </td>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          className={`status-box ${
                            i % 2 === 0 ? 'delivered' : 'cancelled'
                          }`}
                        >
                          {product?.status}
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className="bold-p">
                        {formatDate(product?.updated_at)}
                      </p>
                    </td>
                    <td>
                      <p className="bold-p">${product?.grand_total}</p>
                    </td>
                    <td>
                      <div className="button-div">
                        <Button
                          className="presc-details-bttn"
                          onClick={() =>
                            navigate(`/dashboard/order/${product?.entity_id}`)
                          }
                        >
                          <InsertDriveFileOutlinedIcon />
                          Details
                        </Button>
                        <Button
                          className="reorder-bttn"
                          onClick={() => handleReorderClick(product)}
                        >
                          Reorder
                        </Button>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>
                      <div className="order-detail-mobile">
                        <div className="top">
                          <p className="bold-p">#{product?.increment_id}</p>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              className={`status-box ${
                                i % 2 === 0 ? 'delivered' : 'cancelled'
                              }`}
                            >
                              {product?.status}
                            </div>
                          </div>
                        </div>
                        <div className="middle">
                          <p className="bold-p">
                            {' '}
                            {product?.extension_attributes
                              ?.shipping_assignments[0]?.shipping.address
                              .country_id +
                              ', ' +
                              product?.extension_attributes
                                ?.shipping_assignments[0]?.shipping.address
                                .city}
                          </p>
                          <Divider
                            orientation="vertical"
                            flexItem
                            style={{
                              borderColor: 'black',
                              height: '14px',
                              alignSelf: 'center',
                            }}
                          />
                          <p className="bold-p">
                            {formatDate(product?.created_at)}
                          </p>
                          <Divider
                            orientation="vertical"
                            flexItem
                            style={{
                              borderColor: 'black',
                              height: '14px',
                              alignSelf: 'center',
                            }}
                          />
                          <p className="price">${product?.grand_total}</p>
                        </div>
                        <div className="button-div">
                          <Button
                            className="presc-details-bttn"
                            onClick={() =>
                              navigate(`/dashboard/order/${product?.entity_id}`)
                            }
                          >
                            <InsertDriveFileOutlinedIcon />
                            Details
                          </Button>
                          <Button
                            className="reorder-bttn"
                            onClick={() => handleReorderClick(product)}
                          >
                            Reorder
                          </Button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              ) : !isTablet ? (
                <tr key={data._id}>
                  <td>
                    <p
                      className="bold-p"
                      style={{ textAlign: 'left', paddingLeft: '24px' }}
                    >
                      {product?.name}
                    </p>
                  </td>
                  <td>
                    <p className="pres-status">{product?.type}</p>
                  </td>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <div className={`status-box ${product?.status}`}>
                        {product?.status}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      className="button-div"
                      style={{ justifyContent: 'flex-start' }}
                    >
                      {/* <Button
                        className="presc-details-bttn"
                        onClick={() => setOpenWriteNote(true)}
                      >
                        <ModeEditOutlineOutlinedIcon />
                        Write Your Note
                      </Button> */}
                      <Link
                        className="presc-details-bttn"
                        to={`/dashboard/prescription/${product?._id}`}
                      >
                        <InsertDriveFileOutlinedIcon />
                        Details
                      </Link>
                    </div>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>
                    <div className="presc-data">
                      <div className="top">
                        <p
                          className="bold-p"
                          style={{ textAlign: 'left', paddingLeft: '24px' }}
                        >
                          {product?.name}
                        </p>
                        <div
                          style={{
                            display: 'flex',
                            maxHeight: '26px',
                          }}
                        >
                          <div className={`status-box ${product?.status}`}>
                            {product?.status}
                          </div>
                        </div>
                      </div>
                      <div
                        className="button-div"
                        style={{ justifyContent: 'flex-start' }}
                      >
                        {/* <Button
                          className="presc-details-bttn"
                          onClick={() => setOpenWriteNote(true)}
                        >
                          <ModeEditOutlineOutlinedIcon />
                          Write Your Note
                        </Button> */}
                        <Link
                          className="presc-details-bttn"
                          to={`/dashboard/prescription/${product?._id}`}
                        >
                          <InsertDriveFileOutlinedIcon />
                          Details
                        </Link>
                      </div>
                    </div>
                  </td>
                </tr>
              )
            )}
        </tbody>
      </table>
      {data?.length > perPage && (
        <Pagination
          style={{
            alignSelf: 'flex-end',
            marginTop: '24px',
          }}
          count={Math.ceil(data?.length / perPage)}
          variant="outlined"
          shape="rounded"
          onChange={(e, value) => setPage(value)}
        />
      )}
    </div>
  );
};

export default DashboardTable;
