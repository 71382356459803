import React from 'react';
import './Banner.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';
import { useMediaQuery } from '@mui/material';

const Banner = () => {
  const matches = useMediaQuery('(max-width:630px)');

  return (
    <div className="delivery-banner">
      {!matches ? (
        <div className="desktop-view">
          <p className="notice">
            Free Delivery for prescriptions within the GTA and for regular
            orders $50.00 and above.
          </p>
        </div>
      ) : (
        <div>
          <Swiper
            loop={true}
            slidesPerView={'auto'}
            spaceBetween={30}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
            }}
            freeMode={true}
            speed={6000}
            modules={[Autoplay, FreeMode]}
            className="mySwiper"
          >
            {[0, 1].map((swiper, index) => (
              <SwiperSlide key={index}>
                <span className="notice">
                  Free Delivery for prescriptions within the GTA and for regular
                  orders $50.00 and above.
                </span>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  );
};

export default Banner;
