import React, { useEffect } from 'react';
import './Wishlist.scss';
import DashboardNavigation from '../../../components/DashboardComponents/DashboardNavigation';
import { Alert, Button } from '@mui/material';
import DashboardTable from '../../../components/DashboardComponents/DashboardTable';
import { useDispatch, useSelector } from 'react-redux';
import { getUserWishlist } from '../../../redux/WishListSlice';
import { bulkAddTocart } from '../../../redux/CartSlice';
import { Link } from 'react-router-dom';

const Wishlist = () => {
  const { WishItems, loading, error } = useSelector((state) => state.wishlist);
  const { cartLoading } = useSelector((state) => state.cart);

  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchWishList = () => {
      dispatch(
        getUserWishlist({
          userId: user?._id,
        })
      );
    };
    fetchWishList();
  }, []);

  const hanldeBulkAddTocart = () => {
    dispatch(
      bulkAddTocart({
        magentoUserId: user.refrence_id,
        cartItems: WishItems,
      })
    );
  };

  return (
    <div className="Wishlist">
      {error?.status && (
        <Alert
          severity="error"
          style={{
            position: 'fixed',
            top: '16px',
            right: '16px',
            zIndex: 100,
          }}
        >
          {error?.message}
        </Alert>
      )}
      <div className="main-conatiner">
        <div className="wrapper">
          <DashboardNavigation />
          <div
            className={`right-div-wishlist ${
              loading || cartLoading ? 'disabled' : ''
            }`}
          >
            <div className="top">
              <p>My wishlist</p>
              {WishItems?.length > 0 && (
                <Button
                  className="add-all-bttn"
                  onClick={hanldeBulkAddTocart}
                  disabled={cartLoading}
                >
                  Add all to cart
                </Button>
              )}
            </div>
            {WishItems?.length > 0 ? (
              <DashboardTable
                data={WishItems}
                cartLoading={cartLoading}
                perPage={4}
                type={'wishlistProducts'}
              />
            ) : (
              <div className="textwrap">
                {' '}
                <h1 className="note">Your Whishlist is Empty</h1>
                <Link to="/shop" className="button">
                  Go to shop
                </Link>
              </div>
            )}

            {WishItems?.length > 0 && (
              <Button
                className="add-all-bttn-mobile"
                onClick={hanldeBulkAddTocart}
                disabled={cartLoading}
              >
                Add all to cart
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wishlist;
